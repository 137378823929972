/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import { useState } from "react";
import "../../styles/myprofile.css";
import CloseIcon from "../../images/CloseIcon.svg";
import axios from "axios";
import Smartphone from "../../images/AboutLanding/Smartphone 2.svg";
import Location from "../../images/solar_map-point-bold-duotone.svg";
import Mail from "../../images/MailIcon.svg";
import Gender from "../../images/GenIcon.svg";
import { Dialog } from "@mui/material";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { Amplify } from "aws-amplify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import star from "../../images/icons/star.svg";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});
const Wrapper = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;
const Name = styled.div`
  color: #8653b6;
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 10px 0;
`;
const AssesHeading = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;
const Title = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  text-decoration: underline;
  text-underline-offset: 5px;
  margin-top: 20px;
`;
const Questions = styled.div`
  color: #4f4953;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 10px 0;
  background: #f6f6f6;
  border-radius: 12px;
  padding: 5px;
`;

const AtsProfile = ({ onClose, data, jobApply, selectedCompany }) => {
  const [assesment, setAssesment] = useState(false);
  const handleClick = () => {
    setAssesment(true);
  };
  const history = useHistory();
  const questionList = data && data.jobTitleData?.questionList;
  const sortedQuestionList = questionList?.sort((a, b) => {
    return b.isStarred - a.isStarred;
  });

  const [detail, setDetail] = useState();
  const getAllDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${data.applicant?.userId}`
      )
      .then((res) => {
        setDetail(res.data?.aboutOne);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClicks = () => {
    history.push({
      pathname: `/portal/other-profile/${data.applicant.userId}`,
      state: { form: selectedCompany },
    });
  };
  const dob = new Date(detail?.dob);
  const currentDate = new Date();

  const timeDiff = currentDate.getTime() - dob.getTime();

  const age = Math.floor(timeDiff / (1000 * 3600 * 24 * 365.25)); // Considering leap years

  return (
    <div>
      <ToastContainer />
      <Dialog
        open={assesment}
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div className="mTitle">View Assesment</div>
          <img src={CloseIcon} alt="img" onClick={() => setAssesment(false)} />
        </div>
        <div className="modalBody">
          <AssesHeading>{`${data.applicant.firstName}'s Assessment`}</AssesHeading>
          {sortedQuestionList && sortedQuestionList.length > 0 ? (
            // eslint-disable-next-line array-callback-return

            sortedQuestionList.map((question, index) => {
              const userAnswers = question.answers.filter(
                (answer) => answer.userId === data.applicant.userId
              );

              if (userAnswers.length > 0) {
                const lastUserAnswer = userAnswers[userAnswers.length - 1];
                return (
                  <>
                    {index === 0 ? <Title>Starred Questions</Title> : ""}
                    <Questions key={question.questionId}>
                      {/* Displaying Starred Questions first */}
                      {question.isStarred && (
                        <>
                          <Questions style={{ fontweight: "normal" }}>
                            <p>{`${index + 1}. ${question.questionName}`}</p>
                            <p>
                              Ans:
                              {`${
                                lastUserAnswer.answer || "No answer provided"
                              }`}
                            </p>
                          </Questions>
                        </>
                      )}
                      {/* Displaying remaining questions */}
                      {!question.isStarred && (
                        <>
                          index===0 && (<Title>Genaral Questions</Title>)
                          <Questions style={{ fontweight: "normal" }}>
                            <p>{`${index + 1}. ${question.questionName}`}</p>
                            <p style={{ marginTop: "-15px" }}>{`${
                              lastUserAnswer.answer || "No answer provided"
                            }`}</p>
                          </Questions>
                        </>
                      )}
                    </Questions>
                  </>
                );
              }
            })
          ) : (
            <p>No Question is Provided</p>
          )}
        </div>
      </Dialog>

      <div className="modalBody ats-app-det-content">
        <div className="ats-pro-btn-wrapper">
          <img
            src={detail?.avatar}
            alt="img"
            className="image-ats"
          />
          <div className="ats-btn-wrapper">
            <button className="button2" onClick={handleClicks}>
              View Profile
            </button>
          </div>
        </div>
        <div>
          <div>
            <div style={{ display: "flex", gap: "30px" }}>
              <div>
                <Name>{`${data.applicant?.firstName} ${data.applicant?.lastName}`}</Name>
                {detail?.totalAverageRating !== undefined &&
                detail?.totalAverageRating !== null &&
                detail?.totalAverageRating !== 0 &&
                detail?.totalAverageRating !== 0.5 ? (
                  <div>
                    <img src={star} className="star-people" alt="" />
                    <span className="star-average">
                      {detail.totalAverageRating.toFixed(1)}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <Wrapper>
              <div>
                <img src={Gender} alt="img" />
              </div>
              <div style={{ marginTop: "5px" }}>
                {detail?.gender},{age}{" "}
              </div>
            </Wrapper>

            <Wrapper>
              <div>
                <img src={Smartphone} alt="img" />
              </div>
              <div style={{ marginTop: "5px" }}>{detail?.phoneNumber}</div>
            </Wrapper>
            <Wrapper>
              <div>
                <img src={Mail} alt="img" />
              </div>
              <div style={{ marginTop: "5px" }}>{detail?.email}</div>
            </Wrapper>
          </div>
          <Wrapper>
            <div>
              <img src={Location} alt="img" />
            </div>
            <div
              style={{ marginTop: "5px" }}
            >{`${detail?.primaryAddress?.city} ${detail?.primaryAddress?.state} ${detail?.primaryAddress?.country}`}</div>
          </Wrapper>
        </div>
      </div>
      <div className="modalFooter">
        {sortedQuestionList.some(
          (question) => question.questionName.trim() !== ""
        ) ? (
          <div className="ats-app-btn-wrapper">
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <a href="#">View Assessment </a>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="ats-app-btn-wrapper">
          <div>
            <a href={data.applicant?.resume} target="_blank" rel="noreferrer">
              View Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtsProfile;
