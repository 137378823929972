import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";
import backArrow from "../images/arrow-box.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiUser } from "react-icons/ci";
import styled from "styled-components";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Amplify, Auth } from "aws-amplify";
import Common from "../utils/common";
import PassHint from "../images/PassHint.svg";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});
const HeaderLayout = styled.div``;

const FormContainer = styled.div``;
const ChangePassword = styled.h5``;

const Text = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button5 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const OTPChange = ({ props }) => {
  const [user, setUsers] = useState("");
  const [success4, setSuccess4] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  function onSubmit() {
    setSuccess4(true);
  }
  const [email, setEmail] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [valid, setValid] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState(null);
  const [count, setCount] = useState(5);
  const [disable, setDisable] = useState(true);
  const [message, setMessage] = useState(null);

  const location = useLocation();
  useEffect(() => {
    const { email } = location.state;
    setEmail(email);
  }, [location.state]);

  const [otp, setOtp] = useState(["", "", "", ""]);

  const inputRefs = useRef(otp.map(() => React.createRef()));

  const handleChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);
    setValid(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setValid(true);
    } else {
      setValid(false);
    }

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };

  const handleEnable = (event) => {
    event.preventDefault();
    setMinutes(1);
    setSeconds(0);
  };

  console.log(props);

  const resendOtp = async (e) => {
    e.preventDefault();
    setDisable(true);
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        email: email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resendPasswordOtp`,
        data
      );
      if (response.status === 200) {
        // setSpiner(false);
        handleEnable(e);
        toast.success("OTP Sent Successfully...!");
        // handleShowToaster();
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };
  const history = useHistory();

  const restartTimer = () => {
    setMinutes(15);
    setSeconds(0);
    setErrors(null); // Clear the error message
    setCount(5); // Reset the attempts
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if (otp.join("").length !== 4) {
      setErrors("Invalid OTP. Please enter a 4-digit OTP.");
    } else {
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/reset-password`, {
          otp: otp.join(""),
          email: email,
        })
        .then((response) => {
          if (response.data.success) {
            setValid(false);
            setMessage(response.data.message);
            setErrors(null); // Clear any previous error messages
            setCount(4); // Reset attempts on successful verification
          } else if (response.data.verified) {
            setVerify(true);
            setMessage(response.data.message);
            setErrors(null);
            setDisable(false);
          } else {
            if (count > 1) {
              setCount(count - 1);
              setErrors("Incorrect OTP!");
            } else {
              setErrors("Maximum attempts reached. Please wait for 1 minute.");
              setValid(false);
              setCount(1); // Set attempts to 1 to display the remaining attempts message
              restartTimer();
            }
          }
        })
        .catch((err) => setErrors(err.error));
    }
  };
  const [openSetting, setOpenSetting] = useState(false);

  // useEffect(() => {
  //   setMinutes(1);
  //   setSeconds(0);
  //   setErrors(null); // Clear the error message
  //   setCount(5); // Reset the attempts
  // },[]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  const Back = () => {
    history.push({
      pathname: "./forget-password",
      state: { email: email },
    });
  };
  const searchedKey=()=>{}
  return (
    <>
      <ToastContainer />
      <HeaderLayout>
        {/* Start of Mobile Header  */}
        <MobileHeader onlyLogo={true} />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"OTP Verification"}
          searchedKey={searchedKey}

          isSearchActive={false}
          backbtn={true}
          back={Back}
        />
        {/* End of desktop Header  */}
      </HeaderLayout>
      {/* <MainHeader>
          <img
            src={Back}
            alt="img"
            onClick={() => history.push({
              pathname:"/portal/forget-password",
              state:{email:email},
            })}
          />

        </MainHeader> */}

      <div className="p-15 bg-m-white in-content-wrapper">
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header o-mobile">
              {/* <LuArrowLeftSquare
              size={30}
              style={{ marginTop: "2px" }}
              onClick={() => history.push("/")}
            /> */}
              <div className="arrow-box">
                <Link
                  to={{
                    pathname: "/portal/forget-password",
                   
                  }}
                ></Link>
                <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
              </div>
              <h4 className="textpass font-face-gm mb-0">
                Change Your Password Here
              </h4>
            </div>
            <div className="cp-wrapper">
              <h4 className="cp-header o-desk">OTP Verification</h4>
              <FormContainer className="cp-body">
                <div>
                  <ChangePassword className="textpass font-face-gm">
                    Enter the OTP sent {`${email}`}
                  </ChangePassword>

                  <div style={{ textAlign: "center" }}>
                    {/* <div className="verify"  style={{ marginTop:"0px",gap: "0" }}> */}
                    <div className="otp-group">
                      {otp.map((digit, idx) => (
                        <input
                          key={idx}
                          type="text"
                          inputMode="numeric"
                          autoComplete="one-time-code"
                          pattern="\d{1}"
                          className="otp-inputs"
                          value={digit}
                          id={`otp-input-${idx}`}
                          ref={inputRefs.current[idx]}
                          onChange={(e) => handleChange(e, idx)}
                          onPaste={(e) => handlePaste(e, idx)}
                          // disabled={disableFields}
                        />
                      ))}
                    </div>

                    <div>
                      <div style={{ position: "relative" }}>
                        {disable ? (
                          <>
                            {seconds > 0 || minutes > 0 ? (
                              <p
                                style={{
                                  textAlign: "left",
                                  color: "#362A41",
                                  fontSize: "14px",
                                  fontweight: "normal",
                                }}
                                className="mt-2"
                              >
                                Time Remaining:{" "}
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </p>
                            ) : (
                              <p
                                style={{
                                  position: "relative",
                                }}
                                className="mt-5"
                              >
                                Didn't received the code yet?{" "}
                                <span
                                  style={{
                                    color: "rgba(134, 82, 182, 1)",
                                    fontweight: "normal",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={resendOtp}
                                >
                                  Resend
                                </span>
                              </p>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {verify ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "14px",
                            color: "#4F4953",
                            margin:
                              seconds || minutes > 0
                                ? "-55px 75px 0 0"
                                : "-95px 75px 0 0",
                          }}
                        >
                          <div
                            className="f12"
                            style={{ margin: "40px 0 0 10px" }}
                          >
                            {errors}
                          </div>
                        </div>
                      )}
                    </div>
                    {valid && !verify && (
                      <div>
                        <button
                          className="verify-text"
                          onClick={verifyOtp}
                          disabled={!valid}
                          style={{
                            opacity: valid ? "100%" : "50%",
                            cursor: "pointer",
                            color: "#362A41",
                            fontSize: "14px",
                            textDecoration: "underline",
                          }}
                        >
                          Verify
                        </button>
                      </div>
                    )}
                    {count === 1 && (
                      <div id="message8">
                        <div
                          style={{
                            position: "absolute",
                            left: "-15px",
                            top: "10px",
                          }}
                        >
                          <img src={PassHint} alt="img" />
                        </div>

                        <div style={{ marginTop: "5px" }}>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div className="check"></div>
                            <p className="valid">{count} attempt left</p>
                          </div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div className="check"></div>
                            <p className="valid" style={{ textAlign: "left" }}>
                              After this attempt, you’ll have to wait for 15
                              minutes
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {verify ? (
                    <p
                      style={{
                        color: "#8DB600",
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      {message}
                    </p>
                  ) : null}
                  <div className="container-login100-form-btn d-block  m-b-20">
                    <Link
                      to={{
                        pathname: "/portal/new-password",
                        email: email,
                      }}
                    >
                      <button
                        className="login100-form-btn"
                        type="submit"
                        disabled={!verify}
                        style={{
                          opacity: verify ? "100%" : "50%",
                          marginTop: "35px",
                        }}
                      >
                        Next
                      </button>
                    </Link>
                  </div>
                </div>
              </FormContainer>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success4}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want logout?</Text>

          <DateWrapperButton>
            <Button5 onClick={() => setSuccess4(false)}>No</Button5>
            {user ? (
              <Button3
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button3>
            ) : (
              <Button3 onClick={logout}>Yes</Button3>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OTPChange;
