import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { components } from "react-select";
import axios from "axios";
import Common from "../../utils/common";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { getYear } from "date-fns";
import CreatableSelect from "react-select/creatable";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import CloseIcon from "../../images/CloseIcon.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";

const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;
const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    color: "rgba(198, 202, 210)",
    fontweight: "normal",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

const OnlineHead = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin-top: 30px;
`;
const OnlineContent = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  margin-top: 5px;
`;
const ProjectModal = ({ onClose, courId }) => {
  const [closemodal, setCloseModal] = useState(false);
  const [popupopen, setPopupOpen] = useState(false);
  const [course, setCourse] = useState({
    online: false,
    course: "",
    organisation: "",
    startDate: "",
    endDate: "",
    issueDate: "",
    validity: "",
  });
  const [error, setError] = useState({
    online: "",
    course: "",
    organisation: "",
    startDate: "",
    endDate: "",
    issueDate: "",
    validity: "",
    skill: "",
  });
  const [skill, setSkill] = useState([]);
  const [dirty, setDirty] = useState(false);
  const handleSkillChange = (selectedOptions) => {
    // Format selected skills to match the desired structure
    const formattedSkills = selectedOptions.map((option) => ({
      skillIds: option.skillIds,
      label: option.label,
      value: option.value,
      ratings: option.ratings,
      averageSkillRating: option.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    setSkill(formattedSkills);
    setSkillCreate("");
    setOptionsOne([]);
    setDirty(true); // Not sure where setDirty comes from in your code
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCourse((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    setDirty(true);
  };
  const DiscardChanges = () => {
    setCourse({
      ...course,
      online: "",
      course: "",
      organisation: "",
      startDate: "",
      endDate: "",
      issueDate: "",
      validity: "",
    });

    setSkill("");

    onClose();
  };

  const CloseModalClear = () => {
    if (!dirty) {
      onClose(); // No modifications, directly close the modal
    } else {
      setCloseModal(true); // Show the discard popup
      setPopupOpen(true);
    }
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "course":
          // const regex = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] =
              "Please enter the name of the course / certification";
            // } else if (!regex) {
            //   stateObj[name] = "Please enter course.";
          }
          break;

        case "organisation":
          // const regex1 = /^[a-zA-Z\s]+$/.test(value);
          if (value === "") {
            stateObj[name] =
              "Please enter the name of the organisation issuing the course / certification  ";
          }
          // if (!regex1 && value !== "") {
          //   stateObj[name] = "Please enter the name of the organization issuing the course / certification  .";
          // }
          break;

        case "startDate":
          //   const regex2 = /^[a-zA-Z]+$/.test(value);
          // if (!value) {
          //   stateObj[name] = "Please Enter Start Date";
          // }
          break;

        case "endDate":
          // if (course.endDate === "") {
          //   stateObj[name] = "Please Enter End Date";
          // } else
          if (course.endDate < course.startDate) {
            stateObj[name] = "End date can't be earlier than start date";
          }
          break;
        case "issueDate":
          //   const regex2 = /^[a-zA-Z]+$/.test(value);
          if (!value) {
            stateObj[name] = "Please select the issue date  ";
          } else if (course.endDate > course.issueDate) {
            stateObj[name] = "Issue date can't be earlier than end date";
          }

          break;

        case "validity":
          if (course.validity === "") {
            stateObj[name] = "Please select the validity date";
          } else if (course.validity < course.issueDate) {
            stateObj[name] = "Validity date can't be earlier than issue date";
          }

          break;

        default:
          break;
      }
      return stateObj;
    });
  };
  const [companyData, setCompanyData] = useState([]);
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company`)
      .then((response) => {
        const companyValue = response.data.company.map((e) => ({
          label: e.companyName,
          value: e.companyName,
          website: e.website,
          comID: e.companyId,
          companyLogo: e.companyLogo,
        }));

        setCompanyData(companyValue);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    GetAllCompany();
  }, []);
  const changeonline = (value) => {
    setCourse({ ...course, online: value === "true" ? true : false });
    setDirty(value === "true" ? true : false);
  };
  useEffect(() => {
    if (courId) {
      prefillValue(courId);
    } else {
      changeonline("false");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courId]);

  const prefillValue = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-certi-detail/${id}`)
      .then((response) => {
        console.log(response, "response");

        if (
          response.data.certiOne.skill &&
          response.data.certiOne.skill.length > 0
        ) {
          setSkill(response.data.certiOne.skill);
        }
        const data = response.data.certiOne;
        setCourse({
          ...course,
          id: data._id,
          online: data.online,
          course: data.course,
          userId: data.userId,
          startDate: data.startDate,
          endDate: data.endDate,
          issueDate: data.issueDate,
          validity: data.validity,
          organisation: data.organisation,
        });
      })
      .catch((err) => err.error);
  };
  const [loading, setIsLoading] = useState(false);
  const courseCreate = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors = {};

    if (!course.course)
      errors.course = "Please enter the name of the course / certification";
    if (!course.organisation)
      errors.organisation =
        "Please enter the name of the organisation issuing the course / certification  ";
    if (!course.issueDate) errors.issueDate = "Please select the issue date  ";
    if (!course.validity) errors.validity = "Please select validity date";
    if (course.endDate <= course.startDate)
      errors.endDate = "End Date Should be Greater than Start Date";
    if (course.issueDate <= course.endDate)
      errors.endDate = "Issue date Should be Greater than end Date";
    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          online: course.online,
          course: course.course,
          organisation: course.organisation,
          startDate: course.startDate,
          endDate: course.endDate,
          issueDate: course.issueDate,
          validity: course.validity,
          skill: skill,
          deleteFlag: "N",
          userId: Common.loggedIn(),
        };

        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/certi-create`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form");
          setCourse({
            ...course,
            online: "",
            course: "",
            organisation: "",
            startDate: "",
            endDate: "",
            issueDate: "",
            validity: "",
          });
          onClose(); // Close the form only if the submission is successful
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(errors);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };
  const courseUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors = {};

    if (!course.course)
      errors.course = "Please enter the name of the course / certification";
    if (!course.organisation)
      errors.organisation =
        "Please enter the name of the organization issuing the course / certification  ";
    if (!course.issueDate) errors.issueDate = "Please select the issue date  ";
    if (!course.validity) errors.validity = "Please select validity date";
    if (course.endDate <= course.startDate)
      errors.endDate = "End Date Should be Greater than Start Date";
    if (course.issueDate <= course.endDate)
      errors.endDate = "Issue date Should be Greater than end Date";
    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          online: course.online,
          course: course.course,
          organisation: course.organisation,
          startDate: course.startDate,
          endDate: course.endDate,
          issueDate: course.issueDate,
          validity: course.validity,
          skill: skill,
          deleteFlag: "N",
          userId: Common.loggedIn(),
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-certi/${courId}`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form");
          setCourse({
            ...course,
            online: "",
            course: "",
            organisation: "",
            startDate: "",
            endDate: "",
            issueDate: "",
            validity: "",
          });
          onClose(); // Close the form only if the submission is successful
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError(errors);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };

  const handleOrganisationChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";

    setCourse({ ...course, organisation: value });
    setDirty(true);
  };
  const [skillData, setSkillData] = useState([]);

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        console.log(JSON.stringify(response), "skilldata");
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const optionsOne = skillData.map((skill) => ({
    value: skill,
    label: skill,
  }));
  console.log(optionsOne, "skillfdsafssdvsdfdvdsfvdfvdf");
  useEffect(() => {
    getSkill();
  }, []);
  const [skillcreate, setSkillCreate] = useState("");
  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");
  const [optionData, setOptionsOne] = useState([]);

  const createSkill = () => {
    const payload = {
      name: skillcreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "skilldata");
        setSkillCreate("");
        setOptionsOne([]);
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChange(e);
  };

  const [orgData, setOrgsData] = useState([]);
  const [orgTool, setOrgOption] = useState([]);

  const [orgCreate, setOrgCreate] = useState("");
  const getOrgSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/orgextra`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          label: e.label,
          value: e.value,
        }));

        setOrgsData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const createOrgSkill = () => {
    const payload = {
      name: orgCreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/org-create`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "skilldata");
        setOrgCreate("");
        getOrgSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOrgCreatable = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__) {
      await createOrgSkill();
    }
    handleOrganisationChange(selectedOption);
  };
  useEffect(() => {
    getSkill();
    getOrgSkill();
  }, []);
  useEffect(() => {
    setOrgOption(
      orgData.map((e) => ({
        skillIds: e.skillId,
        label: e.label,
        value: e.value,
      }))
    );
  }, [orgData]);
  const [mergedData, setMergedData] = useState([]);
  useEffect(() => {
    const mergedData = [...companyData, ...orgTool];
    // Do something with mergedData if needed
    setMergedData(mergedData);
  }, [companyData, orgTool]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (popupopen === false) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [popupopen]);

  return (
    <div className="">
      {popupopen ? (
        <Dialog
          open={closemodal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              top: "10px",
              transform: "translateY(0%)",
              position: "absolute",
              backgroundColor: "#FFDAE0",
              padding: "5px 8px",
              height: "fit-content",
            },
          }}
          halfWidth
          maxWidth="md"
          className="def"
        >
          <DialogContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              left: "5px",
            }}
          >
            <IconWrapper>
              <img src={DeleteImg} alt="img" />
            </IconWrapper>
            <JobTitle>
              Are you sure you want to discard the changes you made?
            </JobTitle>
            <DialogActions>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  gap: "10px",
                  marginLeft: "10px",
                }}
              >
                <Button4
                  onClick={() => {
                    setCloseModal(false);
                    setPopupOpen(false);
                  }}
                >
                  Go Back
                </Button4>
                <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div className="modalHeader">
            <h1 className="mTitle">Add Course / Certification</h1>
            <img src={CloseIcon} alt="img" onClick={CloseModalClear} />
          </div>
          <div className="modalBody mBody">
            <div className="Edu-Popup-wrapper course-head">
              {course.online !== undefined && course.online.length > 0 ? (
                <div>
                  <input
                    type="checkbox"
                    defaultChecked={course.online === false}
                    name="online"
                    onClick={() => {
                      if (course.online === true) {
                        changeonline("false");
                      } else {
                        changeonline("true");
                      }
                    }}
                  />
                  <h4>Online</h4>
                  <h4>
                    Select if the course / certification was pursued online
                  </h4>
                </div>
              ) : (
                <div className="Edu-Popup-wrapper">
                  <div>
                    <OnlineHead>Online</OnlineHead>
                    <OnlineContent>
                      Select if the course / certification was pursued online
                    </OnlineContent>
                  </div>

                  <input
                    type="checkbox"
                    checked={course.online === true}
                    name="online"
                    className="location-suit"
                    style={{
                      marginTop: "35px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      if (course.online === true) {
                        changeonline("false");
                      } else {
                        changeonline("true");
                      }
                    }}
                  />
                </div>
              )}
              <div className="input-space">
                <label className="label-name">
                  Course / Certification Title<span>*</span>
                </label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Type / Search"
                    aria-label="Type / Search"
                    name="course"
                    onBlur={validateInput}
                    value={course.course}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    className="footer-header mb-2"
                  />
                </InputGroup>
                {error.course && (
                  <span className="text-danger f12">{error.course}</span>
                )}
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="input-space">
                <label className="label-name">
                  Issuing Organisation<span>*</span>
                </label>
                <div>
                  <CreatableSelect
                    className="footer-header mb-2"
                    components={{ Placeholder, DropdownIndicator }}
                    styles={customStyle}
                    name="organisation"
                    placeholder="Type / Search"
                    onChange={(e) => handleOrgCreatable(e)}
                    onInputChange={(e) => setOrgCreate(e)}
                    options={mergedData}
                    value={mergedData.find(
                      (option) => option.value === course.organisation
                    )}
                    onBlur={() =>
                      validateInput({
                        target: {
                          name: "organisation",
                          value: course.organisation,
                        },
                      })
                    }
                  />
                </div>

                {error.organisation && (
                  <span className="text-danger f12">{error.organisation}</span>
                )}
              </div>
              <div className="myprofile-content-head ">
                <div className="input-space">
                  <label className="label-name">Start Date</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      className="footer-header"
                      placeholder="DD MMM"
                      aria-label="YYYY"
                      name="startDate"
                      max={`${getYear(new Date())}-12`}
                      min="1990-01"
                      value={course.startDate}
                      onBlur={validateInput}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  {error.startDate && (
                    <div>
                      <span className="text-danger">{error.startDate}</span>
                    </div>
                  )}
                </div>

                <div className="input-space">
                  <label className="label-name">End Date</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      className="footer-header"
                      placeholder="YYYY"
                      onBlur={validateInput}
                      aria-label="YYYY"
                      value={course.endDate}
                      max={`${getYear(new Date())}-12`}
                      min="1990-01"
                      name="endDate"
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  {error.endDate && (
                    <div>
                      <span className="f12">{error.endDate}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="Edu-Popup-wrapper">
              <div className="myprofile-content-head ">
                <div className="input-space">
                  <label className="label-name">
                    Issue Date<span>*</span>
                  </label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="date"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      onBlur={validateInput}
                      value={course.issueDate}
                      name="issueDate"
                      max={`${getYear(new Date())}-12`}
                      min="1990-01"
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      className="footer-header"
                    />
                  </InputGroup>
                  {error.issueDate && (
                    <div>
                      <span className="f12">{error.issueDate}</span>
                    </div>
                  )}
                </div>

                <div className="input-space">
                  <label className="label-name">
                    Validity<span>*</span>
                  </label>
                  <InputGroup className="mb-2">
                    <Form.Control
                      type="date"
                      className="footer-header"
                      placeholder="YYYY"
                      aria-label="YYYY"
                      name="validity"
                      value={course.validity}
                      onBlur={validateInput}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  {error.validity && (
                    <div>
                      <span className="text-danger f12">{error.validity}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-space">
                <label className="label-name">Skills</label>
                <div>
                  <CreatableSelect
                    isMulti
                    className="footer-header"
                    menuPlacement="top"
                    components={{ Placeholder, DropdownIndicator }}
                    styles={customStyle}
                    name="skill"
                    noOptionsMessage={() => null}
                    isValidNewOption={() => optionData.length}
                    placeholder="Type / Select"
                    onChange={(e) => handleCreatable(e)}
                    onInputChange={(e) => setSkillCreate(e)}
                    options={optionData}
                    value={skill}
                  />
                </div>
                {error.skill && (
                  <span className="text-danger">{error.skill}</span>
                )}
              </div>
            </div>
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={CloseModalClear}>
              Cancel
            </button>
            {courId ? (
              <button className="button3" onClick={(e) => courseUpdate(e)}>
                {loading ? <Spinner /> : "Update"}
              </button>
            ) : (
              <button className="button3" onClick={(e) => courseCreate(e)}>
                {loading ? <Spinner /> : "Save"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectModal;
