import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Auth } from "aws-amplify";
import "./organisation.css";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCreditCard2Front } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import styled from "styled-components";
import axios from "axios";
import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Settings from "../settings";
import AddIcon from "../../images/add-bg.svg";
import { Spinner } from "react-bootstrap";
import DesktopHeader from "../desktopHeader";
const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;

const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const CompanyWrapper = styled.div`
  width: calc(100% - 226px);
  // margin-left: 180px;
  margin-top: 124px;
  padding: 20px;
  padding-top: 120px;
  display: flex;
  gap: 18px;
`;

const CreateNew = styled.div`
  width: 100%;
  max-width: 480px;
  background: #ececef;
  padding: 53px 104px 53px 103px;
  border-radius: 16px;
  display: flex;
  justify-content: space-around;
`;

const CreateNewText = styled.div`
  font-size: 18px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #362a41;
  margin: auto;
`;
const Organisation = () => {
  const [company, setCompany] = useState([]);
  const [institute, setInstitute] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [adminList, setAdminList] = useState([]);

  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setSuccess(true);
  }
  const history = useHistory();
  const [openSetting, setOpenSetting] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (
      (company && company.length > 0) ||
      (institute && institute.length > 0) ||
      (companyList && companyList.length > 0) ||
      (adminList && adminList.length > 0)
    ) {
      history.push("/portal/view-company");
    }
  }, [company, institute, history, companyList, adminList]);

  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const GetAllCompany = () => {
    setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        setCompany(response.data.company);
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false); // Set loading to false regardless of success or failure
      });
  };
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        setInstitute(response.data.company);
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false); // Set loading to false regardless of success or failure
      });
  };

  const GetAllAdmin = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/manage-admin`)
      .then((response) => {
        setCompanyList(response.data.companies);
      })
      .catch((err) => {
        console.log(err.error);
      });
  };

  const GetAllInstituteAdmin = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute-admin`)
      .then((response) => {
        setAdminList(response.data.companies);
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  useEffect(() => {
    GetAllCompany();
    GetAllAdmin();
    GetAllInstitute();
    GetAllInstituteAdmin();
  }, []);

  useEffect(() => {
    document.title = "Organisation | Xakal"
  }, [])

  const searchedKey = () => { }
  return (
    <>
      {/* {(company && company.length > 0 ) || (institute && institute.length > 0) ? 
    <ViewCompany /> : */}
      <div>
        <Dialog
          open={openSetting}
          PaperProps={{
            style: {
              width: "347px",
              height: "200px",
              top: "50px",
              right: "35px",
              borderRadius: "24px",
              position: "fixed",
            },
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Settings open={() => setOpenSetting(false)} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={success}
          fullWidth
          maxWidth="sm"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium,sans-serif",
            }}
          ></DialogTitle>
          <DialogContent>
            <Text>Are you sure you want to logout?</Text>

            <DateWrapperButton>
              <button className="button5" onClick={() => setSuccess(false)}>
                No
              </button>
              {user ? (
                <button
                  className="button3"
                  onClick={() => {
                    Auth.signOut();
                    Common.logout();
                  }}
                >
                  Yes
                </button>
              ) : (
                <button className="button3" onClick={logout}>
                  Yes
                </button>
              )}
            </DateWrapperButton>
          </DialogContent>
        </Dialog>

        <HeaderLayout>
          <DesktopHeader title={"Organisation"} searchedKey={searchedKey} />
        </HeaderLayout>

        {/* {(company && company.length > 0 ) || (institute && institute.length > 0) ?   
      <ViewCompany /> : */}
        {loading ? (
          <div>
            <Spinner
              style={{
                display: "flex",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </div>
        ) : (
          <CompanyWrapper
            style={{ marginTop: "50px", display: "flex", flexFlow: "wrap", justifyContent: "center" }}
          >
            <CreateNew
              style={{ flexDirection: "column", cursor: "pointer" }}
              onClick={() => history.push("/portal/company")}
            >
              <img
                src={AddIcon}
                style={{ width: "80px", height: "80px", margin: "auto" }}
                alt=""
              />
              <CreateNewText>Create New</CreateNewText>
            </CreateNew>
          </CompanyWrapper>
        )}
        <Dialog
          open={openSetting}
          PaperProps={{
            style: {
              width: "347px",
              height: "200px",
              top: "50px",
              right: "35px",
              borderRadius: "24px",
              position: "fixed",
            },
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium,sans-serif",
              position: "relative",
            }}
          >
            <span
              style={{
                width: "347px",
                height: "50px",
                padding: "19px, 16px, 19px, 25px",
                border: "0px, 0px, 1px, 0px",
                borderBottom: "1px solid #F4F4F6",
              }}
            >
              Setting
            </span>
            <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                position: "relative",
                fontFamily: "Inter-Medium,sans-serif",
                gap: "10px",
                cursor: "pointer",
                marginleft: "20px",
              }}
            >
              <BsCreditCard2Front size={20} />
              <p
                onClick={() => history.push("/portal/changepassword")}
              >
                Change Password
              </p>
            </div>
            <div
              style={{
                display: "flex",
                position: "relative",
                fontFamily: "Inter-Medium,sans-serif",
                gap: "10px",
                cursor: "pointer",
                marginLeft: "20px",
              }}
            >
              {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
            </div>
            <div
              style={{
                display: "flex",
                position: "relative",
                fontFamily: "Inter-Medium,sans-serif",
                gap: "10px",
                cursor: "pointer",
              }}
              onClick={onSubmit}
            >
              <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
              <p style={{ color: "#FF5252" }}> Logout</p>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={success}
          fullWidth
          maxWidth="sm"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
            }}
          ></DialogTitle>
          <DialogContent>
            <Text>Are you sure you want to logout?</Text>

            <DateWrapperButton>
              <Button8 onClick={() => setSuccess(false)}>No</Button8>
              {user ? (
                <Button9
                  onClick={() => {
                    Auth.signOut();
                    Common.logout();
                  }}
                >
                  Yes
                </Button9>
              ) : (
                <Button9 onClick={logout}>Yes</Button9>
              )}
            </DateWrapperButton>
          </DialogContent>
        </Dialog>
      </div>
      {/* } */}
    </>
  );
};

export default Organisation;
