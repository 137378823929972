import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import "./pendingfeedback.css";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const PendingFeedback = () => {
  const history = useHistory();
  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const [candidateServices, setCandidateServices] = useState({});
  const getAllPending = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-pending-feedback`)
      .then((res) => {
        setPendingFeedback(res.data.book);
        const candidateIds = res.data.book.map((item) => item.candidateId);
        // Fetching services for each candidateId
        getCandidateServices(candidateIds);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getCandidateServices = (candidateIds) => {
    // Assuming you have an API endpoint to fetch candidate services
    candidateIds.forEach((id) => {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
        .then((res) => {
          setCandidateServices((prevServices) => ({
            ...prevServices,
            [id]: res.data.aboutOne,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };
  useEffect(() => {
    getAllPending();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getUTCFullYear();

    let suffix = "";
    if (day === 1 || day === 21 || day === 31) {
      suffix = "st";
    } else if (day === 2 || day === 22) {
      suffix = "nd";
    } else if (day === 3 || day === 23) {
      suffix = "rd";
    } else {
      suffix = "th";
    }

    const formattedDate = `${day}${suffix} ${month} ${year}`;

    return formattedDate;
  };

  const handleFeedback = (id) => {
    history.push(`/portal/feedback/${id}`);
  };
  const handleBack = () => {
    history.goBack();
  };
  return (
    <div>
      {/* Start of desktop Header  */}
      <DesktopHeader
        title={"Pending Feedback"}
        isSearchActive={false}
        backbtn
        back={handleBack}
      />
      {/* End of desktop Header  */}
      <div className="feedbackmain">
        {/* Column Headers */}
        <div className="feedback-flex">
          <div style={{ flex: 1 }}>Image</div>
          <div style={{ flex: 2 }}>Interview Candidate</div>
          <div style={{ flex: 2 }}>Time of Interview</div>
          <div style={{ flex: 1 }}>Feedback Status</div>
          <div style={{ flex: 1 }}>Action</div>
        </div>
        {/* Cards */}
        {pendingFeedbackData.map((item) => {
          const candidateId = item.candidateId;
          const candidateDetail = candidateServices[candidateId] || {};
          return (
            <div key={item.id} className="card-style">
              <div style={{ flex: 1 }}>
                <img
                  src={candidateDetail.avatar || ""}
                  alt="Candidate"
                  className="candidate-image"
                />
              </div>
              <div
                style={{ flex: 2 }}
              >{`${candidateDetail.firstName} ${candidateDetail.lastName}`}</div>
              <div style={{ flex: 2 }}>
                {formatDate(item.date)} {item.time}
              </div>
              <div style={{ flex: 1 }}>{item.pendingFeedback}</div>
              <div style={{ flex: 1 }}>
                <button onClick={() => handleFeedback(item._id)}>
                  Provide Feedback
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PendingFeedback;
