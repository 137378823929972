import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ImageAvatar from "../images/company.png";
import Common from "../utils/common";
import axios from "axios";

const ProfileHeader = ({ onFalse, onNavaigation }) => {
  const [fullName, setFullName] = useState();

  const [avatar, setAvtar] = useState();
  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        const firstName = response.data.aboutOne?.firstName;
        const lastName = response.data.aboutOne?.lastName;
        const middleName = response.data.aboutOne?.middleName
          ? response.data.aboutOne?.middleName
          : "";

        const fullName = `${firstName} ${middleName} ${lastName}`;
        setFullName(fullName);

        setAvtar(response.data.aboutOne?.avatar);
      })
      .catch((err) => {});
  });

  return (
    <div className="profile-cont">
      <div>
        <li className="nav-item">
          {onFalse ? (
            <NavLink
              activeClassName="active"
              to={{
                pathname: `/portal/my-profile`,
              }}
              className="iProfile"
              isActive={(match, location) => {
                const profilePaths = [
                  "/portal/my-profile",
                  "/portal/changepassword",
                  "/portal/otp-changed",
                  "/portal/new-password",
                  "/portal/view-feedback",
                  "/portal/MyProfile/repoDetails",
                  "/portal/transaction",
                ];

                return profilePaths.some((path) =>
                  location.pathname.startsWith(path)
                );
              }}
              onClick={onNavaigation}
            >
              <div className="prof-name-header">
                {avatar ? (
                  <span>
                    <img src={avatar} alt="img" className="prof-img" />
                  </span>
                ) : (
                  <span>
                    <img src={ImageAvatar} alt="img" className="prof-img" />
                  </span>
                )}
                <div className="profile-name">
                  <span className="pro-name nav-weight">
                    {" "}
                    {fullName?.substring(0, 12)}
                    {fullName?.length > 12 ? "..." : ""}
                  </span>
                  {fullName?.length > 12 ? (
                    <p className="prof-name">{fullName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </NavLink>
          ) : (
            <NavLink
              activeClassName="active"
              to={{
                pathname: `/portal/my-profile`,
              }}
              className="iProfile"
              isActive={(match, location) => {
                const profilePaths = [
                  "/portal/my-profile",
                  "/portal/changepassword",
                  "/portal/otp-changed",
                  "/portal/new-password",
                  "/portal/view-feedback",
                  "/portal/MyProfile/repoDetails",
                  "/portal/transaction",
                ];

                return profilePaths.some((path) =>
                  location.pathname.startsWith(path)
                );
              }}
            >
              <div className="prof-name-header">
                {avatar ? (
                  <span>
                    <img src={avatar} alt="img" className="prof-img" />
                  </span>
                ) : (
                  <span>
                    <img src={ImageAvatar} alt="img" className="prof-img" />
                  </span>
                )}
                <div className="profile-name">
                  <span className="pro-name nav-weight">
                    {" "}
                    {fullName?.substring(0, 12)}
                    {fullName?.length > 12 ? "..." : ""}
                  </span>
                  {fullName?.length > 12 ? (
                    <p className="prof-name">{fullName}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </NavLink>
          )}
        </li>
      </div>
    </div>
  );
};

export default ProfileHeader;
