import React, { useEffect, useRef, useState } from "react";
import xalogo from "../images/xakallogo.png";
import styled from "styled-components";
import backArrow from "../images/arrow-box.svg";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import PassHint from "../images/PassHint.png";
import Spinner from "react-bootstrap/Spinner";
import Progress from "./progressBar";
const PassHintHead = styled.div`
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;

  img {
    position: absolute;
    top: 336px;
    left: 112px;
    @media (max-width: 500px) {
      left: 30px;
    }
    @media (min-height: 465px) and (max-height: 665px) {
      top: 160px;
    }
    @media (min-height: 666px) and (max-height: 825px) {
      top: 260px;
    }
    @media (min-width: 821px) and (max-width: 1024px) {
      left: 325px;
    }
    @media (min-width: 1025px) and (max-width: 1179px) {
      left: 375px;
    }
  }
`;

const OtpWrapper = styled.div``;
const Time = styled.div``;
const Resend = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-top: 20px;
  text-align: center;
`;
const BtnWrapper = styled.div`
  margin-top: 20px;
`;

const ErrWrapper = styled.div``;
const OtpVerification = () => {
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [middleName, setMiddleName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [errors, setErrors] = useState(null);
  const [verify, setVerify] = useState(false);
  const [count, setCount] = useState(5);
  const [showTimeRemaining, setShowTimeRemaining] = useState(true);
  const [timerExpired, setTimerExpired] = useState(false);
  const location = useLocation();
  const [shareId, setShareId] = useState();
  const [shareInterviewId, setShareInterviewId] = useState();
  useEffect(() => {
    if (location.state && location.state) {
      setEmail(location.state.email);
      setFirstName(location.state.firstName);
      setLastName(location.state.lastName);
      setMiddleName(location.state.middleName);
      setPassword(location.state.password);
      setConfirmPassword(location.state.confirmPassword);
      setShareId(location && location.state.shareJobId);
    setShareInterviewId(location && location.state.shareInterviewId);
    }
  }, [location]);
 
  console.log(shareInterviewId, "shareInterviewId");
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const submitRegister = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    // const hashedPassword = await bcrypt.hash(password, 10);
    const payload = {
      // firstName: firstName,
      // lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      // middleName: middleName,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/create`, payload)
      .then((response) => {
        console.log(response);
        if (response && response.data && response.data.success) {
          if (
            response &&
            response.data &&
            response.data.user &&
            response.data.user.userRole === "student"
          )
            axios.defaults.headers.common["x-access-token"] =
              response.data.user.token;
          document.cookie = `userid=${response.data.user._id}; path=/`;
          history.push({
            pathname: "/on-boarding",
            state: {
              email: email,
              firstName: firstName,
              middleName: middleName,
              lastName,
              shareJobId: shareId,
              shareInterviewId: shareInterviewId,
            },
            // userDetails: response.data.user,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => err.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const inputRefs = useRef(otp.map(() => React.createRef()));

  const handleChange = (event, index) => {
    const updatedOtp = [...otp];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtp(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtp(updatedOtp);

    // Clear the error message when any input changes
    setErrors(null);

    // Focus on the next input field if available
    if (index < otp.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otp.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtp(updatedOtp);

    // Focus on the next input field if available
    if (index + pastedData.length < otp.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };
  const [disableFields, setDisableFields] = useState(false);

  const resendOtp = async (e) => {
    e.preventDefault();
    setErrors(null);
    setTimerExpired(false); // Reset the timer state
    setMinutes(1);
    setSeconds(0);
    setShowTimeRemaining(true);
    setDisableFields(false);
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      // setSpiner(true);
      const data = {
        email: email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/resendOtp`,
        data
      );
      if (response.status === 200) {
        // handleShowToaster();
        toast.success("OTP Sent Successfully...!");
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };

  const restartTimer = () => {
    setMinutes(15);
    setSeconds(0);
    setErrors(null);
    setCount(1);
    setDisableFields(true); // Disable the fields
    setErrors(null);
  };

  useEffect(() => {
    let timer;
    if (showTimeRemaining && !verify && !timerExpired) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(timer);
          setTimerExpired(true);
          setDisableFields(false); // Enable the fields after the timer expires
          if (count === 1) {
            restartTimer(); // Restart the timer after it reaches zero during the last attempt
          }
        }
        if (disableFields && timerExpired) {
          setDisableFields(false); // Enable the fields after the timer expires
        }
      }, 1000);
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimeRemaining, verify, minutes, seconds, timerExpired]);
  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      if (otp.join("").length !== 4) {
        setErrors("Invalid OTP");
      } else {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/verify`,
          {
            otp: otp.join(""),
            email: email,
          }
        );

        if (response.data.success) {
          toast.success(response.data.message);
          setErrors(null);
          setShowTimeRemaining(false);
          setErrors4(null);
        } else if (response.data.verified) {
          setVerify(true);
          toast.success(response.data.message);
          setErrors(null);
          setShowTimeRemaining(false);
          submitRegister(e);
        } else {
          // Handle verification failure
          handleVerificationFailure();
          if (count === 1) {
            // Start 15-minute timer when one attempt is left
            restartTimer();
          }
        }
      }
    } catch (error) {
      console.error("Error in verifyOtp:", error);
    }
  };
  const [errors4, setErrors4] = useState(null);
  const handleVerificationFailure = () => {
    if (count > 1) {
      setCount(count - 1);
      if (count === 2) {
        setErrors4("Only 1 attempt left!");

        setErrors(null);

        // Set a timeout to clear the maximum attempts reached message after 1 second
        setTimeout(() => {
          setErrors4(null);
        }, 5000);
      } else {
        setErrors(`Incorrect OTP!`);
      }
    } else {
      setErrors4("Maximum attempts reached. Please wait for 15 minutes.");
      setCount(1);
      setDisableFields(true); // Disable the fields

      if (!timerExpired) {
        restartTimer();

        setTimeout(() => {
          setErrors4(null);
        }, 5000);
      }
    }
  };
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const calculateProgress = () => {
      // Calculate progress based on current page and total number of pages
      // For simplicity, let's assume we have 3 pages and progress increases by 33.33% on each page
      const currentPage = window.location.pathname;
      const totalPages = 3;
      const currentPageIndex = [
        "/signup",
        "/otp-verification",
        "/on-boarding",
      ].indexOf(currentPage);
      const newProgress = (currentPageIndex + 1) * (100 / totalPages);
      setProgress(newProgress);
    };

    calculateProgress();
  }, []);

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <div className="form-wrapper  wrap-login100 p-b-20">
        <div className="margin-logo">
          <div className="logo-holder">
            <Progress progress={progress} />
            <img
              src={xalogo}
              className="login-logo signup-xakal-logo"
              alt="XAKAL"
            />
          </div>
        </div>

        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header">
              <div className="arrow-box">
                <Link
                  to={{
                    pathname: "/signup",
                    state: {
                      input: {
                        email: email,
                        password: password,
                        confirmPassword: confirmPassword,
                      },
                      // Include other data you want to pass here
                    },
                  }}
                >
                  <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
                </Link>
              </div>
              <h4 className="textpass font-face-gm mb-0">OTP Verification</h4>
            </div>

            <h4 className="textpass">Enter the OTP sent to {email}</h4>

            <OtpWrapper>
              <div className="otp-group">
                {otp.map((digit, idx) => (
                  <input
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d"
                    className="otp-inputs"
                    value={digit}
                    id={`otp-input-${idx}`}
                    ref={inputRefs.current[idx]}
                    onChange={(e) => handleChange(e, idx)}
                    onPaste={(e) => handlePaste(e, idx)}
                    disabled={disableFields}
                  />
                ))}
              </div>
            </OtpWrapper>
            <div className="error-wrapper">
              <div>
                {" "}
                {errors && (
                  <ErrWrapper className="error-msg">{errors}</ErrWrapper>
                )}
              </div>
            </div>
            <Wrapper>
              {showTimeRemaining && !verify && !timerExpired && (
                <Time className="otp-timer">
                  Time remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Time>
              )}
            </Wrapper>
            <Resend show={timerExpired && !verify}>
              <p class="login-signupphrase">
                Didn’t receive the code yet?{" "}
                <span onClick={resendOtp} style={{ cursor: "pointer" }}>
                  Resend
                </span>{" "}
              </p>
            </Resend>
            {errors4 && (
              <div id="message6">
                <div
                  style={{ position: "absolute", left: "-15px", top: "10px" }}
                >
                  <img src={PassHint} alt="img" />
                </div>
                <PassHintHead style={{ color: "white" }}>
                  {errors4}
                </PassHintHead>
                <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                  <div className="check"></div>
                  <p className="valid">
                    After this attempt, you’ll have to wait for 15 minutes
                  </p>
                </div>
              </div>
            )}

            <BtnWrapper>
              <div className="container-login100-form-btn">
                <Link to={{ pathname: "/new-passwords", email: email }}>
                  <button className="login100-form-btn" onClick={verifyOtp}>
                    {loading ? <Spinner /> : "Next"}
                  </button>
                </Link>
              </div>
            </BtnWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
