import React from "react";
import styled from "styled-components";
import parse from "html-react-parser"
import Image from "../../images/company.png";
import { FaRupeeSign } from "react-icons/fa";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import Back from "../../images/Back.svg";

const MainLayout = styled.div`
  // margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: #fff;
  // border-radius: 10px;
  padding: 15px;
  // margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 1rem 0;
  }
`;


const ContentWrapper = styled.div`
img{
  cursor:pointer;
}  
@media(max-width:576px){
    margin-bottom:0;
  }
`;

const Wrapper = styled.div``;

const JobDetailIcon = styled.div`
  display: flex;
  gap:10px;
  position: relative;
  left: 5px;
  margin-top: 20px;
  @media(max-width:576px){
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
const JobTitle = styled.div``;
const JobTitle2 = styled.div``;
const JobTitleIcon = styled.div``;

const Dates = styled.div`
  padding: 5px;
  color: #764f90;
  font-size: 16px;
  font-weight: normal;
  width: 120px;
  margin: 20px;
`;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap:wrap;
  position: relative;
  // height: auto;
  margin: 10px 0;
  background-color: #f7f7f8;
  gap: 10px;
  padding: 12px 6px;
  border-radius: 8px;
`;
const Preference = styled.div`
  font-style:
    Inter-Medium,
    sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  color: #362a41;
`;
const JobPreference = styled.div`
  color: #4f4953;
  background: #f4e8ff;
  padding: 5px 10px;
  border-radius: 5px;
  // height: 33px;
  font-size: 14px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-weight: normal;
  @media (max-width: 576px) {
    height:auto;
  }
`;

const Content = styled.div`
  font-style:Inter-Medium,sans-serif;
  font-size:14px;
  line-height:24px;
  font-weight: normal;
  color;black;
  color:#4F4953;
`;
const ViewApplicant = ({ jobData, setIsOpen }) => {
  var scheduleDate = jobData.scheduleDate;
  var dateString = new Date(scheduleDate);
  var forDate = dateString.toLocaleDateString();

  return (
    <div>
      <MainLayout>
        <div className="modalBody mBody" style={{padding:"0px"}}>
        <PageWrapper>
          <ContentWrapper className="job-content-wrapper">
          <img src={Back} alt="img" onClick={setIsOpen}/>
            <div className="company-logo-container" style={{padding:"0", border:"0"}}>
              {jobData.companyName && jobData.companyName.companyLogo ? (
                <>
                  <img
                    src={jobData.companyName && jobData.companyName.companyLogo}
                    alt="img"
                    className="company-logo"
                  />
                </>
              ) : (
                <img src={Image} alt="img" className="company-logo" />
              )}
            </div>
            <Wrapper className="job-title-wrapper">
              <JobTitle  className="job-title">{jobData.jobTitle}</JobTitle>
              <JobTitle2 className="company-title">
                {jobData.companyName && jobData.companyName.value}
              </JobTitle2>
            </Wrapper>
          </ContentWrapper>

          <JobDetailIcon  className="job-details">
            <JobTitleIcon className="icon-title">
              <img src={Suit} alt="img" style={{ marginTop: "-5px", width: "20px", height: "20px" }} />
              <span>
                {" "}
                Exp{" "}
                {jobData.experienceRequired2
                  ? `${jobData.experienceRequired} - ${jobData.experienceRequired2} yrs`
                  : jobData.experienceRequired
                    ? `${jobData.experienceRequired} yrs`
                    : ""}{" "}
                |{" "}
              </span>
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              <img src={Salary} alt="img" style={{ marginTop: "-5px", width: "20px", height: "20px" }} />{" "}
              <FaRupeeSign size={10} color="grey" />
              {`${jobData.salaryFrom}L - ${jobData.salaryTo}L`}
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              {" "}
              <img src={Location} alt="img" style={{ marginTop: "-5px", width: "20px", height: "20px" }} />
              {jobData.location?.substring(0, 8)}
                {jobData.location?.length > 8 ? "..." : ""}
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              {" "}
              <img src={Home} alt="img" style={{ marginTop: "-5px", width: "20px", height: "20px" }} />
              {jobData.workMode}
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              {" "}
              <img src={Clock} alt="img" style={{ marginTop: "-5px", width: "20px", height: "20px" }} />
              {jobData.employeeType}{" "}
            </JobTitleIcon>
          </JobDetailIcon>

          <Preference>Preferred :</Preference>
          <PreferenceWrapper  className="prefer-wrap">
            {jobData.preference
              ? jobData.preference.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
              : []}
          </PreferenceWrapper>

          <Preference>Skills :</Preference>

          <PreferenceWrapper>
            {jobData.skill
              ? jobData.skill.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
              : []}
          </PreferenceWrapper>

          <Preference>Job Description & Benefits :</Preference>
          <PreferenceWrapper>
            <Content>{parse(jobData.jobDesc)}</Content>
          </PreferenceWrapper>
          {jobData?.scheduleDate ? <Dates>{forDate}</Dates> : ""}
          
        </PageWrapper>
        </div>
      </MainLayout>
    </div>
  );
};

export default ViewApplicant;
