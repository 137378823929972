import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../MyProfile/service.Popup.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Jobs/shared.css";
import interviewLogo from "../../images/interview.png";
import iPlay from "../../images/icons/play-transparent.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import Alaram from "../../images/Alarm.svg";
import DesktopHeader from "../desktopHeader";
import Common from "../../utils/common";
import ExploreTimeZone from "../MyProfile/timeExploreInterview";
import { Dialog, DialogContent } from "@mui/material";
import SlotInterview from "../MyProfile/slotexplore";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import Profile from "../../images/company.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import I from "../../images/Danger Circle.svg";
import interviewLogos from "../../images/xakallogo.png";
const Preference = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  position: relative;
  top: 10px;
`;
const MainLayout = styled.div`
  overflow-x: hidden;
  margin-left: 100px;
  padding: 0px 20px 0px 20px;
  margin-top: 10px;
`;
const Description = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  margin-top: 25px;
`;
const Designation = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a8a1ac;
  margin-top: 5px;
`;
const DesignationList = styled.div`
  font-family:
    General Sans,
    sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  background: #f4e8ff;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  justofy-content: flex-start;
  margin-left: 10px;
  gap: 10px;
  flex-wrap: wrap;
`;
const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 10px 0 0 0;
`;
const ImageWrapper = styled.div`
  img {
    width: 200px;
    height: 200px;
  }
  width: 40%;
  text-align: center;
  background: none;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;
const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #4f4953;
  display: flex;
  gap: 10px;
  text-align: left;
  img {
    width: 24px;
    height: 24px;
  }
`;

const MainFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SharedInterviewApply = () => {
  const { id } = useParams();
  const [openDate, setOpenDate] = useState(false);
  const [inputId, setInputId] = useState();
  const [ids, setIds] = useState("");
  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const openDateForm = (id) => {
    setOpenDate(true);
    setIds(id);
    setInputId(id);
  };
  const availableSkills = [
    "Web Development",
    "Wireless Communications and Networks",
    "Java Script",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "NLP",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Sql",
    "UI Design",
    "UX Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "AWS",
    "Azure DevOps",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Design",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Hardware Development",
    "Hardware Product Development",
    "Internet of Things",
  ];

  const [data, setData] = useState();
  const [userOneId, setUserOneId] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${id}`)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.interview);
          setUserOneId(response.data.interview?.createdBy.userId);
        }
      })
      .catch((err) => {});
  }, [id]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Enable autoplay
  };
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const getOneDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {})
      .catch((err) => {});
  };
  useEffect(() => {
    getOneDetail();
  }, []);
  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const openFirst = (id) => {
    setOpenDate(true);
    setConfirmPopup(false);
    setSlotId(id);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  const history = useHistory();
  const handleProfile = () => {
    history.push(`/portal/my-profile`);
  };
  const routeDirect = () => {
    history.push("/portal/interview/exploreInterview");
  };
  const loginRedirect = () => {
    history.push({
      pathname: "/login",
      state: { data: id },
    });
  };
  const [aboutDetail, setAboutDetail] = useState();

  const getAllUserDetail = (ids) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${ids}`)
      .then((response) => {
        setAboutDetail(response.data.aboutOne);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllUserDetail(userOneId);
  }, [userOneId]);
  return (
    <>
      <DesktopHeader isSearchActive={false} />
      <div className="share-prowrap share-int-wrap">
        <div className="share-setpro">
          Complete setting up your profile to explore through various job
          opportunities{" "}
        </div>
        <button className="share-probtn" onClick={handleProfile}>
          Set up profile now
        </button>
      </div>
      <MainLayout>
        <div className="shared-part-int">
          <div className="shared-part-inner">
            <div className="share-tit-wrap">
              <Preference>{data?.interviewTitle}</Preference>
              <div className="time-ago">
                {" "}
                <img
                  src={aboutDetail?.avatar ? aboutDetail?.avatar : Profile}
                  alt="img"
                />
                <div className="share-by">
                  {aboutDetail?.firstName}
                  <TimeAgo timestamp={data?.timestamps.createdAt} />
                </div>
              </div>
            </div>

            <MainFlex>
              <div className="modalBody">
                {data && data.image && data.image.length > 0 ? (
                  <Slider {...settings}>
                    {data?.image.length ? (
                      data?.image.map((item, index) => (
                        <div key={index}>
                          {item.fileType && (
                            <>
                              {item.fileType.startsWith("image") ? (
                                <img
                                  src={item.url}
                                  className="int-slider-images"
                                  alt={`Images ${index}`}
                                />
                              ) : item.fileType.startsWith("video") ? (
                                <div className="int-video-container">
                                  <div
                                    className="video-poster"
                                    style={{
                                      backgroundImage: {
                                        interviewLogo,
                                      },
                                      display: isPlaying ? "none" : "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={handlePlayClick}
                                  >
                                    <img
                                      src={iPlay}
                                      className="play-icon"
                                      alt="icon"
                                    />
                                  </div>

                                  <video
                                    id={`video-${index}`}
                                    src={item.url}
                                    className="int-slider-video"
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    onEnded={() => setIsPlaying(false)}
                                    alt={`Video ${index}`}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      ))
                    ) : data?.skill && data?.skill.length ? (
                      data?.skill.map((skill, skillIndex) =>
                        availableSkills.includes(skill.label) ? (
                          <div key={skillIndex}>
                            <img
                              src={`${"https://xakalprod.s3.ap-south-1.amazonaws.com/"}${skill.label}${".svg"}`}
                              className="int-slider-images"
                              alt={`Images ${skillIndex}`}
                            />
                          </div>
                        ) : (
                          <img
                            src={interviewLogo}
                            className="int-slider-images"
                            alt="images"
                          />
                        )
                      )
                    ) : (
                      <img
                        src={interviewLogo}
                        className="int-slider-images"
                        alt="images"
                      />
                    )}
                  </Slider>
                ) : (
                  <img
                    src={interviewLogos}
                    alt="interview-logo"
                    style={{ width: "100%", height: "280px" }}
                  />
                )}
                <div>
                  <Description> {data && data?.description}</Description>
                </div>
                <div>
                  <PreferenceWrapper>
                    <Designation>Designation </Designation>
                    {data?.designation.map((item, index) => (
                      <DesignationList key={index}>{item}</DesignationList>
                    ))}
                  </PreferenceWrapper>
                </div>
                <div>
                  <PreferenceWrapper>
                    <Designation>Skills that'll be evaluated </Designation>
                    {data?.skill.map((item, index) => (
                      <DesignationList key={index}>
                        {item.label}
                      </DesignationList>
                    ))}
                  </PreferenceWrapper>
                </div>
              </div>
            </MainFlex>

            <div className="modalFooters">
              <div className="duration-ser">
                <div className="duration-wrap">
                  <img src={Alaram} alt="img" />
                  {data?.duration}
                </div>
                <div>
                  <img src={Salary} alt="img" />
                  {data?.inrService ? (
                    <button>Free</button>
                  ) : (
                    <button>
                      INR
                      {data &&
                        new Intl.NumberFormat("en-IN", {}).format(data?.inr)}
                    </button>
                  )}
                </div>
              </div>
              <div>
                <button
                  className="job-btn"
                  onClick={() => {
                    openDateForm(data._id);
                    setInputId(data.createdBy?.userId);
                  }}
                >
                  Book Slot
                </button>
              </div>
            </div>
          </div>

          <ImageWrapper>
            <img
              src="https://xakalprod.s3.ap-south-1.amazonaws.com/share-int.svg"
              alt="img"
            />

            <div className="mTitle modalheader"></div>
            <Content>
              Get Interviewd By SME's, Undergo skill assessments by industry
              experts. Showcase feedback to recruiters, and stand out in job
              applications.
            </Content>
            <ButtonWrapper>
              {Common.loggedIn() ? (
                <button className="button07" onClick={routeDirect}>
                  Explore other interview
                </button>
              ) : (
                <button className="button07" onClick={loginRedirect}>
                  Explore other interview
                </button>
              )}
            </ButtonWrapper>
            <Content className="int-content">
              <img src={I} alt="img" />
              If this interview doesn't match your skillset, explore other
              interviews that suited to your skillset.
            </Content>
          </ImageWrapper>
        </div>
      </MainLayout>
      <Dialog
        open={openDate}
        PaperProps={{
          style: {
            height: "650px",
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ExploreTimeZone
            id={inputId}
            interviewId={ids}
            open={openConfirmPopup}
            onClose={() => setOpenDate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "660px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotInterview
            inputId={inputId}
            openFirst={() => openFirst(inputId)}
            slotId={slotId}
            close={close}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SharedInterviewApply;
