import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./aptitude.css";
import axios from "axios";
import Common from "../../utils/common";
const AptitudeList = () => {
  const history = useHistory();
  const handleStartTest = () => {
    history.push("/portal/home");
  };
  const onNavLinkClick = async (event) => {
    const currentRoute = window.location.href;
    const allowedRoutes = [
      "http://localhost:3000/portal/exam",
      "http://localhost:3000/portal/result",
      "http://localhost:3000/portal/home",
      "http://localhost:3000/portal/aptitude-test",
      "https://thexakal.com/portal/exam",
      "https://thexakal.com/portal/result",
      "https://thexakal.com/portal/home",
      "https://thexakal.com/portal/aptitude-test",
      "https://test-staging.thexakal.com/portal/exam",
      "https://test-staging.thexakal.com/portal/result",
      "https://test-staging.thexakal.com/portal/home",
      "https://test-staging.thexakal.com/portal/aptitude-test",
      "https://qa.thexakal.com/portal/exam",
      "https://qa.thexakal.com/portal/result",
      "https://qa.thexakal.com/portal/home",
      "https://qa.thexakal.com/portal/aptitude-test",
    ];
  
    // Register event listener for beforeunload
    window.addEventListener("beforeunload", (event) => {
      if (allowedRoutes.includes(currentRoute)) {
        event.preventDefault(); // Prevent default navigation behavior
        event.returnValue = ""; // For older browsers
      }
    });
  
    // Check if current route is in allowedRoutes
    if (allowedRoutes.includes(currentRoute)) {
      event.preventDefault(); // Prevent default navigation behavior
      alert(
        "Browser or tab switch detected. Please don't switch or open a new tab."
      );
      // Optionally, add more logic here if needed
      await getUserDeactivityStatus();
    }
  };
  const [firstName, setFirstName] = useState();
  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        const firstName = response.data.aboutOne?.firstName;

        setFirstName(firstName);
      })
      .catch((err) => {});
  });
  const getUserDeactivityStatus = () => {
    const payload = {
      userName: firstName,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/detectuseractivity`, payload)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };
  const [onFalse, setFalse] = useState(true);

  return (
    <div>
      <DesktopHeader
        title={"Assesment Test"}
        isSearchActive={false}
        onNavaigation={onNavLinkClick}
        onFalse={onFalse}
      />
      <div className="button-center-aptitude">
        <button className="button3" onClick={handleStartTest}>
          Start Test
        </button>
      </div>
    </div>
  );
};

export default AptitudeList;
