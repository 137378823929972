import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AccountCreation from "./accountCreation";
import axios from "axios";
import Common from "../../utils/common";
import DesktopHeader from "../desktopHeader";
import CloseIcon from "../../images/CloseIcon.svg";
import AddIcon from "../../images/add-bg.svg";
import iEdit from "../../images/solar_pen-new-round-bold-duotone.svg";
import BankImg from "../../images/BankImg.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const HeaderLayout = styled.div``;

const BankWrapper = styled.div`
  width: calc(100% - 226px);
  // margin-left: 180px;
  margin-top: 124px;
  padding: 20px;
  padding-top: 120px;
  display: flex;
  gap: 18px;
`;
const AddBank = styled.div`
  

height: 165px
top: 178px
left: 272px
padding: 53px, 80px, 53px, 80px
border-radius: 16px


 width:100%;
  max-width: 480px;
  background: #ececef;
  padding: 53px 104px 53px 103px;
  border-radius: 16px;
  display: flex;
  justify-content: space-around;
`;
const AddBankAccount = styled.div`
  height: 19px;
  top: 93px;
  left: 80px;
  font-family: Inter-Medium, Sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;

const MainHead = styled.div`
  height: 29px;
  top: -180px;
  marginleft: 300px;
  font-family: Inter-Medium, Sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  margin: 3rem;
`;

const ImgWrapper = styled.div`
  position: absolute;
  top: -110px;
  left: 110px;
  // display: flex;
  // justify-content: center;
`;
const CenterLayouts = styled.div`
  position: relative;
  width: 500px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px #362a411a;
  padding: 80px 30px 30px 30px;
  top: 90px;
  height: fit-content;
  //   display: flex;
  //   justify-content:end;
  left: 31%;
`;
const BankName = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(134, 83, 182, 1);
  text-align: center;
  // margin-left: 140px;
`;
const Wrapper = styled.div`
  display: flex;
  // flex-wrap:wrap;
  flex-direction: row;
  height: fit-content;
  gap: 20px;
  margin-top: 20px;
  margin-left: 20px;
`;
const ContentHead = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(168, 161, 172, 1);
  width: 136px;
  flex-wrap: no-wrap;
`;
const ContentData = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #362a41;
  // position:absolute;
  left: 180px;
`;
const Button4 = styled.div`
  width: 390px;
  height: 35px;
  padding: 30px, 10px, 0px, 24px;
  border-radius: 8px;
  border: 1px solid rgba(54, 42, 65, 0.1);
  background: trasperent;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
  margin-top: 30px;
  align-items: left;
  justify-content: center;
  cursor: pointer;
  margin-left: 25px;
  padding: 5px;
`;

const AccountDetails = () => {
  const [openForm, setOpenForm] = useState(false);
  const [accountDetail, setAccountDetail] = useState([]);
  const [accountId, setAccountId] = useState();
  const location = useLocation();

  const getOneAccount = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/account-get/${Common.loggedIn()}`)
      .then((resposne) => {
        setAccountDetail(resposne.data.account[0]);
        setAccountId(resposne.data.account[0]?._id);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getOneAccount();
  }, []);
  const openAccount = (data) => {
    if (accountId !== null) {
      setOpenForm(true);
    } else {
      setAccountId(null);
      setOpenForm(true);
    }
  };
  const closeForm = () => {
    setOpenForm(false);
  };
  const callService = () => {
    getOneAccount();
  };
  const searchedKey = () => {};
  const [open, setOpen] = useState();
  const [inactive, setInActive] = useState();
  const [enableService, setEnableService] = useState();

  console.log(open, "open");
  console.log(inactive, "inactive");
  console.log(enableService, "enableService");
  useEffect(() => {
    if (location.state && location.state) {
      setOpen(location.state.open);
      setInActive(location.state.inactive);
      setEnableService(location.state.enableService);
    }
  }, [location.state]);
  const history = useHistory();
  const handleRouting = () => {
    if(enableService){
      history.push({
        pathname: "/portal/my-profile",
        state: { enableService: enableService, open: open, inactive: inactive },
      });
    }else{
      history.goBack()
    }
   
  };
  return (
    <div>
      <HeaderLayout className="header-layout-one">
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Bank Account Details"}
          searchedKey={searchedKey}
          backbtn
          
          back={() => handleRouting()}
        />
      </HeaderLayout>
      {accountDetail && accountDetail.length > 0 ? (
        <>
          <BankWrapper
            style={{
              marginTop: "-150px",
              display: "flex",
              flexFlow: "wrap",
              justifyContent: "left",
              marginLeft: "50px",
            }}
          >
            <AddBank
              style={{ flexDirection: "column", cursor: "pointer" }}
              onClick={openAccount}
            >
              <img
                src={AddIcon}
                style={{ width: "80px", height: "80px", margin: "auto" }}
                alt=""
              />
              <AddBankAccount>Add Bank Account</AddBankAccount>
            </AddBank>
          </BankWrapper>
        </>
      ) : (
        <>
          <CenterLayouts>
            <ImgWrapper>
              <img src={BankImg} alt="img" />
            </ImgWrapper>
            <BankName>{accountDetail?.bankname}</BankName>
            <Wrapper>
              <ContentHead>Account Name</ContentHead>
              <ContentData>{accountDetail?.accholdername}</ContentData>
            </Wrapper>
            <Wrapper>
              <ContentHead>Account Number </ContentHead>
              <ContentData>{accountDetail?.accnumber}</ContentData>
            </Wrapper>
            <Wrapper>
              <ContentHead>IFSC Number </ContentHead>
              <ContentData>{accountDetail?.ifsc}</ContentData>
            </Wrapper>

            <Button4 onClick={openAccount}>
              <img src={iEdit} alt="img" height="25px" />
              {accountId && accountId
                ? "Edit Bank Account"
                : "Add Bank Account"}
            </Button4>
          </CenterLayouts>
        </>
      )}

      <Dialog
        open={openForm}
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "445px" } }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <span>Bank Account Details</span>
          {/* <AiOutlineCloseCircle onClick={() => setOpenForm(false)} /> */}
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setOpenForm(false)}
          ></img>
        </div>
        <DialogContent style={{ padding: "0" }}>
          <AccountCreation
            id={accountId}
            onClose={closeForm}
            accountDatas={accountDetail}
            onService={callService}
            openPrevious={open}
            inactive={inactive}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AccountDetails;
