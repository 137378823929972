import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../../images/DelIcon.svg";
import Calender from "../../images/Calendar.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import { Dialog, DialogContent } from "@mui/material";
import AddVacation from "./addVacation";
import Plus from "../../images/Add Circle.svg";
import "../../styles/responsive.css";

const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // margin: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 20px;
  // justify-content: space-between;
  // width: 200px;
  margin-bottom: 10px;
`;
const Label = styled.div`
  display: flex;
  gap: 10px;
  p {
    color: #40444f;
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%; /* 16.8px */
    text-transform: capitalize;
    margin-top: 15px;
  }
`;
const Labels = styled.div`
  // display:flex;
  // gap:10px;
  p {
    color: #40444f;
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%; /* 16.8px */
    text-transform: capitalize;
    margin-top: 25px;
  }
`;
const DeleteWrapper = styled.div`
  position: relative;
  top: 45px;
  left: 20px;
  background: white;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 4px;
`;
const SlotWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Slot = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #f7f7f8;
  width: 120px;
  background: white;
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 9px 0px 9px 0px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  h4 {
    background: #1c274c;
    width: 24px;
    font-size: 22px;
    height: 24px;
    border-radius: 50%;
    padding-bottom: 5px;
    color: white;
    margin-top: 5px;
  }
  p {
    color: #000;
    font-family: Inter-Medium, sans-serif;
    font-size: 12px;
    font-weight: normal;
  }
  margin-top: 30px;
`;
const Vacation = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #f7f7f8;
  width: 40%;
  background: white;
  display: flex;
  height: 43px;
  padding: 9px 0px 9px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  // right: 40px;
  color: #362A41;
  font-family:Inter-Medium,sans-serif;
  font-size: 16px;
  font-weight: normal;
  // margin-top: 15px;
  }
`;

const SaveButton = styled.button`
  margin-right: 10px;
`;

const Title = styled.div`
  font-weight: normal;
  font-size: 20px;
  color: #362a41;
`;
const AvailabilityTimePicker = ({
  handleChange,
  onId,
  onGetAll,
  onOpen,
  onCloseAvaial,
}) => {
  const [activeDay, setActiveDay] = useState("Sunday");

  const [availabilityData, setAvailabilityData] = useState({
    Sunday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Monday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Tuesday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Wednesday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Thursday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Friday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
    Saturday: {
      hours: false,
      hoursUnavailable: false,
      dateInterview: [{ fromTime: "", toTime: "" }],
    },
  });
  const [height, setHeight] = useState(false);
  const [dates, setDates] = useState({
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  });

  const handleAddDate = () => {
    setDates((prevDates) => ({
      ...prevDates,
      [activeDay]: [
        ...(prevDates[activeDay] || []),
        { fromTime: "", toTime: "" },
      ],
    }));

    setAvailabilityData((prevData) => ({
      ...prevData,
      [activeDay]: {
        hours: false,
        hoursUnavailable: false,
        dateInterview: [
          ...(prevData[activeDay]?.dateInterview || []),
          { fromTime: "", toTime: "" },
        ],
      },
    }));
  };

  const handleRemoveDate = (index) => {
    setDates((prevDates) => ({
      ...prevDates,
      [activeDay]: prevDates[activeDay].filter((_, i) => i !== index),
    }));

    setAvailabilityData((prevData) => ({
      ...prevData,
      [activeDay]: {
        ...prevData[activeDay],
        dateInterview:
          prevData[activeDay]?.dateInterview.filter((_, i) => i !== index) ||
          [],
      },
    }));
  };

  const [user, setUser] = useState();
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data.userId);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);

  const handleSubmit = async () => {
    const activeDayData = availabilityData[activeDay];
    if (
      activeDayData &&
      activeDayData.dateInterview.some((slot) => slot.fromTime && slot.toTime)
    ) {
      const payload = {
        availabilityUserId: user,
        daysAvailability: Object.keys(availabilityData).map((day) => ({
          day: day,
          available: availabilityData[day].hours,
          hours: availabilityData[day].hours,
          hoursUnavailable: availabilityData[day].hoursUnavailable,
          dateInterview: availabilityData[day].dateInterview.map((date) => ({
            fromTime: date.fromTime,
            toTime: date.toTime,
          })),
        })),
      };

      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/create-availability`,
          payload
        );

        const { data } = response;

        if (data.success) {
          // eslint-disable-next-line no-unused-vars
          const { availableCreate } = data;
          handleChange();
          toast.success("Availability Added Successfully");
          onGetAll();
        } else {
          console.error("Failed to create availability:", data.error);
          toast.error("Failed to create availability");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred");
      }
    } else {
      toast.error("Please enter at least one time slot before saving.");
    }
  };
  const handleUpdate = async () => {
    const activeDayData = availabilityData[activeDay];
    if (
      activeDayData &&
      activeDayData.dateInterview.some((slot) => slot.fromTime && slot.toTime)
    ) {
      const payload = {
        availabilityUserId: user,
        daysAvailability: Object.keys(availabilityData).map((day) => ({
          day: day,
          available: availabilityData[day].hours,
          hours: availabilityData[day].hours,
          hoursUnavailable: availabilityData[day].hoursUnavailable,
          dateInterview: availabilityData[day].dateInterview.map((date) => ({
            fromTime: date.fromTime,
            toTime: date.toTime,
          })),
        })),
      };

      try {
        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/getOneUpdate/${onId}`,
          payload
        );

        // Assuming that your server sends back the created availability data
        const { data } = response;

        // Handle the response data as needed
        if (data.success) {
          // Assuming 'availableCreate' is the created availability data
          // eslint-disable-next-line no-unused-vars
          const { interviewUpdate } = data;
          handleChange();
          toast.success("Availability Updated Successfully");
          onGetAll();
        } else {
          // Handle unsuccessful response
          console.error("Failed to create availability:", data.error);
          toast.error("Failed to create availability");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred");
      }
    } else {
      toast.error("Please enter at least one time slot before saving.");
    }
  };
  const handleTabChange = (day) => {
    setActiveDay(day);
  };

  const handleDateChange = (index, field, value) => {
    // Update the dates state
    setDates((prevDates) => ({
      ...prevDates,
      [activeDay]: prevDates[activeDay].map((date, i) => {
        if (i === index) {
          return {
            ...date,
            [field]: value,
          };
        }
        return date;
      }),
    }));
  
    // Update the availabilityData state without converting to local time
    setAvailabilityData((prevData) => {
      const activeDayData = prevData[activeDay] || {
        hours: false,
        hoursUnavailable: false,
        dateInterview: [],
      };
  
      const updatedDateInterview = activeDayData.dateInterview.map((date, i) => {
        if (i === index) {
          let updatedDate = { ...date };
  
          // Handle the case where fromTime is being updated
          if (field === 'fromTime') {
            updatedDate.fromTime = value;
            
            // Ensure that fromTime is selected before toTime
            if (!updatedDate.fromTime) {
              toast.error('Please select fromTime first.');
              return date; // Revert to previous state
            }
            
            // If toTime is already set, validate it against fromTime
            if (updatedDate.toTime && updatedDate.fromTime > updatedDate.toTime) {
              toast.error('From time cannot be greater than to time.');
              updatedDate.toTime = updatedDate.fromTime; // Revert to fromTime
            }
          }
  
          // Handle the case where toTime is being updated
          if (field === 'toTime') {
            updatedDate.toTime = value;
  
            // Ensure that fromTime is selected before toTime
            if (!updatedDate.fromTime) {
              toast.error('Please select fromTime first.');
              return date; // Revert to previous state
            }
  
            // Validate that toTime is not earlier than fromTime
            if (updatedDate.toTime < updatedDate.fromTime) {
              toast.error('To time cannot be earlier than from time.');
              updatedDate.toTime = updatedDate.fromTime; // Revert to fromTime
            }
          }
  
          return updatedDate;
        }
        return date;
      });
  
      // If the dateInterview array is empty, add a default entry
      if (updatedDateInterview.length === 0) {
        updatedDateInterview.push({ fromTime: '', toTime: '' });
      }
  
      return {
        ...prevData,
        [activeDay]: {
          ...activeDayData,
          dateInterview: updatedDateInterview,
        },
      };
    });
  };
  

  useEffect(() => {
    setAvailabilityData((prevData) => {
      const activeDayData = prevData[activeDay] || {};
      const defaultData = {
        hours: false,
        hoursUnavailable: false,
        dateInterview: activeDayData.dateInterview || [
          { fromTime: "", toTime: "" },
        ],
      };

      return {
        ...prevData,
        [activeDay]: {
          ...defaultData,
          ...activeDayData,
        },
      };
    });
  }, [activeDay, dates]);

  const getOne = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getAllOneAvailability/${onId}`)
      .then((response) => {
        const responseData = response.data;

        if (
          responseData &&
          responseData.daysAvailability &&
          Array.isArray(responseData.daysAvailability)
        ) {
          const updatedAvailabilityData = {};
          responseData.daysAvailability.forEach((dayData) => {
            const day = dayData.day;

            updatedAvailabilityData[day] = {
              hours: dayData.hours,
              hoursUnavailable: dayData.hoursUnavailable,
              dateInterview: dayData.dateInterview || [
                { fromTime: "", toTime: "" },
              ],
            };
          });

          setAvailabilityData((prevData) => ({
            ...prevData,
            ...updatedAvailabilityData,
          }));

          // Set dates state if needed
          setDates((prevDates) => ({
            ...prevDates,
            [activeDay]: responseData[activeDay]?.dateInterview || [],
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    if (onId) {
      getOne();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onId]);
  const [vaccationData, setVaccationData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [openVacation, setOpenVacation] = useState(false);
  const handleAvailabilityButtonClick = () => {
    if (Array.isArray(vaccationData) && vaccationData.length === 0) {
      // Open create popup
      setEditId(null);
      // onCloseAvaial();
      setOpenVacation((prevOpen) => !prevOpen);
    } else {
      const idToEdit = vaccationData[0]?._id;
      setEditId(idToEdit);
      // onCloseAvaial();
      setOpenVacation((prevOpen) => !prevOpen);
    }
  };
  const closeVacaction = () => {
    setOpenVacation(false);
    getOneUser(user);
  };
  const getOneUser = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-vaccation-user/${id}`)
      .then((response) => {
        setVaccationData(response.data.vaccation);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    if (user) {
      getOneUser(user);
    }
  }, [user]);

  return (
    <TimePickerContainer>
      <ToastContainer />
      <div className="modalBody">
        <div className="avail-day">
          {Object.keys(availabilityData).map((day) => (
            <div
              key={day}
              onClick={() => handleTabChange(day)}
              style={{
                padding: "12px",
                cursor: "pointer",
                // border: '2px solid #E1DAE7',
                background: activeDay === day ? "#362A41" : "none",
                color: activeDay === day ? "#FFF" : "#362A41",
                border: "2px solid #AE9CBF",
                borderRadius: "50%",
                width: "42px",
                height: "42px",
                margin: "0 5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {day.charAt(0)}
            </div>
          ))}
        </div>

        <div className={height ? "aval-pick" : "aval-picks"}>
          <div>
            <Label>
              <div style={{ fontSize: "24px", marginTop: "20px" }}>
                {activeDay}
              </div>
            </Label>
            <CheckboxContainer></CheckboxContainer>
          </div>

          {availabilityData[activeDay] &&
            availabilityData[activeDay]?.dateInterview && (
              <div>
                {availabilityData[activeDay]?.dateInterview.map(
                  (date, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "10px",
                        position: "relative",
                      }}
                    >
                      <Labels>
                        <p>
                          Start Time:<span className="text-danger">*</span>
                        </p>

                        <input
                          type="time"
                          value={date.fromTime}
                          style={{
                            padding: "12px",
                            borderRadius: "8px",
                            width: "150px",
                          }}
                          onChange={(e) =>
                            handleDateChange(index, "fromTime", e.target.value)
                          }
                        />
                      </Labels>
                      <Labels>
                        <p>
                          End Time:<span className="text-danger">*</span>
                        </p>
                        <input
                          type="time"
                          id="time"
                          value={date.toTime}
                          style={{
                            padding: "12px",
                            borderRadius: "8px",
                            width: "150px",
                          }}
                          onChange={(e) =>
                            handleDateChange(index, "toTime", e.target.value)
                          }
                        />
                      </Labels>
                      {availabilityData === "" ? (
                        ""
                      ) : (
                        <DeleteWrapper onClick={() => handleRemoveDate(index)}>
                          <img src={Delete} alt="img" />
                        </DeleteWrapper>
                      )}
                    </div>
                  )
                )}
                <SlotWrapper>
                  <Slot onClick={handleAddDate}>
                    <img src={Plus} alt="img" />
                    <p>Add Slot</p>
                  </Slot>
                </SlotWrapper>
              </div>
            )}
        </div>
      </div>
      <div
        className="modalFooter modalfooters"
        style={{ justifyContent: "space-between" }}
      >
        {/* <ButtonContainer> */}
        <Vacation onClick={handleAvailabilityButtonClick}>
          <img src={Calender} alt="img" />
          <div>Add Vacation</div>
        </Vacation>
        {onId ? (
          <SaveButton
            className="button2"
            style={{ width: "85px", height: "43px", padding: "12px" }}
            onClick={handleUpdate}
          >
            Update
          </SaveButton>
        ) : (
          <button
            className="button2"
            style={{ width: "120px", height: "43px", padding: "12px" }}
            onClick={handleSubmit}
          >
            Save & Next
          </button>
        )}
        {/* </ButtonContainer> */}
      </div>
      <Dialog
        open={openVacation}
        PaperProps={{
          style: {
            width: "100%",
            minHeight: "75vh",
            borderRadius: "24px",
            maxWidth: "400px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          {/* <TitleHeadar> */}
          <div>
            <Title>Add Vacation</Title>
          </div>
          <div>
            {/* <IoCloseCircleOutline
              size={20}
              onClick={() => setOpenVacation(false)}
            /> */}
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setOpenVacation(false)}
            />
          </div>
          {/* </TitleHeadar> */}
        </div>
        <DialogContent>
          <AddVacation id={editId} onClose={closeVacaction} />
        </DialogContent>
      </Dialog>
    </TimePickerContainer>
  );
};

export default AvailabilityTimePicker;
