import React from "react";
import xalogo from "../images/xakallogo.png";

const MobileHeader = ({ hTitle, onlyLogo = false }) => {
  return (
    <>
      {onlyLogo ? (
        <div className="o-mobile" style={{ padding: "10px" }}>
          <img src={xalogo} className="login-logo" alt="XAKAL" />
        </div>
      ) : (
        <div className="top-header">
          <h2 className="th-title">{hTitle}</h2>
          {/* <div>
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div> */}
        </div>
      )}
    </>
  );
};

export default MobileHeader;
