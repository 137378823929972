import React, { useState, useRef } from "react";
import { Dialog, Slider } from "@material-ui/core";
import Webcam from "react-webcam";
import { dataURLToBlob } from "blob-util";
import AvatarEditor from "react-avatar-editor";
import "./click.css";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import Camera from "../../images/Group 626979.svg";
import Upload from "../../images/Camera.svg";

const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 16px;
  color: white;
  padding: 12px 18px;
  margin: 15px 0;
  cursor: pointer;
  width: 85px;
  height: 43px;
  display:flex;
  justify-content:center;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  width: 104px;
  height: 43px;
  border-radius: 10px;
  border: 1px solid rgba(54, 42, 65, 0.1);
  // background: rgba(54, 42, 65, 0.1);
  font-weight: normal;
  font-size: 16px;
  color: #362a41;
  padding: 12px 18px;
  margin: 15px 0;
  display:flex;
  justify-content:center;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Span = styled.div``;

const PopDiv = styled.div`
  // position: relative;
  // display: flex;
  // justify-content: space-around;
  margin: 15px 0 30px 10px;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 0 10px 10px;
  cursor: pointer;
`;
const TextDiv = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 15px;
  cursor:pointer;
`;
const ButtonDiv = styled.div`
  // position: relative;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 3px 10px 20px 10px;
  gap: 15px;
  height: 75px;
`;
export default function ClickOrUpload({ selectedImage, closeAll }) {
  let editor = "";
  const [isCamera, setIsCamera] = useState(false);
  const [isMenu, setIsMenu] = useState(true);
  const webcamRef = useRef(null);

  const [url, setUrl] = React.useState(null);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Man_Silhouette.png",
  });

  const fileInputRef = useRef(null);

  // Function to handle file selection
  const handleFileSelect = () => {
    // Access the selected file using the ref
    const selectedFile = fileInputRef.current.files[0];

    if (selectedFile) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add 'image/hpg' if it's a custom image format
      const maxSize = 12 * 1024 * 1024; // 2MB in bytes// 50KB in bytes
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSize) {
          console.log("Selected file:", selectedFile);

          // Here, you can set the URL in the state or perform any other actions with the file.
          // For this example, I'm setting the URL in the state:
          setUrl(selectedFile);
          setIsMenu(false);
        } else {
          // Show a toaster message for files exceeding the maximum size
          showMaxSizeToast();
        }
      } else {
        // Show a toaster message for invalid file types
        showInvalidTypeToast();
      }
    } else {
      console.log("No file selected.");
    }
  };
  const showMaxSizeToast = () => {
    // Implement your toaster message for max size here
    toast.error("File size exceeds the maximum allowed 12MB.");
  };

  const showInvalidTypeToast = () => {
    // Implement your toaster message for invalid file type here
    toast.error(
      "Invalid file type. Only JPEG, PNG, and JPG images are allowed."
    );
  };
  const setEditorRef = (ed) => {
    editor = ed;
  };
  //   Use Camera Starts
  const videoConstraints = {
    width: 600,
    facingMode: "environment",
  };

  const capturePhoto = React.useCallback(async () => {
    setIsCamera(false);
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = dataURLToBlob(imageSrc);

    // Create a File from the Blob
    const imageFile = new File([blob], "captured_image.png", {
      type: "image/png",
    });

    // Now you can upload `imageFile` to your server or use it as needed
    console.log(imageFile, "url2");
    setUrl(imageFile);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };
  // Use Camera Ends

  // crop image
  // const handleSlider = (level) => {
  //   let value = zoomCounter;
  //   if (level == 1) {
  //     value = value + 1;
  //     setZoomCounter(value);
  //   }
  //   if (level == 0 && value > 1) {
  //     value = value - 1;
  //     setZoomCounter(value);
  //   }
  //   setPicture({
  //     ...picture,
  //     zoom: value,
  //   });
  // };

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };
  const handleSliders = (action) => {
    let newZoom = picture.zoom;

    if (action === "zoomIn") {
      newZoom += 0.1; // or any other desired step
    } else if (action === "zoomOut" && newZoom > 1) {
      newZoom -= 0.1; // or any other desired step
    }

    setPicture((prevPicture) => ({
      ...prevPicture,
      zoom: newZoom,
    }));
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      canvasScaled.toBlob((blob) => {
        // Create a File object from the Blob
        const fileName = "cropped_image.png"; // Customize the filename
        const file = new File([blob], fileName, { type: "image/png" });

        // Update the 'url' state with the cropped image blob
        setUrl(false);
        try {
          // Perform the asynchronous save operation
          save(file);
        } catch (error) {
          // Handle any error that occurred during the save operation
          console.error("Error saving the file:", error);
        } finally {
          // Step 3: Set loading state back to false
          setIsLoading(false);

          // Step 4: Close the popup only if there was no error
          !isLoading && closeAll();
        }
        // Perform any further actions, like form submission
        console.log("Cropped image converted to File:", file);
      });
    }
  };

  const selectMediaType = (type) => {
    setIsMenu(false);
    if (type === "camera") {
      setIsCamera(true);
    }
  };

  const save = (val) => {
    let formData = new FormData();
    formData.append("image", val);
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
      .then((res) => {
        console.log("file upload res", res.data.url);
        selectedImage(res.data.url);
      });
  };
  const cancel = () => {
    setUrl(null);
    setIsMenu(true);
    setIsCamera(false);
  };
  return (
    <div className="">
      <ToastContainer />
      <Dialog
        open={isMenu}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "24px", width: "290px" },
        }}
      >
        <div className="modalHeader modalHeaders">

          <div>
            <Span>Add your photo</Span>
          </div>

          <div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => closeAll()}
            />
          </div>

        </div>
        <DialogContent>
          <PopDiv>
            <div onClick={() => selectMediaType("camera")}>
              <Wrapper>
                <img src={Camera} alt="img" />
                <TextDiv>Capture</TextDiv>
              </Wrapper>
            </div>
            <input
              className="file-btn"
              type="file"
              id="file-input"
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept=".jpg, .jpeg, .png"
            />

            <div style={{}} onClick={() => fileInputRef.current.click()}>
              {/* <PiUploadSimple size={50} color="grey" /> */}
              <Wrapper style={{ marginTop: "20px" }}>
                <img src={Upload} alt="img" />
                <TextDiv>Upload</TextDiv>
              </Wrapper>
            </div>
          </PopDiv>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isCamera}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", width: "420px" },
        }}
      >
        <DialogTitle className="modalHeader" style={{ borderBottom: "0.5px solid #F4F4F6" }}>
          
          <div>
            <Span>Capture photo</Span>
          </div>

          <div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => closeAll()}
              style={{ cursor: "pointer", marginTop: "10px", marginRight: "25px", }}
            />
          </div>
          
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              // minHeight:"370px",
            }}
          >
            <Webcam
              ref={webcamRef}
              // audio={true}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={onUserMedia}
              style={{
                width: "370px",
                // height: "270px",
                borderRadius: "8px",
                marginTop: "10px",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "0", marginTop: "10px" }}>
          <ButtonDiv>
            <Button8 onClick={() => setUrl(null)}>Refresh</Button8>
            <Button9 onClick={capturePhoto} style={{ width: "110px" }}>
              Capture
            </Button9>
          </ButtonDiv>
        </DialogActions>
      </Dialog>
      <Dialog
        open={url}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "20px",
            width: "420px",
            padding: "0px",
          },
        }}
      >
        <div className="modalHeader">
          <div >Edit Photo</div>

          <div>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => closeAll()}
            />
          </div>
        </div>
        <div
          
        >
          
            <AvatarEditor
              ref={setEditorRef}
              image={url}
              width={340}
              height={240}
              border={40}
              color={[180, 180, 180, 0.6]} // RGBA
              rotate={0}
              scale={picture.zoom}
            />
          
          <div className="myp-edit-scroll" >
            <LuMinusCircle
              size={30}
              className="myp-edit-plus"
              onClick={() => handleSliders("zoomOut")}
            />

            <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
            // style={{ marginTop: "-5px" }}
            ></Slider>
            <LuPlusCircle
              size={30}
              className="myp-edit-plus"
              onClick={() => handleSliders("zoomIn")}
            />
          </div>
        </div>
        <DialogActions>
          <ButtonDiv>
            <Button8 onClick={() => cancel()}>Cancel</Button8>
            <Button9 onClick={handleSave} disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </Button9>
          </ButtonDiv>
        </DialogActions>
      </Dialog>
    </div>
  );
}
