import React, { useEffect, useState } from "react";
import DesktopHeader from "../desktopHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import avatar1 from "../../images/company.png";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Common from "../../utils/common";
import { Auth } from "aws-amplify";
import Settings from "../settings";
import "./deactivate.css";
import CloseIcon from "../../images/CloseIcon.svg";
import AddIcon from "../../images/add-bg.svg";

const DeActpad = styled.div`
  //padding: 0.5rem;
`;

const HeaderLayout = styled.div``;

const CreateNew = styled.div`
  width: 100%;
  max-width: 300px;
  height: 165px;
  background: #ececef;
  padding: 53px 104px 53px 103px;
  border-radius: 16px;
  display: flex;
  margin: 20px 30px 20px 20px;
  justify-content: space-around;
  @media (min-width: 1440px) {
    margin-right: 60px;
  }
`;

const CreateNewText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-family: var(--pf-medium);
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  color: #362a41;
  margin: auto;
  margin-top:-15px;
`;

const CompanyText = styled.div``;

const Heading = styled.div`
  color: #362a41;
  font-family: var(--pf-medium);
  font-size: 24px;
  font-weight: normal;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  // justify-content: center;
`;

const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const DeActHead = styled.div`
  width: auto;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
  margin: 20px 10px;
`;
const DeActContent = styled.div`
  width: 379px;
  height: 66px;
  flex-shrink: 0;
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 22.4px */
  margin: 20px 10px;
`;
const DeActContents = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  margin: 2px;
  margin-left: 10px;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: end;
  cursor: pointer;
  padding: 16px 16px 16px 16px;
  height: 75px;
  background: #fff;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  // left:-5px;
  // right:-3px;
  margin-left: -10px;
`;
const Button = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(54, 42, 65, 0.12);
  background: #fff;
`;
const Buttons = styled.div`
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #fd2145;
`;

const Description = styled.div``;
const ViewCompany = ({ props }) => {
  // eslint-disable-next-line no-unused-vars
  const [isListVisible, setListVisible] = useState(null);

  const history = useHistory();

  const [company, setCompany] = useState();
  const [companyList, setCompanyList] = useState([]);
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      .then((response) => {
        setCompany(response.data.company);
        // Call GetAllAdmin after fetching user companies
        GetAllAdmin(response.data.company);
      })
      .catch((err) => console.error(err));
  };

  const GetAllAdmin = (userCompanies) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/manage-admin`)
      .then((response) => {
        // Filter out duplicate companies based on companyId
        const filteredCompanies = response.data.companies.filter(
          (adminCompany) =>
            !userCompanies?.some(
              (userCompany) => userCompany.companyId === adminCompany.companyId
            )
        );
        setCompanyList(filteredCompanies);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [loginId, setLoginId] = useState();

  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setLoginId(response.data?.userId);
      })
      .catch((err) => err.error);
  };

  // eslint-disable-next-line no-unused-vars
  const [adminList, setAdminList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [institute, setInstitute] = useState();
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        setInstitute(response.data.company);
        GetAllInstituteAdmin(response.data.company);
      })
      .catch((err) => err.error);
  };
  const GetAllInstituteAdmin = (userCompanies) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute-admin`)
      .then((response) => {
        const filteredCompanies = response.data.companies.filter(
          (adminCompany) =>
            !userCompanies.some(
              (userCompany) =>
                userCompany.instituteId === adminCompany.instituteId
            )
        );
        setAdminList(filteredCompanies);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUser();
    GetAllCompany();
    GetAllAdmin();
    GetAllInstituteAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [success, setSuccess] = useState(false);
  const [logsuccess, setLogSuccess] = useState(false);

  const [selectedId, setSelectedId] = useState();

  const handleOpen = (id) => {
    setSuccess(true);
    setSelectedId(id);
  };

  const deactivateCompany = () => {
    const checkbox = document.getElementById("confirmationCheckbox");
    if (!checkbox.checked) {
      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`)
      .then((response) => {
        setSuccess(false);
        GetAllCompany();
        toast.success("Company is Successfully Deactivated");
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
  };

  useEffect(() => {
    GetAllInstitute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [user, setUsers] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  const logout = () => {
    Common.logout();
  };

  const [openSetting, setOpenSetting] = useState(false);

  const searchedKey = () => {};
  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const getAllPending = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-pending-feedback`)
      .then((res) => {
        setPendingFeedback(res.data.book);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);
  return (
    <>
      {" "}
      <ToastContainer />
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={logsuccess}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setLogSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <HeaderLayout>
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Organization"}
          searchedKey={searchedKey}
          isSearchActive={false}
        />
        {/* End of desktop Header  */}
      </HeaderLayout>
      {/* Need this for future enchacement  */}
      {/* {openPending && (
        <div className="pending-flex">
          <p>
            You have {pendingFeedbackData.length} pending feedback. Please
            provide your feedback to receive the interview fees.
          </p>
          <button onClick={provideFeedback}>Provide Feedback</button>
          <button onClick={() => setOpenPending(false)}>X</button>
        </div>
      )} */}
      <div className="in-content-wrapper company-wrapper">
        <Heading>Companies</Heading>
        <div className="myprofile-row">
          <div>
            <CreateNew
              style={{ flexDirection: "column", cursor: "pointer" }}
              onClick={() => history.push("/portal/company")}
            >
            
              <img
                src={AddIcon}
                alt="img"
                style={{ width: "80px", height: "80px", margin: "auto" }}
              />
              
              <CreateNewText>Create New</CreateNewText>
            </CreateNew>
          </div>

          {company &&
            company
              .filter((com) =>
                com.companyDomain.some((domain) => {
                  return (
                    domain.userId === loginId && domain.role.superAdmin === true
                  );
                })
              )
              .map((com, index) => {
                return (
                  <div className="Myprofile-containerNew">
                    <div
                      className="Myprofile-topcontainer"
                      style={{ height: "74px" }}
                    >
                      {/* <div className="myp-avatar" style={{}}> */}
                      {com.companyLogo ? (
                        <>
                          <img
                            src={com.companyLogo}
                            alt="img"
                            style={{
                              width: "32px",
                              height: "32px",
                              margin: "auto",
                              marginTop: "55px",
                              borderRadius: "50%",
                            }}
                          />
                        </>
                      ) : (
                        <img
                          src={avatar1}
                          alt="img"
                          style={{
                            width: "32px",
                            height: "32px",
                            margin: "auto",
                            marginTop: "55px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {/* </div> */}
                    </div>
                    <div
                      className="myp-wrapper2"
                      style={{
                        display: "block",
                        background: "white",
                        paddingBottom: "0",
                      }}
                    >
                      <CompanyText
                        className="company-name"
                        onClick={() =>
                          history.push({
                            pathname: `/portal/edit-companyview/${com.companyId}`,
                            state: {
                              website: com && com.website,
                            },
                          })
                        }
                      >
                        {com.companyName}
                      </CompanyText>
                      <Description className="company-des">
                        {`${com.organisationType} | `}

                        {com.additionalAddress.map((x) => {
                          return x.city + "," + x.state;
                        })}
                      </Description>

                      {isListVisible === com.companyId && (
                        <div className="DeActWrapper">
                          <div></div>
                          <div
                            className="DeActPage"
                            onClick={() => handleOpen(com._id)}
                          >
                            Deactivate Page
                          </div>
                        </div>
                      )}
                      <div className="Edu-data-wrapper3 social-container"></div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="in-content-wrapper company-wrapper">
        <div className="myprofile-row">
          {companyList &&
            companyList.map((com, index) => {
              return (
                <div className="Myprofile-containerNew">
                  <div
                    className="Myprofile-topcontainer"
                    style={{ height: "74px" }}
                  >
                    {/* <div className="myp-avatar" style={{}}> */}
                    {com.companyLogo ? (
                      <>
                        <img
                          src={com.companyLogo}
                          alt="img"
                          style={{
                            width: "32px",
                            height: "32px",
                            margin: "auto",
                            marginTop: "55px",
                            borderRadius: "50%",
                          }}
                        />
                      </>
                    ) : (
                      <img
                        src={avatar1}
                        alt="img"
                        style={{
                          width: "32px",
                          height: "32px",
                          margin: "auto",
                          marginTop: "55px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    {/* </div> */}
                  </div>
                  <div
                    className="myp-wrapper2"
                    style={{
                      display: "block",
                      background: "white",
                      paddingBottom: "0",
                    }}
                  >
                    <CompanyText
                      className="company-name"
                      onClick={() =>
                        history.push({
                          pathname: `/portal/edit-companyview/${com.companyId}`,
                          state: {
                            website: com && com.website,
                          },
                        })
                      }
                    >
                      {com.companyName}
                    </CompanyText>
                    <Description className="company-des">
                      {`${com.organisationType} | `}

                      {com.additionalAddress.map((x) => {
                        return x.city + "," + x.state;
                      })}
                    </Description>

                    {isListVisible === com.companyId && (
                      <div className="DeActWrapper">
                        <div></div>
                        <div
                          className="DeActPage"
                          onClick={() => handleOpen(com._id)}
                        >
                          Deactivate Page
                        </div>
                      </div>
                    )}
                    <div className="Edu-data-wrapper3 social-container"></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Dialog
        open={success}
        halfWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", width: "450px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "left",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            color: "#362A41",
            fontSize: "18px",
            fontweight: "normal",
          }}
        >
          Deactivate Page
          {/* <AiOutlineCloseCircle onClick={() => setSuccess(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setSuccess(false)} />
        </DialogTitle>

        <DialogContent>
          <DeActpad>
            <DeActHead>We're really sorry for your decision!</DeActHead>
            <DeActContent>
              Deactivation will remove the page entirely from Xakal and your
              company admins will no longer will have the access to the page
            </DeActContent>

            <div style={{ display: "flex", marginLeft: "10px" }}>
              {/* <label for="input" id="confirmationCheckbox" > */}
              <input
                style={{}}
                type="checkbox"
                id="confirmationCheckbox"
                className="deact"
              />
              {/* </label> */}
              <DeActContents>
                I hereby confirm that deactivate the Company page
              </DeActContents>
            </div>
          </DeActpad>
        </DialogContent>
        <DialogActions>
          <Wrapper>
            <Button onClick={() => setSuccess(false)} className="button5">
              Cancel
            </Button>
            <Buttons
              onClick={() => deactivateCompany(selectedId)}
              className="button3"
            >
              Deactivate
            </Buttons>
          </Wrapper>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewCompany;
