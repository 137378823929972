import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import xalogo from "../images/xakallogo.png";
import backArrow from "../images/arrow-box.svg";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import styled from "styled-components";
import PassHint from "../images/PassHint.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const Span = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  color: #362a41;
`;
const Text = styled.p`
  color: #78767a;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 25px;
`;

const Forgetpassword = (props) => {
  const [valid, setValid] = useState(false);

  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
  };
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          const uppercaseRegExp = /(?=.*?[A-Z])/;
          const lowercaseRegExp = /(?=.*?[a-z])/;
          const digitsRegExp = /(?=.*?[0-9])/;
          const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
          const minLengthRegExp = /.{8,}/;

          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter your new password";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Re-entered password doesn't match with the previous password.";
          } else if (!uppercasePassword) {
            stateObj[name] = "Atleast one uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "Atleast one lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "Atleast one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "Atleast one special characters";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least minumum 8 characters";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter confirm password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] =
              "Re-entered password doesn't match with the previous password.";
          }
          break;

        default:
          break;
      }
      if (
        stateObj[name] === "" &&
        input.password !== "" &&
        input.confirmPassword !== ""
      ) {
        setValid(true);
      }
      return stateObj;
    });
  };
  const history = useHistory();
  function handleSubmit(e) {
    handleFunction(e);
  }

  function onSubmit(e) {
    e.preventDefault();
    setSuccess(true);
  }
  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };

  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };
  const [email,setEmail] = useState()
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);
  const handleFunction = () => {
    Object.assign(input, props);
  
    const data = {
      email: email,
      newPassword: input.password,
    };
   
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/password-change`, data)
      .then((response) => {
        if (response && response.data && response.data.success) {
          if (response && response.data && response.data.user)
            axios.defaults.headers.common["x-access-token"] =
              response.data.user.token;
          document.cookie = `userid=${response.data.user._id}; path=/`;
          setSuccess(false);
          toast.success(
            "Your password has been changed and your account has been logged out from all other devices!"
          );
          setTimeout(() => {
            history.push({
              pathname: "/login",
            });
          }, 2000);
        }
      })
      .catch((error) => error.error)
  };

  return (
    <div className="login-wrapper new-passwords">
      <ToastContainer />
      <div className="form-wrapper  wrap-login100 p-b-20">
        <div className="margin-logo">
          <div className="logo-holder">
            <img
              src={xalogo}
              className="login-logo signup-xakal-logo"
              alt="XAKAL"
            />
          </div>
        </div>
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header">
              {/* <LuArrowLeftSquare
              size={30}
              style={{ marginTop: "2px" }}
              onClick={() => history.push("/")}
            /> */}
              <div
                className="arrow-box"
                onClick={() => history.push("/new-otp")}
              >
                <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
              </div>
              <h4 className="textpass font-face-gm mb-0">Forgot Password</h4>
            </div>

            <div className="form-class">
              <p className="textpass font-face-gm">New Password</p>
              <div className={"wrap-input100"}>
              <div className="pass-input-wrapper">
                <input
                  autoComplete="off"
                  type={type ? "password" : "text"}
                  className={"input101 "}
                  name="password"
                  placeholder="Enter Password"
                  value={input.password}
                  onChange={onInputChange}
                  onBlur={validateInput}
                />
                <div
                  className="pass-eye-container"
                >
                  {type ? (
                    <PiEyeSlashLight size={30} onClick={Eye} />
                  ) : (
                    <PiEyeLight size={30} onClick={Eye} />
                  )}
                </div>
                </div>
              </div>
              {error.password && (
                <p className="error-text text-danger">{error.password}</p>
              )}
              <p className="textpass font-face-gm">Re-type Password</p>
              <div className={"wrap-input100 m-b-30"}>
              <div className="pass-input-wrapper">
                <input
                  autoComplete="off"
                  type={type2 ? "password" : "text"}
                  className={"input101 "}
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  value={input.confirmPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  disabled={error.password}
                />
                <div
                  className="pass-eye-container"
                >
                  {type2 ? (
                    <PiEyeSlashLight size={30} onClick={Eye2} />
                  ) : (
                    <PiEyeLight size={30} onClick={Eye2} />
                  )}
                </div>
                </div>
              </div>
              {error.confirmPassword && (
                <p className="error-text text-danger">
                  {error.confirmPassword}
                </p>
              )}
              <div className="container-login100-form-btn">
                <button
                  type="submit"
                  className="login100-form-btn"
                  disabled={!valid}
                  style={{ opacity: valid ? "100%" : "50%" }}
                  onClick={onSubmit}
                >
                  Change Password
                </button>
              </div>
            </div>

            {error.password ? (
              <div id="message5">
                <div  className="pass-instruct mt-4 mt-md-0">
                  <div
                    style={{
                      position: "absolute",
                      left: "-15px",
                      // background: "#1F1A24",
                      color: "white",
                    }}
                  >
                    {/* <PiTrafficSign size={30} className="o-desk" /> */}
                    <img src={PassHint} alt="img" />
                  </div>

                  <div
                    style={{
                      position: "relative",
                      marginLeft: "6px",
                    }}
                  >
                    <h3 style={{ color: "white" }}>
                      Password must contain the following:
                    </h3>

                    <div className="pass-in-check">
                      <div
                        className={passwordVal.lowercase ? "check" : "uncheck"}
                      ></div>
                      <p
                        className={passwordVal.lowercase ? "valid" : "invalid"}
                      >
                        Must have 1 Lower Case Alphabet
                      </p>
                    </div>

                    <div className="pass-in-check">
                      <div
                        className={passwordVal.uppercase ? "check" : "uncheck"}
                      ></div>
                      <p
                        className={passwordVal.uppercase ? "valid" : "invalid"}
                      >
                        Must have 1 Upper Case Alphabet
                      </p>
                    </div>

                    <div className="pass-in-check">
                      <div
                        className={passwordVal.numeral ? "check" : "uncheck"}
                      ></div>
                      <p
                        id="number"
                        className={passwordVal.numeral ? "valid" : "invalid"}
                      >
                        Must have 1 Numeral Case Alphabet
                      </p>
                    </div>

                    <div className="pass-in-check">
                      <div
                        className={passwordVal.minchar ? "check" : "uncheck"}
                      ></div>
                      <p
                        id="length"
                        className={passwordVal.minchar ? "valid" : "invalid"}
                      >
                        Minimum 8 characters required
                      </p>
                    </div>

                    <div className="pass-in-check">
                      <div
                        className={
                          passwordVal.specialChar ? "check" : "uncheck"
                        }
                      ></div>
                      <p
                        className={
                          passwordVal.specialChar ? "valid" : "invalid"
                        }
                      >
                        Must have 1 Special Character [#?!@$%^&*-]
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Dialog
          open={success}
          halfWidth
          maxWidth="md"
          PaperProps={{ padding: "0" }}
        >
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Inter-Medium",
              position: "relative",
              borderBottom: "1px solid #F4F4F6",
              marginTop:"15px",
            }}
          >
            <Span className="modal-title-sub">
            <div style={{marginTop:"15px", marginLeft:"10px",padding:"15px",}}>
            Are you sure you want to proceed?
            </div> 
            </Span>
            
          </DialogTitle>
          <DialogContent>
            <Text className="modal-b-title" style={{ padding: "30px",marginLeft: "-5px",}} >
              Changing the password will logout your account from all other
              devices
            </Text>
            <div className="modalFooter">
              <button className="modal-btn2" style={{border:"1px solid rgba(54, 42, 65, 0.1)",fontweight: "normal",fontSize:"16px"}} onClick={() => setSuccess(false)}>
                NO
              </button>
              <button className="modal-btn" style={{fontweight: "normal",fontSize:"16px"}} onClick={handleSubmit}>
                YES
              </button>
              {/* <button className="modal-btn" onClick={handleSubmit}>
                {loading ? <Spinner /> : "YES"}
              </button> */}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default Forgetpassword;
