/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import "../../styles/myprofile.css";
import axios from "axios";
import CloseIcon from "../../images/CloseIcon.svg";
import styled from "styled-components";
import DownArrow from "../../images/Downarrow.svg";
import Back from "../../images/Back.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.Selected ? "#C6C6C6" : "#C6C6C6",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    zIndex: "999",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  margin-top: 3px;
  margin-bottom: 10px;
`;
const EditTimePicker = ({
  input,
  id,
  interviewId,
  open,
  slotId,
  serviceId,
  onClose,
  close,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(true);
  const [data, setData] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [des, setDesi] = useState("");
  const [allAvailableTimeSlots, setAllAvailableTimeSlots] = useState([]);
  const history = useHistory();

  const handleTimeButtonClick = (time) => {
    const istTime = moment.tz(time, "h:mm A", "Asia/Kolkata");

    const unselectedTimes = getAvailableTimeSlots().filter((availableTime) =>
      istTime.isBefore(moment.tz(availableTime, "h:mm A", "Asia/Kolkata"))
    );

    setSelectedTime(istTime.format("h:mm A"));
    setAllAvailableTimeSlots([...allAvailableTimeSlots, ...unselectedTimes]);
  };

  const handleDatePickerClick = (date) => {
    if (
      moment(date).isSame(selectedDateFromGetOneBooking, "day") &&
      selectedTime
    ) {
      setSelectedTime(selectedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const [isInitialLoad, setInitialLoad] = useState(true);

  const fetchAvailabilityForDate = () => {
    // Perform the necessary actions to get availability for the specified date
    // Update the state or perform other logic based on the fetched availability

    // eslint-disable-next-line no-unused-vars
    const availability = getAvailableTimeSlots(); // You can replace this with the actual logic to fetch availability
  };
  const [selectedDateFromGetOneBooking, setSelectedDateFromGetOneBooking] =
    useState(null);
  const handleDateChange = (date) => {
    if (des === "") setError({ des: "Please select designation" });
    else setSelectedDate(date);
    setSelectedTime(null);
    getOneBooking(slotId);

    if (isDatePreFilledFromGetOneBooking(date)) {
      getOneBookings(slotId);
      setSelectedTime(selectedTime);
    } else {
      setSelectedTime(null);
      getOneBooking(slotId);
      fetchAvailabilityForDate(date);
    }
  };

  const isDatePreFilledFromGetOneBooking = (date) => {
    return (
      selectedDateFromGetOneBooking &&
      moment(date).isSame(selectedDateFromGetOneBooking, "day")
    );
  };

  const getOneBooking = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
      .then((res) => {
        setDesi(res.data.bookingSlot?.designation);

        const dateTimeFromApi = res.data.bookingSlot?.date;
        const momentDateTime = moment(dateTimeFromApi);

        if (!selectedDateFromGetOneBooking) {
          setSelectedDate(
            momentDateTime.isValid() ? momentDateTime.toDate() : null
          );
          setSelectedTime(res.data.bookingSlot?.time);
          setSelectedDateFromGetOneBooking(
            momentDateTime.isValid() ? momentDateTime.toDate() : null
          );
        }

        if (isInitialLoad && momentDateTime.isValid()) {
          fetchAvailabilityForDate(momentDateTime.toDate());
          setInitialLoad(false);
        } else {
          // Fetch availability for the current date
          fetchAvailabilityForDate(selectedDate);
        }
      })
      .catch((res) => {
        console.error(res);
      });
  };

  // Add a new state for selectedDateFromGetOneBooking

  useEffect(() => {
    fetchAvailabilityForDate(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasFetchedBooking, setHasFetchedBooking] = useState(false);

  useEffect(() => {
    if (isInitialLoad) {
      fetchAvailabilityForDate();
      setInitialLoad(false);
    }

    if (slotId && !hasFetchedBooking) {
      getOneBooking(slotId);
      setHasFetchedBooking(true);
    }
    // Fetch availability when date changes
    if (selectedDate) {
      fetchAvailabilityForDate(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, isInitialLoad, slotId, hasFetchedBooking]);

  const getOneBookings = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${id}`)
      .then((res) => {
        setDesi(res.data.bookingSlot?.designation);

        const dateTimeFromApi = res.data.bookingSlot?.date;
        const momentDateTime = moment(dateTimeFromApi);

        setSelectedDate(
          momentDateTime.isValid() ? momentDateTime.toDate() : null
        );
        setSelectedTime(res.data.bookingSlot?.time);

        if (isInitialLoad && momentDateTime.isValid()) {
          // Fetch availability for the pre-filled date
          fetchAvailabilityForDate(momentDateTime.toDate());
          setInitialLoad(false);
        } else {
          // Fetch availability for the current date
          fetchAvailabilityForDate(selectedDate);
        }
      })
      .catch((res) => {
        console.error(res);
      });
  };

  const getAvailableTimeSlots = () => {
    const currentDate =
      selectedDate || new Date() || selectedDateFromGetOneBooking;
    const selectedDayFullName = moment(currentDate, "ddd").format("dddd");

    const selectedDayAvailability = data[0]?.daysAvailability?.find(
      (availability) => availability.day === selectedDayFullName
    );

    if (selectedDayAvailability) {
      if (selectedDayAvailability.hours) {
        const startHour = 0;
        const allSlots = [];

        for (let hour = startHour; hour < startHour + 24; hour++) {
          const currentTime = moment().startOf("day").add(hour, "hours");

          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(currentTime.minutes() / 30) * 30;
          currentTime.minutes(roundedMinutes);

          allSlots.push(currentTime.format("h:mm A"));
        }

        return allSlots;
      }

      const { dateInterview } = selectedDayAvailability;

      const availableSlots = dateInterview.filter(
        (slot) => slot.fromTime !== "" && slot.toTime !== ""
      );

      const formattedSlots = availableSlots.reduce((acc, slot) => {
        const fromTime = moment(slot.fromTime, "HH:mm");
        const toTime = moment(slot.toTime, "HH:mm");

        for (
          let current = fromTime.clone();
          current.isBefore(toTime);
          current.add(30, "minutes")
        ) {
          // Round the minutes to the nearest 30 minutes
          const roundedMinutes = Math.ceil(current.minutes() / 30) * 30;
          current.minutes(roundedMinutes);

          acc.push(current.format("h:mm A"));
        }

        return acc;
      }, []);

      return [...new Set(formattedSlots)].sort();
    }

    return [];
  };
  const getAllAvialabilityService = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-all-availability-user/${interviewId}`
      )
      .then((response) => {
        setData(response.data.interview);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [vacationDates, setVacationDates] = useState([]);

  const getAllVaccationService = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-all-vaccation-user/${interviewId}`
      )
      .then((response) => {
        const vacations = response.data.vaccation[0]?.vacation; // Assuming data is in response.data[0].vacation
        const disabledDates =
          vacations && vacations?.map((vacation) => new Date(vacation));
        setVacationDates(disabledDates || []);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [designation, setDesignation] = useState([]);
  const [price, setPrice] = useState();
  const [duration, setDuration] = useState();
  const [serviceTitle, setServiceTitle] = useState("");
  const [candidateDetail, setCandidateDetail] = useState();
  const [free, setFree] = useState(false);
  const handleChange = (selectedOptions) => {
    const selectedValue = selectedOptions.value;
    setDesi(selectedValue);
    setError({ des: "" });
  };
  const [interviewCode, setInterviewCode] = useState(null);
  const getOneInterview = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${serviceId}`)
      .then((response) => {
        if (response.data && response.data.interview.designation.length > 0) {
          setDesignation(response.data.interview.designation);
        }
        if (
          response.data &&
          response.data.interview.candidateDetail.length > 0
        ) {
          const bookingDetails = response.data.interview.candidateDetail.map(
            (item) => ({
              date: item.bookingService.date,
              time: item.bookingService.time,
            })
          );
          setCandidateDetail(bookingDetails);
        }
        setPrice(response.data.interview.inr);
        setDuration(response.data.interview.duration);
        setServiceTitle(response.data.interview.interviewTitle);
        setFree(response.data.interview.inrService);
        setInterviewCode(response.data.interview?.interviewCodeId);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [userId, setUser] = useState({});
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getAllAvialabilityService();
    getAllVaccationService();
    getOneInterview();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const optionsOne = designation.map((desc) => ({
    value: desc,
    label: desc,
  }));
  const [errors, setError] = useState({ des: "" });
  const handleSubmit = () => {
    debugger;
    const payload = {
      interviewId: interviewId,
      candidateId: userId.userId,
      serviceId: serviceId,
      designation: des,
      date: selectedDate,
      time: selectedTime,
      price: price,
      isFree: free,
      unselectedTimeSlots: adjustTimeAndFormat(selectedTime),
      duration: duration,
      serviceName: serviceTitle,
      interviewCodeId: interviewCode,
    };
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/get-update-time/${slotId}`, payload)
      .then((response) => {
        const data = response.data.bookingSlotUpdate?._id;
        open(data.toString());
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const selectedDayAvailability = data[0]?.daysAvailability;

  const unavailableDays = selectedDayAvailability
    ? selectedDayAvailability
        .map((day) => {
          const currentDate = new Date();
          const dayIndex = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].indexOf(day.day);

          const shouldDisableDay = day.dateInterview.some(
            (interview) =>
              (interview.fromTime.trim() !== "" &&
                interview.toTime.trim() === "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() !== "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() === "")
          );

          if (shouldDisableDay) {
            const datesToDisable = [];
            for (let month = 0; month < 12; month++) {
              const lastDayOfMonth = new Date(
                currentDate.getFullYear(),
                month + 1,
                0
              ).getDate();
              for (let i = 1; i <= lastDayOfMonth; i++) {
                const nextOccurrenceDate = new Date(
                  currentDate.getFullYear(),
                  month,
                  i
                );
                if (nextOccurrenceDate.getDay() === dayIndex) {
                  const isoDate = nextOccurrenceDate.toISOString();
                  datesToDisable.push(
                    isoDate.substring(0, isoDate.indexOf("T") + 1) +
                      "18:30:00.000Z"
                  );
                }
              }
            }

            return datesToDisable;
          }

          return null; // Return null for days that should not be disabled
        })
        .flat() // Flatten the array of dates
        .filter((date) => date !== null) // Filter out null values
    : [];

  const combinedExcludedDates = [...unavailableDays, ...(vacationDates || [])];
  const onClick = () => {
    onClose();
    history.push("/portal/interview/exploreInterview");
  };
  const [inputs, setInputs] = useState();
  const prefillValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${interviewId}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setInputs({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
          });
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    prefillValue();
  }, []);
  const adjustTimeAndFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0; // Midnight
    }

    let adjustedMinutes = minutes + 30;
    if (adjustedMinutes >= 60) {
      adjustedHours += 1;
      adjustedMinutes -= 60;
    }

    const adjustedPeriod = adjustedHours >= 12 ? "PM" : "AM";
    const formattedHours = adjustedHours % 12 || 12; // Convert hours from 24-hour to 12-hour format

    const formattedTime = `${formattedHours}:${
      adjustedMinutes < 10 ? "0" : ""
    }${adjustedMinutes} ${adjustedPeriod}`;

    return formattedTime;
  };
  return (
    <div style={{ maxWidth: selectedDate != null ? "580px" : "580px" }}>
      <div className="modalHeader mheader">
        <div className="mTitle modTitle">
          <img src={Back} alt="back" onClick={onClick} className="head-img" />
          {inputs ? (
            <>
              {`${inputs?.firstName} ${inputs?.middleName} ${inputs?.lastName}`}
            </>
          ) : (
            <>{`${input?.firstName} ${input?.middleName} ${input?.lastName}`}</>
          )}

          <div
            style={{
              color: "rgba(168, 161, 172, 1)",
              marginLeft: "3px",
              fontSize: "16px",
              fontweight: "normal",
            }}
          >
            Interviewer
          </div>
        </div>
        <img src={CloseIcon} alt="img" onClick={onClose} />
      </div>
      <div className="modalBody" style={{ minHeight: "450px" }}>
        <div className={selectedDate ? "pos-fixed" : "pos-static"}>
          <Title>Select the designation you want to be interviewed for</Title>
          <Select
            value={optionsOne.find((option) => option.value === des)}
            options={optionsOne}
            onChange={handleChange}
            styles={customStyle}
            menuPlacement="top"
            components={{ Placeholder, DropdownIndicator }}
          />

          {/* {errors.des && <div className="error-message">{errors.des}</div>} */}
          <Title>Select date & time</Title>
        </div>
        <div>
          <DatePicker
            open={isDatePickerOpen}
            onClose={() => setDatePickerOpen(false)}
            onCalendarOpen={() => setDatePickerOpen(true)}
            onChange={handleDateChange}
            excludeDates={combinedExcludedDates.map(
              (isoDate) => new Date(isoDate)
            )}
            selected={selectedDate}
            onClickDay={handleDatePickerClick}
            showTimeSelect={false}
            minDate={new Date()}
          />
          <div className="edit-picker-time">
            {getAvailableTimeSlots().map((time, index) => {
              const isDisabled =
                candidateDetail &&
                candidateDetail.some(
                  (bookingDetail) =>
                    moment(bookingDetail.date).isSame(selectedDate, "day") &&
                    bookingDetail.time === time
                );

              return (
                <button
                  className={`button10 ${
                    selectedDate && selectedTime === time ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => handleTimeButtonClick(time)}
                  disabled={isDisabled}
                  style={{ opacity: isDisabled ? 0.5 : 1 }}
                >
                  {isDisabled ? `${time} Slot booked` : time}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {/* {isNextButtonVisible && selectedTime && ( */}
      <div
        className="modalFooter"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 20px",
          // marginTop: "20px",
        }}
      >
        <button
          className="button3"
          onClick={handleSubmit}
          disabled={!selectedTime}
          style={{ opacity: selectedTime ? "100%" : "50%" }}
        >
          Next
        </button>
      </div>
      {/* )} */}
    </div>
  );
};

export default EditTimePicker;
