import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";
import backArrow from "../images/arrow-box.svg";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCreditCard2Front } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { MdOutlineLogout } from "react-icons/md";
import styled from "styled-components";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { Amplify, Auth } from "aws-amplify";
import Common from "../utils/common";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});

const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button5 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const HeaderLayout = styled.div``;

const FormContainer = styled.div``;
const ChangePassword = styled.h5``;
const Span = styled.div`
  font-family:
    Inter-Medium,
    Sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  color: #362a41;
`;
const Text = styled.p`
  color: #78767a;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin: 38px 10px 30px 5px;
`;
const Forgetpassword = (props) => {
  const [user, setUsers] = useState("");
  const [success4, setSuccess4] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  function onSubmit2() {
    setSuccess4(true);
  }
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
  };
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          const uppercaseRegExp = /(?=.*?[A-Z])/;
          const lowercaseRegExp = /(?=.*?[a-z])/;
          const digitsRegExp = /(?=.*?[0-9])/;
          const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
          const minLengthRegExp = /.{8,}/;

          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter your new Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Re-entered password doesn't match with the previous password.";
          } else if (!uppercasePassword) {
            stateObj[name] = "At least one Uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "At least one Lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "At least one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "At least one Special Characters";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least minumum 8 characters";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] =
              "Re-entered password doesn't match with the previous password.";
          }
          break;

        default:
          break;
      }
      if (
        stateObj[name] === "" &&
        input.password !== "" &&
        input.confirmPassword !== ""
      ) {
        setValid(true);
      }
      return stateObj;
    });
  };
  const history = useHistory();

  function onSubmit(e) {
    e.preventDefault();
    setSuccess(true);
  }
  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };

  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };

  const handleFunction = (e) => {
    Object.assign(input, props);
    console.log(props);
    const data = {
      email: input.location.email,
      newPassword: input.password,
    };
    console.log(data, props, "fgFGJHGFAHDFGH");
    const response = axios.put(
      `${process.env.PUBLIC_URL}/xakal/password-change`,
      data
    );

    if (response.status === 200) {
      // setError2("Pls fill all the details valid. ")
      // toast.error(response.response.data.error);
    }

    setSuccess(false);
    toast.success(
      "Your password has been changed and your account has been logged out from all other devices!"
    );
    setTimeout(() => {
      history.push({
        pathname: "/portal/jobs",
      });
    }, 4000);
  };

  const [openSetting, setOpenSetting] = useState(false);

  const BackBtn = () => {
    history.push({
      pathname: "/portal/otp-changed",
    });
  };
  const searchedKey = () => {};
  return (
    <div>
      <ToastContainer />

      <HeaderLayout>
        {/* <MainHeader><img src={Back} alt="img"  onClick={() => history.push("/portal/forget-password")} />Change Password</MainHeader> */}
        {/* Start of Mobile Header  */}
        <MobileHeader onlyLogo={true} />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Change Password"}
          searchedKey={searchedKey}
          isSearchActive={false}
          back={BackBtn}
          backbtn
        />
        {/* End of desktop Header  */}
      </HeaderLayout>
      <div className="p-15 bg-m-white  in-content-wrapper">
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header o-mobile">
              {/* <LuArrowLeftSquare
              size={30}
              style={{ marginTop: "2px" }}
              onClick={() => history.push("/")}
            /> */}
              <div
                className="arrow-box"
                onClick={() => history.push("/portal/forget-password")}
              >
                <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
              </div>
              <h4 className="textpass font-face-gm mb-0">Change Password</h4>
            </div>
            <div className="cp-wrapper">
              <h4 className="cp-header o-desk">New Password</h4>
              <FormContainer className="cp-body">
                <div>
                  <ChangePassword className="textpass font-face-gm">
                    New Password
                  </ChangePassword>
                  <div className={""} style={{ marginBottom: "20px" }}>
                    <div className="pass-input-wrapper">
                      <input
                        autoComplete="off"
                        type={type ? "password" : "text"}
                        className={"input101"}
                        style={{ paddingLeft: "10px", height: "48px" }}
                        name="password"
                        placeholder="Enter new password"
                        value={input.password}
                        onChange={onInputChange}
                        onBlur={validateInput}
                      />
                      <div className="pass-eye-container">
                        {type ? (
                          <PiEyeSlashLight size={30} onClick={Eye} />
                        ) : (
                          <PiEyeLight size={30} onClick={Eye} />
                        )}
                      </div>
                    </div>
                  </div>
                  {error.password && (
                    <p className="text-danger f12 mb-0">{error.password}</p>
                  )}
                  <ChangePassword className="textpass font-face-gm">
                    Re-type Password
                  </ChangePassword>
                  <div className={""} style={{ marginBottom: "40px" }}>
                    <div className="pass-input-wrapper">
                      <input
                        autoComplete="off"
                        type={type2 ? "password" : "text"}
                        className={"input101"}
                        style={{ paddingLeft: "10px", height: "48px" }}
                        name="confirmPassword"
                        placeholder="Enter Confirm Password"
                        value={input.confirmPassword}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={error.password}
                      />
                      <div className="pass-eye-container">
                        {type2 ? (
                          <PiEyeSlashLight size={30} onClick={Eye2} />
                        ) : (
                          <PiEyeLight size={30} onClick={Eye2} />
                        )}
                      </div>
                    </div>
                    {error.confirmPassword && (
                      <p className="text-danger f12 mb-0">
                        {error.confirmPassword}
                      </p>
                    )}
                  </div>

                  <div className="container-login100-form-btn m-b-30">
                    <button
                      type="submit"
                      className="login100-form-btn"
                      disabled={!valid}
                      style={{ opacity: valid ? "100%" : "50%" }}
                      onClick={onSubmit}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </FormContainer>
              {error.password ? (
                <div>
                  <div id="message9" className="pass-instruct">
                    <div className="ms3i o-desk">
                      {/* <PiTrafficSign size={30} /> */}
                      {/* <img src={PassHint} alt="img" /> */}
                    </div>

                    <div
                      style={{
                        position: "relative",
                        marginLeft: "12px",
                      }}
                    >
                      <div style={{ color: "white", fontSize: "16px" }}>
                        Password must contain the following:
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.lowercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.lowercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 lower case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.uppercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.uppercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 upper case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={passwordVal.numeral ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="number"
                          className={passwordVal.numeral ? "valid" : "invalid"}
                        >
                          Must have 1 numeral case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={passwordVal.minchar ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="length"
                          className={passwordVal.minchar ? "valid" : "invalid"}
                        >
                          Minimum 8 characters required
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.specialChar ? "check" : "uncheck"
                          }
                        ></div>
                        <div
                          className={
                            passwordVal.specialChar ? "valid" : "invalid"
                          }
                        >
                          Must have 1 special character [#?!@$%^&*-]
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>My Profile</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit2}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          width: "auto",
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
            borderBottom: "1px solid #F4F4F6",
            marginTop: "15px",
          }}
        >
          <Span className="modal-title-sub">
            <div className="pop_ui">Are you sure you want to proceed?</div>
          </Span>
          {/* <AiOutlineCloseCircle
          className="flex-shrink-0"
            onClick={() => setSuccess(false)}
            style={{ cursor: "pointer" }}
          /> */}
        </DialogTitle>
        <DialogContent>
          <Text className="modal-b-title">
            Changing the password will logout your account from all other
            devices.
          </Text>
        </DialogContent>
        <div className="modalFooter">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "20px",
              padding: "0px",
            }}
          >
            <button
              className="button5"
              style={{
                width: "65px",
                height: "43px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => setSuccess(false)}
            >
              NO
            </button>

            <button
              className="modal-btn"
              style={{
                width: "65px",
                height: "43px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleFunction}
            >
              YES
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={success4}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want logout?</Text>

          <DateWrapperButton>
            <Button5 onClick={() => setSuccess4(false)}>No</Button5>
            {user ? (
              <Button3
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button3>
            ) : (
              <Button3 onClick={logout}>Yes</Button3>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Forgetpassword;
