/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import { FaRupeeSign } from "react-icons/fa";
import parse from "html-react-parser";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Upload from "../../images/Upload Minimalistic.svg";
import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./shared.css";
import DesktopHeader from "../desktopHeader";
const PopUpTitle = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;
const MainLayout = styled.div`
  overflow-x: hidden;
  width: 70%;
  margin-left: 200px;
  padding: 0px 20px 20px 20px;
`;
const QuestionLabel = styled.label`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const PageWrapper = styled.div`
  background: #fff;
  border-radius: 24px;
  padding: 15px;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 1rem 0;
  }
`;

const ContentWrapper = styled.div`
  border-bottom: 0.89px solid #cecece;

  @media (max-width: 576px) {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div``;
const JobDetailIcon = styled.div``;
const JobTitle = styled.div``;
const JobTitle2 = styled.div`
  color: #4f4953;
`;
const JobTitleIcon = styled.div``;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 10px 0;
  background-color: #f7f7f8;
  gap: 10px;
  padding: 6px 6px;
  border-radius: 8px;
`;
const Preference = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  color: #362a41;
`;
const JobPreference = styled.div`
  color: #4f4953;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  height: 33px;
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  @media (max-width: 576px) {
    height: auto;
  }
`;

const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #4f4953;
`;
const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;
const FileTypeInfo = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  position: relative;
  right: 15px;
  bottom: 20px;
  margin: 10px 0 0 10px;
  span {
    color: #ff596a;
  }
`;
const FileTypeInfos = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  span {
    color: #ff596a;
    margin-top: 20px;
  }
`;
const PopUpTitles = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const ShareApplyJob = () => {
  const { id } = useParams();
  const history = useHistory();
  const [jobData, setJobData] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedQuestionList, setSelectedQuestionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [formData, setFormData] = useState({
    answers: [], // Store answers as an array of objects
  });
  const [file, setFile] = useState(null);
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [open3, setOpen3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataValue, setDataValue] = useState();
  const [userId, setUser] = useState({});
  const handleInputChange = (e, questionId) => {
    const newAnswers = { ...formData.answers };
    newAnswers[questionId] = { questionId, answer: e.target.value };
    setFormData((prevData) => ({ ...prevData, answers: newAnswers }));
  };
  const closeAnswer = () => {
    setOpen(false);
    setFormData({
      answers: [], // Resetting the answers array to an empty array
    });
  };
  const checkUnansweredMandatoryQuestions = () => {
    const unansweredMandatoryQuestions = selectedQuestionList.filter(
      (question) => {
        const answer = formData.answers[question._id];
        const isMandatory = question.isMandatory === true;
        const isStarred = question.isStarred === true;
        const condition1 = isMandatory && isStarred;
        const condition2 = isMandatory && !isStarred;
        const isUnanswered = !answer || answer.answer.trim() === "";
        return (condition1 || condition2) && isUnanswered;
      }
    );

    return unansweredMandatoryQuestions;
  };
  const getOneJob = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${id}`)
      .then((res) => {
        setJobData(res.data.updateJob);
      })
      .catch((err) => {});
  };
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };

  const handleApplyClick = (id, questionList, applyThrough, urls) => {
    if (applyThrough === "companySite") {
      window.open(urls, "_blank");
    } else {
      setSelectedId(id); // Set the selected id in the state

      if (
        Array.isArray(questionList) &&
        questionList.length > 0 &&
        questionList.some((q) => q.questionName.trim() !== "")
      ) {
        setOpen(true);
        setSelectedQuestionList(questionList);
      } else {
        setOpen2(true);
      }
    }
  };
  const sortedQuestions = selectedQuestionList.slice().sort((a, b) => {
    if (a.isMandatory && a.isStarred && (!b.isMandatory || !b.isStarred)) {
      return -1; // A comes before B if A is both mandatory and starred, and B is not
    } else if (
      b.isMandatory &&
      b.isStarred &&
      (!a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is both mandatory and starred, and A is not
    } else if (
      !a.isMandatory &&
      a.isStarred &&
      (b.isMandatory || !b.isStarred)
    ) {
      return -1; // A comes before B if A is not mandatory but starred, and B is mandatory or not starred
    } else if (
      !b.isMandatory &&
      b.isStarred &&
      (a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is not mandatory but starred, and A is mandatory or not starred
    } else {
      return 0; // No change in order for other cases
    }
  });
  const handleSubmitOne = async () => {
    try {
      // Create an array of answers based on the questions
      const answers = sortedQuestions.map((question) => ({
        questionId: question._id,
        answer: formData.answers[question._id]?.answer || "",
      }));

      const unansweredMandatoryQuestions = checkUnansweredMandatoryQuestions();

      if (unansweredMandatoryQuestions.length > 0) {
        toast.error("Please answer all mandatory questions.");
      } else {
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/add-answer/${selectedId}`, {
            answers: answers, // Send the answers array
          })
          .then(() => {
            setFormData({
              ...formData,
              answers: Array(formData.answers.length).fill(""),
            });
          })
          .catch((err) => {
            console.error(err);
          });

        // Handle the response here as needed
        setOpen(false);
        setOpen2(true);
      }
    } catch (error) {}
  };
  const handleFileChnage = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ["pdf", "doc", "docx"];

    if (
      selectedFile &&
      selectedFile.size <= fileSizeLimit &&
      allowedFileTypes.some((type) =>
        selectedFile.name.toLowerCase().endsWith(type)
      )
    ) {
      setFile(selectedFile);
      setError2("");
      setError3("");
    } else {
      // Display an error message or take any other action
      setFile(null);
      setError2("File size should not exceed 2mb and PDF,");
      setError3("Word doc only allowed.");
    }
  };

  const handlePreview = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };
  const handlePreviews = () => {
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (["pdf", "doc", "docx"].includes(fileExtension)) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };
  const handleCancel = () => {
    // Clear the selected file and any error messages
    setFile(null);
    setOpen2(false);
  };

  const [dataOne, setDataOne] = useState();
  const [dataOneResume, setDataOneResume] = useState();
  const fetchData = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/jobs/${selectedId}/applicants/questions`
      )
      .then((response) => {
        const questionList = response.data.job.questionList;
        const sortedQuestionList = questionList.sort((a, b) => {
          return b.starred - a.starred;
        });
        setDataOne(sortedQuestionList);
        setDataOneResume(response.data);
        // Continue with the rest of your logic, for example, call setOpen2 and setOpen3.
        setOpen2(false);
        setOpen3(true);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };
  const handleSubmitTwo = () => {
    if (error2 === "") {
      setLoading(true); // Set loading state to true

      const formData1 = new FormData();
      formData1.append("image", file);

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData1)
        .then((res) => {
          if (res.data) {
            const params = {
              url: res.data.url ? res.data.url : "",
            };

            axios
              .post(
                `${process.env.PUBLIC_URL}/xakal/apply/${selectedId}`,
                params
              )
              .then(() => {
                setOpen2(false);
                setOpen3(true);
                fetchData();
              })
              .catch((error) => {
                console.error("Error updating item:", error);
              })
              .finally(() => {
                setLoading(false); // Set loading state to false on completion
              });
          }
        })
        .catch(() => {
          setLoading(false); // Set loading state to false on error
        });
    } else {
      setError2("File size should not exceed 2mb and PDF");
      setError3("Word doc only allowed.");
    }
  };
  const getOneDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        setDataValue(response.data.aboutOne);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getOneJob();
    getOneDetail();
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFunctionClose = () => {
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/apply-submit/${selectedId}/${userId.userId}`
      )
      .then(() => {
        setOpen3(false);
        history.push("/portal/my-profile");
        toast.success("Job Applied Successfully...");
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      });
  };
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();

  const formattedYear = currentYear.toString();
  const handleProfile = () => {
    history.push(`/portal/my-profile`);
  };
  return (
    <div>
      <DesktopHeader />
      <MainLayout>
        <div className="share-prowrap">
          <div className="share-setpro">
            Complete setting up your profile to explore through various job
            opportunities{" "}
          </div>
          <button className="share-probtn" onClick={handleProfile}>Set up profile now</button>
        </div>
        <PageWrapper>
          <ContentWrapper className="job-content-wrapper">
            <div className="company-logo-container space">
              {jobData?.companyName && jobData?.companyName?.companyLogo ? (
                <>
                  <img
                    src={
                      jobData?.companyName && jobData?.companyName?.companyLogo
                    }
                    alt="img"
                    className="company-logo"
                  />
                </>
              ) : (
                <img src={Image} alt="img" className="company-logo" />
              )}
            </div>
            <div className="share-job-div">
              <Wrapper className="job-title-wrapper">
                <JobTitle className="share-job-title">
                  {jobData?.jobTitle}
                </JobTitle>
                <JobTitle2 className="share-company-title">
                  {jobData?.companyName && jobData?.companyName.value}
                </JobTitle2>
              </Wrapper>
            </div>
          </ContentWrapper>

          <JobDetailIcon className="share-job-details">
            <JobTitleIcon className="icon-title">
              <img src={Suit} alt="img" className="suit-share" />
              <span>
                Exp{" "}
                {jobData?.experienceRequired2
                  ? `${jobData?.experienceRequired} - ${jobData?.experienceRequired2} years`
                  : jobData?.experienceRequired
                    ? `${jobData?.experienceRequired} years`
                    : ""}
              </span>
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              <img src={Salary} alt="img" className="salary-suit" />
              <FaRupeeSign size={10} color="grey" />
              <span>{`${jobData?.salaryFrom}L - ${jobData?.salaryTo}L`}</span>
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              <img src={Location} alt="img" className="location-suit" />
              <span>{jobData?.location}</span>
            </JobTitleIcon>

            <JobTitleIcon className="icon-title">
              <img src={Home} alt="img" className="home-suit" />
              <span> {jobData?.workMode}</span>
            </JobTitleIcon>
            <JobTitleIcon className="icon-title">
              <img src={Clock} alt="img" className="clock-share" />
              <span> {jobData?.employeeType} </span>
            </JobTitleIcon>
          </JobDetailIcon>

          <Preference>Preferred :</Preference>
          <PreferenceWrapper>
            {jobData?.preference
              ? jobData?.preference.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
              : []}
          </PreferenceWrapper>

          <Preference>Skills :</Preference>

          <PreferenceWrapper>
            {jobData?.skill
              ? jobData?.skill.map((i) => {
                  return <JobPreference>{i}</JobPreference>;
                })
              : []}
          </PreferenceWrapper>

          <Preference>Job Description and Benefits :</Preference>
          <PreferenceWrapper>
            <Content>
              {jobData && jobData?.jobDesc
                ? parse(String(jobData?.jobDesc))
                : "-"}
            </Content>
          </PreferenceWrapper>
          <div className="button-matchs">
            <button className="share-job-explore-btn" onClick={()=>history.push("/portal/jobs")}>Explore other jobs</button>
            <button
              className="job-btn"
              onClick={() =>
                handleApplyClick(
                  jobData._id,
                  jobData.questionList,
                  jobData.applyThrough,
                  jobData.urls
                )
              }
            >
              Apply now
            </button>
          </div>
        </PageWrapper>
      </MainLayout>

      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Assessment Questions
          </PopUpTitle>
        </DialogTitle>
        <DialogContent>
          <div className="modalBody">
            {sortedQuestions
              .filter((question) => question.questionName.trim() !== "")
              .map((question, index) => (
                <div key={question.questionId}>
                  <div>
                    <QuestionLabel htmlFor={`answer_${question._id}`}>
                      {index + 1}. {question.questionName}
                      {question.isMandatory && (
                        <span className="mandatory-star">*</span>
                      )}
                    </QuestionLabel>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        id={`answer_${question._id}`}
                        name={`answer_${question._id}`}
                        value={
                          formData.answers[question._id]
                            ? formData.answers[question._id].answer
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, question._id)}
                        className="footer-header"
                        maxLength={250}
                      />
                    </InputGroup>
                  </div>
                </div>
              ))}
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={closeAnswer}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={() => handleSubmitOne(selectedId)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "430px", borderRadius: "20px" } }}
      >
        <div className="modalHeader">Document Submission</div>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            <PopUpTitle>Resume</PopUpTitle>

            <InputGroup className="mb-3">
              <label
                for="file-upload"
                className="custom-file-upload"
                onChange={handleFileChnage}
              >
                <Form.Control id="file-upload" type="file" />
                {file === null ? (
                  <div>
                    <div className="upload-icon">
                      <img src={Upload} alt="img" />
                    </div>

                    <div>
                      <FileTypeInfos>
                        <span>*</span>Upload file size should be less than 2mb
                        (File type: Pdf, Doc)
                      </FileTypeInfos>
                    </div>
                  </div>
                ) : (
                  <div className="upload-icons">
                    <FileTypeInfo className="file-wrap">
                      {handlePreview()}
                    </FileTypeInfo>

                    <div>
                      <img src={Upload} alt="img" />
                    </div>
                  </div>
                )}
              </label>
            </InputGroup>
            <div className="text-danger f12">{error2}</div>

            <div className="text-danger f12">{error3}</div>
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={handleCancel}>
              Cancel
            </button>
            <button
              onClick={handleSubmitTwo}
              disabled={!file || loading}
              className={`button3 ${loading ? "loading" : ""}`}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open3}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { width: "440px", borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <span className="mTitle">Application Preview</span>
        </div>
        <HrLine />
        <DialogContent>
          <div className="modalBody">
            <div className="app-preview">
              {dataOne ? (
                <>
                  {dataOne &&
                    // eslint-disable-next-line array-callback-return
                    dataOne.map((question, index) => {
                      const userAnswers = question.answers.filter(
                        (answer) => answer.userId === userId.userId
                      );

                      // Check if there are any user answers
                      if (userAnswers.length > 0) {
                        const lastUserAnswer =
                          userAnswers[userAnswers.length - 1];

                        return (
                          <>
                            <PopUpTitles>{`${index + 1}. ${
                              question.questionName
                            }`}</PopUpTitles>
                            <div>
                              <PopUpTitles>
                                {`Answer: ${
                                  lastUserAnswer.answer || "No answer provided"
                                }`}
                              </PopUpTitles>
                            </div>
                          </>
                        );
                      }
                    })}
                </>
              ) : (
                ""
              )}
            </div>

            <div>
              <PopUpTitle>Documents Submitted</PopUpTitle>

              <div className="doc-submit-row">
                <FileTypeInfo className="file-wrap">
                  {handlePreviews()}
                </FileTypeInfo>
                <a
                  href={
                    dataOneResume ? dataOneResume.resume : "No resume provided"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <PopUpTitle className="doc-title">{`${
                    dataValue?.firstName && dataValue?.firstName
                  } ${
                    dataValue?.lastName && dataValue?.lastName
                  }_resume_${formattedYear}`}</PopUpTitle>
                </a>
              </div>
            </div>
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={() => setOpen3(false)}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={handleFunctionClose}
            >
              Submit
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShareApplyJob;
