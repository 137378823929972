import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";

const HamburgerMenu = () => {
  const [expanded, setExpanded] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
    setIsNavbarOpen(!isNavbarOpen);
    document.body.style.overflowY = isNavbarOpen ? 'auto' : 'hidden';
  };
  const toggleNavbars = () => {
    setExpanded(!expanded);
    setIsNavbarOpen(!isNavbarOpen);
    document.body.style.overflow = isNavbarOpen ? 'auto' : 'hidden';
  }
  return (
    <Navbar onToggle={(expanded) => setExpanded(expanded)} expanded={expanded} expand="xl"  style={{position:"absolute",right:"0px",top:"20px",zIndex:"2"}} className='navbar-m'>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        <h1 style={{position:"absolute",right:"50px",top:"15px",color:"white",fontSize:"24px"}} onClick={toggleNavbars}>X</h1>
        <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/my-profile`,
                    // userID: this.props.userID || this.state.userID("userid"),
                  }}
                  className="nav-link "
                >
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    My Profile
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/jobs`,
                    // userID: this.props.userID || this.state.userID("userid"),
                  }}
                  className="nav-link collapsed "
                >
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    Jobs
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/manage-jobs`,
                    
                  }}
                  className="nav-link collapsed"
                >
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    Manage Jobs
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/application-tracking-system`,
                    
                  }}
                  className="nav-link collapsed "
                >
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    ATS
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/my-calendar`,
                    
                  }}
                  className="nav-link collapsed "
                >
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {" "}
                    My Calendar
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  style={{ width: "12rem" }}
                  to={{
                    pathname: `portal/organisation`,
                    
                  }}
                  className="nav-link collapsed "
                >
                  
                  <RxDashboard style={{ height: "17px" }} />
                  <span
                    style={{ marginLeft: "8px" }}
                    className="pro nav-weight"
                    onClick={() => setExpanded(!expanded)}
                  >
                    Organisation
                  </span>
                </NavLink>
              </li>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HamburgerMenu;
