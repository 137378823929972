/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import "../Jobs/jobs.css";
import companyLogo from "../../images/company.png";
import "./interview.css";
import iClock from "../../images/Alarm-icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import CustomHeaderInterview from "./customHeaderInterview";
import styled from "styled-components";
import ExploreTimeZone from "../MyProfile/timeExploreInterview";
import { Dialog, DialogContent } from "@mui/material";
import InterviewPopup from "../MyProfile/interviewPopup";
import SlotInterview from "../MyProfile/slotexplore";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { CiLinkedin } from "react-icons/ci";
import { MdCheck, MdContentCopy } from "react-icons/md";
import CloseIcon from "../../images/CloseIcon.svg";
import ShimmerLoading from "./shimmerloading";

const Time = styled.div`
  margin-left: auto;
  font-size: 14px;
  margin-top: 5px;
`;

const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SavedInterview = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [inputId, setInputId] = useState();
  const [social, setOpenSocial] = useState(false);
  const [onPopupData, setPopupData] = useState();
  const openDateForm = (id) => {
    setOpenDate(true);
    setIds(id);
    setInputId(id);
  };

  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];
  const [savedInterviews, setSavedInterviews] = useState([]);
  const updateToUnsavedInterview = async (savedId) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/unsaved-booked/${savedId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(savedId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== savedId
            );
          } else {
            return [...prevSavedInterviews, savedId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    phoneNumber: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
  });

  const prefillValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${inputId}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setInput({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
            email: response.data.aboutOne.email,
            bio: response.data.aboutOne.bio,
            city: response.data.aboutOne.city,
            state: response.data.aboutOne.state,
            avatar: response.data.aboutOne.avatar,
            country: response.data.aboutOne.country,
            language: response.data.aboutOne.language,
            proficiency: response.data.aboutOne.proficiency,
            about: response.data.aboutOne.about,
            gender: response.data.aboutOne.gender,
            workProfile: response.data.aboutOne.workProfile,
            urlLink: response.data.aboutOne.urlLink,
            phoneNumber: response.data.aboutOne.phoneNumber,
            inputList: response.data.aboutOne.inputList,
            inputList2: response.data.aboutOne.inputList2,
            inputList3: response.data.aboutOne.inputList3,
            createdDate: response.data.aboutOne.createdDate,
          });
        }
      })
      .catch((err) => err.error);
  };
  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  const [ids, setIds] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const [openConfirm, setConfirmPopup] = useState(false);
  const [slotId, setSlotId] = useState("");
  const openConfirmPopup = (id) => {
    setConfirmPopup(true);
    setOpenDate(false);
    setSlotId(id);
  };
  const openFirst = (id) => {
    setOpenDate(true);
    setConfirmPopup(false);
    setSlotId(id);
  };
  const close = () => {
    setConfirmPopup(false);
  };
  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    document.title = "Interviews | Xakal";
  }, []);

  useEffect(() => {
    prefillValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
      const intervalId = setInterval(calculateTimeAgo, 60000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      };
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };
  const [selectedData, setSelectedData] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [trues, setTrue] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const handleOpen = (data) => {
    setOpenForm(true);
    setSelectedData(data);
    setActiveCard(data._id);
    setTrue(true);
  };

  const closeForm = () => {
    setOpenForm(false);
    getAllSavedInterview();
    setActiveCard(null);
  };
  const getAllSavedInterview = () => {
    setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-saved-interview`)
      .then((res) => {
        const data = res.data.interview;
        setData(data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllSavedInterview();
    getAllBookedInterview();
  }, []);
  const convertdata = (dateString) => {
    var date = new Date(dateString);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var day = date.getUTCDate();
    var month = months[date.getUTCMonth()];
    var year = date.getUTCFullYear();
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    var ordinalSuffix = getOrdinalSuffix(day);
    var formattedDate = day + ordinalSuffix + " " + month + " " + year;
    return formattedDate;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [datainter, setDatainter] = useState({});

  const getAllBookedInterview = () => {
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-book-saved-interview`;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          setLoading(false);

          // Store interview data in the datainter state
          let a = datainter;
          for (let i = 0; i < response.data.interview.length; i++) {
            a[response.data.interview[i]["_id"]] = response.data.interview[i];
          }
          setDatainter(a);

          // Update data state with unique booking entries

          setDatas(response.data.book);
        }
      })
      .catch((err) => {});
  };
  const cardClick = (path) => {
    return history.push("/portal/my-calendar");
  };
  const handleWhatsAppShare = () => {
    const jobName = onPopupData.interviewTitle;
    const jobId = onPopupData._id;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    const message = `${jobName} (Job ID: ${jobId}) - Click the link to view the job details:`;

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${message} ${jobUrl}`
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleEmailShare = () => {
    const jobId = onPopupData._id;
    const jobTitle = onPopupData.interviewTitle;

    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const subject = `Job Opportunity: ${jobTitle} (Job ID: ${jobId})`;
    const body = `Check out this job opportunity: ${jobUrl}\n\nJob Title: ${jobTitle}\nJob ID: ${jobId}`;

    const emailUrl = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(emailUrl, "_blank");
  };
  const handleLinkedInShare = () => {
    const jobTitle = onPopupData.interviewTitle;
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;

    const message = `${jobTitle} (Job ID: ${jobId}) - Click the link to view the job details: ${jobUrl}`;

    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(jobUrl);

    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}&title=${encodedMessage}`;

    window.open(linkedinUrl, "_blank");
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const jobId = onPopupData._id;
    const currentUrl = window.location.href;
    const domain = currentUrl.split("/").slice(0, 3).join("/");
    const jobUrl = `${domain}/share-interview?jobId=${jobId}`;
    navigator.clipboard.writeText(jobUrl);

    navigator.clipboard.writeText(jobUrl).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };
  const openPopup = (data) => {
    setPopupData(data);
    setOpenSocial(true);
  };
  const updateToSavedInterview = (savedId) => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/unsaved-interview/${savedId}`)
      .then((res) => {
        getAllBookedInterview();
        getAllSavedInterview();
      })
      .catch((err) => {});
  };
  const updateToBookedInterview = (savedId) => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/unsaved-booked/${savedId}`)
      .then((res) => {
        getAllBookedInterview();
        getAllSavedInterview();
      })
      .catch((err) => {});
  };
  const [userId, setUser] = useState({});
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);
  const inputFormInput = (id) => {
    setInputId(id);
  };
  const [isScrolled, setIsScrolled] = useState(false);
  const formatDates = (date) => {
    const currentDate = new Date(date).toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    return currentDate;
  };
  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });

  const currentDatesss = new Date(); // Get current date/time

  // Extract hours and minutes
  let hours = currentDatesss.getHours();
  let minutes = currentDatesss.getMinutes();

  // Determine AM/PM and adjust hours
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert hours from 24-hour to 12-hour format

  // Format the time as "hh:mm AM/PM"
  const formattedTime = `${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;

  const adjustTimeAndFormat = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map(Number);

    let adjustedHours = hours;
    if (period === "PM" && hours !== 12) {
      adjustedHours += 12;
    } else if (period === "AM" && hours === 12) {
      adjustedHours = 0; // Midnight
    }

    let adjustedMinutes = minutes + 30;
    if (adjustedMinutes >= 60) {
      adjustedHours += 1;
      adjustedMinutes -= 60;
    }

    const adjustedPeriod = adjustedHours >= 12 ? "PM" : "AM";
    const formattedHours = adjustedHours % 12 || 12; // Convert hours from 24-hour to 12-hour format

    const formattedTime = `${formattedHours}:${
      adjustedMinutes < 10 ? "0" : ""
    }${adjustedMinutes} ${adjustedPeriod}`;

    return formattedTime;
  };
  return (
    <div>
      {openForm ? (
        <div className={isScrolled ? "int-previews" : "int-previews"}>
          <InterviewPopup
            openDateForm={openDateForm}
            inputId={inputFormInput}
            onPopupData={selectedData}
            onClose={closeForm}
            callAll={getAllSavedInterview}
            callAlls={getAllBookedInterview}
            trues={trues}
          />
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={openDate}
        PaperProps={{
          style: {
            height: "650px",
            borderRadius: "24px",
            maxWidth: "560px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ExploreTimeZone
            id={inputId}
            interviewId={ids}
            open={openConfirmPopup}
            onClose={() => setOpenDate(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "660px",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <SlotInterview
            inputId={inputId}
            openFirst={() => openFirst(inputId)}
            slotId={slotId}
            close={close}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={social}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", maxWidth: "540px" } }}
      >
        <div className="modalHeader mheader">
          <PopUpTitle>Share</PopUpTitle>
          <img src={CloseIcon} alt="img" onClick={() => setOpenSocial(false)} />
        </div>
        <DialogContent>
          <Wrapper className="modalBody">
            <div>
              <BsWhatsapp
                size={24}
                onClick={handleWhatsAppShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Whats App</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <HiOutlineMail
                size={24}
                onClick={handleEmailShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> E-Mail</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              <CiLinkedin
                size={24}
                onClick={handleLinkedInShare}
                style={{ cursor: "pointer" }}
              />
              <h4 className="sb-text"> Linkedin</h4>
            </div>
            <div style={{ position: "relative", display: "grid" }}>
              {isCopied ? (
                <MdCheck size={30} style={{ color: "green" }} />
              ) : (
                <MdContentCopy
                  size={24}
                  onClick={handleCopyToClipboard}
                  style={{ cursor: "pointer" }}
                />
              )}
              <h4 className="sb-text">{isCopied ? "Copied" : "Copy Link"}</h4>
            </div>
          </Wrapper>
        </DialogContent>
      </Dialog>
      <div className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader hTitle="Interviews" />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader title={"Interviews"} />
        {/* End of desktop Header  */}
      </div>

      {/* {searchInputVal?.length == 0 ? ( */}

      <>
        <CustomHeaderInterview />

        {loading ? (
          <div style={{ top: "160px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : data && data?.length > 0 ? (
          <div className="job-card-container job-card-containers">
            {data &&
              data.map((i, index) => {
                const saved = i.savedBy.find(
                  (fav) => fav.userId === userId.userId
                );

                const savedApplied =
                  saved && saved.status === "saved" ? true : false;
                return (
                  <div
                    className="int-row int-rows"
                    onClick={() => handleOpen(i)}
                  >
                    <div
                      className={
                        openForm
                          ? "job-card-wrap int-contain save-wrap"
                          : "job-card-wrapper save-wrap int-container"
                      }
                    >
                      <div
                              className={
                                activeCard === i._id ? "under-line" : ""
                              }
                            ></div>
                      <div className="int-content-container">
                        
                          <div className="int-title-row int-titles">
                            <div className="int-creator">
                              <div>
                                {" "}
                                {i.createdBy && i.createdBy?.logo ? (
                                  <img
                                    src={i.createdBy.logo}
                                    alt="img"
                                    className="int-creator-img img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={companyLogo}
                                    alt="img"
                                    className="int-creator-img img-fluid"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="int-tit-save">
                            <div className="int-details">
                              <h5
                                className="title"
                                onClick={() => handleOpen(i)}
                              >
                                <div className="profile-name">
                                      <span className="pro-name">
                                        {" "}
                                        {i.interviewTitle?.substring(0, 15)}
                                        {i.interviewTitle?.length > 15 ? "..." : ""}
                                      </span>
                                      {i.interviewTitle?.length > 12 ? (
                                        <p className="prof-name">{i.interviewTitle}</p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                              </h5>
                              <div>
                                <span className="int-cre-lite">by</span>
                                <span
                                  className="int-cre-title"
                                  onClick={() =>
                                    history.push(
                                      `/portal/other-profile/${i?.createdBy?.userId}`
                                    )
                                  }
                                >
                                  {i?.createdBy?.firstName}
                                </span>
                              </div>
                              {/* <TimeAgo timestamp={i.timestamps.createdAt} /> */}
                            </div>
                            <div className="ver-line"></div>
                                <div className="b-slot">
                                  <div>
                                    {i?.inr ? (
                                      <p className="b-price">
                                        <i className="fa fa-rupee"></i>{" "}
                                        {new Intl.NumberFormat("en-IN", {}).format(
                                          i?.inr
                                        )}
                                      </p>
                                    ) : (
                                      <p className="b-price">Free</p>
                                    )}

                                    
                                  </div>
                                  <div className="text-center">
                                    <span className="timer">
                                      <span>{i?.duration}</span>
                                      <span>
                                        {" "}
                                        <img
                                          src={iClock}
                                          className="clock-icon"
                                          alt="icon"
                                        />
                                      </span>
                                    </span>
                                  </div> 
                                </div>
                            </div>
                          </div>
                        

                        <>
                          {" "}
                          {i?.designation.length ? (
                            <div className="prefer-wraps">
                              <div className="job-preferences">
                                Designations :
                              </div>
                              {i?.designation
                                ?.slice(0, 3)
                                .map((item, index) => (
                                  <div
                                    className={
                                      openForm
                                        ? "job-pref-fill"
                                        : "job-preference-pill"
                                    }
                                  >
                                    {item}
                                  </div>
                                ))}
                              {i.designation.length > 3 ? (
                                <div
                                  className="job-preference-pill"
                                  onClick={() => handleOpen(i)}
                                >
                                  +{i.designation.length - 3}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          ""
        )}
        {loading ? (
          <div>
            {Array.from({ length: 1 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : datas && datas?.length > 0 ? (
          <div
            className="job-card-container job-card-containers"
            style={{ paddingBottom: "30px" }}
          >
            {datas &&
              datas.map((a, index) => {
                let i = datainter[a["serviceId"]];
                const saved = a.savedBy.find(
                  (fav) => fav.userId === userId.userId
                );

                const savedApplied =
                  saved && saved.status === "saved" ? true : false;
                  const dates = formatDates(a?.date);
                  const adjustTime = adjustTimeAndFormat(a?.time);
                  const isDisplay =
                    currentDate >= dates &&
                    adjustTime >= adjustTimeAndFormat(a?.time);
                return (
                  <>
                    {i !== undefined ? (
                      <div className="int-row">
                        <div
                          className={
                            openForm
                              ? "job-card-wrap int-contain save-wrap"
                              : "job-card-wrapper save-wrap int-container"
                          }
                        >
                          <div className="int-content-container">
                            <div className="int-title-row">
                              <div className="int-creator">
                                <div>
                                  {i?.createdBy?.logo ? (
                                    <img
                                      src={i?.createdBy?.logo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={companyLogo}
                                      alt="img"
                                      className="int-creator-img img-fluid"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="int-details">
                                <h5
                                  className="title mb-0"
                                  onClick={() => handleOpen(i)}
                                >
                                  {i.interviewTitle}
                                </h5>
                                <div>
                                  <span className="int-cre-lite">by</span>
                                  <span
                                    className="int-cre-title"
                                    onClick={() =>
                                      history.push(
                                        `/portal/other-profile/${i?.createdBy?.userId}`
                                      )
                                    }
                                  >
                                    {i?.createdBy?.firstName}
                                  </span>
                                </div>
                                <div
                                    onClick={(e) => {
                                      updateToBookedInterview(a?._id);
                                      e.stopPropagation();
                                    }}
                                  >
                                  {savedApplied ? (
                                    <img
                                      src={iBookmarkActive}
                                      className="bookmark-icon"
                                      alt="icon"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  </div>
                              
                                <div>
                                  {i?.inr ? (
                                    <p className="b-price">
                                      <i className="fa fa-rupee"></i>{" "}
                                      {new Intl.NumberFormat(
                                        "en-IN",
                                        {}
                                      ).format(i?.inr)}
                                    </p>
                                  ) : (
                                    <p className="b-price">Free</p>
                                  )}
                                </div>
                              </div>
                                  
                            </div>

                            {i?.designation.length ? (
                                <div className="prefer-wrap">
                                  <div className="prefer-wraps">
                                    <div className="job-preferences">
                                      Designations :
                                    </div>
                                    {i?.designation
                                      ?.slice(0, 3)
                                      .map((item, index) => (
                                        <div class="job-preference-pill">
                                          {item}
                                        </div>
                                      ))}
                                    {i.designation.length > 3 ? (
                                      <div class="job-preference-pill">
                                        +{i.designation.length - 3}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {/* need in future enhancement */}
                                  {/* <div className="int-btn-wrap">
                                    {isDisplay ? (
                                      ""
                                    ) : (
                                      <div className="text-center">
                                        {a.score ? (
                                          ""
                                        ) : (
                                          <button
                                            style={{ cursor: "pointer" }}
                                            className="apt-test-btn"
                                            onClick={() =>
                                              history.push({
                                                pathname: `/portal/home`,
                                                state: {
                                                  bookId: a && a._id,
                                                  saved: true,
                                                },
                                              })
                                            }
                                          >
                                            Start Test
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div> */}
                                </div>
                              ) : (
                                <></>
                              )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
};

export default SavedInterview;
