import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import DigitalSambaEmbedded from "@digitalsamba/embedded-sdk";
import Common from "../utils/common";

const VideoRoom = () => {
  const { roomName, bookingId } = useParams();
  const history = useHistory();
  const location = useLocation();

  // Extract main_id from location state
  const main_id = location.state?.main_id;
  const serviceId = location.state?.serviceId;

  const [userId, setUserId] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const handlePendingFeedback = () => {
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/update-pending-feedback/${bookingId}`
      )
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };
  const handleStatusEventCompleted = () => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-event-status/${main_id}`)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };
  const formatDate = (inputDate) => {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Add one day to the input date
    date.setDate(date.getDate());

    // Extract year, month, and day from the Date object
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed
    const day = ("0" + date.getDate()).slice(-2);

    // Format the date in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };
  const formatDates = (inputDate) => {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);

    // Add 80 years to the input date
    date.setFullYear(date.getFullYear() + 80);

    // Extract year, month, and day from the Date object
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero indexed
    const day = ("0" + date.getDate()).slice(-2);

    // Format the date in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const [bookingSlot, setBookingSlot] = useState();
  const [skill, setSkill] = useState();

  useEffect(() => {
    // Fetch booking details
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${bookingId}`)
      .then((response) => {
        setInterviewId(response.data.bookingSlot?.interviewId);
        setBookingSlot(response.data.bookingSlot);
        setCandidateId(response.data.bookingSlot?.candidateId);
      })
      .catch((err) => {});

    // Fetch user details
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUserId(response.data?.userId);
      })
      .catch((err) => {});
  }, [bookingId]);
  useEffect(() => {
    // Fetch booking details
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${serviceId}`)
      .then((response) => {
        const responseData = response.data.interview;

        if (responseData && responseData.skill.length > 0) {
          const formattedSkills = responseData.skill.map((e) => ({
            skillIds: e.skillIds,
            label: e.label,
            value: e.value,
            ratings: e.ratings,
            averageSkillRating: e.averageSkillRating,
          }));

          setSkill(formattedSkills);
        }
      })
      .catch((err) => {});

    // Fetch user details
  }, [serviceId]);
  const handleCourseCreateCertificate = () => {
    const data = {
      online: true,
      course: bookingSlot.serviceName,
      organisation: "Xakal",
      startDate: formatDate(bookingSlot?.date),
      endDate: formatDate(bookingSlot?.date),
      issueDate: formatDate(bookingSlot?.date),
      validity: formatDates(bookingSlot?.date),
      skill: skill,
      deleteFlag: "N",
      userId: candidateId,
      event: "Xakal",
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/certi-create`, data)
      .then(() => {})
      .catch(() => {});
  };
  useEffect(() => {
    if (userId && interviewId && candidateId) {
      // All required data is available, so load the iframe
      const sambaFrame = DigitalSambaEmbedded.createControl({
        url: `https://thexakal.digitalsamba.com/${roomName}`,
      });

      sambaFrame.load();
      sambaFrame.on("userJoined", (event) => {
        const data = event.data;
        if (data.type === "local") {
          sambaFrame.startRecording();
        }
      });

      sambaFrame.frame.style.width = "100%";
      sambaFrame.frame.style.height = "90%";
      sambaFrame.frame.style.maxWidth = "1200px";
      sambaFrame.frame.style.maxHeight = "800px";
      sambaFrame.frame.style.marginLeft = "320px";
      sambaFrame.frame.style.marginTop = "-44%";
      sambaFrame.on("sessionEnded", (event) => {
        if (userId === interviewId) {
          history.push(`/portal/feedback/${bookingId}`);
          handlePendingFeedback();
          handleStatusEventCompleted();
        } else if (userId === candidateId) {
          history.push(`/portal/my-profile`);
          handleCourseCreateCertificate()
        }
      });

      return () => {
        // Cleanup logic here
        sambaFrame.frame.style.display = "none"; // Hide iframe
      };
    }
  }, [userId, interviewId, candidateId, roomName, bookingId, history]);

  return null; // No need to render anything here
};

export default VideoRoom;
