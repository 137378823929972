import React, { useEffect, useState } from "react";
import xalogo from "../images/xakallogo.png";
import "../styles/login.css";
import "../styles/utils.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { Amplify, Auth } from "aws-amplify";
import Spinner from "react-bootstrap/Spinner";
import Common from "../utils/common";
import "react-toastify/dist/ReactToastify.css";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});

function Login(props) {
  const { linkedInLogin } = useLinkedIn({
    clientId: "867b8wu7jboe77",
    redirectUri: `https://thexakal.com/linkedin`,
    scope: ["openid", "profile", "email"],
    state: [
      "iss",
      "aud",
      "iat",
      "exp",
      "sub",
      "name",
      "given_name",
      "family_name",
      "picture",
      "email",
      "email_verified",
      "locale",
    ],
    onSuccess: async (code) => {
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/linkedinUser`, {
          code: code,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data && response.data.r) {
              let r = response.data.r;
              if (r.email && r.family_name && r.given_name) {
                axios
                  .post(`${process.env.PUBLIC_URL}/xakal/getUser`, {
                    email: r.email,
                  })
                  .then((response) => {
                    if (
                      response &&
                      response.data &&
                      response.data.user &&
                      response.data.user.email
                    ) {
                      axios.defaults.headers.common["x-access-token"] =
                        response.data.token;
                      document.cookie = `userid=${response.data.user._id}; path=/`;
                      checkForSkills(response.data, true, r);
                    } else {
                      setInput({
                        ...input,
                        firstName: r.given_name,
                        middleName: "",
                        lastName: r.family_name,
                        email: r.email,
                        password: "",
                        confirmPassword: "",
                      });
                      history.push("/signup", {
                        input: {
                          firstName: r.given_name,
                          middleName: "",
                          lastName: r.family_name,
                          email: r.email,
                          password: "",
                          confirmPassword: "",
                        },
                      });
                    }
                  });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const [errorOne, setErrorOne] = useState(null);
  const [errorTwo, setErrorTwo] = useState(null);
  
  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [chrm, setChrm] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    
    }
  }, [location.state]);
  console.log(email,"email")
  if (chrm == null) {
    let rm = localStorage.getItem("rm");
    if (rm) {
      setChrm(true);
      rm = JSON.parse(rm);
      setEmail(rm.email);
      setPassword(rm.password);
    } else {
      setChrm(false);
    }
  }
  const [types, setTypes] = useState(true);
  const Eyes = () => {
    setTypes(!types);
  };
  useEffect(() => {
    if (Common.loggedIn() && Common.loggedIn().length > 0) {
      const params = {
        user: {
          _id: Common.loggedIn(),
        },
      };
      checkForSkills(params, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const history = useHistory();

  function isValidEmail(email) {
    return /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(email);
  }

  function isValidPhone(phone) {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  }
  const { state } = useLocation();
  const [shareId, setShareId] = useState();
  const [shareInterviewId, setShareInterviewId] = useState();

  useEffect(() => {
    setInput(state && state.input);
    setShareId(state && state.from);
    setShareInterviewId(state && state.data);
  }, [state]);
  const handleChanges = (event) => {
    setEmail(event.target.value);
    setErrorOne(null);
    setErrorTwo("");
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrorTwo(null);
  };

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!value) {
            setErrorOne("Please enter email");
          } else if (!(isValidEmail(value) || isValidPhone(value))) {
            setErrorOne("Enter a valid email");
          } else {
            setErrorOne(null);
          }
          break;
        case "password":
          if (!value) {
            setErrorTwo("Please enter password");
          } else {
            setErrorTwo(null);
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/getUser`, {
              email: user.signInUserSession.idToken.payload.email,
            })
            .then((response) => {
              if (
                response &&
                response.data &&
                response.data.user &&
                response.data.user.email
              ) {
                axios.defaults.headers.common["x-access-token"] =
                  response.data.token;
                document.cookie = `userid=${response.data.user._id}; path=/`;
                setInput({
                  ...input,
                  firstName: user.signInUserSession.idToken.payload.given_name,
                  middleName: "",
                  lastName: user.signInUserSession.idToken.payload.family_name,
                  email: user.signInUserSession.idToken.payload.email,
                  password: "",
                  confirmPassword: "",
                });
                checkForSkills(
                  response.data,
                  true,
                  user.signInUserSession.idToken.payload
                );
              } else {
                setInput({
                  ...input,
                  firstName: user.signInUserSession.idToken.payload.given_name,
                  middleName: "",
                  lastName: user.signInUserSession.idToken.payload.family_name,
                  email: user.signInUserSession.idToken.payload.email,
                  password: "",
                  confirmPassword: "",
                });
                history.push("/signup", {
                  input: {
                    firstName:
                      user.signInUserSession.idToken.payload.given_name,
                    middleName: "",
                    lastName:
                      user.signInUserSession.idToken.payload.family_name,
                    email: user.signInUserSession.idToken.payload.email,
                    password: "",
                    confirmPassword: "",
                  },
                });
              }
            });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForSkills = (data, defaultRoute = false, user) => {
    let alreadyLoggedIn = false;
    var match = document.cookie.match(
      // eslint-disable-next-line no-useless-concat
      new RegExp("(^| )" + "alreadyLoggedIn" + "=([^;]+)")
    );
    if (match && match[2]) {
      alreadyLoggedIn = true;
      history.push({
        pathname: "/portal/jobs",
        state: [email, password],
      });
    }

    if (data && data.user && data.user._id && !alreadyLoggedIn) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-about-detail/${data.user._id}`,
          data
        )
        .then((response) => {
          if (
            response.data &&
            response.data.aboutOne &&
            response.data.aboutOne.coreSkill &&
            response.data.aboutOne.coreSkill.length
          ) {
            if (shareId) {
              history.push(`/portal/shares-apply-job/${shareId}`);
            } else if (shareInterviewId) {
              history.push(
                `/portal/shares-apply-interview/${shareInterviewId}`
              );
            } else {
              history.push({
                pathname: "/portal/interview/exploreInterview",
                state: [email, password],
              });
            }
          } else if (!defaultRoute) {
            history.push({
              pathname: "/on-boarding",
              state: {
                input: {
                  email: input?.email,
                  firstName: input?.firstName,
                  lastName: input?.lastName,
                  middleName: input?.middleName,
                  password: input?.password,
                  confirmPassword: input?.confirmPassword,
                  // minutes: minutes,
                  // seconds: seconds,
                },
              },
            });
          } else if (user && user.given_name) {
            history.push({
              pathname: "/on-boarding",
              state: {
                firstName: user.given_name,
                middleName: "",
                lastName: user.family_name,
                email: user.email,
                password: "",
                confirmPassword: "",
                shareJobId: shareId,
                shareInterviewId: shareInterviewId,
              },
            });
          }
        });
    }
  };

  const submitLogin = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const data = {
      email,
      password,
    };
    if (chrm) {
      localStorage.setItem("rm", JSON.stringify(data));
    } else {
      localStorage.removeItem("rm");
    }
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/signinUser`, data)
      .then((response) => {
        if (response && response.data && response.data.success) {
          if (
            response &&
            response.data &&
            response.data.user &&
            response.data.user.userRole === "student"
          )
            axios.defaults.headers.common["x-access-token"] =
              response.data.user.token;
          document.cookie = `userid=${response.data.user._id}; path=/`;
          checkForSkills(response.data);
        } else if (response.data.error) {
          setErrorOne("");
console.log(response.data.error)
          setErrorTwo(response.data.error);
        }
      })
      .catch((err) => {
        setErrorOne(err.error);
        setErrorTwo(err.error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading back to false
      });
  };

  useEffect(() => {
    document.title = "Login | Xakal";
  }, []);
  return (
    <div className="App">
      <div className="login-wrapper login-main">
        <div className="form-wrapper wrap-login100 p-b-20">
          <div className="margin-logo">
            <div className="logo-holder">
              <img
                src={xalogo}
                className="login-logo signup-xakal-logo"
                alt="XAKAL"
              />
            </div>
            <div className="let-getStarted">
              <span>Welcome to Xakal!</span>
            </div>
          </div>
          <div className="form-wrapper-body">
            <form className="" onSubmit={submitLogin}>
              <p className="textreg login-email">Email</p>
              <div className="input-holder">
                <input
                  autoComplete="off"
                  value={email}
                  className="input101 position"
                  type="text"
                  onChange={handleChanges}
                  onBlur={validateInput}
                  name="email"
                />
              </div>
              {errorOne !== null && (
                <p
                  className="err-position"
                  style={{
                    color: " #FF596A",
                    textAlign: "left",
                    fontSize: "12px",
                    marginTop: "10px",
                    fontFamily: "Inter-Medium, sans-serif",
                  }}
                >
                  {errorOne}
                </p>
              )}

              <p className="textpass login-pass">Password</p>
              <div className="input-holder">
                <div className="pass-input-wrapper">
                  <input
                    autoComplete="off"
                    value={password}
                    className="input101 position2 "
                    onChange={handlePasswordChange}
                    onBlur={validateInput}
                    name="password"
                    type={types ? "password" : "text"}
                    style={{ position: "relative" }}
                  />
                  <div className="pass-eye-container">
                    {types ? (
                      <PiEyeSlashLight size={30} onClick={Eyes} />
                    ) : (
                      <PiEyeLight size={30} onClick={Eyes} />
                    )}
                  </div>
                </div>
              </div>
              {errorTwo !== null && (
                <p
                  className="passerr-position"
                  style={{
                    color: " #FF596A",
                    textAlign: "left",
                    fontSize: "12px",
                    marginTop: "10px",
                    fontFamily: "Inter-Medium, sans-serif",
                  }}
                >
                  {errorTwo}
                </p>
              )}

              <Link
                to={{
                  pathname: "/forgot-password",
                  state: { email: email },
                }}
              >
                <p className="forgot-password">Forgot your password?</p>
              </Link>

              <>
                <button type="submit" className="login-btn">
                  {loading ? <Spinner /> : "Login"}
                </button>
              </>
            </form>
            <p className="con-options">or continue with</p>
            <div className="social-icon-position">
              <i
                className="ci ci-google"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Google" });
                }}
              ></i>
              {/* <i
                className="ci ci-facebook"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Facebook" });
                }}
              ></i> */}
              <i className="ci ci-linked-in o-desk" onClick={linkedInLogin}></i>
            </div>
            <br />
            {/* <hr className="hr" /> */}
            <p className="login-signupphrase" ser pools>
              Doesn't have an account?{" "}
              <span
                onClick={() =>
                  history.push({
                    pathname: "/signup",
                    state: {
                      shareJobId: shareId,
                      shareInterviewId: shareInterviewId,
                    },
                  })
                }
              >
                Sign Up
              </span>
            </p>
          </div>
          
        </div>
        <div
            className="foot-links o-desk"
          >
            <span
              style={{
                // display: "block",
                marginTop: "10px",
              }}
              onClick={() => window.open("/TermsAndConditions", "_blank")}
            >
              Terms and Conditions
            </span>
            <span
              style={{ marginTop: "10px" }}
              onClick={() => window.open("/refund-policy", "_blank")}
            >
              Refund Policy
            </span>
            <span
              style={{ marginTop: "10px" }}
              onClick={() => window.open("/privacy-policy", "_blank")}
            >
              Privacy Policy
            </span>
            <span
              style={{ marginTop: "10px" }}
              onClick={() => window.open("/contact", "_blank")}
            >
              Contact
            </span>
          </div>
      </div>
    </div>
  );
}
export default Login;
