import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Common from "../../utils/common";
import TopHeader from "../MyProfile/TopHeader";
import Downcap from "../../images/DownCap.png";

const HeaderLayout = styled.div`
  position: absolute;
  margin-left: 5px;
  width: 1200px;
//   height: 105px;
  left: 226px;
  border: 1px;
  // height: 174px;
  background: white;
  display:flex;
  justify-content:space-around;
`;
const FilterIcon = styled.div`
background:#F4F4F6;
padding:12px;
border-radius:8px;
margin:20px 0;
`
const FilterIconTwo = styled.div`
background:#362A41;
padding:12px;
border-radius:8px;
margin:20px 0;
`
const AssociatesCards = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 12px;
  padding-top: 24px;
`;
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  width: 100%;
  gap: 24px;
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;
const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;
const TabContainer = styled.div`
  display: flex;
  justify-content:center;
  margin-top:150px;
  
`;
const TabHeaderWrapper = styled.div`
background:#2FB766;
border-radius:8px;
width: 260px;
display: flex;
padding: 0 20px;
`
const TabHeader = styled.div`
  padding: 20px; 
  color:#FFF;
  font-size:${(props) => (props.selected ? "24px" : "16px")};
  margin-top:${(props) => (props.selected ? "0px" : "5px")};
  font-weight:${(props) => (props.selected ? "700" : "500")};
  text-align: center;
  align-items:center;
  cursor: pointer;
`;
const FilterOption = styled.div`
color: #40444F;
font-family: Inter-Medium,sans-serif;
font-size: 12px;
font-style: normal;
font-weight: normal;
line-height: 120%; /* 14.4px */
text-transform: capitalize;
`
const AllPageResults = () => {
  const [selectedView, setSelectedview] = useState("profile");
  const [associates, setAssociates] = useState([]);
  const history = useHistory();
  const getAssociatesList = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL
        }/xakal/get/all/associates/${Common.loggedIn()}`,
      )
      .then((res) => {
        setAssociates(res?.data);
      });
  };
  useEffect(() => {
    getAssociatesList();
  }, []);
  return (
    <div>
      <TopHeader />
      <HeaderLayout>
        <FilterIcon>
          <FilterOption>Sort By</FilterOption>
          <img src={Downcap} alt="img" />
        </FilterIcon>
        <FilterIconTwo>Date Posted</FilterIconTwo>
        <FilterIcon>Experience Level</FilterIcon>
        <FilterIcon>Location</FilterIcon>
        <FilterIcon>Designation</FilterIcon>
        <FilterIcon>All Filters</FilterIcon>
        <FilterIconTwo>Reset</FilterIconTwo>
      </HeaderLayout>
      <div style={{ marginLeft: "300px" }}>
        <TabContainer>
          <TabHeaderWrapper>
            <TabHeader
              selected={selectedView === "profile"}
              onClick={() => setSelectedview("profile")}
            >
              Profile
            </TabHeader>
            <TabHeader
              selected={selectedView === "all"}
              onClick={() => setSelectedview("all")}
            >
              All
            </TabHeader>
            <TabHeader
              selected={selectedView === "jobs"}
              onClick={() => setSelectedview("jobs")}
            >
              Jobs
            </TabHeader>
          </TabHeaderWrapper>
        </TabContainer>
        {selectedView === "profile" ? (
          <AssociatesCards>
            {associates?.map((invitation, idx) => (
              <InvitationCard key={idx}>
                <Img src={invitation?.avatar} alt="" />
                <DetailsWrapper>
                  <Title>
                    {invitation?.firstName + " " + invitation?.lastName}
                  </Title>
                  <a href
                    onClick={() =>
                      history.push(
                        "/portal/otherProfile/" +
                        invitation?.userId,
                      )
                    }
                  >
                    View
                  </a>
                </DetailsWrapper>
              </InvitationCard>
            ))}
          </AssociatesCards>
        ) : null}
      </div>
    </div>
  )
}

export default AllPageResults;