import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Auth } from "aws-amplify";
import "./organisation.css";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCreditCard2Front } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { MdOutlineLogout } from "react-icons/md";
import styled from "styled-components";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import axios from "axios";
import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const HeaderLayout = styled.div`
  position: relative;
  top: 0;
  margin-left: 5px;
  width: 1200px;
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin-left: 39px;
  color: #362a41;
  font-family: "Inter-Medium,sans-serif";
  font-size: 24px;
`;
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const Card = styled.div`
  position: relative;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(
    100vh - 90px
  ); /* Adjust the height based on your header height */
`;

const ImageContainer = styled.div`
  border-radius: 30px;
  background: white;
  cursor: pointer;
  height: auto;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px; /* Adjust the margin between images */
`;
const OrganisationInstitute = () => {
  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [institute, setInstitute] = useState([]);

  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setSuccess(true);
  }
  const history = useHistory();
  const [openSetting, setOpenSetting] = useState(false);
  const openSubmit = () => {
    setOpenSetting(true);
  };
  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        setCompany(response.data.company);
      })
      .catch((err) => err.error);
  };
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuserinstitute`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        setInstitute(response.data.company);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    GetAllCompany();
    GetAllInstitute();
  }, []);

  const handleCompanyCreate = () => {
    history.push("/portal/company");
  };

  const handleInstitute = () => {
    history.push("/portal/institute");
  };
  return (
    <>
      <HeaderLayout>
        <MainHeader> Organisation Page</MainHeader>
        <SeparteDiv>
          <div
            style={{ position: "relative", marginTop: "12px", right: "90px" }}
          >
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>
      <Card>
        <ImageContainer onClick={handleCompanyCreate}>
          <div>
            <h4 className="text-centerOrgg">Create Company</h4>
            <img src={image3} alt="images" />
          </div>
        </ImageContainer>

        <ImageContainer onClick={handleInstitute}>
          <div>
            <h4 className="text-centerOrgg">Create Insititution</h4>
            <img src={image2} alt="image45" />
          </div>
        </ImageContainer>
      </Card>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrganisationInstitute;
