/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Back from "../../images/Back.svg";
import "./reschedule.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Common from "../../utils/common";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import * as moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Reschedule = ({ open, bookingId }) => {
  const openInterviewRequest = () => {
    open();
  };
  // Generate time slots every 30 minutes starting from 12:00 AM
  const generateTimeSlots = () => {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const period = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        const time = `${String(formattedHour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")} ${period}`;
        timeSlots.push(time);
      }
    }
    return timeSlots;
  };

  const [timeSlots] = useState(generateTimeSlots());
  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  // Helper function to get month name from month index
  const getMonthName = (monthIndex) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  };
  const generateDateOptions = () => {
    const options = [];
    const currentDate = new Date();
    for (let i = 0; i < 30; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() + i);
      options.push(date);
    }
    return options;
  };
  const [dateOptions] = useState(generateDateOptions());
  const [selectedDate, setSelectedDate] = useState();

  const [isDatePickerOpen, setDatePickerOpen] = useState(true);
  const [bookingDetail, setBookingDetail] = useState();
  const [toTime, setToTime] = useState([]);
  const [eventId, setEventId] = useState();
  const history = useHistory();
  const [reason, setReason] = useState();
  const [data, setData] = useState([]);
  const getAllAvialabilityService = () => {
    axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-all-availability-user/${Common.loggedIn()}`
      )
      .then((response) => {
        setData(response.data.interview);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [vacationDates, setVacationDates] = useState([]);
  const getAllVaccationService = () => {
    axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-all-vaccation-user/${Common.loggedIn()}`
      )
      .then((response) => {
        const vacations = response.data?.vaccation[0]?.vacation; // Assuming data is in response.data[0].vacation

        const disabledDates = vacations?.map((vacation) => new Date(vacation));
        setVacationDates(disabledDates);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAllAvialabilityService();
    getAllVaccationService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectedDayAvailability = data[0]?.daysAvailability;

  const unavailableDays = selectedDayAvailability
    ? selectedDayAvailability
        .map((day) => {
          const currentDate = new Date();
          const dayIndex = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].indexOf(day.day);

          const shouldDisableDay = day.dateInterview.some(
            (interview) =>
              (interview.fromTime.trim() !== "" &&
                interview.toTime.trim() === "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() !== "") ||
              (interview.fromTime.trim() === "" &&
                interview.toTime.trim() === "")
          );

          if (shouldDisableDay) {
            const datesToDisable = [];
            for (let month = 0; month < 12; month++) {
              const lastDayOfMonth = new Date(
                currentDate.getFullYear(),
                month + 1,
                0
              ).getDate();
              for (let i = 1; i <= lastDayOfMonth; i++) {
                const nextOccurrenceDate = new Date(
                  currentDate.getFullYear(),
                  month,
                  i
                );
                if (nextOccurrenceDate.getDay() === dayIndex) {
                  const isoDate = nextOccurrenceDate.toISOString();
                  datesToDisable.push(
                    isoDate.substring(0, isoDate.indexOf("T") + 1) +
                      "18:30:00.000Z"
                  );
                }
              }
            }

            return datesToDisable;
          }

          return null; // Return null for days that should not be disabled
        })
        .flat() // Flatten the array of dates
        .filter((date) => date !== null) // Filter out null values
    : [];

  const combinedExcludedDates = [...unavailableDays];
  if (vacationDates?.length > 0) {
    combinedExcludedDates.push(...vacationDates);
  }
  const formatValue = (data) => {
    if (data) {
      const dateObject = new Date(data);
      dateObject.setDate(dateObject.getDate() - 1);
      dateObject.setUTCHours(18, 30, 0, 0);
      const isoString = dateObject.toISOString();
      return isoString;
    }
  };
  const id = formatValue(selectedDate);

  const getOneBookingSlot = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${bookingId}`)
      .then((res) => {
        setBookingDetail(res.data.bookingSlot);
        if (res.data && res.data.bookingSlot.unselectedTimeSlots.length > 0) {
          setToTime(res.data.bookingSlot.unselectedTimeSlots);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getOneBookingSlot();
  }, []);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    date.setDate(date.getDate());

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const suffix = (() => {
      if (day === 1 || day === 21 || day === 31) return "st";
      if (day === 2 || day === 22) return "nd";
      if (day === 3 || day === 23) return "rd";
      return "th";
    })();

    return `${day}${suffix} ${month}, ${year}`;
  };

  // Example usage:
  const formattedDate = formatDate(bookingDetail?.date);

  const rescheduleSubmit = () => {
    const payload = {
      date: formatValue(selectedDate),
      time: selectedTime,
      rescheduleReason: reason,
    };
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/get-update-time/${bookingId}`,
        payload
      )
      .then((res) => {
        const date = res.data.bookingSlotUpdate.date;
        const time = res.data.bookingSlotUpdate.time;

        const payload = {
          fromEpoch: combineDateAndTime(date, time),
          toEpoch: combineDateAndTime(date, time) + 600,
        };
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/get-calendar-update/${eventId}`,
            payload
          )
          .then(() => {
            toast.success("Slot is Reschedule Successfully");
            history.goBack();
          })
          .catch(() => {});
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getOneEvent = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/event-get/${bookingId}`)
      .then((res) => {
        setEventId(res.data.updateJob?._id);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getOneEvent();
  }, []);
  const combineDateAndTime = (dateString, timeString) => {
    const dateMoment = moment(dateString);
    const timeMoment = moment(timeString, "h:mm A");

    const combinedMoment = dateMoment.set({
      hour: timeMoment.hours(),
      minute: timeMoment.minutes(),
      second: timeMoment.seconds(),
    });

    const momentEpoch = combinedMoment.unix();

    return momentEpoch;
  };

  return (
    <>
      <div>
        <div className="reschedule-main-div">
        <div>
          <div className="re-head-wrap">
            <img
              src={Back}
              alt="test"
              onClick={openInterviewRequest}
              className="reschedule-open"
            />
            <div className="re-head">Reschedule Service</div>
          </div>
          <div className="re-head re-subhead">Slot Book on: </div>
        <span className="re-date">{formattedDate}, 
        {toTime.length > 0 ? <> {`${bookingDetail?.time}`} {`${toTime[0]}`}</> : <div>-</div>}
        </span>
        <div className="re-head re-subhead">Reschedule On</div>
          <div className="re-head-wrap">
            <div>
              <div className="re-date-head">Select Date</div>
              <select
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="re-sel-date"
              >
                {dateOptions.map((date, index) => (
                  <option key={index} value={date} >
                    {formatDate(date)}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="re-date-head">Select Time</div>
              <select value={selectedTime} onChange={handleTimeChange} className="re-sel-date">
                {timeSlots.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
          <div style={{ position: "relative", bottom: "180px",left:"50px" }}>
            <DatePicker
              onCalendarOpen={() => setDatePickerOpen(true)}
              open={isDatePickerOpen}
              excludeDates={combinedExcludedDates.map(
                (isoDate) => new Date(isoDate)
              )}
              minDate={new Date()}
            />
          </div>
        </div>
        
          <div className="resch-reas">
            <label className="re-head">Reason for reschedule</label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter the reason"
                // className="font"
                aria-label="Job Title"
                name="rescheduleReason"
                value={reason}
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "93px",
                  color: "#362A41",
                  fontSize: "12px",
                  fontweight: "normal",
                  // fontStyle: "italic",
                }}
                onChange={(e) => setReason(e.target.value)}
              />
            </InputGroup>
          </div>
        </div>
        <div className="request-button">
          <button className="button5">Cancel</button>
          <button className="button3" onClick={rescheduleSubmit}>
            Request
          </button>
        </div>
    </>
  );
};

export default Reschedule;
