import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import { FaRupeeSign } from "react-icons/fa";
import parse from "html-react-parser";
import Common from "../../utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./shared.css";
import xalogo from "../../images/xakallogo.png";
import DesktopHeader from "../desktopHeader";

const MainLayout = styled.div`
  overflow-x: hidden;
  width: 100%;
  padding: 0px 0px 20px 0px;
  // border: 1px solid grey;
  // box-shadow: 5px 5px 5px 5px;
`;
const Wrappers = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-top: 20px;
`;

const PageWrapper = styled.div`
  background: #fff;
  border-radius: 24px;
  padding: 15px;
  max-width: 50%;
  margin-right: 2rem;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 1rem 0;
  }
  @media screen and (min-width: 1104px) {
    width: 820px;
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: 576px) {
    margin-bottom: 0;
  }
`;

const Wrapper = styled.div``;
const JobDetailIcon = styled.div``;
const JobTitle = styled.div``;
const JobTitle2 = styled.div``;
const JobTitleIcon = styled.div``;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 10px 0;
  background: #f7f7f8;
  gap: 10px;
  padding: 6px 6px;
  border-radius: 8px;
`;
const Preference = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  color: #362a41;
`;
const JobPreference = styled.div`
  color: #4f4953;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  height: 33px;
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  @media (max-width: 576px) {
    height: auto;
  }
`;
const CardWrapper = styled.div`
  margin-left: 20%;
  min-width: 450px;
`;
const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  color: #4f4953;
`;
const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  margin-top: 10px;
`;
const ShareJob = () => {
  const [jobData, setJobData] = useState();
  const getJobIdFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("jobId");
  };
  const history = useHistory();
  const [dataValue, setDataValue] = useState([]);
  const [id, setId] = useState(null);
  const getOneDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        setDataValue(response.data.aboutOne.coreSkill);
      })
      .catch((err) => {});
  };
  const clickHome = () => {
    history.push(`/portal/shares-apply-job/${id}`);
  };
  const clickHomeRedirect = () => {
    const jobId = getJobIdFromURL();
    history.push(`/portal/shares-apply-job/${jobId}`);
  };
  const loginRedirect = () => {
    history.push({
      pathname: "/login",
      state: { from: id },
    });
  };

  const getOneJob = () => {
    axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-manage-job-detail/${getJobIdFromURL()}`
      )
      .then((res) => {
        setJobData(res.data.updateJob);
        setId(res.data.updateJob._id);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getOneJob();
    var match = document.cookie.match(
      // eslint-disable-next-line no-useless-concat
      new RegExp("(^| )" + "alreadyLoggedIn" + "=([^;]+)")
    );
    if (match && match[2]) {
      getOneDetail();
      clickHomeRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isSkillMatch = jobData?.skill?.some((skill) =>
    dataValue.some((data) => data.value === skill)
  );
  useEffect(() => {
    document.title = "Share Job | Xakal";
  }, []);

  return (
    <div>
      
      <div className="login-wrappersShare">
        <MainLayout>
          <ul
            className="navbar-nav nav-desktop   bg-gradient-primary sidebar sidebar-dark accordion html"
            id="accordionSidebar"
          >
            <button className="sidebar-brand d-flex align-items-center justify-content-center">
              <div className="logo-container ">
                <img className="sb-main-logo" src={xalogo} alt="XAKAL" />
              </div>
            </button>
            <hr className="sidebar-divider my-0" />
            <br></br>
            <div className="nav-wrapper">
              <div>
                <li className="nav-item">
                  <div className="nav-link iInterviews collapsed ">
                    <span className="pro nav-weight"> Interviews</span>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-link iJobs collapsed ">
                    <span className="pro nav-weight"> Jobs</span>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-link iATS collapsed ">
                    <span className="pro nav-weight"> ATS</span>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-link iCalendar collapsed ">
                    <span className="pro nav-weight"> My Calender</span>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-link iOrganisation collapsed ">
                    <span className="pro nav-weight"> Organisation</span>
                  </div>
                </li>
              </div>
              <div>
                <div className="job-btn-wrapper">
                  <button
                    className="job-btn share-signbtn"
                    onClick={loginRedirect}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </ul>
          <DesktopHeader />
          <Wrappers>
            <CardWrapper className="job-card-wrapper">
              <div>
                <ContentWrapper className="job-content-wrapper">
                  <div className="company-logo-container">
                    <div className="company-logo-container space">
                      {jobData?.companyName &&
                      jobData?.companyName?.companyLogo ? (
                        <>
                          <img
                            src={
                              jobData?.companyName &&
                              jobData?.companyName?.companyLogo
                            }
                            alt="img"
                            className="company-logo"
                          />
                        </>
                      ) : (
                        <img src={Image} alt="img" className="company-logo" />
                      )}
                    </div>
                  </div>
                  <div className="share-job-div">
                    <Wrapper className="job-title-wrapper">
                      <JobTitle className="share-job-title">
                        {jobData?.jobTitle}
                      </JobTitle>
                      <JobTitle2 className="share-company-title">
                        {jobData?.companyName && jobData?.companyName.value}
                      </JobTitle2>
                    </Wrapper>
                  </div>
                </ContentWrapper>
                <HrLines className="" />
                <JobDetailIcon className="share-job-details">
                  <JobTitleIcon className="icon-title">
                    <img src={Suit} alt="img" className="suit-share" />
                    <span>
                      Exp{" "}
                      {jobData?.experienceRequired2
                        ? `${jobData?.experienceRequired} - ${jobData?.experienceRequired2} yrs`
                        : jobData?.experienceRequired
                          ? `${jobData?.experienceRequired} yrs`
                          : ""}
                    </span>
                  </JobTitleIcon>
                  <JobTitleIcon className="icon-title">
                    <img src={Salary} alt="img" className="salary-suit" />
                    <FaRupeeSign size={10} color="grey" />
                    <span>{`${jobData?.salaryFrom}L - ${jobData?.salaryTo}L`}</span>
                  </JobTitleIcon>
                  <JobTitleIcon className="icon-title">
                    <img src={Location} alt="img" className="location-suit" />
                    <span>
                      {jobData?.location?.substring(0, 8)}
                      {jobData?.location?.length > 8 ? "..." : ""}
                    </span>
                  </JobTitleIcon>

                  <JobTitleIcon className="icon-title">
                    <img src={Home} alt="img" className="home-suit" />
                    <span> {jobData?.workMode}</span>
                  </JobTitleIcon>
                </JobDetailIcon>
              </div>
            </CardWrapper>

            <PageWrapper>
              <ContentWrapper className="job-content-wrapper">
                <div className="company-logo-container space">
                  {jobData?.companyName && jobData?.companyName?.companyLogo ? (
                    <>
                      <img
                        src={
                          jobData?.companyName &&
                          jobData?.companyName?.companyLogo
                        }
                        alt="img"
                        className="company-logo"
                      />
                    </>
                  ) : (
                    <img src={Image} alt="img" className="company-logo" />
                  )}
                </div>
                <div className="share-job-div">
                  <Wrapper className="job-title-wrapper">
                    <JobTitle className="share-job-title">
                      {jobData?.jobTitle}
                    </JobTitle>
                    <JobTitle2 className="share-company-title">
                      {jobData?.companyName && jobData?.companyName.value}
                    </JobTitle2>
                  </Wrapper>
                </div>
              </ContentWrapper>

              <JobDetailIcon className="share-job-details">
                <JobTitleIcon className="icon-title">
                  <img src={Suit} alt="img" className="suit-share" />
                  <span>
                    Exp{" "}
                    {jobData?.experienceRequired2
                      ? `${jobData?.experienceRequired} - ${jobData?.experienceRequired2} years`
                      : jobData?.experienceRequired
                        ? `${jobData?.experienceRequired} years`
                        : ""}
                  </span>
                </JobTitleIcon>
                <JobTitleIcon className="icon-title">
                  <img src={Salary} alt="img" className="salary-suit" />
                  <FaRupeeSign size={10} color="grey" />
                  <span>{`${jobData?.salaryFrom}L - ${jobData?.salaryTo}L`}</span>
                </JobTitleIcon>
                <JobTitleIcon className="icon-title">
                  <img src={Location} alt="img" className="location-suit" />
                  <span>{jobData?.location}</span>
                </JobTitleIcon>

                <JobTitleIcon className="icon-title">
                  <img src={Home} alt="img" className="home-suit" />
                  <span> {jobData?.workMode}</span>
                </JobTitleIcon>
                <JobTitleIcon className="icon-title">
                  <img src={Clock} alt="img" className="clock-share" />
                  <span> {jobData?.employeeType} </span>
                </JobTitleIcon>
              </JobDetailIcon>

              <Preference>Preferred :</Preference>
              <PreferenceWrapper>
                {jobData?.preference
                  ? jobData?.preference.map((i) => {
                      return <JobPreference>{i}</JobPreference>;
                    })
                  : []}
              </PreferenceWrapper>

              <Preference>Skills :</Preference>

              <PreferenceWrapper>
                {jobData?.skill
                  ? jobData?.skill.map((i) => {
                      return <JobPreference>{i}</JobPreference>;
                    })
                  : []}
              </PreferenceWrapper>

              <Preference>Job Description and Benefits :</Preference>
              <PreferenceWrapper>
                <Content>
                  {jobData && jobData?.jobDesc
                    ? parse(String(jobData?.jobDesc))
                    : "-"}
                </Content>
              </PreferenceWrapper>
              <div className="button-match">
                {isSkillMatch ? (
                  <button className="job-btn" onClick={clickHome}>
                    Apply now
                  </button>
                ) : (
                  <>
                    {Common.loggedIn() ? (
                      <button className="job-btn" onClick={clickHome}>
                        Apply Now
                      </button>
                    ) : (
                      <button className="job-btn" onClick={loginRedirect}>
                        Apply Now
                      </button>
                    )}
                  </>
                )}
              </div>
            </PageWrapper>
          </Wrappers>
        </MainLayout>
      </div>
    </div>
  );
};

export default ShareJob;
