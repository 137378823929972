import React, { useState } from 'react';
import "./interview.css";

const ReadMore = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className='des-text'>
      {isExpanded ? text : `${text?.slice(0, maxLength)}`}
      <button onClick={toggleExpanded} className='read-more'>
        {isExpanded ? 'Read less' : text?.length > 50 ? '...Read more' : ""}
      </button>
    </div>
  );
};

export default ReadMore;