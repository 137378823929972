import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import xalogo from "../images/xakallogo.png";
import "../styles/utils.css";
import backArrow from "../images/arrow-box.svg";
import axios from "axios";
import {
  useHistory,
  Link,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Forgetpassword = () => {
  const [mail, setMail] = useState();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
      setEnabled(true); // Enable the button when email is present
      setMail(null);
    }
  }, [location.state]);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const history = useHistory();

  const handleChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);

    if (isValidEmail(inputEmail) && inputEmail.trim() !== "") {
      setError(null);
      setMail(null);
    } else {
      setError("Email is invalid");
      setEnabled(false);
      setMail(null);
    }
  };

  const handleInput = async (e) => {
    e.preventDefault();
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setMail(null);
    setError(null);

    if (!inputEmail) {
      setEnabled(false);
    } else if (isValidEmail(inputEmail)) {
      try {
        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/validateForgotEmail`,
          {
            email: inputEmail,
          }
        );

        if (response && response.data && response.data.success === true) {
          setMail("");
          setEnabled(true);
        } else {
          setMail(response.data.error);
          setEnabled(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMail("Email id is invalid");
      setEnabled(false);
    }
  };

  const handleEnable = (event) => {
    event.preventDefault();
    setMinutes(1);
    setSeconds(0);
    setEnabled(false);
    // sendOtp();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOtp = async (e) => {
    if (email === "") {
      alert("Enter Your Email !");
    } else if (!email.includes("@")) {
      alert("Enter Valid Email !");
    } else {
      const data = {
        email: email,
      };

      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/forgot-password`,
        data
      );
      if (response.status === 200) {
        handleEnable(e);
        toast.success("OTP Sent Successfully");
        history.push({
          pathname: "/new-otp",
          state: { email: email, minutes: minutes, seconds: seconds },
        });
      } else {
        // toast.error(response.response.data.error);
      }
    }
  };

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <div className="form-wrapper  wrap-login100 p-b-20">
        <div className="margin-logo">
          <div className="logo-holder">
            <img
              src={xalogo}
              className="login-logo signup-xakal-logo"
              alt="XAKAL"
            />
          </div>
        </div>
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header">
              {/* <LuArrowLeftSquare
              size={30}
              style={{ marginTop: "2px" }}
              onClick={() => history.push("/")}
            /> */}
              <div className="arrow-box">
                <Link
                  to={{
                    pathname: "/login",
                    state: {
                      email: email,

                      // Include other data you want to pass here
                    },
                  }}
                >
                  <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
                </Link>
              </div>
              <h4 className="textpass font-face-gm mb-0">Forgot Password</h4>
            </div>

            <div className="form-class p-0">
              <p className="textpass font-face-gm">Email</p>
              <div className={"wrap-input100"}>
                <input
                  autoComplete="off"
                  className="input101"
                  onChange={handleChange}
                  onBlur={handleInput}
                  value={email}
                  placeholder="Enter your email"
                />
              </div>
              {mail && <span className="text-danger">{mail}</span>}
              <p style={{ color: "red" }}>{error}</p>

              <div className="container-login100-form-btn m-b-20">
                <button
                  className="login100-form-btn w-100"
                  disabled={!enabled}
                  style={{ opacity: enabled ? "100%" : "50%" }}
                  onClick={sendOtp}
                >
                  Get OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
