import React, { useEffect, useState } from "react";
import axios from "axios";
import Common from "../../utils/common";
import styled from "styled-components";

const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  width: 300px;
  gap: 24px;
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyRequests = () => {
  const [requests, setRequests] = useState([]);
  const getMyRequest = async () => {
    return await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`,
      )
      .then((res) => {
        console.log("my request", res?.data);
        setRequests(res?.data);
      });
  };
  useEffect(() => {
    getMyRequest();
  }, []);
  return (
    <div style={{ marginLeft: "260px", paddingBottom: "30px" }}>
      <div className="Myprofile-Head">
        <p>My Requests</p>
      </div>
      <div style={{ paddingTop: "120px" }}>
        {requests?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <Img src={invitation?.details?.avatar} alt="" />
            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              <p></p>
            </DetailsWrapper>
          </InvitationCard>
        ))}
      </div>
    </div>
  );
};
export default MyRequests;
