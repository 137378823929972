import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import Suit from "../../images/suitcase.svg";
import Clock from "../../images/clock-circle.svg";
import Location from "../../images/AboutLanding/solar_map-point-bold-duotone.svg";
import Home from "../../images/solar_home-bold-duotone.png";
import Salary from "../../images/solar_hand-money-bold-duotone.png";
import Spinner from "react-bootstrap/Spinner";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import "../../styles/navbar.css";
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;
const MainLayout = styled.div`
  // margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: #f7f7f8;
  border-radius: 0px;
  padding: 15px;
  // margin: 1rem 0 0rem 0;

  // @media (max-width: 500px) {
  //   padding: 1.5rem 1.5rem 1.5rem 1rem;
  //   margin: 2rem 0;
  // }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 5px;
`;

const Wrappers = styled.div`
  margin-top: 10px;
`;
const JobTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #362a41;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const JobTitle2 = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #4f4953;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0 0 0;
  }
`;
const JobDetailIcon = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  left: 5px;
  margin-top: 20px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const JobTitleIcon = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: #4f4953;
  font-size: 12px;
  display: flex;
  // gap:10px;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 22px;
    margin: 10px 0 0 0;
  }
`;
const PreferenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  // height: auto;
  margin: 10px 0;
  background-color: white;
  // gap: 10px;
  padding: 12px 6px;
  border-radius: 8px;
`;
const Preference = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-top: 20px;
  color: #362a41;
`;
const JobPreference = styled.div`
  color: #4f4953;
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  // height: 33px;
  font-size: 14px;
  margin: 5px 5px;
  width: fit-content;
  font-family: Inter-Medium, sans-serif;
  display: flex;
  font-weight: normal;
`;
const Content = styled.div`
  font-style: Inter-Medium, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #4f4953;
`;

const ArchivedPreview = ({
  previewId,
  goToJobTwo,
  closePreview,
  callAll,
  archivedPreviewId,
  openPopup
}) => {
  const { id } = useParams();
  const [data, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (previewId) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${previewId}`
        )
        .then((response) => {
          setInput(response.data.updateJob);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (archivedPreviewId) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${archivedPreviewId}`
        )
        .then((response) => {
          setInput(response.data.updateJob);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewId, archivedPreviewId]);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/job-posted/${data._id}`)
      .then(() => {
        toast.success("Job posted successfully...");
        setTimeout(() => {
          closePreview();
          callAll();
              openPopup()
        }, 1000);
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSubmitArchived = () => {
    setLoading(true);
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/job-posted/${archivedPreviewId}`)
      .then(() => {
        toast.success("Job posted successfully...");
        setTimeout(() => {
          closePreview();
          callAll();
          openPopup()
        }, 1000);
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const [shouldSave, setShouldSave] = useState(false);
  const handleSave = () => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-post-drafts/${data._id}`)
      .then((response) => {})
      .catch((error) => {
        console.error("Error updating item:", error);
      });
  };
  return (
    <div style={{ margin: "0px" }}>
      <ToastContainer />
      <MainLayout>
        <div className="modalHeader mheader">
          <div className="mTitle">Job Post Preview</div>
          <img src={CloseIcon} alt="img" onClick={() => setShouldSave(true)} />
        </div>
        <div className="modalBody" style={{ padding: "0px" }}>
          <PageWrapper>
            {/*<HeaderText>{"Preview Job"}</HeaderText>*/}
            <ContentWrapper>
              {data.companyName && data.companyName.companyLogo ? (
                <img
                  src={data.companyName && data.companyName.companyLogo}
                  alt="img"
                  style={{ width: "60px" }}
                />
              ) : (
                ""
                // <img src={Image} alt="img" style={{ width: "60px" }} />
              )}
              <Wrappers>
                <JobTitle>{data.jobTitle}</JobTitle>
                <JobTitle2>
                  {data.companyName && data.companyName.value}
                </JobTitle2>
              </Wrappers>
            </ContentWrapper>
            <JobDetailIcon>
              <JobTitleIcon>
                <img
                  src={Suit}
                  alt="img"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                Exp {`${data.experienceRequired} - ${data.experienceRequired2}`}{" "}
                Years
              </JobTitleIcon>
              <JobTitleIcon>
                <img
                  src={Salary}
                  alt="img"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                {/* <FaRupeeSign size={15} color="grey" style={{margin:"5px 5px 0 0"}}/> */}
                {` ₹${data.salaryFrom} L -  ₹${data.salaryTo} L`}
              </JobTitleIcon>
              <JobTitleIcon>
                {" "}
                <img
                  src={Location}
                  alt="img"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                {data.location}
              </JobTitleIcon>
              <JobTitleIcon>
                <img
                  src={Home}
                  alt="img"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                {data.employeeType}
              </JobTitleIcon>
              <JobTitleIcon>
                {" "}
                <img
                  src={Clock}
                  alt="img"
                  style={{ marginRight: "5px", width: "20px", height: "20px" }}
                />
                {data.workMode}
              </JobTitleIcon>
            </JobDetailIcon>

            <Preference>Preferred :</Preference>
            <PreferenceWrapper>
              {data.preference
                ? data.preference.map((i) => {
                    return <JobPreference>{i}</JobPreference>;
                  })
                : []}
            </PreferenceWrapper>

            <Preference>Skills :</Preference>
            <PreferenceWrapper>
              {data.skill
                ? data.skill.map((i) => {
                    return <JobPreference>{i}</JobPreference>;
                  })
                : []}
            </PreferenceWrapper>

            <Preference>Job Description And Benefits :</Preference>
            <PreferenceWrapper>
              <Content>
                {data.jobDesc
                  ? parse(data.jobDesc)
                  : "No description available"}{" "}
              </Content>
            </PreferenceWrapper>
          </PageWrapper>
        </div>
      </MainLayout>
      <div className="modalFooter modalfooter">
        <button className="button5" onClick={() => goToJobTwo()}>
          Back
        </button>
        {archivedPreviewId ? (
          <button
            className="button3"
            style={{ marginRight: "2rem" }}
            onClick={() => handleSubmit(id)}
          >
            {loading ? <Spinner /> : "POST"}
          </button>
        ) : (
          <button
            className="button3"
            style={{ marginRight: "2rem" }}
            onClick={() => handleSubmitArchived(id)}
          >
            {loading ? <Spinner /> : "POST"}
          </button>
        )}

        {data.scheduleDate ? (
          <div style={{ display: "block" }}>
            <p style={{ color: "#964F90", fontweight: "normal" }}>
              Scheduled On :
            </p>
            <p style={{ color: "#362A41", fontweight: "normal" }}>
              {data.scheduleDate}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={shouldSave}
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#F4F4F6",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
        halfWidth
        maxWidth="md"
        className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img
              src={QuestionImg}
              style={{ marginTop: "2px", width: "24px", height: "24px" }}
              alt="img"
            />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            Save Changes As Draft ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => closePreview()}
                style={{ cursor: "pointer" }}
              >
                No
              </Button4>
              <Button6
                onClick={() => {
                  closePreview();
                  handleSave()
                }}
              >
                Yes, Save
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ArchivedPreview;
