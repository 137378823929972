import React from "react";
import { useState, useEffect } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import xalogo from "../images/xakallogo.png";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { Amplify, Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Progress from "./progressBar";
import Common from "../utils/common";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
  
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});

function Signup() {
  const { linkedInLogin } = useLinkedIn({
    clientId: "867b8wu7jboe77",
    redirectUri: `https://thexakal.com/linkedin`,
    scope: ["openid", "profile", "email"],
    state: [
      "iss",
      "aud",
      "iat",
      "exp",
      "sub",
      "name",
      "given_name",
      "family_name",
      "picture",
      "email",
      "email_verified",
      "locale",
    ],
    onSuccess: async (code) => {
      axios
        .post(`${process.env.PUBLIC_URL}/xakal/linkedinUser`, {
          code: code,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data && response.data.r) {
              let r = response.data.r;
              if (r.email && r.family_name && r.given_name) {
                axios
                  .post(`${process.env.PUBLIC_URL}/xakal/getUser`, {
                    email: r.email,
                  })
                  .then((response) => {
                    if (
                      response &&
                      response.data &&
                      response.data.user &&
                      response.data.user.email
                    ) {
                      axios.defaults.headers.common["x-access-token"] =
                        response.data.token;
                      document.cookie = `userid=${response.data.user._id}; path=/`;
                      checkForSkills(response.data, true, r);
                    } else {
                      setInput({
                        ...input,
                        firstName: r.given_name,
                        middleName: "",
                        lastName: r.family_name,
                        email: r.email,
                        password: "",
                        confirmPassword: "",
                      });
                      history.push("/signup", {
                        input: {
                          firstName: r.given_name,
                          middleName: "",
                          lastName: r.family_name,
                          email: r.email,
                          password: "",
                          confirmPassword: "",
                        },
                      });
                    }
                  });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const [input, setInput] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    middleName: "",
    lastName: "",
  });
  const { state } = useLocation();
  const [shareId, setShareId] = useState();
  const [shareInterviewId, setShareInterviewId] = useState();

  useEffect(() => {
    setInput(state && state.input);
    setShareId(state && state.shareJobId);
    setShareInterviewId(state && state.shareInterviewId);
  }, [state]);
  console.log(shareInterviewId,"shareInterviewId")

  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });
  const [enable, setEnable] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [mail, setMail] = useState(null);
  const [error3, setError3] = useState(null);

  const onInputEmail = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMail(null);
    setError3("")
  };

  const onInputChanges = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMail(null);
    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
    setError3("");
  };

  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          const mail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            value
          );
          if (!value) {
            stateObj[name] = "Please enter email";
          } else if (!mail) {
            stateObj[name] = "Please enter valid email";
          } else {
            setTimeout(() => {
              submitSignup();
            }, 1000);
          }
          break;
        case "password":
          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input?.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter password";
          } else if (!uppercasePassword) {
            stateObj[name] = "At least one uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "At least one lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "At least one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "At least one special character";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least 8 characters";
          } else {
            stateObj["confirmPassword"] = input?.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please re-type your password";
          } else if (input?.password && value !== input?.password) {
            stateObj[name] =
              "Re-entered password doesn't match with the previous password";
          }
          break;

        default:
          break;
      }

      const allFieldsFilled =
        input?.email && input?.password && input?.confirmPassword;

      const passwordsMatch = input?.password === input?.confirmPassword;

      if (allFieldsFilled && passwordsMatch) {
        setEnable(true);
      } else {
        setEnable(false);
      }

      return stateObj;
    });
  };
  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    console.log(enable);
    setMinutes(1);
    setSeconds(0);
    setEnable(false);
    if (
      input?.email === "" ||
      !input?.email ||
      input?.password === "" ||
      !input?.password ||
      input?.confirmPassword === "" ||
      !input?.confirmPassword
    ) {
      setError3("Please fill all the fields");
    } else if (input?.password !== input?.confirmPassword) {
      setError3("Password mismatch");
    } else if (error.confirmPassword || error.password || error.email) {
      setError3("Please check errors");
    } else {
      submitSignup(true);
    }
  };

  const submitSignup = (flag = false) => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/validateUserEmail`, {
        email: input.email,
      })
      .then((response) => {
        if (response.data.success === true) {
          flag = false;
          setMail(response.data.error);
          setError3("Please check errors");
          console.log(response.data.message);
        } else if (response.data.success === false) {
          setMail(null);
          if (flag) {
            setError3(null);
            const data = {
              email: input?.email,
            };
            axios
              .post(`${process.env.PUBLIC_URL}/xakal/otp`, data)
              .then((response) => {
                if (response.status === 200) {
                  toast.success("OTP Sent Successfully...!");
                  // handleShowToaster();
                  history.push({
                    pathname: "/otp-verification",
                    state: {
                      email: input?.email,
                      password: input?.password,
                      confirmPassword: input?.confirmPassword,
                      minutes: minutes,
                      seconds: seconds,
                      firstName: input?.firstName,
                      middleName: "",
                      lastName: input?.lastName,
                      shareJobId: shareId,
                      shareInterviewId: shareInterviewId,
                    },
                  });
                } else {
                  // toast.error(response.response.data.error);
                }
              });
          }
        } else {
          setMail(null);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };
  const history = useHistory();
  useEffect(() => {
    document.title = "Signup | Xakal";
  }, []);

  const checkForSkills = (data, defaultRoute = false, user) => {
    let alreadyLoggedIn = false;
    var match = document.cookie.match(
      // eslint-disable-next-line no-useless-concat
      new RegExp("(^| )" + "alreadyLoggedIn" + "=([^;]+)")
    );
    if (match && match[2]) {
      alreadyLoggedIn = true;
      history.push({
        pathname: "/portal/jobs",
        state: [input.email, input.password],
      });
    }

    if (data && data.user && data.user._id && !alreadyLoggedIn) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-about-detail/${data.user._id}`,
          data
        )
        .then((response) => {
          if (
            response.data &&
            response.data.aboutOne &&
            response.data.aboutOne.coreSkill &&
            response.data.aboutOne.coreSkill.length
          ) {
            history.push({
              pathname: "/portal/jobs",
              state: [input.email, input.password],
            });
          } else if (!defaultRoute) {
            history.push({
              pathname: "/on-boarding",
              state: {
                input: {
                  email: input?.email,
                  firstName: input?.firstName,
                  lastName: input?.lastName,
                  middleName: input?.middleName,
                  password: input?.password,
                  confirmPassword: input?.confirmPassword,
                  // minutes: minutes,
                  // seconds: seconds,
                },
              },
            });
          } else if (user && user.given_name) {
            history.push({
              pathname: "/on-boarding",
              state: {
                firstName: user.given_name,
                middleName: "",
                lastName: user.family_name,
                email: user.email,
                password: "",
                confirmPassword: "",
              },
            });
          }
        });
    }
  };
  useEffect(() => {
    if (Common.loggedIn() && Common.loggedIn().length > 0) {
      const params = {
        user: {
          _id: Common.loggedIn(),
        },
      };
      checkForSkills(params, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateProgress = () => {
      // Calculate progress based on current page and total number of pages
      // For simplicity, let's assume we have 3 pages and progress increases by 33.33% on each page
      const currentPage = window.location.pathname;
      const totalPages = 3;
      const currentPageIndex = [
        "/signup",
        "/otp-verification",
        "/on-boarding",
      ].indexOf(currentPage);
      const newProgress = (currentPageIndex + 1) * (100 / totalPages);
      setProgress(newProgress);
    };

    calculateProgress();
  }, []);
  return (
    <div>
      <ToastContainer />
      <div className="login-wrapper signup-main">
        <div className="form-wrapper wrap-login100 p-b-20">
          <div className="margin-logo">
            <div className="logo-holder">
              <Progress progress={progress} />
              <img
                src={xalogo}
                className="login-logo signup-xakal-logo"
                alt="XAKAL"
              />
            </div>
            <div className="lets-getStarted">
              <span>Sign Up</span>
            </div>
          </div>
          <form className="form-class">
            <div className="">
              <div className={"wrap-input100"}>
                <p className="textpass font-face-gm">
                  Email<span className="text-danger">*</span>
                </p>
                <div className={"wrap-input100"}>
                  <input
                    autoComplete="off"
                    type="email"
                    className={"input101"}
                    name="email"
                    placeholder="Enter your mail here"
                    value={input?.email}
                    style={{ color: "#362A41", fontweight: "normal" }}
                    onChange={onInputEmail}
                    onBlur={validateInput}
                  />
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {error.email && (
                    <span className="text-danger f12">{error.email}</span>
                  )}
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {mail && <span className="text-danger f12">{mail}</span>}
                </div>
                <p className="textpass font-face-gm">
                  Password<span className="text-danger">*</span>
                </p>
                <div className={"wrap-input100"}>
                  <div className="pass-input-wrapper">
                    <input
                      autoComplete="off"
                      type={type ? "password" : "text"}
                      className={"input101"}
                      name="password"
                      placeholder="- - - - - - - - "
                      // disabled={!passenable}
                      value={input?.password}
                      onChange={onInputChanges}
                      onBlur={validateInput}
                    />
                    <div className="pass-eye-container">
                      {type ? (
                        <PiEyeSlashLight size={30} onClick={Eye} />
                      ) : (
                        <PiEyeLight size={30} onClick={Eye} />
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  {error.password && (
                    <span className="text-danger f12">{error.password}</span>
                  )}
                </div>

                <p className="textpass font-face-gm">
                  Re-type Password<span className="text-danger">*</span>
                </p>
                <div className={"wrap-input100 m-b-30"}>
                  <div className="pass-input-wrapper">
                    <input
                      autoComplete="off"
                      type={type2 ? "password" : "text"}
                      className={"input101"}
                      name="confirmPassword"
                      placeholder="- - - - - - - - "
                      style={{ color: "#362A41" }}
                      value={input?.confirmPassword}
                      onChange={onInputChanges}
                      onBlur={validateInput}
                    />
                    <div className="pass-eye-container">
                      {type2 ? (
                        <PiEyeSlashLight size={30} onClick={Eye2} />
                      ) : (
                        <PiEyeLight size={30} onClick={Eye2} />
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ position: "relative", bottom: "15px" }}>
                  {error.confirmPassword && (
                    <span className="text-danger f12">
                      {error.confirmPassword}
                    </span>
                  )}
                </div>
                <div className="m-b-20" style={{ position: "relative" }}>
                  {<span className="text-danger f12">{error3}</span>}
                </div>

                <div className="container-login100-form-btn">
                  <button
                    type="submit"
                    className="login100-form-btn"
                    onClick={sendOtp}
                  >
                    Sign Up
                  </button>
                </div>

                <p className="login-signupphrases">
                  Already have an account?{" "}
                  <span
                    onClick={() => history.push("/login")}
                    style={{ margin: "0 3px" }}
                  >
                    Sign In
                  </span>
                </p>
              </div>
            </div>
            <p className="con-options">or continue with</p>
            <div className="social-icon-position">
              <i
                className="ci ci-google"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Google" });
                }}
              ></i>
              {/* <i
                className="ci ci-facebook"
                onClick={() => {
                  Auth.federatedSignIn({ provider: "Facebook" });
                }}
              ></i> */}
               <i
                className="ci ci-linked-in o-desk"
              onClick={linkedInLogin}  
              ></i> 
            </div>
          </form>

          {error.password ? (
            <div>
              <div
                id="message4"
                className="pass-instruct"
                style={{ top: "43%" }}
              >
                {/* <div
                  style={{
                    position: "absolute",
                    left: "-15px",
                    top: "10px",
                    // background: "#1F1A24",
                    // color: "white",
                  }}
                >
                  <PiTrafficSign size={30} />
                </div> */}

                <div
                  style={{
                    position: "relative",
                    marginLeft: "12px",
                  }}
                >
                  <p className="pass-hint-head">
                    Password must contain the following
                  </p>

                  <div
                    className="pass-in-check"
                    style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                  >
                    <div
                      className={passwordVal.lowercase ? "check" : "uncheck"}
                    ></div>
                    <p className={passwordVal.lowercase ? "valid" : "invalid"}>
                      Must have 1 lowercase alphabet
                    </p>
                  </div>

                  <div
                    className="pass-in-check"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <div
                      className={passwordVal.uppercase ? "check" : "uncheck"}
                    ></div>
                    <p className={passwordVal.uppercase ? "valid" : "invalid"}>
                      Must have 1 uppercase alphabet
                    </p>
                  </div>

                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="pass-in-check"
                  >
                    <div
                      className={passwordVal.numeral ? "check" : "uncheck"}
                    ></div>
                    <p
                      id="number"
                      className={passwordVal.numeral ? "valid" : "invalid"}
                    >
                      Must have 1 numeral case alphabet
                    </p>
                  </div>

                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="pass-in-check"
                  >
                    <div
                      className={passwordVal.minchar ? "check" : "uncheck"}
                    ></div>
                    <p
                      id="length"
                      className={passwordVal.minchar ? "valid" : "invalid"}
                    >
                      Minimum 8 characters required
                    </p>
                  </div>

                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="pass-in-check"
                  >
                    <div
                      className={passwordVal.specialChar ? "check" : "uncheck"}
                    ></div>
                    <p
                      className={passwordVal.specialChar ? "valid" : "invalid"}
                    >
                      Must have 1 special character [#?!@$%^&*-]
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Signup;
