import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

import React, { useEffect, useState } from "react";
import "../../styles/myprofile.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Select from "react-select";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import CloseIcon from "../../images/CloseIcon.svg";
import styled from "styled-components";
import { InputGroup, Form } from "react-bootstrap";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";

import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import AvailabilityTimePicker from "../MyProfile/availabilty";
import PreviewPopup from "../MyProfile/previewPopup";
import UpdateServicePopup from "../MyProfile/updateServicePopup";
import ServicePopup from "../MyProfile/servicePopup";
import Common from "../../utils/common";

const TitleHeadar = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  // padding: 30px 30px 0 30px;
  img {
    margin-top: -8px;
    cursor: pointer;
    // margin-left: 240px;
  }
`;
const Title = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  letter-spacing: 0em;
  // text-align: center;
  color: #362a41;
  margin-top: 2px;
`;
const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  position: relative;
  // top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;

  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding: 7px 10px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  // top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const reasonOptions = [
  {
    value: "Not Acceptable",
    label: "Not Acceptable",
  },
  {
    value: "Not enough Skill",
    label: "Not enough Skill",
  },
];

const CustomHeaderInterview = () => {
  const history = useHistory();
  const location = useLocation();
  const isCardActive = (path) => {
    return location.pathname === path;
  };

  const [openService, setOpenService] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [data, setData] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [resetPopup, setResetPopup] = useState(false);
  const [openServiceUpdate, setOpenServiceUpdate] = useState(false);
  const openCreateService = () => {
    setOpenService(true);
    setEditedData(null);
    setResetPopup(!resetPopup);
  };
  const closeServiceUpdate = () => {
    setOpenServiceUpdate(false);
  };
  const closeCreateService = () => {
    setOpenService(false);
  };
  const getAllInterviewService = (status) => {
    const url =
      status === "archived"
        ? `${process.env.PUBLIC_URL}/xakal/get-all-archived`
        : `${process.env.PUBLIC_URL}/xakal/get-all-interview`;

    axios
      .get(url)
      .then((response) => {
        setData(response.data.interview);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [previewForm, setPreviewForm] = useState(false);
  const [previewId, setPreviewId] = useState("");

  const previewFormOpen = (id) => {
    setPreviewForm(true);
    setPreviewId(id);
  };
  const previewFormId = () => {
    setPreviewForm(true);
  };
  const previewClose = () => {
    setPreviewForm(false);
  };

  useEffect(() => {
    if (Common.loggedIn() && Common.loggedIn().length > 0) {
      axios
        .get(
          `${
            process.env.PUBLIC_URL
          }/xakal/get-about-detail/${Common.loggedIn()}`
        )
        .then((response) => {
          setIsAdmin(response.data.aboutOne.userType.includes("admin"));
        })
        .catch((err) => {
          console.error(err);
        });
    }
    getAllInterviewService();
  }, []);

  const [open4, setOpen4] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleteId, setDeleteId] = useState(false);

  const deleteInterviewJob = async (jobId) => {
    try {
      // Make a DELETE request to the delete service endpoint
      await axios.delete(
        `${process.env.PUBLIC_URL}/xakal/get-delete-interview/${jobId}`
      );

      // Update the state to reflect the changes (remove the deleted job from the list)
      setData(data.filter((item) => item._id !== jobId));
      setOpen4(false);
      toast.success("Interview Deleted Successfully...");
    } catch (error) {
      console.error("Error deleting archived job:", error);
    }
  };

  const [openAvailability, setAvailability] = useState(false);

  const [isAvailableButtonClicked, setAvailableButtonClicked] = useState(false);

  const availabiltyCancel = () => {
    setAvailability(false);
    if (!isAvailableButtonClicked) {
      openCreateService();
    }
    setAvailableButtonClicked(false);
  };
  const goToService = (id) => {
    setOpenServiceUpdate(true);
    setPreviewForm(false);
    setPreviewId(id);
  };

  const handleClose = () => {
    history.push({
      pathname: "/portal/my-profile",
      state: {
        goInActive: true,
      },
    });
  };

  const [availabilityData, setAvailabilityData] = useState([]);
  const [editId, setEditId] = useState(null);

  const handleAvailabilityButtonClick = (isButtonClicked = false) => {
    setAvailableButtonClicked(isButtonClicked);
    if (Array.isArray(availabilityData) && availabilityData.length === 0) {
      // Open create popup
      setEditId(null);
      setAvailability(true);
    } else {
      const idToEdit = availabilityData[0]?._id;
      setEditId(idToEdit);
      setAvailability(true);
    }
  };
  const getAllAvialability = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-availability`)
      .then((response) => {
        setAvailabilityData(response.data.interview);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAllAvialability();
  }, []);

  const [declineDescription, setDeclineDescription] = useState("");
  const [reason, setReason] = useState("");
  const handleDecline = (id) => {
    const payload = {
      declineDescription: declineDescription,
      declineReason: reason,
    };
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/get-update-decline/${id}`, payload)
      .then((response) => {
        toast.success("Slot  Declined Successfully");
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/remove/event`, {
            bookingId: id,
          })
          .then((res) => {
          });
        setDeclineOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [declineOpen, setDeclineOpen] = useState(false);
  const declineForm = () => {
    setDeclineOpen(false);
  };
  const handleReason = (selectedOptions) => {
    const selectedOption = selectedOptions.value;
    setReason(selectedOption);
  };
  // eslint-disable-next-line no-unused-vars
  const [declineId, setIdDecline] = useState();

  const closeAvail = () => {
    setAvailability(false);
  };
  const [userRole, setUserRole] = useState(false);
  const prefill = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.aboutOne) {
          setUserRole(response.data.aboutOne.userType.includes("interviewer"));
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    prefill();
  }, []);
  return (
    <>
      <div>
        <div className="job-nav-container">
          <div className="job-nav">
            <div
              onClick={() => history.push("/portal/interview/exploreInterview")}
              className={
                isCardActive("/portal/interview/exploreInterview")
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Explore Interviews
            </div>
            <div
              onClick={() => history.push("/portal/interview/bookedInterview")}
              className={
                isCardActive("/portal/interview/bookedInterview")
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Booked Interviews
            </div>
            <div
              onClick={() => history.push("/portal/interview/savedInterview")}
              className={
                isCardActive("/portal/interview/savedInterview")
                  ? "active job-nav-link"
                  : "job-nav-link"
              }
            >
              Saved Interviews
            </div>
            {isAdmin ? (
              <div
                onClick={() =>
                  history.push("/portal/interview/pendingInterview")
                }
                className={
                  isCardActive("/portal/interview/pendingInterview")
                    ? "active job-nav-link"
                    : "job-nav-link"
                }
              >
                Pending Interviews
              </div>
            ) : (
              <></>
            )}
          </div>
          {userRole ? (
            <div className="o-desk">
              <button
                className="button2"
                onClick={() => handleAvailabilityButtonClick()}
              >
                <img src={Plus} alt="img" className="plus-icon" />
                <img src={PlusHover} alt="img" className="plus-icon2" />

                <span>Create an Interview</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Dialog
        open={openService}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "415px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div>
            <Title>Create an Interview</Title>
          </div>
          <div>
            {/* <IoCloseCircleOutline
              size={20}
              onClick={() => setOpenService(false)}
            /> */}
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setOpenService(false)}
            />
          </div>
        </div>

        <DialogContent>
          <ServicePopup
            onCloseAll={previewFormId}
            onPopup={closeCreateService}
            onService={getAllInterviewService}
            previewId={previewFormOpen}
            onClick={() => handleAvailabilityButtonClick(true)}

          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={open4}
        PaperProps={{
          style: {
            borderRadius: "20px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            // height: "56px",
            padding: "5px 8px",
          },
        }}
        fullWidth
        maxWidth="sm"
        // className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "34px",
              fontFamily: "Inter-Medium,sans-serif",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" />
            {/* <BsShieldExclamation
                size={20}
                style={{
                  position: "relative",
                  display: "flex",
                  marginTop: "8px",
                  marginLeft: "10px",
                  color: "#FFDAE0",
                }}
              /> */}
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontStyle: "normal",
              fontweight: "normal",
              // lineHeight: "37px",
              gap: "10px",
              display: "flex",
              color: "black",
              // position: "relative",
              // top: "5px",
            }}
          >
            Are you sure you want to delete this interview?
          </JobTitle>
          <DialogActions>
            <div style={{ position: "relative", display: "flex", gap: "10px" }}>
              <Button5
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={() => deleteInterviewJob(deleteId)}>
                Yes, Delete
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAvailability}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "470px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div
          className="modalHeader"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Title>Manage Availability</Title>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setAvailability(false)}
            style={{ marginTop: "0px" }}
          />
        </div>
        <DialogContent>
          <AvailabilityTimePicker
            handleChange={availabiltyCancel}
            onId={editId}
            onGetAll={getAllAvialability}
            onCloseAvaial={closeAvail}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openServiceUpdate}
        PaperProps={{
          style: {
            width: "100%",
            // height: "800px",
            borderRadius: "24px",
            maxWidth: "415px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        {/* <TitleHeadar> */}
        <div className="modalHeader">
          <Title>Create an Interview</Title>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => handleAvailabilityButtonClick()}
          />
        </div>
        {/* </TitleHeadar> */}

        <DialogContent>
          <UpdateServicePopup
            onPopup={closeServiceUpdate}
            onService={getAllInterviewService}
            id={editedData ? editedData : null}
            previewId={previewFormOpen}
            onCloseAll={previewFormId}
            previewInterviewId={previewId}
            onClick={() => handleAvailabilityButtonClick(true)}

          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={previewForm}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "570px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <PreviewPopup
            previewId={previewId}
            onClose={previewClose}
            onBack={goToService}
            onService={handleClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={declineOpen}
        PaperProps={{
          style: {
            width: "100%",
            height: "auto",
            borderRadius: "24px",
            maxWidth: "540px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <TitleHeadar>
          <div>
            <Title>Decline Requesting</Title>
          </div>
          <div>
            <IoCloseCircleOutline size={20} onClick={declineForm} />
          </div>
        </TitleHeadar>

        <DialogContent>
          <div>
            <label>Reason For Declining?</label>
            <Select
              options={reasonOptions}
              value={reasonOptions.find((option) => option.value === reason)}
              onChange={handleReason}
            />
          </div>
          <div>
            <label>Description (optional)</label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Reason"
                aria-label="Bank Name"
                name="description"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "48px",
                  position: "relative",
                  width: "480px",
                }}
                onChange={(e) => setDeclineDescription(e.target.value)}
              />
            </InputGroup>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button5
              onClick={() => setDeclineOpen(false)}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Button5>
            <Button6 onClick={() => handleDecline(declineId)}>Submit</Button6>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CustomHeaderInterview;
