/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import imageAvatar from "../../images/company.png";
import styled from "styled-components";
import Star from "../../images/star.svg";
import {
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TopHeader from "./TopHeader";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ContactWebsite from "../../images/Letter Opened.svg";
import Contactmail from "../../images/Global.svg";
import Contactjoin from "../../images/Global.png";
import ContactNo from "../../images/Smartphone 2.png";
import Education from "../../images/image 134.svg";
import axios from "axios";
import Common from "../../utils/common";
const HRLine = styled.div`
  border: 1px solid #525252;
  height: 70px;
`;

const ProfileSkills = () => {
  const [contactDetails, setContactDetails] = useState(false);
  const { associateId } = useParams();

  // follwersData

  const [myFollowers, setMyFollowers] = useState([]);
  const [myFollowing, setMyFollowing] = useState([]);

  const [otherFollowers, setOtherFollowers] = useState([]);
  const [otherFollowing, setOtherFollowing] = useState([]);

  const [followingStatus, setFollowingStatus] = useState(false);

  const [educationDetail, setEducationdetails] = useState([]);
  const [experienceDetail, setExperiencedetails] = useState([]);
  const [projectDetail, setProjectdetails] = useState([]);
  const [courseDetail, setCoursedetails] = useState([]);
  
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);


  const [associateCount, setAssociateCount] = useState(0);

  const [input, setInput] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    avatar: "",
    bio: "",
    country: "",
    city: "",
    // skill: "",
    state: "",
    language: "",
    proficiency: "",
    dob: "",
    title: "",
    gender: "",
    pincode: "",
    phoneNumber: "",
    about: "",
    workProfile: "",
    urlLink: "",
    resume: "",
  });

  const [deleteId, setDeleteId] = useState(null);

  const [requests, setRequests] = useState([]);
  const [isAssociate, setIsAssociate] = useState(false);
  const [asscStatus, setAsscStatus] = useState(null);

  const getFollowers = async () => {
    await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/followers/${Common.loggedIn()}`)
      .then((res) => {
        if (res?.data) {
          setMyFollowers(res?.data?.followers ? res?.data?.followers : []);
          setMyFollowing(res?.data?.following ? res?.data?.following : []);
        }
      });
  };

  const getOtherFollowers = async () => {
    await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/followers/${associateId}`)
      .then((res) => {
        console.log("followers data", res);
        if (res?.data) {
          setOtherFollowers(res?.data?.followers ? res?.data?.followers : []);
          setOtherFollowing(res?.data?.following ? res?.data?.following : []);
          const findFollowed = res?.data?.followers?.find(
            (e) => e.followerId === Common.loggedIn()
          );
          if (findFollowed && findFollowed?.status === true)
            setFollowingStatus(true);
        }
      });
  };

  useEffect(() => {
    getFollowers();
    getOtherFollowers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getMyRequest = async () => {
    return await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`
      )
      .then((res) => {
        setRequests(res?.data);
      });
  };
  useEffect(() => {
    getMyRequest();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const findItem = requests.find((e) => e?.associateId === associateId);
    if (findItem) setIsAssociate(true);
    if (findItem && findItem.connectionStatus)
      setAsscStatus(findItem.connectionStatus);
  });
  const [aboutSkill, setAboutSkill] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);

  const getMyConnectionDetails = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/associates/${associateId}`)
      .then((response) => {
        console.log("my connections", response?.data);
        const associatesdataCount = response?.data?.filter(
          (e) => e.connectionStatus === "ASSOCIATE"
        );
        setAssociateCount(associatesdataCount.length);
      });
  };

  const prefillValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${associateId}`)
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setInput({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
            email: response.data.aboutOne.email,
            bio: response.data.aboutOne.bio,
            city: response.data.aboutOne.city,
            state: response.data.aboutOne.state,
            avatar: response.data.aboutOne.avatar,
            country: response.data.aboutOne.country,
            language: response.data.aboutOne.language,
            proficiency: response.data.aboutOne.proficiency,
            about: response.data.aboutOne.about,
            gender: response.data.aboutOne.gender,
            workProfile: response.data.aboutOne.workProfile,
            urlLink: response.data.aboutOne.urlLink,
            phoneNumber: response.data.aboutOne.phoneNumber,
            inputList: response.data.aboutOne.inputList,
            inputList2: response.data.aboutOne.inputList2,
            inputList3: response.data.aboutOne.inputList3,
            createdDate: response.data.aboutOne.createdDate,
          });
          if (
            response.data.aboutOne.coreSkill &&
            response.data.aboutOne.coreSkill.length > 0
          ) {
            setAboutSkill(response.data.aboutOne.coreSkill);
          }
          if (
            response.data.aboutOne.softSkill &&
            response.data.aboutOne.softSkill.length > 0
          ) {
            setSoftSkills(response.data.aboutOne.softSkill);
          }
          setDeleteId();
          setProfileImage(null);
          setPopupData(response.data.aboutOne);
          setShowPopup(true);
        }
      })
      .catch((err) => err.error);
  };

  console.log("profileImage", profileImage);

  console.log(input.userId, "userID dekeet");
  const [educationSkill, setEducationSkill] = useState([]);
  const getalledudetails = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-education-detail-all/${associateId}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setEducationdetails(response.data.educationOne);
        }
        const skills = response.data.educationOne
          .map((cert) => cert.skill)
          .flat();
        setEducationSkill(skills);
      })
      .catch((err) => err.error);
  };
  const [expererienceSkill, setExperienceSkill] = useState([]);
  const getallexpdetails = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-experience-detail-all/${associateId}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setExperiencedetails(response.data.experience);
        }
        const skills = response.data.experience
          .map((cert) => cert.skill)
          .flat();
        setExperienceSkill(skills);
      })
      .catch((err) => err.error);
  };
  const [projectSkill, setProjectSkill] = useState([]);
  const getallprodetails = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-project-detail-all/${associateId}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setProjectdetails(response.data.projectOne);
        }
        const skills = response.data.projectOne
          .map((cert) => cert.skill)
          .flat();
        setProjectSkill(skills);
      })
      .catch((err) => err.error);
  };
  const [courseSkill, setCourseSkill] = useState([]);

  const getallcourseDetails = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-certi-detail-all/${associateId}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response && response.data && response.data.success) {
          setCoursedetails(response.data.certiOne);
        }
        const skills = response.data.certiOne.map((cert) => cert.skill).flat();
        setCourseSkill(skills);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getMyConnectionDetails();
    prefillValue();
    getallexpdetails();
    getallprodetails();
    getalledudetails();
    getallcourseDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const allSkills = [
    ...aboutSkill,
    ...courseSkill,
    ...expererienceSkill,
    ...projectSkill,
    ...educationSkill,
  ];

  const skillCounts = allSkills.reduce((counts, skill) => {
    const { label, value, rating, userId } = skill;
    const key = `${label}_${value}_${rating}_${userId}`;
    counts[key] = (counts[key] || 0) + 1;
    return counts;
  }, {});

  // Sort the array based on the count of occurrences
  const sortedSkills = allSkills.sort((a, b) => {
    const countA = skillCounts[`${a.label}_${a.value}_${a.rating}_${a.userId}`];
    const countB = skillCounts[`${b.label}_${b.value}_${b.rating}_${b.userId}`];
    return countB - countA; // Sort in descending order of counts
  });

  // Filter out items with identical label, value, rating, and userId, keeping the highest count
  const uniqueSkills = [];
  const addedSkills = new Set();

  sortedSkills.forEach((skill) => {
    const key = `${skill.label}_${skill.value}_${skill.rating}_${skill.userId}`;
    if (!addedSkills.has(key)) {
      uniqueSkills.push(skill);
      addedSkills.add(key);
    }
  });

  console.log(uniqueSkills);

  // const ratingChanged = (newRating) => {
  //   console.log(newRating);
  // };
  const [loginId, setLoginId] = useState();
  console.log(loginId);

  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setLoginId(response.data?.userId);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);
  const [ratings, setRatings] = useState([]);
  const [coreRatings, setCoreRatings] = useState([]);

  const openVisitingCrad = () => {
    setContactDetails(true);
  };
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  };
  const handleProfileClick = () => {
    const id = input.userId; // Replace 'your-id' with the actual ID you want to pass
    const url = `https://thexakal.com/portal/otherProfile/${id}`;

    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        // URL successfully copied to clipboard
      })
      .catch((err) => {
        // Unable to copy URL to clipboard
        console.error("Failed to copy URL: ", err);
      });
  };
  const separateRating = uniqueSkills.map((rate) => {
    return rate.averageSkillRating;
  });
  const softRating = softSkills.map((rate) => {
    return rate.averageSkillRating;
  });

  const combinedRating = separateRating.concat(softRating);
  console.log(combinedRating, "combinedRatingcombinedRatingcombinedRating");
  // Calculate the average of the combined values
  const totalAverage =
    combinedRating.reduce((a, b) => a + b, 0) / combinedRating.length;
  return (
    <div>
      <TopHeader data={ProfileSkills} />
      <div className="Myprofile-container" style={{ marginLeft: "300px" }}>
        <div className="Myprofile-topcontainer">
          <div className="myp-avatar">
            {input.avatar ? (
              <>
                <div>
                  <img
                    src={input.avatar}
                    alt="img"
                    style={{
                      width: "190px",
                      height: "190px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </>
            ) : (
              <div>
                <img
                  src={imageAvatar}
                  alt="img"
                  style={{
                    width: "160px",
                    height: "160px",
                    borderRadius: "50%",
                  }}
                />
              </div>
            )}
          </div>
          <div className="star-wrapper">
            <img src={Star} alt="img" />
            <span className="rating">
              {totalAverage ? totalAverage.toFixed(1) : 0}
            </span>
          </div>
        </div>
        <div className="myp-wrapper">
          <div className="myp-in-wrapper">
            <div className="myp-name">
              <p>{input.firstName}</p>
              <p>{input.middleName}</p>
              <p>{input.lastName}</p>
            </div>
            <div className="myp-jobtitle">{input.bio}</div>
            <div className="myp-jobtitle" style={{ color: "#A8A1AC" }}>
              {" "}
              {input.city},{input.state},{input.country}
            </div>
            <div className="myp-contact" onClick={openVisitingCrad}>
              Contact Details
            </div>
          </div>
          <div className="social-data social-datas">
            <div className="">
              <div>Associates </div>
              <div className="social-count">{associateCount} </div>
            </div>
            <HRLine />
            <div className="">
              <div>Followers </div>
              <div className="social-count">{otherFollowers.length} </div>
            </div>
            <HRLine />
            <div className="">
              <div>Following </div>
              <div className="social-count">{otherFollowing.length} </div>
            </div>
          </div>
        </div>
      </div>

      <div className="myp-wrapper2" id="courses">
        <div className="Edu-head">Skills</div>
        <div className="Edu-wrapper">
          <div className="Edu-header">Core Skills</div>
          {/* <div className="Add-btn">
                        <button
                          className="Add-btn-content"
                          onClick={() => {
                            history.push(`/portal/profile-skills/${input?.userId}`);
                          }}
                        >
                          View Other Core Skills
                        </button>
                      </div> */}
        </div>
        <div className="Edu-data-wrapper EduType">
          {uniqueSkills.map((skill, index) => {
            const hasRated = skill.ratings?.some(
              (rating) => rating.userId === loginId
            );
            return (
              <div key={index} className="Edu-data-wrapper skill-wrapper">
                <div>
                  <div className="skill-content">{skill.value}</div>
                  <div className="skill-content-rating">
                    {hasRated ? (
                      <>{skill.averageSkillRating} Rating</>
                    ) : (
                      <> {coreRatings[index]} Rating</>
                    )}
                    || {skill.ratings?.length} ratings
                  </div>
                </div>

                {hasRated && (
                  <div className="Edu-data-wrapper2">
                    <img
                      src={Star}
                      alt="img"
                      style={{ width: "15px", height: "15px" }}
                    />
                    <div className="star-rating-no">
                      {skill.averageSkillRating}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="Edu-wrapper">
          <div className="Edu-header">Soft Skills</div>
          {/* <div className="Add-btn">
                        <button
                          className="Add-btn-content"
                          onClick={() => {
                            history.push(`/portal/profile-skills/${input.userId}`);
                          }}
                        >
                          View Other Soft Skills
                        </button>
                      </div> */}
        </div>
        <div className="Edu-data-wrapper EduType">
          {softSkills.map((skill, index) => {
            const hasRated = skill.ratings?.some(
              (rating) => rating.userId === loginId
            );
            const formattedAverageRating = skill.averageSkillRating.toFixed(1);
            return (
              <div key={index} className="Edu-data-wrapper skill-wrapper">
                <div>
                  <div className="skill-content">{skill.value}</div>
                  <div className="skill-content-rating">
                    {hasRated ? (
                      <>
                        {formattedAverageRating} Rating ||{" "}
                        {skill.ratings?.length} ratings
                      </>
                    ) : (
                      <>
                        {ratings[index]} Rating || {skill.ratings?.length}{" "}
                        ratings
                      </>
                    )}
                  </div>
                  {hasRated && (
                    <div className="Edu-data-wrapper2">
                      <img
                        src={Star}
                        alt="img"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <div className="star-rating-no">
                        {skill.averageSkillRating}
                      </div>
                    </div>
                  )}
                </div>

                {/* Display ReactStars component when the user hasn't rated */}

                {/* Display average rating using ReactStars if the user has rated */}
              </div>
            );
          })}
        </div>
      </div>
      <Dialog open={contactDetails} fullWidth maxWidth="sm">
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
            }}
          >
            {experienceDetail.map((exp) => {
              return (
                !exp.endDate && (
                  <div
                    key={exp._id}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src={
                        exp.company.companyLogo
                          ? exp.company.companyLogo
                          : Education
                      }
                      alt="Education"
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                    <div>
                      <span>{exp.company.value}</span>
                    </div>
                  </div>
                )
              );
            })}
          </span>
          <AiOutlineCloseCircle onClick={() => setContactDetails(false)} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="Edu-data-wrapper3">
              <div className="contact-hr"></div>
              <div
                className="myp-contact"
                style={{ fontSize: "24px", marginLeft: "-8px" }}
              >
                {`${input.firstName} ${input.middleName} ${input.lastName}`}
              </div>
            </div>
            {experienceDetail
              .filter((exp) => !exp.endDate)
              .map((a) => {
                return (
                  <div key={a._id} className="myp-contact-desig">
                    {a.designation}
                  </div>
                );
              })}

            <div className="contact-wrapper">
              <div className="Edu-data-wrapper">
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={Contactmail} alt="img" />
                  </div>
                  <div className="contact-details" onClick={handleProfileClick}>
                    xakal.com/{`${input.firstName} ${input.lastName}`}
                  </div>
                </div>
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={ContactWebsite} alt="img" />
                  </div>
                  <div className="contact-details">{input.email}</div>
                </div>
              </div>
              <div className="Edu-data-wrapper contact-container">
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={Contactjoin} alt="img" />
                  </div>
                  <div className="contact-details">
                    Associated on {formatDate(input.createdDate)}
                  </div>
                </div>
                <div className="Edu-data-wrapper3">
                  <div>
                    <img src={ContactNo} alt="img" />
                  </div>
                  <div className="contact-details">{input.phoneNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProfileSkills;
