import React, { useState, useEffect } from "react";
import DesktopHeader from "../desktopHeader";
import styled from "styled-components";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const HeaderDownLayout = styled.div``;

export default function AdminCompany() {
  const [companyData, setCompanyData] = useState([]);
  const history = useHistory();

  const getAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company-for-admin`)
      .then((response) => {
        setCompanyData(response.data.company);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getAllCompany()
  }, [])

  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 200 },
    { field: 'status', headerName: 'Status', width: 130 },
    {
      field: 'industry',
      headerName: 'Industry',
      width: 180,
    },
    {
      field: 'organisationSize',
      headerName: 'Organisation Size',
      width: 200,
    },
  ];

  const handleRowClick = (e) => {
    history.push(
      `${'/portal/edit-companyview/'}${e.row.companyId}`
    )
  }


  return (
    <>
      <HeaderLayout>
        <DesktopHeader title={"Admin Companies"} />
      </HeaderLayout>
      <div className="admin">
        <HeaderDownLayout>
          <DataGrid
            getRowId={(row) => row._id}
            onRowClick={handleRowClick}
            rows={companyData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </HeaderDownLayout>
      </div>
    </>
  );
}
