/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import rightarrow from "../../images/arrow_right.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser"
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  p {
    font-size: 22px;
    cursor: pointer;
  }
`;
const HeaderText = styled.h2`
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: black;
  margin: 10px 0 30px 0;
  text-align: center;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 20px;
  color: #362a41;
  font-weight: normal;
  margin: 30px 0 10px 0;
`;
const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Button3 = styled.div`
  background-color: #764f90;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #764f90;
    border: 1px solid #764f90;
  }
`;
const Date = styled.div`
  padding: 5px;
  color: #764f90;
  font-size: 16px;
  font-weight: normal;
  width: 120px;
  margin: 23px;
  cursor: pointer;
`;
const Value = styled.div`
font-size:18px;
color:#362A41;
font-weight: normal;
margin:10px 0 10px 0;
  }
`;

const PreviewJob = ({ previewId, goToJobTwo, closePreview }) => {
  const history = useHistory();
  const { id } = useParams();
  const [data, setInput] = useState({});
  const [data1, setInput1] = useState([]);

  const previewOne = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${id}`)
      .then((response) => {

        setInput(response.data.updateJob);
        setInput1(response.data.updateJob.questionList);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  console.log("input");

  useEffect(() => {
    var updateId = window.localStorage.getItem("updateId");

    if (updateId) previewOne(updateId);
  }, []);

  const handleSubmit = (id) => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/job-posted-draft/${data._id}`)
      .then((response) => {
        toast.success("Job Posted Successfully...");
        setTimeout(() => {
          closePreview();
        }, 6000);
      })
      .catch((err) => err.error);
  };

  console.log(data.scheduleDate);

  return (
    <div>
      <MainLayout>
        <ToastContainer />
        <Wrapper>
          <p onClick={() => history.push("./jobs")}>{"Jobs"}</p>
          <img
            src={rightarrow}
            alt="img"
            style={{ width: "25px", height: "25px" }}
          />
          <p onClick={() => history.push(`/portal/addnew-jobs/${id}`)}>
            {"Add New Jobs"}
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ width: "25px", height: "25px" }}
          />
          <p
            onClick={() =>
              history.push(`/portal/addnew-jobinfo/${id}`)
            }
          >
            {"Add Jobs Info"}
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ width: "25px", height: "25px" }}
          />
          <p style={{ color: "#D04C8D" }}>{"Preview Job"}</p>
        </Wrapper>
        <PageWrapper>
          <HeaderText>{"Preview Job"}</HeaderText>

          <Label>Job Title</Label>
          <Value>{data.jobTitle}</Value>
          <Label>Company Name</Label>
          <Value>{data.companyName && data.companyName.value}</Value>
          <Label>Experience Required*</Label>
          <Value>
            {`${data.experienceRequired} - ${data.experienceRequired2 || ""}`}
            Years
          </Value>
          <Label>Location</Label>
          <Value>{data.location}</Value>
          <Label>Salery Range per Annum</Label>
          <Value>
          {` ₹${data.salaryFrom} L -  ₹${data.salaryTo} L`}
          </Value>
          <Label>Employement Type</Label>
          <Value>{data.employeeType}</Value>
          <Label>Work Mode</Label>
          <Value>{data.workMode}</Value>
          <Label>Preference</Label>
          <Value>{data.preference}</Value>
          <Label>Skills</Label>

          <Value>{data.skill}</Value>
          <Label>Job Description & Benefits</Label>
          <Value>{data.jobDesc ? parse(data.jobDesc) : 'No description available'}</Value>

          <Label>Documents To Be Attached By The Candidate</Label>
          <a href={data.resume} target="_blank">
            {data.resume}
          </a>
          <Label>Questions To Be Asked To The Candidate</Label>
          {data1 &&
            data1.map((a, i) => {
              return (
                <>
                  <Value>{a.questionName}</Value>
                </>
              );
            })}
          {/* <Value>Associate Product Manager</Value> */}
          <Label>Apply Through</Label>
          <Value>{data.applyThrough}</Value>
          {data.applyThrough === "Xakal" ? (
            <></>
          ) : (
            <>
              <Label>URL</Label>
              <a href={data.urls} target="_blank">
                {data.urls}
              </a>
            </>
          )}

          <DateWrapper>
            <Date onClick={() => goToJobTwo()}>Back</Date>
            <Button3 onClick={() => handleSubmit(id)}>POST</Button3>
            {data.scheduleDate ? <p>Scheduled On :{data.scheduleDate}</p> : ""}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default PreviewJob;
