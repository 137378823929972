import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import size from "./organiType.json";
import softskill from "../MyProfile/industry.json";
import types from "./typesOrgi.json";
import service from "./service.json";
import "../Jobs/buttonStyle.css";
import { BsUpload } from "react-icons/bs";
import DesktopHeader from "../desktopHeader";
import DownArrow from "../../images/Downarrow.svg";
import PassHint from "../../images/PassHint.svg";
import I from "../../images/Danger Circle.svg";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    minHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    color: "#40444F",
    fontweight: "normal",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
  max-width: 840px;
  margin: auto;
`;
const PageContainer = styled.div`
  padding: 30px;
`;

const HeaderText = styled.h2`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  text-transform: capitalize;
  margin: 10px 0 30px 0;

  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 24px;
  // padding: 30px;
  margin: 1rem 0rem;
  max-width: 840px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  color: #4F4953;
  font-family: Inter-Medium,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  // text-transform: capitalize;
  margin: 10px 0 10px 0;
  // span{
  //   color: #FF4873;
  //   font-size: 16px;
  //   font-weight: 800;
  //   margin-left:5px;
  // }
 }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  // box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 12px;
`;

const AboutCompany = ({ match }) => {
  const history = useHistory();

  const [input, setInput] = useState({
    company: "",
    xakalAddress: "",
    website: "",
    organisation: "",
  });

  const optionsOne = softskill.industry.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [establish, setEstablish] = useState("");
  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
    organisation: "",
  });
  const [gst, setGst] = useState("");
  const handleIndustryChnage = (event) => {
    const selectedValue = event.value;
    console.log(event.value);
    setIndustry(selectedValue);
  };
  const handleServiceChange = (event) => {
    const selectedValue = event.value;

    setServiceProvider(selectedValue);
  };
  const handleEstablishChange = (event) => {
    const selectedValue = event.value;

    setEstablish(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };
  const [errorGst, setErrorGst] = useState();
  const [errorOne, setErrorOne] = useState();

  const handleGstChange = (e) => {
    const input = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    const capitalizedInput = input
      .split("")
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join("");

    const truncatedInput = capitalizedInput.substring(0, 15);

    setGst(truncatedInput);

    if (truncatedInput.length < 15) {
      setErrorGst(
        "Please check the GST number you have entered!   Allowed characters: letters (A-Z) and numbers."
      );
      setErrorOne("");
    } else {
      setErrorGst("");
      setErrorOne("");
    }
  };

  const [industryNameTouched, setIndustryNameTouched] = useState(false);
  const [serviceProviderTouched, setServiceProviderTouched] = useState(false);
  const [establishTouched, setEstablishTouched] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "companyDescription") {
      let v = value.slice(0, 1000);
      setInput((prevProps) => ({
        ...prevProps,
        [name]: v,
      }));
      event.target.value = v;
      return event;
    } else if (name === "tagline") {
      let v = value.slice(0, 250);
      setInput((prevProps) => ({
        ...prevProps,
        [name]: v,
      }));
      event.target.value = v;
      return event;
    } else {
      setInput((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };
  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          console.log(input.jobTitle);
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);

  const handleSumit = async () => {
    const payload = {
      companyName: input.company,
      xakalAddress: input.xakalAddress,
      organisation: input.organisation,
      website: input.website,
      gst: gst,
      industry: industry,
      organisationSize: organisationSize,
      organisationType: organisationType,
      servicesProvided: serviceProvider,
      establishedInYear: establish,
      companyLogo: imageURL,
      companyTagline: input["tagline"],
      companyDescription: input["companyDescription"],
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/create/company`, payload)
      .then((response) => {
        console.log("company update", response);
        history.push(`/portal/company-contact/${match.params.id}`);
      })
      .catch((err) => err.error);
    // history.push("/portal/company-contact");
  };

  const [imageURL, setImageURL] = useState(null);

  const showMaxSizeToast = () => {
    toast.error("File size exceeds the maximum allowed 12MB.");
  };

  const showInvalidTypeToast = () => {
    toast.error(
      "Invalid file type. Only JPEG, PNG, and JPG images are allowed."
    );
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"]; 
      const maxSize = 12 * 1024 * 1024; 
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSize) {
          console.log("Selected file:", selectedFile);

          let formData = new FormData();
          formData.append("image", file);
          await axios
            .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
            .then((res) => {
              console.log("file upload res", res.data.url);
              setImageURL(res.data.url);
            });
        } else {
          showMaxSizeToast();
        }
      } else {
        showInvalidTypeToast();
      }
    } else {
      console.log("No file selected.");
    }
    // if (file) {

    // }
  };
  const [id, setId] = useState(false);
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        if (response.data) {
          setInput({
            ...input,
            company: response.data.data?.companyName,
            xakalAddress: response.data.data?.xakalAddress,
            website: response.data.data?.website,
            description: response.data.data.companyDescription,
            tagline: response.data.data.companyTagline,
            organisation: response.data.data?.organisation,
          });
          setIndustry(response.data.data?.industry);
          setOrganisationType(response.data.data?.organisationType);
          setOrganisationSize(response.data.data?.organisationSize);
          setServiceProvider(response.data.data.servicesProvided);
          setImageURL(response.data.data.companyLogo);
          setEstablish(response.data.data.establishedInYear);
          setId(response.data.data.companyId);
        }
      });
  };

  useEffect(() => {
    getCompanyDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const startYear = 1900;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }));
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsService = service.service.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const BackToCompany = () => {
    history.push(`/portal/company/${id}`);
  };
  const searchedKey = () => {};

  return (
    <div>
      <DesktopHeader
        backbtn
        back={BackToCompany}
        searchedKey={searchedKey}
        title={"Create your company page"}
        isSearchActive={false}
      />
      
      <MainLayout>
        <PageWrapper>
          <PageContainer>
            <HeaderText>{"About Your Company"}</HeaderText>
            <ToastContainer />
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>
                  Company Page Name<span className="text-danger">*</span>
                </Label>
                <InputGroup className="">
                  <Form.Control
                    placeholder="Enter your company Name"
                    aria-label="Job Title"
                    name="company"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    defaultValue={input.company}
                    style={{
                      background: "rgba(54, 42, 65, 0.04)",
                      height: "48px",
                      borderRadius: "12px",
                      color: "rgba(64, 68, 79, 1)",
                      fontweight: "normal",
                      fontFamily: "Inter-Medium,sans-serif",
                    }}
                  />
                </InputGroup>
                {error.jobTitle && (
                  <span className="text-danger">{error.jobTitle}</span>
                )}
              </div>

              <div style={{ width: "50%" }}>
                <Label className="icon">
                  Xakal Address<span className="text-danger">*</span>
                  <img src={I} alt="img" style={{ cursor: "pointer" }} />
                  <span className="xakalinfos">
                    <img src={PassHint} alt="img" />
                    <span>
                      you can share this unique xakal address to other people
                      whom you wish to visit this page
                    </span>
                  </span>
                </Label>
                <div style={{ display: "flex" }}>
                  <div className="xakal-address" style={{ height: "48px" }}>
                    WWW.Xakal.com/
                  </div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Craft an exclusive tag to represent"
                      aria-label="Job Title"
                      name="xakalAddress"
                      className="font"
                      onBlur={validateInput}
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
                      defaultValue={input.xakalAddress}
                      style={{
                        borderRadius: "5px",
                        height: "48px",
                        background: "rgba(54, 42, 65, 0.04)",
                        color: "#40444F",
                        fontweight: "normal",
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>
                  Website / Domain<span className="text-danger">*</span>
                </Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter your URL"
                    aria-label="Job Title"
                    name="website"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    defaultValue={input.website}
                    style={{
                      height: "48px",
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                      color: "#40444F",
                      fontweight: "normal",
                    }}
                  />
                </InputGroup>
              </div>
              <div style={{ width: "50%" }}>
                <Label>
                  Industry<span className="text-danger">*</span>
                </Label>
                <div>
                  <Select
                    className="footer-header font mb-3"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="industry"
                  
                    value={optionsOne.find(
                      (option) => option.value === industry
                    )}
                    onBlur={() => setIndustryNameTouched(true)}
                    onChange={handleIndustryChnage}
                    options={optionsOne}
                    isSearchable={true}
                    defaultValue={industry}
                    placeholder="Select"
                    menuPlacement="top"
                  />

                  {industryNameTouched && industryNameTouched.industry && (
                    <span className="text-danger">
                      {industryNameTouched.industry}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>Organisation Size</Label>
                <div>
                  <Select
                    className="footer-header font mb-3"
                    styles={customStyle}
                    name="organisationSize"
                    components={{ Placeholder, DropdownIndicator }}
                    value={optionsSize.find(
                      (option) => option.value === organisationSize
                    )}
                    onBlur={() => setOrganisationSizeTouched(true)}
                    onChange={handleOrganisationSizeChange}
                    isSearchable={true}
                    options={optionsSize}
                    defaultValue={organisationSize}
                    placeholder="Select"
                    menuPlacement="top"
                  />

                  {organisationSizeTouched &&
                    organisationSizeTouched.organisationSize && (
                      <span className="text-danger">
                        {organisationSizeTouched.organisationSize}
                      </span>
                    )}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <Label>Organisation Type</Label>
                <div>
                  <Select
                    className="footer-header font mb-3"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="organisationType"
                    value={optionsType.find(
                      (option) => option.value === organisationType
                    )}
                    onBlur={() => setOrganisationTypeTouched(true)}
                    onChange={handleOrganisationTypeChange}
                    isSearchable={true}
                    options={optionsType}
                    defaultValue={organisationType}
                    placeholder="select"
                    menuPlacement="top"
                  />

                  {organisationTypeTouched &&
                    organisationTypeTouched.organisationType && (
                      <span className="text-danger">
                        {organisationTypeTouched.organisationType}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ width: "50%" }}>
                <Label>Services Provided</Label>
                <div>
                  <Select
                    // className="footer-header font mb-3"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="serviceProvider"
                    value={optionsService.find(
                      (option) => option.value === serviceProvider
                    )}
                    onBlur={() => setServiceProviderTouched(true)}
                    onChange={handleServiceChange}
                    isSearchable={true}
                    options={optionsService}
                    defaultValue={serviceProvider}
                    placeholder="Select"
                    menuPlacement="top"
                  />

                  {serviceProviderTouched &&
                    serviceProviderTouched.serviceProvider && (
                      <span className="text-danger">
                        {serviceProviderTouched.serviceProvider}
                      </span>
                    )}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <Label>Establishment Year</Label>
                <div>
                  <Select
                    className="footer-header font mb-3"
                    styles={customStyle}
                    components={{ Placeholder, DropdownIndicator }}
                    name="establish"
                    value={{ label: establish, value: establish }}
                    onBlur={() => setEstablishTouched(true)}
                    onChange={handleEstablishChange}
                    isSearchable={true}
                    options={optionYear}
                    defaultValue={"Select"}
                    placeholder="Select"
                    menuPlacement="top"
                  />

                  {establishTouched && establishTouched.establish && (
                    <span className="text-danger">
                      {establishTouched.establish}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Label>GST</Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter your gst number"
                aria-label="Job Title"
                name="gst"
                className="font"
                onBlur={validateInput}
                aria-describedby="basic-addon1"
                onChange={handleGstChange}
                value={gst}
                style={{
                  height: "48px",
                  borderRadius: "12px",
                  background: "rgba(54, 42, 65, 0.04)",
                  color: "#40444F",
                  fontweight: "normal",
                }}
              />
            </InputGroup>
            {errorGst && <span className="text-danger">{errorGst}</span>}
            {errorOne && <span className="text-danger">{errorOne}</span>}
            <Label>Logo</Label>
            {imageURL ? (
              <div
                style={{
                  border: "1px dotted rgba(198, 202, 210, 1)",
                  padding: "10px",
                  height: "80px",
                  margin: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  display: "flex", // Add this line
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <label style={{ position: "relative", width: "100%" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                  >
                    
                    <img
                      src={imageURL}
                      style={{
                        height: "63px",
                        width: "63px",
                        maxWidth: "160px",
                        margin: "11px auto 0 0",
                        borderRadius: "8px",
                      }}
                      alt="img"
                    />
                    {/* </div> */}
                    <label style={{ position: "relative" }}>
                      <div>
                        <div style={{ textAlign: "center", cursor: "pointer" }}>
                          {" "}
                          {/* Center the BsUpload icon */}
                          <BsUpload size={24} style={{ cursor: "pointer" }} />
                          <div
                            style={{
                              color: "rgba(198, 202, 210, 1)",
                              fontSize: "12px",
                              fontweight: "normal",
                              fontStyle: "italic",
                              marginTop: "10px",
                            }}
                          >
                            Upload as png, jpg, jpeg
                          </div>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          cursor: "pointer",
                        }}
                        onChange={handleImageUpload}
                      />
                    </label>
                    {/* <div style={{width:"120px",height:"90px"}}></div> */}
                  </div>
                </label>
              </div>
            ) : (
              <div
                style={{
                  border: "1px dotted rgba(198, 202, 210, 1)",
                  padding: "10px",
                  height: "73px",
                  margin: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  display: "flex", // Add this line
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                }}
              >
                <label style={{ position: "relative" }}>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      {/* Center the BsUpload icon */}
                      <BsUpload size={24} />
                      <div
                        style={{
                          color: "rgba(198, 202, 210, 1)",
                          fontSize: "12px",
                          fontweight: "normal",
                          fontStyle: "italic",
                          marginTop: "10px",
                        }}
                      >
                        Upload as png, jpg, jpeg
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      opacity: "0",
                      cursor: "pointer",
                    }}
                    onChange={handleImageUpload}
                  />
                </label>
              </div>
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%" }}>
                <Label>Tag Line</Label>
                <InputGroup className="">
                  <Form.Control
                    as="textarea"
                    placeholder=" Write an impressive tagline for your company"
                    aria-label="Job Title"
                    name="tagline"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={input.tagline}
                    maxLength={250}
                    style={{
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                      color: "#40444F",
                      fontweight: "normal",
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom: "20px",
                    right: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <p
                    style={{
                      color: "rgba(198, 202, 210, 1)",
                      fontSize: "10px",
                      fontweight: "normal",
                      fontFamily: "Inter-Medium,sans-serif",
                    }}
                  >
                    {input.tagline === undefined ? "0" : input.tagline.length}
                    /250 Characters
                  </p>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Label>Company Description</Label>
                <InputGroup className="">
                  <Form.Control
                    as="textarea"
                    placeholder="Enter company description"
                    aria-label="Job Title"
                    name="companyDescription"
                    className="font"
                    onBlur={validateInput}
                    aria-describedby="basic-addon1"
                    onChange={handleChange}
                    value={input.description}
                    style={{
                      borderRadius: "12px",
                      background: "rgba(54, 42, 65, 0.04)",
                      color: "#40444F",
                      fontweight: "normal",
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom: "20px",
                    right: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <p
                    style={{
                      color: "rgba(198, 202, 210, 1)",
                      fontSize: "10px",
                      fontweight: "normal",
                      fontFamily: "Inter-Medium,sans-serif",
                    }}
                  >
                    {input.companyDescription === undefined
                      ? "0"
                      : input.companyDescription.length}
                    /1000 Characters
                  </p>
                </div>
              </div>
            </div>
          </PageContainer>
          <DateWrapper>
            {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
            <button
              className="button2"
              style={{ width: "85px" }}
              onClick={handleSumit}
            >
              Save
            </button>
            {/* </Button3> */}
          </DateWrapper>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutCompany;
