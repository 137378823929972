import React, { useState, useEffect } from "react";
import DesktopHeader from "../desktopHeader";
import styled from "styled-components";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const HeaderDownLayout = styled.div``;

export default function AdminUsers() {
  const [userData, setUserData] = useState([]);
  const history = useHistory();

  const getAllUsers = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-user-details-for-admin`)
      .then((response) => {
        const comp = response.data.aboutOne.map(element => {
          return {
            ...element,
            'onBoardingCompleted': element.coreSkill.length ? "Yes" : "No",
            'HR': element.userType.includes('hr') ? "Yes" : "No",
            'candidate': element.userType.includes('candidate') ? "Yes" : "No",
            'interviewer': element.userType.includes('interviewer') ? "Yes" : "No"
          }
        });
        setUserData(comp);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getAllUsers()
  }, [])

  const columns = [
    { field: 'email', headerName: 'Email', width: 400 },
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 180,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 200,
    },
    { field: 'createdDate', headerName: 'Created At', width: 120,
    valueFormatter: (value) => {
      return value && value.value ? moment(value.value).format('DD/MM/yyyy') : "";
    }, },
    { field: 'onBoardingCompleted', headerName: 'On Boarding Completed', width: 250 },
    { field: 'HR', headerName: 'HR', width: 120 },
    { field: 'candidate', headerName: 'Candidate', width: 120 },
    { field: 'interviewer', headerName: 'Interviewer', width: 120 },

  ];

  const handleRowClick = (e) => {
    history.push(
      `${'/portal/otherProfile/'}${e.row.userId}`
    )
  }

  return (
    <>
      <HeaderLayout>
        <DesktopHeader title={"Admin Users"} />
      </HeaderLayout>
      <div className="admin">
        <HeaderDownLayout>
          <DataGrid sx={{ width: '100%' }}
            getRowId={(row) => row._id}
            onRowClick={handleRowClick}
            rows={userData}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </HeaderDownLayout>
      </div>
    </>
  );
}
