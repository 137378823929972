import Question from "../components/Aptitude/question";
import Instruction from "../components/Aptitude/instruction";
import Clock from "../components/Aptitude/clock";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import axios from "axios";
import DesktopHeader from "../components/desktopHeader";
import Common from "../utils/common";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import "./exam.css";
const ExamPage = ({ logOut }) => {
  const [cq, setCQ] = useState(1);
  const [data, setData] = useState();
  const [answers, setAnswers] = useState(null);
  const location = useLocation();
  const bookId = location.state && location.state.bookId;
  const candidateId = location.state && location.state.candidateId;

  const calendar = location.state && location.state.calendar;
  const book = location.state && location.state.book;
  const saved = location.state && location.state.saved;
  const candidate = location.state && location.state.candidate;

  // const handleQuestionChange = (direction) => {
  //   if (direction === "prev") {
  //     if (cq > 1) {
  //       setCQ(cq - 1);
  //     }
  //   } else if (direction === "next") {
  //     if (cq < data.length) {
  //       setCQ(cq + 1);
  //     }
  //   }
  // };
  const handleQuestionChange = (index) => {
    setCQ(index); // Update cq state with the selected question index
  };
  const isLastQuestion = data && cq === data.length;

  // console.log(timeRemaining);
  const [lastOpen, setLastOpen] = useState(false);
  const skipQuestion = () => {
    if (isLastQuestion) {
      setLastOpen(true);
    } else {
      if (cq >= data.length) {
        setCQ(1);
      } else {
        setCQ(cq + 1);
      }
    }
  };

  const [timeRemaining, setTimeRemaining] = useState(15 * 60 * 1000); // 15 minutes in milliseconds
  const [totalTimeExam, setTotalTimeExam] = useState(15); // Total time in minutes
  const updateAnswerToDbs = async (payloads) => {
    try {
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-general-answer`,
        payloads
      );
      return response.data; // Return the response data
    } catch (error) {
      console.error(error);
      throw error; // Re-throwing the error for handling at a higher level if needed
    }
  };
  const [userAnswer, setUserAnswer] = useState();
  const updateAnswers = async (opt) => {
    const updatedAnswerObject = { ...answers };

    updatedAnswerObject[data[cq - 1].qno] = opt;

    const qno = data[cq - 1].qno;
    const answer = updatedAnswerObject[qno];

    const payloads = {
      qno,
      answer,
      candidateId: candidateId,
    };

    let responseValue = null;
    if (candidate) {
      try {
        responseValue = await updateAnswerToDbs(payloads); // Wait for the response here
        console.log("Response value from updateAnswerToDbs:", responseValue);
        setUserAnswer(responseValue.userAnswer);
      } catch (error) {
        console.error("Error updating answer:", error);
        // Handle error if needed
      }
    }

    setAnswers(updatedAnswerObject);
  };

  const getUser = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        setTimeRemaining(15 * 60 * 1000); // Two hours in milliseconds
        setTotalTimeExam(15);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, 20);
  };

  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-all-aptitude`)
      .then((res) => {
        const shuffledData = shuffleArray(res.data.aptitude);
        setData(shuffledData);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-answer`)
      .then((res) => {
        setAnswers(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    document.title = "Assessment | Xakal";
  }, []);
  const onNavLinkClick = async (event) => {
    const currentRoute = window.location.href;
    const allowedRoutes = [
      "http://localhost:3000/portal/exam",
      "http://localhost:3000/portal/result",
      "http://localhost:3000/portal/home",
      "http://localhost:3000/portal/aptitude-test",
      "https://thexakal.com/portal/exam",
      "https://thexakal.com/portal/result",
      "https://thexakal.com/portal/home",
      "https://thexakal.com/portal/aptitude-test",
      "https://test-staging.thexakal.com/portal/exam",
      "https://test-staging.thexakal.com/portal/result",
      "https://test-staging.thexakal.com/portal/home",
      "https://test-staging.thexakal.com/portal/aptitude-test",
      "https://qa.thexakal.com/portal/exam",
      "https://qa.thexakal.com/portal/result",
      "https://qa.thexakal.com/portal/home",
      "https://qa.thexakal.com/portal/aptitude-test",
    ];

    // Register event listener for beforeunload
    window.addEventListener("beforeunload", (event) => {
      if (allowedRoutes.includes(currentRoute)) {
        event.preventDefault(); // Prevent default navigation behavior
        event.returnValue = ""; // For older browsers
      }
    });

    // Check if current route is in allowedRoutes
    if (allowedRoutes.includes(currentRoute)) {
      event.preventDefault(); // Prevent default navigation behavior
      alert(
        "Browser or tab switch detected. Please don't switch or open a new tab."
      );
      // Optionally, add more logic here if needed
      await getUserDeactivityStatus();
    }
  };
  const [firstName, setFirstName] = useState();
  useEffect(() => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        const firstName = response.data.aboutOne?.firstName;

        setFirstName(firstName);
      })
      .catch((err) => {});
  });
  const getUserDeactivityStatus = () => {
    const payload = {
      userName: firstName,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/detectuseractivity`, payload)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const [onFalse, setFalse] = useState(true);
  const handleQuestionChanges = (event, value) => {
    setCQ(value);
  };
  return (
    <div>
      <DesktopHeader
        title={"Assesment Test"}
        isSearchActive={false}
        onNavaigation={onNavLinkClick}
        onFalse={onFalse}
      />
      <Grid container spacing={1} justifyContent="space-between">
        <Grid
          item
          md={3}
          sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
        >
          <Box
            sx={{
              height: "100vh",
              backgroundColor: "#DFF6E2",
              overflowY: "scroll",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              py={3}
            >
              <Grid item>
                {" "}
                <Clock
                  totalTime={totalTimeExam * 60 * 1000}
                  timeRemaining={timeRemaining}
                  candidate={candidate}
                  candidateId={candidateId}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {data && data.length && (
          <Grid item md={9}>
            {" "}
            <Box
              sx={{
                height: "100vh",
                backgroundColor: "#DFF6E2",
                overflowY: "scroll",
              }}
            >
              <div className="button-main-question">
                {data.map((question, index) => (
                  <div
                    key={index}
                    style={{ margin: "5px" }}
                    onClick={() => handleQuestionChange(index + 1)}
                  >
                    <button
                      className={`question-button ${
                        cq === index + 1 ? "question-buttons" : ""
                      }`}
                    >
                      {index + 1}
                    </button>
                  </div>
                ))}
              </div>
              {answers && (
                <Question
                  curQuestion={data[cq - 1]}
                  handleUpdateAnswer={updateAnswers}
                  // handleUpdateAnswer2={updateAnswersFinal}
                  skipQNo={skipQuestion}
                  answer={answers[data[cq - 1].qno] || ""}
                  index={cq}
                  bookId={bookId}
                  calendar={calendar}
                  book={book}
                  saved={saved}
                  candidate={candidate}
                  isLastQuestion={isLastQuestion}
                  opens={lastOpen}
                  candidateId={candidateId}
                  handlePage={handleQuestionChanges}
                  userAnswer={userAnswer}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ExamPage;
