/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select, { components } from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogTitle } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "../../images/CloseIcon.svg";
import Addcircle from "../../images/Add Circle.svg";
import DownArrow from "../../images/Downarrow.svg";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import Back from "../../images/Back.png";
import Spinner from "react-bootstrap/Spinner";
const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 20px 0;
  padding: 0 !important;
`;
const Time = styled.div`
  margin-top: 0px;
  margin-left: 40px;
  span {
    font-size: 12px;
    font-weight: normal;
    color: 4F4953;
  }
`;
const Resend = styled.div`
  text-align: center;
  margin-top: 10px;
  span {
    font-size: 14px;
    font-weight: normal;
    color: #a8a1ac;
  }
`;
const JobTitle = styled.div`
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%; /* 19.6px */

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  p {
    font-size: 22px;
    cursor: pointer;
  }
`;
const ButtonLinks = styled.div`
  width: 146px;
  height: 48px;
  border-radius: 8px;
  // padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  // gap: 8px;
  color: white;
  cursor: pointer;
  // margin-right: 4px;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
  p {
    width: 24px;
    height: 24px;
    // flex-shrink: 0;
    background: #1c274c;
    border-radius: 50%;
    // font-size:24px;
    padding: 0px 0px 5px 10px;
    color: white;
    margin-top: 15px;
  }
  img {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 0px;
  margin: 0rem 0rem;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  line-height: 17px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0;
  span {
    color: #ff4873;
    // margin-left: 4px;
    font-size: 18px;
  }
`;

const options2 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];
const options7 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];

const options5 = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Internship", label: "Internship" },
];
const options6 = [
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];
const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    miHeight: "48px",
    height: "fit-content",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const JobPreference = styled.div`
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  display: flex;
  gap: 10px;
`;
const NewJob = ({ closeAll, jobId, closeJobOne, closeAllFirst }) => {
  const history = useHistory();
  const [companyData, setCompanyData] = useState([]);
  const [closemodal, setCloseModal] = useState(false);
  const [isJobPosted, setIsJobPosted] = useState(false);
  const [input, setInput] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    experienceRequired2: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
    employeeType: "",
    workMode: "",
  });

  const [skill, setSkill] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [experienceRequired, setExperienceRequired] = useState("");
  const [location, setLocation] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [preference, setPreference] = useState([]);

  const [error, setError] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    experienceRequired2: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
  });
  const [verifyId, setVerifyId] = useState("");
  const [verifyTrue, setVerifyTrue] = useState(false);
  console.log(companyName, "companyName");
  const [experienceRequired2, setExperienceRequired2] = useState("");
  const [otps, setOtps] = useState(["", "", "", ""]);
  const [openOTP, setOpenOTP] = useState(false);
  const inputRefs = useRef(otps.map(() => React.createRef()));
  const [valid, setValid] = useState(false);

  const handleChanges = (event, index) => {
    const updatedOtp = [...otps];

    // Handle backspace
    if (event.target.value === "" && index > 0) {
      // Clear the current digit only when backspacing
      updatedOtp[index] = "";
      setOtps(updatedOtp);

      // Focus on the previous input field
      inputRefs.current[index - 1].current.focus();
      return;
    }

    // Update the current digit
    updatedOtp[index] = event.target.value.slice(0, 1);

    // Update the OTP state
    setOtps(updatedOtp);

    // Clear the error message when any input changes
    // setErrors(null);
    setValid(false);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Check if OTP length is equal to 4
    const fullOtp = updatedOtp.join("");
    if (fullOtp.length === 4) {
      setValid(true);
    } else {
      setValid(false);
    }

    // Focus on the next input field if available
    if (index < otps.length - 1 && event.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };
  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4); // Get the pasted content
    const updatedOtp = [...otp];

    // Insert the pasted content into the OTP input fields
    for (let i = 0; i < pastedData.length; i++) {
      if (index + i < otps.length) {
        updatedOtp[index + i] = pastedData[i];
      }
    }

    setOtps(updatedOtp);

    // Check if all OTP digits are entered
    const isAllDigitsEntered = updatedOtp.every((digit) => digit !== "");
    if (isAllDigitsEntered) {
      setValid(true);
    }

    // Focus on the next input field if available
    if (index + pastedData.length < otps.length) {
      inputRefs.current[index + pastedData.length].current.focus();
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [disableFields, setDisableFields] = useState(false);
  const [jobIds, setJobId] = useState("");
  const [value, setValue] = useState();
  const fetchJobDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${id}`
      );
      const jobData = response.data.updateJob;

      // Set the initial state with the fetched job data
      setVerifyId(jobData.verifyId);
      setValue(jobData.companyName);
      setVerifyTrue(jobData.isVerifiedEmail === true);
      setInput({
        jobTitle: jobData.jobTitle,
        companyName: jobData.companyName,
        location: jobData.location,
        salaryFrom: jobData.salaryFrom,
        salaryTo: jobData.salaryTo,
        // ... other fields
      });

      setCompanyName(response.data.updateJob.companyName);
      setExperienceRequired(jobData.experienceRequired);
      setExperienceRequired2(jobData.experienceRequired2);
      setSkill(jobData.skill);
      setWorkMode(jobData.workMode);
      setEmployeeType(jobData.employeeType);
      setLocation(jobData.location);
      // Set skill and preference using the fetched data
      setPreference(jobData.preference);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (jobId) {
      fetchJobDetails(jobId);
    } else if (jobIds) {
      fetchJobDetails(jobIds);
    }
  }, [jobId, jobIds]);

  const handleSkillChnage = (e) => {
    setSkill(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleExperienceChange = (event) => {
    const selectedValue = event.value;

    setExperienceRequired(selectedValue);
  };
  const handleExperience2Change = (event) => {
    const selectedValue = event.value;

    setExperienceRequired2(selectedValue);
    setError({
      ...error,
      experienceRequired2: "",
    });
  };
  const handleEmployementChange = (event) => {
    const selectedValue = event.value;

    setEmployeeType(selectedValue);
  };
  const handleChangesOne = (event) => {
    const { name, value } = event.target;

    const regex = /^[0-9]*$/;
    const isValidInput = regex.test(value) || value === "";

    const isNegative = value.includes("-");

    if (isValidInput && !isNegative) {
      setInput((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
    }
  };
  const handleWorkChange = (event) => {
    const selectedValue = event.value;
    setWorkMode(selectedValue);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddInput = () => {
    if (inputValue.trim() !== "") {
      setPreference([...preference, inputValue]);
      setInputValue(""); // Clear inputValue after adding a new preference
      setPreferenceRequiredTouched("");
    }
  };

  const handleRemoveInput = (index) => {
    const updatedValues = [...preference];
    updatedValues.splice(index, 1);
    setPreference(updatedValues);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "jobTitle":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter job title";
          }
          console.log(input.jobTitle);
          break;

        case "companyName":
          if (value === "") {
            stateObj[name] = "Please enter company name";
          } else {
            stateObj[name] = "";
          }

          console.log(companyName);
          break;

        case "experienceRequired":
          if (!value) {
            stateObj[name] = "Please enter experience required";
          }
          break;
        case "location":
          if (!value) {
            stateObj[name] = "Please enter location";
          }
          break;
        case "salaryFrom":
          const regex3 = /^[0-9,.]+$/.test(value);
          if (input.salaryFrom === "") {
            stateObj[name] = "Please enter salary from";
          } else if (!regex3) {
            stateObj[name] = "Please enter valid salary from";
          }
          break;
        case "salaryTo":
          const Difference = input.salaryTo - input.salaryFrom;
          if (Difference < 0 && input.salaryTo !== "") {
            stateObj[name] = "Salary To  Should be greater than Salary From";
          } else if (input.salaryTo === "") {
            stateObj[name] = "";
          }

          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const [experienceRequiredTouched, setExperienceRequiredTouched] =
    useState(false);
  const [prefrenceRequiredTouched, setPreferenceRequiredTouched] =
    useState(false);
  const [locationRequiredTouched, setLocationRequiredTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const newJobs = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please enter job title";
    }

    if (!input.companyName) {
      errors.companyName = "Please enter company name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please select experience";
    }

    if (!location) {
      errors.location = "Please select location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please enter valid salary from";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary To should be greater than Salary From";
    }
    if (experienceRequired >= experienceRequired2) {
      errors.experienceRequired2 =
        "experience from should be less than experience to";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: input.companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-draft/${jobId}`,
          data
        );

        if (response.data.success) {
          console.log(response, "add form defatvfvfvd");
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setIsJobPosted(true);
        setJobId(response.data.updateJob._id);
        // history.push(`./addnew-jobinfo/${newId}`);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      if (verifyTrue) {
        closeJobOne(jobId);
      } else {
        setOpen(true);
      }
    } else {
      setError(errors);
      setPreferenceRequiredTouched({
        preference:
          !preference.length ||
          (inputValue.trim() !== "" &&
            preference.indexOf(inputValue.trim()) === -1)
            ? "Please enter preference"
            : "",
      });
      setLocationRequiredTouched({
        location: location === "" ? "Please enter location" : "",
      });
      setLoading(false);
    }
  };
  const newJobs2 = async () => {
    try {
      const data = {
        jobTitle: input.jobTitle,
        companyName: input.companyName,
        experienceRequired: parseInt(experienceRequired),
        experienceRequired2: parseInt(experienceRequired2),
        companyId: input.companyName.comId,
        location: location,
        salaryFrom: input.salaryFrom,
        salaryTo: input.salaryTo,
        employeeType: employeeType,
        workMode: workMode,
        skill: skill,
        preference: preference,
      };

      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-draft/${jobId}`,
        data
      );

      if (response.data.success) {
        setInput({
          jobTitle: "",
          companyName: "",
          experienceRequired: "",
          location: "",
          salaryFrom: "",
          salaryTo: "",
          employeeType: "",
          workMode: "",
        });
        setExperienceRequired("");
        setExperienceRequired2("");
        setSkill([]);
        setPreference([]);
        setLocation("");
        setWorkMode("");
        setEmployeeType("");
      }

      setIsJobPosted(true);
      setJobId(response.data.updateJob._id);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  const [error4, setError4] = useState("");
  const [email, setEmail] = useState("");

  const [enableEmailBtn, setEnableEmailBtn] = useState(true);
  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const extractDomain = (url) => {
    const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/);
    return match ? match[1].toLowerCase() : "";
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;

    // Extracting the domain from the company's website
    const companyWebsite = value?.website || "";
    const companyDomain = extractDomain(companyWebsite);
    // List of allowed domains
    const allowedDomains = value?.domains || [];

    if (!validateEmail(enteredEmail)) {
      setError4("Please enter a valid email address.");
      setEnableEmailBtn(false);
    } else {
      const enteredDomain = enteredEmail.split("@")[1].toLowerCase();

      if (
        enteredDomain === companyDomain ||
        allowedDomains.some((domain) =>
          enteredDomain.includes(domain.toLowerCase())
        )
      ) {
        setError4("");
        setEnableEmailBtn(true);
      } else {
        setError4(
          "Invalid email domain. Please use your company email address."
        );
        setEnableEmailBtn(false);
      }
    }

    setEmail(enteredEmail);
  };
  useEffect(() => {
    // Set initial state when the component mounts or when email is initially set
    setEnableEmailBtn(validateEmail(email));
  }, [email]);
  const DiscardChanges = () => {
    console.log(companyName);
    setInput({
      jobTitle: "",
      companyName: "",
      experienceRequired: "",
      location: "",
      salaryFrom: "",
      salaryTo: "",
      employeeType: "",
      workMode: "",
    });
    setSkill("");
    setPreference([""]);
    setInputValue("");
    setCloseModal(false);
    history.push("/portal/jobs");
  };

  const [companyDomain, setDomain] = useState([]);
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company`)
      .then((response) => {
        const companyTwo = response.data.companyValue.map((e) => ({
          domain: e.domainName,
          comId: e.companyId,
        }));
        const companyValue = response.data.company.map((e) => {
          // Find all domains that match the comId in the companyTwo array
          const matchingDomains = companyTwo
            .filter((domainObj) => domainObj.comId === e.companyId)
            .map((domainObj) => domainObj.domain);

          return {
            label: e.companyName,
            value: e.companyName,
            website: e.website,
            comID: e.companyId,
            companyLogo: e.companyLogo,
            xakalAddress: e.xakalAddress,
            domains: matchingDomains,
          };
        });

        setDomain(companyTwo);
        setCompanyData(companyValue);
      })
      .catch((err) => err.error);
  };
  const [insitituteData, setInstitueData] = useState([]);
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-institute`)
      .then((response) => {
        const companyValue = response.data.company.map((e) => ({
          label: e.collegeName,
          value: e.collegeName,
          websites: e.website,
          comID: e.instituteId,
          companyLogo: e.companyLogo,
        }));
        setInstitueData(companyValue);
      })
      .catch((err) => err.error);
  };
  console.log(insitituteData, "insitituteData");

  const [organisation, setOrganisation] = useState([]);
  const mergeCompanyAndInstituteData = () => {
    const mergedData = [...companyData, ...insitituteData];
    // Use mergedData as needed (e.g., set state for dropdown options)
    // Example: setDropdownOptions(mergedData);
    console.log(mergedData, "mergedData");
    setOrganisation(mergedData);
  };
  useEffect(() => {
    if (companyData.length > 0 && insitituteData.length > 0) {
      mergeCompanyAndInstituteData();
    }
  }, [companyData, insitituteData, companyDomain]);
  useEffect(() => {
    GetAllCompany();
    GetAllInstitute();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");
  console.log(errorOtpOne);
  console.log(errorMessage);
  const [resendTimer, setResendTimer] = useState(0);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const sendEmailOtp = async () => {
    setResendTimer(60);
    setLoading(true);
    setOpenOTP(true);
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: companyName.website,
        jobId: jobId,
        verifyId: verifyId,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/send/domain`, payload)
        .then((response) => {
          console.log(response, "otpotp");
          toast.success("OTP sent to Mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setErrorMessage("Enter the OTP that has send to your mail Id!");
          startResendTimer();
          setIsSendOtpClicked(true);
        })
        .catch((err) => {
          toast
            .error("Failed to send OTP", {
              autoClose: 5000,
              position: "top-right",
            })
            .finally(() => {
              setLoading(false);
            });
          console.log(err.error);
        });
    }
  };
  const resendOtp = async () => {
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: companyName.website,
        jobId: jobId,
        verifyId: verifyId,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/resend/domain`, payload)
        .then((response) => {
          console.log(response, "resendresedn");
          toast.success("OTP sent to mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setErrorMessage("Enter the OTP that has send to your mail Id!");
          startResendTimer();
        })
        .catch((err) => {
          toast.error("Failed to send OTP", {
            autoClose: 5000,
            position: "top-right",
          });
          console.log(err.error);
        });
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const verifyOtp = async () => {
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        otp: otps.join(""),
        website: companyName.website,
        jobId: jobId,
        verifyId: verifyId,
      };
      console.log("otp verify", payload);
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/verify/domain`, payload)
        .then((response) => {
          console.log(response);
          if (response && response.data && response.data.success === true) {
            setTimeout(() => {
              setOpen(false);

              closeJobOne(jobId);
              // Redirect to a different page
              // history.push(`/portal/adddraft-save/${jobIds}`);
            }, 1000);
            setErrorOtp(response.data.message);
          } else if (
            response &&
            response.data &&
            response.data.success === false
          ) {
            // Set the error message for an invalid OTP
            setErrorOtpOne("Invalid OTP");
          }
          // history.push(`./addnew-jobinfo/${jobIds}`);
        })
        .catch((err) => err.error);
    }
  };
  const handleRouteChange = () => {
    let data = JSON.parse(localStorage.getItem("draft"));

    data["status"] = "draft";
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-draft/${jobId}`, data)
      .then(() => {
        console.log("saved to draft");
      })
      .catch((err) => err.error);
    // }
    localStorage.removeItem("draft");
  };

  useEffect(() => {
    const data = {
      jobTitle: input.jobTitle,
      companyName: input.companyName,
      experienceRequired: experienceRequired,
      location: location,
      salaryFrom: input.salaryFrom,
      salaryTo: input.salaryTo,
      employeeType: employeeType,
      workMode: workMode,
      skill: skill,
      preference: preference,
    };
    localStorage.setItem("draft", JSON.stringify(data));
  });
  useEffect(() => {
    if (!isJobPosted) {
      const unlisten = history.listen(handleRouteChange);
      return () => {
        unlisten();
      };
    }
  }, [history]);
  const [shouldSave, setShouldSave] = useState(false);
  useEffect(() => {
    GetAllCompany();
  }, []);

  const cancelJob = () => {
    setOpen(false);
    setShouldSave(true);
  };

  const [skillData, setSkillData] = useState([]);

  const [optionsOne, setOptionsOne] = useState([]);
  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        console.log(JSON.stringify(response), "skilldata");
        const namesArray = response.data.map((item) => item.value);
        console.log(namesArray);
        setSkillData(namesArray);
        setOptionsOne(
          namesArray.map((skill) => ({
            value: skill,
            label: skill,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  console.log(optionsOne, "skillfdsafssdvsdfdvdsfvdfvdf");
  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");

  const createSkill = () => {
    const payload = {
      name: skillcreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "skilldata");
        setSkillCreate("");
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setOptionsOne(
      skillData.map((skill) => ({
        value: skill,
        label: skill,
      }))
    );
  }, [skillData]);

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChnage(e);
    console.log("creatable options", optionsOne);
    // getSkill();
    // setSkillCreate(e.value);
  };
  // eslint-disable-next-line no-unused-vars
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setLocation(value);
    setCoordinates(ll);
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };
  // const CaretPlusIcon = () => {
  //   return <img src={Plus} alt="img" style={{width:"30px",background:"#F4F4F6",padding:"0"}}/>;
  // };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  const handleCompany = (selectedOption) => {
    const value = selectedOption ? selectedOption : "";
    setInput({ ...input, companyName: value });
    setError({
      ...error,
      companyName: value ? "" : "Please Enter Company Name",
    });
  };
  console.log(jobIds, "jobIds");
  console.log(email, "email");
  const getOpen = () => {
    setOpen(false);
    fetchJobDetails(jobIds);
  };
  return (
    <div>
      {closemodal ? (
        <div
          className="wrappers2"
          style={{ marginTop: "-80px", height: "170vh" }}
        >
          <div
            className="containerUpload"
            style={{ padding: "20px", marginTop: "700px" }}
          >
            <h4 className="text-name" style={{ color: "black" }}>
              Discard Changes
            </h4>
            <p
              onClick={() => setCloseModal(false)}
              className="close-icon2"
              style={{ top: "700px" }}
            >
              X
            </p>
            <div className="upload-camera">
              <p>Are you sure you want to discard the changes you made?</p>
            </div>
            <div className="uploadButon">
              <h4
                onClick={() => setCloseModal(false)}
                style={{ margin: "5px 20px 0 0", color: "#764F90" }}
              >
                Go Back
              </h4>
              <button className="button2" onClick={DiscardChanges}>
                Discard
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/*<MainLayout>*/}
      <ToastContainer />
      {open && value ? (
        <div
        // style={{ width: "380px", height: "240px",paddingLeft:"30px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              paddingLeft: "30px",
            }}
          >
            <img
              src={Back}
              alt="img"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
                marginTop: "25px",
              }}
              onClick={getOpen}
            />
            <Label style={{ fontSize: "18px", marginTop: "30px" }}>
              Otp Verification
            </Label>
          </div>
          <HrLines />
          <div className="modalBody">
            <Label style={{ margin: "0px 0 5px 15px" }}>
              Enter your company email
            </Label>
            <div style={{ margin: "0 15px" }}>
              <input
                type="text"
                name="email"
                className="font"
                value={email}
                style={{
                  borderRadius: "8px",
                  border: "1px solid #F4F4F6",
                  background: "rgba(54, 42, 65, 0.04)",
                  width: "100%",
                  height: "48px",
                  padding: "20px",
                  marginBottom: "0px",
                }}
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
              {error4 && (
                <div className="f12" style={{ marginTop: "0px" }}>
                  {error4}
                </div>
              )}
            </div>
          </div>
          <div className="modalFooter">
            <button
              className="button3"
              style={{ width: "100%", marginTop: "20px", height: "43px" }}
              disabled={!enableEmailBtn || !email}
              onClick={sendEmailOtp}
            >
              {loading ? <Spinner /> : "Get Otp"}
            </button>
          </div>
          <Dialog open={openOTP} PaperProps={{ style: { width: "430px" } }}>
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "0.5px solid #F4F4F6",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <img
                  onClick={() => setOpenOTP(false)}
                  src={Back}
                  alt="img"
                  style={{
                    marginTop: "4px",
                    marginleft: "5px",
                    padding: "3.89px, 2.67px, 3.89px, 2.67px",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />

                <Label
                  style={{
                    fontFamily: "Inter-Medium,sans-serif",
                    fontSize: "18px",
                    fontweight: "normal",
                    lineHeight: "23px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    marginTop: "4px",
                  }}
                >
                  OTP Verification
                </Label>
              </div>
              <img
                src={CloseIcon}
                alt="img"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenOTP(false)}
              />
            </DialogTitle>
            {/* <div className="modalBody"> */}
            <DialogContent>
              <div className="modalBody">
                <Label style={{ textAlign: "center" }}>
                  Enter the OTP sent to {`${email}`}{" "}
                </Label>
                <div
                  className="otp-group"
                  style={{ margin: "10px auto 20px 40px" }}
                >
                  {otps.map((digit, idx) => (
                    <input
                      key={idx}
                      type="text"
                      inputMode="numeric"
                      autoComplete="one-time-code"
                      pattern="\d"
                      className="otp-inputs"
                      value={digit}
                      id={`otp-input-${idx}`}
                      ref={inputRefs.current[idx]}
                      onChange={(e) => handleChanges(e, idx)}
                      onPaste={(e) => handlePaste(e, idx)}
                      disabled={disableFields}
                    />
                  ))}
                </div>
                <div style={{ margin: "-15px 0 0 40px" }}>
                  <span className="f12">{errorOtpOne}</span>
                </div>

                <Time>
                  {isSendOtpClicked && (
                    <span>
                      {resendTimer > 0
                        ? `Time Remaining: ${formatTimer(resendTimer)}`
                        : ""}
                    </span>
                  )}
                </Time>
                <div
                  style={{
                    color: "green",
                    textAlign: "center",
                    marginTop: "30px",
                  }}
                >
                  {errorOtp}
                </div>

                {isSendOtpClicked && resendTimer === 0 ? (
                  <Resend>
                    <span>
                      Didn't rceive the code yet?{" "}
                      <span
                        style={{
                          color: "#8652B6",
                          fontweight: "normal",
                          cursor: "pointer",
                        }}
                        onClick={resendOtp}
                      >
                        Resend
                      </span>
                    </span>
                  </Resend>
                ) : (
                  ""
                )}
              </div>
            </DialogContent>
            <div className="modalFooter">
              <div>
                <button
                  className="button3"
                  style={{ width: "400px" }}
                  disabled={!valid}
                  onClick={() => verifyOtp()}
                >
                  Verify & Get Started
                </button>
              </div>
            </div>

            {/* </div> */}
          </Dialog>
        </div>
      ) : (
        <PageWrapper>
          <div className="modalHeader mheader">
            <div className="mTitle">Add New Job</div>

            <img src={CloseIcon} alt="img" onClick={() => cancelJob()} />
          </div>
          <div className="modalBody" style={{ padding: "0px 20px 20px 20px" }}>
            <Label>
              Job Title<span>*</span>
            </Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter job title"
                aria-label="Job Title"
                name="jobTitle"
                value={input.jobTitle}
                onBlur={validateInput}
                aria-describedby="basic-addon1"
                onChange={handleChange}
                className="footer-header font"
                style={{
                  borderRadius: "5px",
                  background: "rgba(54, 42, 65, 0.04)",
                }}
              />
            </InputGroup>
            {error.jobTitle && (
              <span className="text-danger">{error.jobTitle}</span>
            )}
            <Label>
              Company Name<span>*</span>
            </Label>

            <div
              style={{ position: "relative", display: "flex", gap: " 20px" }}
            >
              <div style={{ width: "100%" }}>
                <Select
                  // className="footer-header mb-3"
                  value={input.companyName}
                  isSearchable={true}
                  name="companyName"
                  isClearable={false}
                  placeholder="Enter your company name "
                  components={{ Placeholder, DropdownIndicator }}
                  onBlur={() =>
                    validateInput({
                      target: {
                        name: "companyName",
                        value: input.companyName,
                      },
                    })
                  }
                  onChange={handleCompany}
                  options={organisation}
                  className="font"
                  styles={customStyle}
                />
              </div>

              <div>
                <ButtonLinks onClick={() => history.push("/portal/company")}>
                  <img src={Addcircle} alt="img" />
                  Add Company
                </ButtonLinks>
              </div>
            </div>

            <Label>
              Experience<span>*</span>
            </Label>
            <Wrapper>
              <div style={{ width: "50%" }}>
                {/* <Label>Min</Label> */}
                <Select
                  className="footer-header font"
                  components={{ Placeholder, DropdownIndicator }}
                  styles={customStyle}
                  name="experienceRequired"
                  value={options2.find(
                    (option) => option.value === experienceRequired
                  )}
                  onBlur={() => setExperienceRequiredTouched(true)}
                  onChange={handleExperienceChange}
                  options={options2}
                  isSearchable={true}
                />

                {experienceRequiredTouched &&
                  experienceRequiredTouched.experienceRequired && (
                    <span className="text-danger">
                      {experienceRequiredTouched.experienceRequired}
                    </span>
                  )}
              </div>
              <Label style={{ marginTop: "15px" }}>to</Label>
              <div style={{ width: "50%" }}>
                {/* <Label>Max</Label> */}
                <Select
                  className="footer-header font"
                  components={{ Placeholder, DropdownIndicator }}
                  styles={customStyle}
                  name="experienceRequired2"
                  value={options7.find(
                    (option) => option.value === experienceRequired2
                  )}
                  // onBlur={() => setExperienceRequiredTouched(true)}
                  onChange={handleExperience2Change}
                  options={options7}
                  isSearchable={true}
                />

                {experienceRequiredTouched &&
                  experienceRequiredTouched.experienceRequired2 && (
                    <span className="text-danger">
                      {experienceRequiredTouched.experienceRequired2}
                    </span>
                  )}
                <span className="text-danger f12">
                  {error.experienceRequired2}
                </span>
              </div>
            </Wrapper>
            <Label>
              Location<span>*</span>
            </Label>
            <div>
              <PlacesAutocomplete
                value={location}
                onChange={setLocation}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "location-search-input",
                      })}
                      style={{ width: "100%", paddingLeft: "10px" }}
                      className="footer-header font"
                      styles={{
                        height: "40px",
                        control: (base) => ({
                          ...base,
                          width: "100%", // Set the width to 100%
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                        }),
                      }}
                    />
                    <div
                      className="autocomplete-dropdown-container"
                      style={{ width: "100%" }}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              marginTop: "10px",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              marginTop: "10px",
                              fontSize: "12px",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              style={{
                                color: "#362A41", // Add hover text color
                                padding: "5px",
                                background: "#C6C6C6",
                                borderRadius: "8px",
                                width: "calc(100% - 6px)",
                                margin: "0px 10px 0px 10px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {locationRequiredTouched && locationRequiredTouched.location && (
                <span className="text-danger">
                  {locationRequiredTouched.location}
                </span>
              )}
            </div>

            <Label>Salary Range Per Annum</Label>
            {/* <Wrapper>
              <Label style={{ width: "50%", marginTop: "5px" }}>
                From<span>*</span>
              </Label>
              <Label style={{ width: "50%", marginTop: "5px" }}>To</Label>
            </Wrapper> */}
            <Wrapper style={{ width: "100%", marginTop: "5px" }}>
              <InputGroup
                className="mb-3"
                style={{ width: "50%", borderRadius: "5px" }}
              >
                <Form.Control
                  placeholder="₹ - - - - - - - - L"
                  type="number"
                  aria-label="Job Title"
                  name="salaryFrom"
                  value={input.salaryFrom}
                  className="footer-header font"
                  onBlur={validateInput}
                  onChange={handleChangesOne}
                  style={{ background: "rgba(54, 42, 65, 0.04)" }}
                />
              </InputGroup>
              <Label style={{ marginTop: "15px" }}>to</Label>
              <InputGroup
                className="mb-3"
                style={{ width: "50%", borderRadius: "5px" }}
              >
                <Form.Control
                  type="number"
                  aria-label="Job Title"
                  name="salaryTo"
                  className="footer-header font"
                  placeholder="₹ - - - - - - - - L"
                  value={input.salaryTo}
                  aria-describedby="basic-addon1"
                  style={{ background: "rgba(54, 42, 65, 0.04)" }}
                  onBlur={validateInput}
                  onChange={handleChangesOne}
                />
              </InputGroup>
            </Wrapper>
            {error.salaryFrom && (
              <span className="text-danger">{error.salaryFrom}</span>
            )}
            {error.salaryTo && (
              <span className="text-danger" style={{}}>
                {error.salaryTo}
              </span>
            )}
            <Label>Employement Type</Label>
            <div>
              <Select
                class="footer-header  mb-3"
                components={{ Placeholder, DropdownIndicator }}
                styles={customStyle}
                className="footer-header font"
                name="employeeType"
                value={options5.find((option) => option.value === employeeType)}
                onBlur={validateInput}
                onChange={handleEmployementChange}
                isSearchable={true}
                options={options5}
              />
            </div>

            <Label>Work Mode</Label>
            <div>
              <Select
                className="footer-header font"
                components={{ Placeholder, DropdownIndicator }}
                styles={customStyle}
                name="workMode"
                value={options6.find((option) => option.value === workMode)}
                onBlur={validateInput}
                onChange={handleWorkChange}
                isSearchable={true}
                options={options6}
                placeholder="Type/Select"
              />
            </div>

            <Label>Preference</Label>

            <div style={{ display: "flex", gap: "10px" }}>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                name="preference"
                onBlur={() => setPreferenceRequiredTouched(true)}
                className="footer-header font"
                placeholder="Enter your preferences to add"
                style={{
                  paddingLeft: "10px",
                }}
              />
              <ButtonLinks onClick={handleAddInput}>
                <img src={Addcircle} alt="img" />
                Add Preference
              </ButtonLinks>
            </div>
            {preference.map((input, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                <JobPreference>
                  {input}
                  {input !== "" ? (
                    <button onClick={() => handleRemoveInput(index)}>X</button>
                  ) : (
                    ""
                  )}
                </JobPreference>
              </div>
            ))}

            {prefrenceRequiredTouched &&
              prefrenceRequiredTouched.preference && (
                <span className="text-danger">
                  {prefrenceRequiredTouched.preference}
                </span>
              )}
            <Label>Skills</Label>
            <div>
              <CreatableSelect
                isMulti
                styles={customStyle}
                className="footer-header font"
                components={{ Placeholder, DropdownIndicator }}
                name="skill"
                placeholder="Select your skills"
                onChange={(e) => handleCreatable(e)}
                onInputChange={(e) => setSkillCreate(e)}
                options={optionsOne}
                value={optionsOne.filter((e) => skill.includes(e.value))}
              />
            </div>
          </div>
          <div className="modalFooter modalfooter">
            <button className="button5" onClick={() => cancelJob()}>
              Cancel
            </button>
            <button
              onClick={newJobs}
              className="button2"
              style={{ width: "83px" }}
            >
              {loading ? <Spinner /> : "Next"}
            </button>
            {/* </Button3> */}
          </div>
        </PageWrapper>
      )}
      <Dialog
        open={shouldSave}
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#F4F4F6",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
        halfWidth
        maxWidth="md"
        className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img
              src={QuestionImg}
              style={{ marginTop: "2px", width: "24px", height: "24px" }}
              alt="img"
            />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            Save Changes As Draft ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => closeAllFirst()}
                style={{ cursor: "pointer" }}
              >
                No
              </Button4>
              <Button6
                onClick={() => {
                  newJobs2();
                  closeAllFirst();
                  setShouldSave(false);
                }}
                style={{ cursor: "pointer" }}
              >
                Yes, Save
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/*</MainLayout>*/}
    </div>
  );
};

export default NewJob;
