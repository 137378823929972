import React, { useEffect, useState } from "react";
import avatar from "../../images/avatar.jpg";
import "./feedback.css";
import Table from "react-bootstrap/Table";
import ReactStars from "react-rating-stars-component";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import DesktopHeader from "../desktopHeader";
import Delete from "../../images/delete.svg";
import Plus from "../../images/Plus_Icon.svg";
import ProgressBar from "./progressBar";
import CryptoJS from "crypto-js"
const Feedback = () => {
  const { bookingId } = useParams();
  const [value, setValue] = useState();
  const [candidateId, setCandidateId] = useState("");
  const [serviceId, setServiceId] = useState();
  const [candidateDetail, setCandidateDetail] = useState({});
  const [skill, setSkill] = useState([]);
  const [coreSkill, setCoreSkills] = useState([]);
  const [experienceSkill, setExperienceSkills] = useState([]);
  const [courseSkill, setCourseSkills] = useState([]);
  const [educationSkill, setEducationSkills] = useState([]);
  const [projectskill, setProjectSkills] = useState([]);
  const [softSkill, setSoftSkills] = useState([]);
  const getOneValue = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${bookingId}`)
      .then((response) => {
        const bookingSlot = response.data.bookingSlot;
        setCandidateId(bookingSlot?.candidateId);
        setServiceId(bookingSlot?.serviceId);

        setValue(bookingSlot);
      })
      .catch((err) => {});
  };
  const originalDate = value?.date;
  const formattedDate = new Date(originalDate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const deleteTableRows = (index) => {
    setCommonSkills((rows) => rows.filter((r, i) => i !== index));
  };

  const handleClick = () => {
    const item = {
      skillIds: "",
      label: "",
      value: "",
      ratings: [],
      averageSkillRating: 0,
      candidateId: candidateId,
      strengths: [""],
      weaknesses: [""],
      comments: [""],
    };
    setCommonSkills([...commonSkills, item]);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const data = [...commonSkills];
    data[index]["label"] = value;
    data[index]["value"] = value;
    setCommonSkills(data);
  };
  useEffect(() => {
    if (serviceId) {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${serviceId}`)
        .then((response) => {
          if (response.data && response.data.interview.skill.length > 0) {
            setSkill(response.data.interview.skill);
          }
        })
        .catch((err) => {});
    }
  }, [serviceId]);

  useEffect(() => {
    getOneValue();
    // getOneService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [userId, setUserId] = useState({});

  const [commonSkills, setCommonSkills] = useState([]);

  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUserId(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);

  const [strengthInputs, setStrengthInputs] = useState(
    Array(commonSkills.length).fill("")
  );
  const [strengthValues, setStrengthValues] = useState(
    Array(commonSkills.length).fill([])
  );

  const [weaknessInputs, setWeaknessInputs] = useState(
    Array(commonSkills.length).fill("")
  );
  const [weaknessValues, setWeaknessValues] = useState(
    Array(commonSkills.length).fill([])
  );

  const [commentInputs, setCommentInputs] = useState(
    Array(commonSkills.length).fill("")
  );
  const [commentValues, setCommentValues] = useState(
    Array(commonSkills.length).fill([])
  );

  const handleInputChange = (e, inputType, index, setInputValues, setValu) => {
    const input = e.target.value;

    setInputValues((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index] = input;
      return newInputs;
    });

    setValu((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = [input]; // Store the input as a single-value array
      return newValues;
    });
  };

  const [isRatingDone, setIsRatingDone] = useState(false);
  const [isRatingDones, setIsRatingDones] = useState(false);

  const [ratings, setRatings] = useState([]);

  const updateSkillRating = async (skillType, skillId, candidateId, data) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/users/${skillType}-skill/rating/${skillId}/${candidateId}`,
        data
      );
      setIsRatingDone(true);
    } catch (error) {
      console.error(error);
    }
  };

  const ratingChanged = (newRating, index, skillId, label, value) => {
    const newRatings = [...ratings];
    newRatings[index] = newRating;
    setRatings(newRatings);

    const data = {
      rating: newRating,
      label,
      value,
    };

    // Skill types where you want to update the rating
    const applicableSkillTypes = [
      "core",
      "project",
      "education",
      "experience",
      "course",
    ];

    // Update rating for each applicable skill type
    applicableSkillTypes.forEach(async (skillType) => {
      // Check if the skill exists for the current skill type
      if (
        skillType === "core" ||
        skillType === "project" ||
        skillType === "education" ||
        skillType === "experience" ||
        skillType === "course"
      ) {
        await updateSkillRating(skillType, skillId, candidateId, data);
      }
    });
  };

  const [ratingsOne, setRatingsOne] = useState([]);
  const ratingSkillChanged = (newRating, index, skillId, label, value) => {
    const newRatings = [...ratings];
    newRatings[index] = newRating;
    setRatingsOne(newRatings);

    const data = {
      rating: newRating,
      label,
      value,
    };
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/users/soft-skill/rating/${skillId}/${candidateId}`,
        data
      )
      .then((response) => {
        setIsRatingDones(true);
      })
      .catch((err) => console.error(err));
  };

  const [experience, setExperience] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/xakal/get-all-profile/${candidateId}`
        );
        if (response.data.success) {
          if (response.data && response.data.aboutOne.coreSkill.length > 0) {
            setCoreSkills(response.data.aboutOne.coreSkill);
          }
          if (response?.data?.experienceOne?.length > 0) {
            const extractedSkills = response.data.experienceOne
              .map((experience) => experience.skill)
              .flat(); // Flatten the array

            setExperienceSkills(extractedSkills);
          }
          if (response.data && response.data.educationOne?.length > 0) {
            const extractedSkills = response.data.educationOne
              .map((experience) => experience.skill)
              .flat(); // Flatten the array
            setEducationSkills(extractedSkills);
          }

          if (response.data && response.data.projectOne?.length > 0) {
            const extractedSkills = response.data.projectOne
              .map((experience) => experience.skill)
              .flat(); // Flatten the array
            setProjectSkills(extractedSkills);
          }
          if (response.data && response.data.courseOne?.length > 0) {
            const extractedSkills = response.data.courseOne
              .map((experience) => experience.skill)
              .flat(); // Flatten the array
            setCourseSkills(extractedSkills);
          }
          if (response.data && response.data.aboutOne?.softSkill?.length > 0) {
            setSoftSkills(response.data.aboutOne?.softSkill);
          }
        }
        setCandidateDetail(response.data);
        if (response?.data?.experienceOne?.length > 0) {
          setExperience(response?.data?.experienceOne);
        }
        // Your code to set state...
      } catch (err) {}
    };

    if (candidateId) {
      fetchData();
    }
  }, [candidateId]);

  const mergeCompanyAndInstituteData = () => {
    const mergedSkills = [
      ...coreSkill,
      ...skill,
      ...experienceSkill,
      ...educationSkill,
      ...projectskill,
      ...courseSkill,
    ];

    const finalArray = [...new Set(mergedSkills)];

    // Use a Set to keep track of unique skillIds
    const uniqueSkillIds = new Set();

    // Find common skills based on skillIds and candidateId, while avoiding duplicates
    const commonSkillsArray = finalArray.filter((skills) => {
      if (!uniqueSkillIds.has(skills.label)) {
        uniqueSkillIds.add(skills.label);
        return true;
      }
      return false;
    });

    setCommonSkills(commonSkillsArray);
  };
  useEffect(() => {
    mergeCompanyAndInstituteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coreSkill,
    experienceSkill,
    courseSkill,
    educationSkill,
    projectskill,
    skill,
    candidateId,
  ]);
  const [commonSkill, setCommonSkill] = useState([]);
  useEffect(() => {
    // Merge coreSkill and skill arrays
    const mergedSkills = [...commonSkills];

    // Find common skills based on skillIds and candidateId
    const commonSkillsArray = mergedSkills.filter((skill) => {
      return (
        skill.candidateId === candidateId ||
        commonSkills.some((core) => core.skillIds === skill.skillIds)
      );
    });
    setCommonSkill([...commonSkillsArray]);
  }, [commonSkills, candidateId]);
  const history = useHistory();
  const generateSha256 = (input) => {
    return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
  };
  const handleCreate = async () => {
    try {
      // Make the API call to get all profile details
      const candidateResponse = await axios.get(
        `${process.env.PUBLIC_URL}/xakal/get-all-profile/${candidateId}`
      );
      const candidateDetail = candidateResponse.data;

      const payload = {
        bookingId: bookingId,
        candidateDetail: candidateDetail,
        skill: commonSkill.map((skill, index) => ({
          skillIds:
            skill.skillIds === ""
              ? generateSha256(skill.value)
              : skill.skillIds,
          label: skill.label,
          value: skill.value,
          ratings: ratings[index] || 0,
          averageSkillRating: null,
          candidateId: candidateId,
          strengths: strengthValues[index] || [],
          weaknesses: weaknessValues[index] || [],
          comments: commentValues[index] || [],
        })),
        softSkill: skill.map((skill, index) => ({
          skillIds: skill.skillIds,
          label: skill.label,
          value: skill.value,
          ratings: ratingsOne[index] || 0,
          averageSkillRating: null,
          candidateId: candidateId,
        })),
        comment: null,
        weakness: null,
        strength: null,
      };

      // Continue with your axios post request if needed
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-feedback`,
        payload
      );
      try {
        await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-completed-feedback/${bookingId}`
        );
        getAllPending();
      } catch (error) {
        console.error("Error updating completed feedback:", error);
      }
      commonSkill.forEach(async (element) => {
        if (element.skillIds === "") {
          await createSkill(element.label);
        }
      });
    } catch (err) {}
    history.push(`/portal/my-profile`);
  };

  const createSkill = (val) => {
    const payload = {
      name: val,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const getAllPending = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-pending-feedback`)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <DesktopHeader title={"Feedback"} />

      <div id="feedback-container" className="feedback-container">
        <div id="card-container" className="card-container">
          <div
            style={{
              background: "#FFF",
              padding: "40px 20px 0px 20px",
              borderRadius: "16px",
            }}
          >
            <div style={{ display: "flex" }}>
              <>
                <div className="left-section">
                  <div>
                    {candidateDetail && candidateDetail?.aboutOne?.avatar ? (
                      <img
                        src={
                          candidateDetail && candidateDetail?.aboutOne?.avatar
                        }
                        alt="uterrs"
                        className="img-card-div"
                      />
                    ) : (
                      <img src={avatar} alt="uterrs" className="img-card-div" />
                    )}
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <div className="feed-name">
                      {candidateDetail?.aboutOne?.firstName}{" "}
                      {candidateDetail?.aboutOne?.middleName}{" "}
                      {candidateDetail?.aboutOne?.lastName}
                    </div>

                    {/* {candidateDetail &&
                    candidateDetail &&
                    candidateDetail &&
                    candidateDetail?.experienceDetail?.map((ai) => {
                      return (
                        <div>
                          {ai && ai?.designation} || Strategic Planning Building
                        </div>
                      );
                    })} */}
                    <div>
                      {experience && experience.length > 0 ? (
                        experience &&
                        experience
                          .filter(
                            (exp) => !exp.endDate && exp.timestamps.createdAt
                          )
                          .sort(
                            (exp1, exp2) =>
                              new Date(exp2.timestamps.createdAt) -
                              new Date(exp1.timestamps.createdAt)
                          )
                          .slice(0, 1)
                          .map((a) => (
                            <div className="feed-desig">{a.designation}</div>
                          ))
                      ) : (
                        <p className="ap-pos"></p>
                      )}
                    </div>
                    {candidateDetail &&
                    candidateDetail?.aboutOne?.primaryAddress ? (
                      <div className="feed-address">
                        {candidateDetail &&
                          candidateDetail?.aboutOne?.primaryAddress?.state}
                        {candidateDetail &&
                          candidateDetail?.aboutOne?.primaryAddress?.city}
                        ,{" "}
                        {candidateDetail &&
                          candidateDetail?.aboutOne?.primaryAddress
                            ?.country}{" "}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div className="right-section">
                  <div
                    style={{
                      background: "rgba(247, 247, 248, 1)",
                      margin: "-20px",
                      padding: "10px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="feed-name" style={{ fontSize: "16px" }}>
                      {value?.designation} Interview
                    </div>
                    <div
                      className="feed-address"
                      style={{ fontSize: "12px", marginTop: "0" }}
                    >
                      {value?.designation}
                    </div>
                  </div>
                  <div style={{ margin: "40px 0px 0 0" }}>
                    <div className="feed-address" style={{ fontSize: "12px" }}>
                      Interview Date:
                      <div style={{ color: "#4F4953", marginLeft: "5px" }}>
                        {" "}
                        {formattedDate}|| {value?.time}
                      </div>
                    </div>
                    <div className="feed-address" style={{ fontSize: "12px" }}>
                      Duration:
                      <div style={{ color: "#4F4953", marginLeft: "40px" }}>
                        {" "}
                        {value?.duration}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
            <hr />
            <div style={{ marginLeft: "20px" }}>
              <label
                className="feed-name"
                style={{ fontSize: "18px", marginLeft: "-20px" }}
              >
                Skills - Rating and Feedback
              </label>
            </div>
            <div
              style={{
                border: "1px solid rgba(241, 241, 241, 1)",
                borderRadius: "16px",
              }}
            >
              <Table>
                <thead>
                  <tr
                    style={{
                      color: "#4F4953",
                      fontFamily: "Inter-Medium,sans-serif",
                      fontSize: "16px",
                      fontweight: "normal",
                      background: "rgba(134, 83, 182, 0.05)",
                      // textAlign:"center"
                    }}
                  >
                    <th style={{ width: "10%" }}>Skill</th>
                    <th style={{ width: "10%" }}>Strength</th>
                    <th style={{ width: "10%" }}>Weakness</th>
                    <th style={{ width: "10%" }}>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {commonSkills.map((a, index) => {
                    const hasRated = a.ratings?.some(
                      (rating) => rating.userId === userId.userId
                    );
                    const formattedAverageRating =
                      a?.averageSkillRating?.toFixed(1);
                    let userRating = 0;

                    if (hasRated) {
                      userRating = 0;
                    }

                    return (
                      <tr key={`${a.name}-${index}`} style={{ width: "100%" }}>
                        <td>
                          <div className="">
                            <div>
                              <div className="skill-content">
                                <input
                                  type="text"
                                  value={a.value}
                                  onChange={(e) => handleChange(e, index)}
                                  disabled={a.skillIds !== ""}
                                  style={{
                                    width: "110px",
                                    background: "#F4F4F6",
                                    borderRadius: "6px",
                                    fontSize: "12px",
                                    padding: "5px",
                                    color: "#362A41",
                                    fontweight: "normal",
                                  }}
                                ></input>
                              </div>
                            </div>
                            {isRatingDone && hasRated ? (
                              <>
                                <ReactStars
                                  count={5}
                                  value={hasRated ? userRating : null}
                                  size={24}
                                  isHalf={true}
                                  className="star-icons"
                                  emptyIcon={<i className="far fa-star"></i>}
                                  halfIcon={
                                    <i className="fa fa-star-half-alt"></i>
                                  }
                                  fullIcon={<i className="fa fa-star"></i>}
                                  activeColor="#ffd700"
                                  onChange={(newRating) =>
                                    ratingChanged(
                                      newRating,
                                      index,
                                      a.skillIds,
                                      a.label,
                                      a.value
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <ReactStars
                                  count={5}
                                  style={{ position: "absolute", left: "50px" }}
                                  value={hasRated ? userRating : null}
                                  size={24}
                                  className="star-icons"
                                  isHalf={true}
                                  emptyIcon={<i className="far fa-star"></i>}
                                  halfIcon={
                                    <i className="fa fa-star-half-alt"></i>
                                  }
                                  fullIcon={<i className="fa fa-star"></i>}
                                  activeColor="#ffd700"
                                  onChange={(newRating) =>
                                    ratingChanged(
                                      newRating,
                                      index,
                                      a.skillIds,
                                      a.label,
                                      a.value
                                    )
                                  }
                                />
                              </>
                            )}
                            <div className="skill-content-rating">
                              {hasRated ? (
                                <>
                                  <div className="star-icon">
                                    <div style={{ margin: "5px 0 0 -8px" }}>
                                      <ProgressBar
                                        value={formattedAverageRating}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="Type down the strengths of the candidate"
                            className="font feedback-input"
                            value={strengthInputs[index]}
                            style={{
                              background: "#F4F4F6",
                              color: "#362A41",
                              fontweight: "normal",
                              borderRadius: "8px",
                              height: "58px",
                              padding: "10px",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "strength",
                                index,
                                setStrengthInputs,
                                setStrengthValues
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={weaknessInputs[index]}
                            placeholder="Type down the weakness of the candidate"
                            className="font feedback-input"
                            style={{
                              background: "#F4F4F6",
                              color: "#362A41",
                              borderRadius: "8px",
                              height: "58px",
                              padding: "10px",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "weakness",
                                index,
                                setWeaknessInputs,
                                setWeaknessValues
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={commentInputs[index]}
                            placeholder="Type your comments..."
                            className="font feedback-input"
                            style={{
                              background: "#F4F4F6",
                              color: "#362A41",
                              borderRadius: "8px",
                              height: "58px",
                              padding: "10px",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "comment",
                                index,
                                setCommentInputs,
                                setCommentValues
                              )
                            }
                          />
                        </td>
                        {index === commonSkills.length - 1 ? (
                          <td>
                            <img
                              src={Plus}
                              alt="img"
                              style={{ width: "40px" }}
                              onClick={() => handleClick()}
                            />
                          </td>
                        ) : (
                          <></>
                        )}
                        {a.skillIds === "" ? (
                          <td id={index} onClick={() => deleteTableRows(index)}>
                            <img src={Delete} alt="img" />
                          </td>
                        ) : (
                          <></>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            <div
              className="feed-name"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              Rate Other Skill
            </div>
            <div className="dataOne2">
              {softSkill.map((a, index) => {
                const hasRated = a.ratings?.some(
                  (rating) => rating.userId === userId.userId
                );
                const formattedAverageRating =
                  a?.averageSkillRating?.toFixed(1);
                let userRating = 0;

                if (hasRated) {
                  userRating = 0;
                }

                return (
                  <>
                    <div>
                      <div
                        className="Edu-data-wrapper skill-wrapper feed-wrapper"
                        style={{ marginTop: "20px" }}
                      >
                        <div>
                          <div className="skill-content">{a.value}</div>
                          <div className="skill-content-rating">
                            {hasRated ? (
                              <>
                                <div className="">
                                  {/* <img
                                    src={Star}
                                    alt="img"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  /> */}
                                  <div className="star-rating-no">
                                    {formattedAverageRating} Rating
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {isRatingDones && hasRated ? (
                          <>
                            <ReactStars
                              count={5}
                              value={hasRated ? userRating : null}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                              onChange={(newRating) =>
                                ratingSkillChanged(
                                  newRating,
                                  index,
                                  a.skillIds,
                                  a.label,
                                  a.value
                                )
                              }
                            />
                          </>
                        ) : (
                          <>
                            <ReactStars
                              count={5}
                              value={hasRated ? userRating : null}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                              onChange={(newRating) =>
                                ratingSkillChanged(
                                  newRating,
                                  index,
                                  a.skillIds,
                                  a.label,
                                  a.value
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              className="modalFooter"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "20px -20px 0 -20px",
                borderRadius: "0 0 16px 16px",
                fontFamily: "Inter-Medium,sans-serif",
              }}
            >
              <button className="button3" onClick={() => handleCreate()}>
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
