import React from "react";
import "./shimmer.css"; // Assuming you have defined shimmer styles in this file
import "../interview/interview.css";
const ShimmerLoading = () => {
  return (
    <div >
      <div className="job-card-container">
        <div
          className="shimmer-card"
          style={{
            width: "540px",
            margin: "auto",
            height: "165px",
          }}
        >
          {/* Left side image */}
          <div className="shimmer-card-left">
            <div className="shimmer shimmer-img"></div>
          </div>
          {/* Right side content */}
          <div className="shimmer-card-right">
            {/* Top part */}
            <div className="shimmer-card-top">
              {/* Centered name */}
              <div
                className="shimmer shimmer-title"
                style={{ width: "70%" }}
              ></div>
              {/* Paralleled down price */}
              <div
                className="shimmer shimmer-text"
                style={{ width: "30%" }}
              ></div>
            </div>
            {/* Bottom part */}
            <div className="shimmer-card-bottom">
              {/* Top right icon */}
              <div className="shimmer shimmer-icon"></div>
              {/* Horizontal text */}
              <div
                className="shimmer shimmer-text"
                style={{ width: "80%" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerLoading;
