/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import "./applicant.css";
import DesktopHeader from "../desktopHeader";
import profile from "../../images/company.png";
import axios from "axios";
import backArrow from "../../images/arrow-box.svg";
import iMedal from "../../images/icons/medal-ribbon.svg";
import { IoIosArrowForward } from "react-icons/io";
import Resume from "../../images/PlayIcon.svg";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Spinner from "react-bootstrap/Spinner";
import star from "../../images/icons/star.svg";
const HeaderText = styled.h2`
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const PageWrapper2 = styled.div``;

const PageWrapper3 = styled.div``;

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

const Wrapper3 = styled.div``;
const Wrapper4 = styled.div`
  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
`;
const JobTitle = styled.div``;
const AnswerTitle = styled.div``;

const HeaderDownLayout = styled.div`
  li:nth-child(3) {
    color: var(--this-primary);
  }
`;
const HeaderLayout = styled.div``;
const CenterLayout = styled.div``;
const Card = styled.div``;

// Create a styled component for the columns
const Column = styled.div``;
const NameImageContainer = styled.div`
  display: flex;
  align-items: center;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 50px;
`;
const Name = styled.h4`
  margin: 16px 0;
  font-family: "Inter-Medium", sans-serif; /* Use Inter-Medium if available, otherwise fall back to sans-serif */
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
  line-height: 19.2px;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const Button11 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button10 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});
const Applicants = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  function onSubmit() {
    setSuccess(true);
  }
  const history = useHistory();
  const { id } = useParams();
  const { jobId } = useParams();

  const [file, setFile] = useState(null);
  const [input, setInput] = useState();
  const [input2, setInput2] = useState();
  const [input4, setInput4] = useState();
  const [input3, setInput3] = useState();

  const updateOne = (id) => {
    setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/applicant/${id}/${jobId}`)
      .then((response) => {
        setInput(response.data.user);
        setInput3(response.data.detail);
        setInput4(response.data.job._id);
        const resume = response.data.user.resume;
        if (resume) {
          setFile(resume);
        } else {
          console.error("Resume not found in API response.");
        }
        const questionList = response.data.job.questionList;

        // Now you can use sortedQuestionList in your code, where starred items will come first
        setInput2(questionList);
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDownload = (id, price) => {
    window.open(id, "_blank");
    // axios
    //   .post(
    //     `${process.env.PUBLIC_URL
    //     }/xakal/about/getpayment/${Common.loggedIn()}`,
    //     { link: id }
    //   )
    //   .then((response) => {
    //     if (
    //       response.data &&
    //       response.data.payment &&
    //       response.data.payment.paid &&
    //       response.data.payment.paid === "paid"
    //     ) {
    //       window.open(id, "_blank");
    //     } else {
    //       checkForCredit(id, price)
    //     }
    //   });
  };

  const [companyData, setCompanyData] = useState([]);
  const getCompanyDataOnUser = () => {
    axios
      .get(
        `${
          process.env.PUBLIC_URL
        }/xakal/get-company-by-user/${Common.loggedIn()}`
      )
      .then((response) => {
        if (response && response.data && response.data.success) {
          setCompanyData(response.data.company[0]);
        }
      })
      .catch((err) => err.error);
  };

  // eslint-disable-next-line no-unused-vars
  const checkForCredit = (id, price) => {
    if (companyData && companyData.availableProfileCredit > 0) {
      const payload = {
        availableProfileCredit: --companyData.availableProfileCredit,
        totalProfileUsed: ++companyData.totalProfileUsed,
        totalCredit: ++companyData.totalCredit,
      };
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/update-company/update/${companyData._id}`,
          payload
        )
        .then((companyResponse) => {
          if (companyResponse.data.success) {
            axios
              .post(
                `${
                  process.env.PUBLIC_URL
                }/xakal/about/payment/${Common.loggedIn()}`,
                { link: id }
              )
              .then((paymentResponse) => {
                if (paymentResponse.data.success) {
                  window.open(id, "_blank");
                }
              })
              .catch((err) => err.error);
          }
        })
        .catch((err) => err.error);
    } else {
      proceedToPay(id, price);
    }
  };

  const proceedToPay = (id, price) => {
    const amount = Math.round(parseFloat(price) * 100);

    const currency = "INR";

    const payload = {
      amount,
      currency,
      receipt: "qwerr1",
    };

    axios
      .post(`${process.env.PUBLIC_URL}/xakal/payment`, payload)
      .then((response) => {
        const data = response.data.order.id;

        var options = {
          // key: `${process.env.RAZORPAY_KEY_ID_FRONT}`,
          key: "rzp_live_UeS6FFpWM9TW1y",
          amount,
          currency,
          name: "Xakal ",
          description: "Transaction",
          image: "https://xakalprod.s3.ap-south-1.amazonaws.com/logopng.png",
          order_id: data,
          handler: function (response) {
            const body = {
              ...response,
            };
            axios
              .post(`${process.env.PUBLIC_URL}/xakal/payment/validate`, body)
              .then((res) => {
                axios
                  .post(
                    `${
                      process.env.PUBLIC_URL
                    }/xakal/about/payment/${Common.loggedIn()}`,
                    { link: id }
                  )
                  .then((updateResponse) => {
                    // Show toaster message
                    toast.success("Payment is successfully completed");
                    // Additional axios.put request
                    window.open(id, "_blank");
                    // Redirect to the user's profile
                  })
                  .catch((updateErr) => {
                    toast.error("Payment Failed");
                  });
              })
              .catch((err) => {});
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
        });
        rzp1.open();
      })
      .catch((err) => {});
  };

  const handleBack = () => {
    history.push(`/portal/view-applicants/${input4}`);
  };
  const [openSetting, setOpenSetting] = useState(false);

  useEffect(() => {
    updateOne(id);
  }, [id]);

  useEffect(() => {
    getCompanyDataOnUser();
  }, []);

  const searchedKey = () => {};
  const fileType = file?.split(".").pop();
  const src=`https://docs.google.com/gview?url=${file}&embedded=true`
  return (
    <div>
      <HeaderLayout className="header-layout">
        {/* Start of Mobile Header  */}
        <div className="sub-header o-mobile p-3">
          <div className="arrow-box" onClick={handleBack}>
            <img src={backArrow} className="back-arrow-img" alt="XAKAL" />
          </div>
          <h4 className="textpass font-face-gm mb-0">Applicant Details</h4>
        </div>
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader
          title={"Manage Jobs"}
          searchedKey={searchedKey}
          isSearchActive={false}
        />
        {/* End of desktop Header  */}

        <HeaderDownLayout className="o-desk">
          <ul className="breadcrumbs">
            <li>
              <a>Manage Job</a>
            </li>
            <li>
              <IoIosArrowForward />
            </li>
            <li onClick={handleBack} style={{ cursor: "pointer" }}>
              View Applicants
            </li>
            <li>
              <IoIosArrowForward />
            </li>
            <li style={{ cursor: "pointer" }}>{`${
              input && input.firstName && input.firstName
            } ${input && input.lastName && input.lastName}`}</li>
          </ul>
        </HeaderDownLayout>
      </HeaderLayout>
      {loading ? (
        <Spinner
          style={{
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <div className="in-content-wrapper">
          <CenterLayout className="ap-cl mb-3">
            <Card className="ap-card apd-card">
              <Column className="ap-image">
                <NameImageContainer>
                  {input && input?.avatar ? (
                    <Image src={input.avatar} alt="Image Alt Text" />
                  ) : (
                    <Image src={profile} alt="Image Alt Text" />
                  )}
                  {input && input.firstName && input.lastName ? (
                    <div>
                      <div className="star-main">
                        <Name
                          className="name"
                          onClick={() =>
                            history.push(
                              `/portal/other-profile/${input.userId}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >{`${input.firstName} ${input.lastName}`}</Name>
                        {input3?.totalAverageRating !== undefined &&
                        input3?.totalAverageRating !== null &&
                        input3?.totalAverageRating !== 0 &&
                        input3?.totalAverageRating !== 0.5 ? (
                          <div>
                            <img src={star} className="star-people" alt="" />
                            <span className="star-average">
                              {input3.totalAverageRating.toFixed(1)}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      {input &&
                      input.experienceIds &&
                      input.experienceIds.length > 0 ? (
                        input &&
                        input.experienceIds
                          .filter(
                            (exp) => !exp.endDate && exp.timestamps?.createdAt
                          )
                          .sort(
                            (exp1, exp2) =>
                              new Date(exp2.timestamps?.createdAt) -
                              new Date(exp1.timestamps?.createdAt)
                          )
                          .slice(0, 1)
                          .map((a) => <p className="ap-pos">{a.designation}</p>)
                      ) : (
                        <p className="ap-pos">-</p>
                      )}
                    </div>
                  ) : (
                    <Name className="name">No Name is Provided</Name>
                  )}
                </NameImageContainer>
              </Column>
              <Column className="ap-deg">
                {input &&
                input.educationIds &&
                input.educationIds.length > 0 ? (
                  input &&
                  input.educationIds
                    .filter((exp) => exp.timestamps.createdAt)
                    .sort(
                      (exp1, exp2) =>
                        new Date(exp2.timestamps.createdAt) -
                        new Date(exp1.timestamps.createdAt)
                    )
                    .slice(0, 1)
                    .map((a) => (
                      <div>
                        <Name className="deg">{a.degree}</Name>
                        <p className="ap-pos">{a.specialization}</p>
                      </div>
                    ))
                ) : (
                  <Name className="deg">No Degree Provided</Name>
                )}
              </Column>

              <Column className="apd-vp-container o-desk"></Column>
            </Card>
          </CenterLayout>

          <Wrapper className="ap-qr-container">
            <PageWrapper2 className="ap-ques-box">
              <HeaderText className="apd-head">
                <img src={iMedal} alt="" />
                <span>Questions</span>
              </HeaderText>
              <Wrapper3 className="apd-body">
                {input2 &&
                  input2.map((question, index) => {
                    const userAnswers = question.answers.filter(
                      (answer) => answer.userId === input.userId
                    );

                    if (userAnswers.length > 0) {
                      const lastUserAnswer =
                        userAnswers[userAnswers.length - 1];

                      return (
                        <Wrapper4 key={index} className="apd-qa-container">
                          <JobTitle className="apd-ques">{`${index + 1}. ${
                            question.questionName
                          }`}</JobTitle>
                          <div>
                            <AnswerTitle className="apd-ans">{` ${
                              lastUserAnswer.answer || "No answer provided"
                            }`}</AnswerTitle>
                          </div>
                        </Wrapper4>
                      );
                    }
                  })}
              </Wrapper3>
            </PageWrapper2>

            <PageWrapper3 className="ap-resume o-desk">
              <div className="mp-c-li">
                <img src={Resume} className="" alt="icon" />
                <a
                  onClick={() => {
                    handleDownload(file, 25);
                  }}
                  target="_blank"
                  rel="noreferrer"
                  className="mp-so-link"
                >
                  Download Resume
                </a>
              </div>
              {fileType === "docx" || fileType === "doc" ? (
                <div style={{ width: "100%", height: "600px" }}>
                  <iframe
                    src={src}
                    title="Document Viewer"
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "600px",
                    position: "relative",
                  }}
                >
                  {/* Container for the blurred document */}

                  <iframe
                    src={file}
                    title="Document Viewer"
                    style={{ width: "100%", height: "100%" }}
                  ></iframe>
                </div>
              )}
            </PageWrapper3>
          </Wrapper>

          <Dialog
            open={openSetting}
            PaperProps={{
              style: {
                width: "347px",
                height: "200px",
                top: "50px",
                right: "35px",
                borderRadius: "24px",
                position: "fixed",
              },
            }}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Inter-Medium",
                position: "relative",
              }}
            >
              <span
                style={{
                  width: "347px",
                  height: "50px",
                  padding: "19px, 16px, 19px, 25px",
                  border: "0px, 0px, 1px, 0px",
                  borderBottom: "1px solid #F4F4F6",
                }}
              >
                Setting
              </span>
              <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  fontFamily: "Inter-Medium",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <BsCreditCard2Front size={20} />
                <p onClick={() => history.push("/portal/changepassword")}>
                  Change Password
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  fontFamily: "Inter-Medium",
                  gap: "10px",
                  cursor: "pointer",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  fontFamily: "Inter-Medium",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={onSubmit}
              >
                <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
                <p style={{ color: "#FF5252" }}> Logout</p>
              </div>
            </DialogContent>
          </Dialog>
          <Dialog
            open={success}
            fullWidth
            maxWidth="sm"
            PaperProps={{ style: { borderRadius: "20px" } }}
          >
            <DialogTitle
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Inter-Medium",
              }}
            ></DialogTitle>
            <DialogContent>
              <Text>Are You Sure You Want To Log Out?</Text>

              <DateWrapperButton>
                <Button10 onClick={() => setSuccess(false)}>No</Button10>
                {user ? (
                  <Button11
                    onClick={() => {
                      Auth.signOut();
                      Common.logout();
                    }}
                  >
                    Yes
                  </Button11>
                ) : (
                  <Button11 onClick={logout}>Yes</Button11>
                )}
              </DateWrapperButton>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default Applicants;
