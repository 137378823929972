import React, { useState, useEffect } from "react";
import "../../styles/myprofile.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import Select, { components } from "react-select";
import Common from "../../utils/common";
import styled from "styled-components";
import uni from "./universities.json";
import Spinner from "react-bootstrap/Spinner";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "../../images/CloseIcon.svg";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import { toast } from "react-toastify";
const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    border: "none",
    height: "48px",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    color: "rgba(198, 202, 210, 1)",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
    overflowX: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 8px)",
      marginLeft: "4px",
    },
  }),
  // Add more styles as needed
};
const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    border: "none",
    height: "48px",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
    fontweight: "normal",
    color: "rgba(198, 202, 210, 1)",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      // padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};

const options3 = [
  { value: "PartTime", label: "PartTime" },
  { value: "FullTime", label: "FullTime" },
];

const OnlineHead = styled.div`
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
`;
const OnlineContent = styled.div`
  color: #4f4953;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  margin-top: 5px;
`;
const JobTitle = styled.div`
  width: auto;
  // height: 22px;
  top: 24px;
  left: 0px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;
  margin-left: 10px;

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    // margin: -15px 0 0 0;
  }
`;
const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    gap: 4px;
  }
`;
const Button4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Inter-Medium, sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px;
  width: 94px;
  height: 40px;
  // position: relative;
  // top: 3px;
  cursor: pointer;
  @media (max-width: 576px) {
    width: 65px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  // top: 3px;
  font-family: Inter-Medium, sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;

  cursor: pointer;
  @media (max-width: 576px) {
    width: 85px;
    height: 30px;
    padding: 6px;
    font-size: 12px;
  }
`;
const EducationModal = ({ onClose, eduId }) => {
  const [aboutInput, setAboutInput] = useState([
    {
      online: false,
      university: "",
      degree: "",
      specialization: "",
      startDate: "",
      endDate: "",
      percentage: "",
      educationType: "",
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState("");
  const [error2, setError2] = useState({
    university: "",
    degree: "",
    specialization: "",
    startDate: "",
    endDate: "",
    percentage: "",
    educationType: "",
    skill: "",
  });
  const [closemodal, setCloseModal] = useState(false);
  const [popupopen, setPopupOpen] = useState(false);
  const [skill, setSkill] = useState([]);
  const handleSkillChnage = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
      candidateId: Common.loggedIn(),
    }));

    setSkill(formattedSkills);
    setSkillCreate("");
    setOptionsOne([]);
    setDirty(true); // Not sure where setDirty comes from in your code
  };
  const optionsTwo = uni.Universities.map((skill) => ({
    value: skill,
    label: skill,
  }));

  const [dirty, setDirty] = useState(false);

  // const handleChangePercentage = (event) => {
  //   const { name, value } = event.target;

  //   // Check if the value is a valid number from 1 to 100 (excluding exponential notation)
  //   if (
  //     value === "" ||
  //     (/^[0-9]+$/.test(value) &&
  //       !/[eE]/.test(value) &&
  //       value >= 0 &&
  //       value <= 100)
  //   ) {
  //     setAboutInput((prevProps) => ({
  //       ...prevProps,
  //       [name]: value,
  //     }));
  //     setDirty(true);
  //   } else {
  //     console.error("Invalid percentage value");
  //   }
  // };
  const handleChangePercentage = (event) => {
    const { name, value } = event.target;
  
    // Check if the value is a valid number from 1 to 100 (excluding exponential notation)
    if (
      value === "" || // Allow empty string for clearing the input
      (value >= 1 && value <= 100 && !/[eE]/.test(value)) // Check for valid range and no exponential notation
    ) {
      setAboutInput((prevProps) => ({
        ...prevProps,
        [name]: value,
      }));
      setDirty(true); // Mark the form as dirty when valid input is entered
    } else {
      console.error("Invalid percentage value");
      // Optionally, you can handle the error state here, e.g., set an error message
      // setError("Invalid percentage value");
    }
  };
  
  const changeonline = (value) => {
    setAboutInput({ ...aboutInput, online: value === "true" ? true : false });
    setDirty(value === "true" ? true : false);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError2((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "university":
          if (value === undefined || value === "") {
            stateObj[name] = "Please enter name of the school / university";
          }
          break;

        case "degree":
          if (value === undefined || value === "") {
            stateObj[name] = "Please enter the degree";
          }

          break;

        case "specialization":
          if (value === undefined || value === "") {
            stateObj[name] = "Please select specilization";
          }
          break;

        case "startDate":
          if (value === "") {
            stateObj[name] = "Please enter start date";
          } else if (value >= aboutInput.endDate) {
            stateObj[name] = "Start date should not be greater than end date";
          }
          break;
        case "endDate":
          if (value === "") {
            stateObj[name] = "Please enter end date";
          } else if (aboutInput.startDate && value <= aboutInput.startDate) {
            stateObj[name] = "End Date Should be Greater than Start Date";
          }
          break;

        case "percentage":
          const regex3 = /^[0-9,.]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the percentage";
          } else if (!regex3) {
            stateObj[name] =
              "Please check the percentage you have entered allows characters: number and decimal points{“-”} percentage should not exceed 100..";
          } else if (value > 100) {
            stateObj[name] = "Percentage should be between 0 and 100.";
          } else if (value < 0) {
            stateObj[name] = "Percentage should be between 0 and 100.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const prefillValue = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-education-detail/${id}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          if (
            response.data.educationOne.skill &&
            response.data.educationOne.skill.length > 0
          ) {
            setSkill(response.data.educationOne.skill);
          }
          setAboutInput({
            ...aboutInput,
            id: response.data.educationOne._id,
            userId: response.data.educationOne.userId,
            online: response.data.educationOne.online,
            university: response.data.educationOne.university,
            degree: response.data.educationOne.degree,
            specialization: response.data.educationOne.specialization,
            startDate: response.data.educationOne.startDate,
            endDate: response.data.educationOne.endDate,
            percentage: response.data.educationOne.percentage,
            educationType: response.data.educationOne.educationType,
          });
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    if (eduId) {
      prefillValue(eduId);
    } else {
      changeonline("false");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eduId]);
  // eslint-disable-next-line no-unused-vars
  const [selectedClass, setSelectedClass] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [selectedSection, setSelectedSection] = useState("");
  const [loading, setIsLoading] = useState(false);

  const submitExperience = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors = {};

    if (!aboutInput.university)
      errors.university = "Please enter name of the school / university";
    if (!aboutInput.degree) errors.degree = "Please enter the degree";
    if (!aboutInput.specialization)
      errors.specialization = "Please enter the specialization";
    if (!aboutInput.startDate) errors.startDate = "Please select start date";
    if (!aboutInput.endDate) errors.endDate = "Please select end date";
    if (aboutInput.endDate <= aboutInput.startDate)
      errors.endDate = "End Date Should be Greater than Start Date";

    if (Object.keys(errors).length === 0) {
      try {
        const params = {
          online: aboutInput.online,
          university: aboutInput.university,
          degree: aboutInput.degree,
          specialization: aboutInput.specialization,
          startDate: aboutInput.startDate,
          endDate: aboutInput.endDate,
          percentage: aboutInput.percentage,
          educationType: aboutInput.educationType,
          skill: skill,
          class: selectedClass,
          section: selectedSection,
          deleteFlag: "N",
          userId: Common.loggedIn(),
        };

        const response = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/education-create`,
          params
        );

        if (response.data.success) {
          setAboutInput({
            ...aboutInput,
            online: "",
            university: "",
            degree: "",
            specialization: "",
            startDate: "",
            endDate: "",
            percentage: "",
            educationType: "",
          });
          setSkill([...skill]);
          onClose();
        }
      } catch (err) {
        toast.error(err.response?.data?.message || "An error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      setError2(errors);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };
  const submitExperience2 = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const errors = {};

    if (!aboutInput.university)
      errors.university = "Please enter name of the school / university";
    if (!aboutInput.degree) errors.degree = "Please enter the degree";
    if (!aboutInput.specialization)
      errors.specialization = "Please enter the specialization";
    if (!aboutInput.startDate) errors.startDate = "Please select start date";
    if (!aboutInput.endDate) errors.endDate = "Please select end date";
    if (aboutInput.endDate <= aboutInput.startDate)
      errors.endDate = "End Date Should be Greater than Start Date";

    if (Object.keys(errors).length === 0) {
      try {
        const params = {
          online: aboutInput.online,
          university: aboutInput.university,
          degree: aboutInput.degree,
          specialization: aboutInput.specialization,
          startDate: aboutInput.startDate,
          endDate: aboutInput.endDate,
          percentage: aboutInput.percentage,
          educationType: aboutInput.educationType,
          skill: skill,
          class: selectedClass,
          section: selectedSection,
          deleteFlag: "N",
          userId: Common.loggedIn(),
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-education/${aboutInput.id}`,
          params
        );

        if (response.data.success) {
          setAboutInput({
            ...aboutInput,
            online: "",
            university: "",
            degree: "",
            specialization: "",
            startDate: "",
            endDate: "",
            percentage: "",
            educationType: "",
          });
          setSkill([...skill]);
          onClose();
        }
      } catch (err) {
        toast.error(err.response?.data?.message || "An error occurred");

      } finally {
        setIsLoading(false);
      }
    } else {
      setError2(errors);
      setIsLoading(false); // Set loading to false if there are errors
    }
  };

  const DiscardChanges = () => {
    setAboutInput({
      ...aboutInput,
      online: "",
      university: "",
      degree: "",
      specialization: "",
      startDate: "",
      endDate: "",
      percentage: "",
      educationType: "",
    });
    setSkill([...skill]);
    onClose();
  };
  const CloseModalClear = () => {
    if (!dirty) {
      onClose(); // No modifications, directly close the modal
    } else {
      setCloseModal(true); // Show the discard popup
      setPopupOpen(true);
    }
  };
  const handleUniveristy = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setAboutInput({ ...aboutInput, university: value });
    setDirty(true);
  };

  const handleStartDate = (event) => {
    const { name, value } = event.target;
    setAboutInput({ ...aboutInput, [name]: value });
    setDirty(true);
  };
  const handleEndDate = (event) => {
    const { name, value } = event.target;
    setAboutInput({ ...aboutInput, [name]: value });
    setDirty(true);
  };
  const educationType = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    setAboutInput({ ...aboutInput, educationType: value });
    validateInput({ target: { name: "educationType", value } });
    setDirty(true);
  };
  const [skillData, setSkillData] = useState([]);

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");

  const createSkill = () => {
    const payload = {
      name: skillcreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then(() => {
        setSkillCreate("");
        setOptionsOne([]);
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const extractClassAndSection = (classes) => {
    const uniqueData = [];
    const uniqueClasses = new Set();
    const uniqueSections = new Set();

    classes.forEach((item) => {
      item.classes.forEach((classItem) => {
        const { classNo, sectionNo } = classItem;
        if (!uniqueClasses.has(classNo) || !uniqueSections.has(sectionNo)) {
          uniqueData.push({ classNo, sectionNo });
          uniqueClasses.add(classNo);
          uniqueSections.add(sectionNo);
        }
      });
    });
  };

  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-institute`)
      .then((response) => {
        extractClassAndSection(response.data.company);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    GetAllInstitute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [optionData, setOptionsOne] = useState([]);
  useEffect(() => {
    if (skillcreate.length > 2) {
      setOptionsOne(
        skillData.map((e) => ({
          skillIds: e.skillIds,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
          candidateId: Common.loggedIn(),
        }))
      );
    } else {
      setOptionsOne([]);
    }
  }, [skillData, skillcreate]);
  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChnage(e);
  };

  const [optionsDegree, setOptionsDegree] = useState([]);
  const [optionsSpec, setOptionsSpec] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState("");
  const getAllSpec = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getSpec`)
      .then((response) => {
        const formattedOptions = response.data.map((degree) => ({
          value: degree.name,
          label: degree.name,
          specializations: degree.specialization || [],
        }));
        setOptionsDegree(formattedOptions);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAllSpec();
  }, []);
  const getSpecByDegree = (selectedDegree) => {
    // Assuming that the API response contains a property named 'specializations' for each degree
    const selectedDegreeObj = optionsDegree.find(
      (option) => option.value === selectedDegree
    );
    if (selectedDegreeObj) {
      const sortedSpecializations = (
        selectedDegreeObj.specializations || []
      ).sort((a, b) => {
        // Assuming the specializations are strings; adjust the comparison as needed
        return a.localeCompare(b);
      });

      setOptionsSpec(sortedSpecializations);
    }
  };

  const handleDegree = (selectedOption) => {
    setSelectedDegree(selectedOption.value);
    setAboutInput((prevInput) => ({
      ...prevInput,
      degree: selectedOption.value,
    }));
    setError2((prevError) => ({ ...prevError, degree: "" }));
  };

  const handleSpecialization = (selectedOption) => {
    setAboutInput((prevInput) => ({
      ...prevInput,
      specialization: selectedOption.value,
    }));
    setError2((prevError) => ({ ...prevError, specialization: "" }));
  };
  useEffect(() => {
    if (selectedDegree) {
      getSpecByDegree(selectedDegree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDegree]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (popupopen === false) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "auto";
  }, [popupopen]);
  return (
    <div>
      <div className="" id="education-popup">
        {popupopen ? (
          <Dialog
            open={closemodal}
            PaperProps={{
              style: {
                borderRadius: "16px",
                top: "10px",
                transform: "translateY(0%)",
                position: "absolute",
                backgroundColor: "#FFDAE0",
                padding: "5px 8px",
                height: "fit-content",
              },
            }}
            halfWidth
            maxWidth="md"
            // className="def"
          >
            <DialogContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontFamily: "Inter-Medium",
                position: "relative",
                left: "5px",
                // top: "-10px",
              }}
            >
              <IconWrapper>
                <img
                  src={DeleteImg}
                  alt="img"
                  style={{ width: "24px", height: "24px" }}
                />
              </IconWrapper>
              <JobTitle>
                Are you sure you want to discard the changes you made?
              </JobTitle>
              <DialogActions>
                <div
                  style={{ display: "flex", gap: "10px", marginLeft: "2rem" }}
                >
                  <Button4
                    onClick={() => {
                      setCloseModal(false);
                      setPopupOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Go Back
                  </Button4>
                  <Button6 onClick={DiscardChanges}>Yes, Discard</Button6>
                </div>
              </DialogActions>
            </DialogContent>
          </Dialog>
        ) : (
          <>
            <div className="modalHeader">
              <h1 className="mTitle">Add Education</h1>
              <img
                src={CloseIcon}
                alt="img"
                className="mClose"
                // style={{ marginRight: "2rem", cursor: "pointer" }}
                onClick={CloseModalClear}
              />
            </div>
            <div className="modalBody">
              <div className="Edu-Popup-wrapper" style={{ marginTop: "-10px" }}>
                <div
                  className="myprofile-content-head"
                  style={{ width: "50%" }}
                >
                  {aboutInput.online !== undefined &&
                  aboutInput.online.length > 0 ? (
                    <div style={{ display: "flex", gap: "20px" }}>
                      <input
                        type="checkbox"
                        defaultChecked={aboutInput.online === false}
                        name="online"
                        onClick={() => {
                          if (aboutInput.online === true) {
                            changeonline("false");
                            setDirty(true);
                          } else {
                            changeonline("true");
                            setDirty(true);
                          }
                        }}
                      />
                      <h4>Online</h4>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <div style={{ marginTop: "15px" }}>
                        <OnlineHead style={{ textAlign: "left" }}>
                          Online
                        </OnlineHead>
                        <OnlineContent>
                          Select if the course/certification was pursued online
                        </OnlineContent>
                      </div>
                      <input
                        type="checkbox"
                        checked={aboutInput.online === true}
                        name="online"
                        style={{
                          marginTop: "20px",
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => {
                          if (aboutInput.online === true) {
                            changeonline("false");
                            setDirty(true);
                          } else {
                            changeonline("true");
                            setDirty(true);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="input-space">
                  <label className="label-name">
                    School / University<span>*</span>
                  </label>
                  <div>
                    <CreatableSelect
                      styles={customStyles}
                      components={{ Placeholder, DropdownIndicator }}
                      name="university"
                      placeholder="Type / Search"
                      value={optionsTwo.find(
                        (option) => option.value === aboutInput.university
                      )}
                      onChange={handleUniveristy}
                      options={optionsTwo}
                      onBlur={() =>
                        validateInput({
                          target: {
                            name: "university",
                            value: aboutInput.university,
                          },
                        })
                      }
                    />

                    {error2.university && (
                      <span className="text-danger f12">
                        {error2.university}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="Edu-Popup-wrapper">
                <div className="input-space">
                  <label className="label-name">
                    Degree<span>*</span>
                  </label>
                  <div>
                    <Select
                      className="footer-header mb-2"
                      components={{ Placeholder, DropdownIndicator }}
                      isSearchable
                      name="degree"
                      options={optionsDegree}
                      value={optionsDegree.find(
                        (option) => option.value === aboutInput.degree
                      )}
                      onChange={handleDegree}
                      placeholder="Type / Search"
                      styles={
                        customStyles
                        // height: "40px",
                        // control: (base, state) => ({
                        //   ...base,
                        //   width: "100%",
                        //   backgroundColor: "#F4F4F6",
                        //   border: "1px solid #F4F4F6",
                        //   borderRadius: "8px",
                        //   fontSize:"12px",
                        //   fontFamily:"Inter-Medium,sans-serif"
                        // }),
                      }
                      onBlur={() =>
                        validateInput({
                          target: {
                            name: "degree",
                            value: aboutInput.degree,
                          },
                        })
                      }
                    />
                    {error2.degree && (
                      <span className="text-danger f12">{error2.degree}</span>
                    )}
                  </div>
                </div>
                <div className="input-space">
                  <label className="label-name">
                    Specialization<span>*</span>
                  </label>

                  <Select
                    className="footer-header mb-2"
                    components={{ Placeholder, DropdownIndicator }}
                    isSearchable
                    name="specialization"
                    options={optionsSpec.map((spec) => ({
                      value: spec,
                      label: spec,
                    }))}
                    value={
                      aboutInput.specialization
                        ? {
                            value: aboutInput.specialization,
                            label: aboutInput.specialization,
                          }
                        : null
                    }
                    onChange={handleSpecialization}
                    onBlur={() =>
                      validateInput({
                        target: {
                          name: "specialization",
                          value: aboutInput.specialization,
                        },
                      })
                    }
                    placeholder="Type / Search"
                    styles={customStyle}
                  />
                  {error2.specialization && (
                    <span className="text-danger f12">
                      {error2.specialization}
                    </span>
                  )}
                </div>
              </div>

              <div className="Edu-Popup-wrapper">
                <div className="myprofile-content-head input-space">
                  <div style={{ width: "100%" }}>
                    <label className="label-name">
                      Start<span>*</span>
                    </label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="date"
                        placeholder="YYYY"
                        aria-label="date"
                        // aria-describedby="basic-addon1"
                        name="startDate"
                        className="footer-header"
                        value={aboutInput.startDate}
                        style={{
                          fontSize: "12px",
                        }}
                        onChange={handleStartDate}
                        onBlur={validateInput}
                        max={new Date().toISOString().split("T")[0]} // Set max attribute to current date
                      />
                    </InputGroup>
                    {error2.startDate && (
                      <span className="text-danger f12">
                        {error2.startDate}
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      position: "relative",
                      marginLeft: "5px",
                      width: "100%",
                    }}
                  >
                    <label className="label-name">
                      End<span>*</span>
                    </label>
                    <InputGroup className="mb-2">
                      <Form.Control
                        type="date"
                        placeholder="YYYY"
                        aria-label="YYYY"
                        name="endDate"
                        aria-describedby="basic-addon1"
                        className="footer-header"
                        value={aboutInput.endDate}
                        style={{
                          fontSize: "12px",
                        }}
                        onChange={handleEndDate}
                        onBlur={validateInput}
                      />
                    </InputGroup>
                    {error2.endDate && (
                      <span className="text-danger f12">{error2.endDate}</span>
                    )}
                  </div>
                </div>
                <div className="input-space">
                  <label className="label-name">Percentage Obtained</label>
                  <InputGroup className="footer-header mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Enter percentage in numeric decimal"
                      aria-label="Date of birth"
                      aria-describedby="basic-addon1"
                      className="footer-header"
                      name="percentage"
                      max={100}
                      value={aboutInput.percentage}
                      onChange={handleChangePercentage}
                      onBlur={validateInput}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="Edu-Popup-wrapper">
                <div className="input-space">
                  <label className="label-name">Education Type</label>
                  <div>
                    <Select
                      components={{ Placeholder, DropdownIndicator }}
                      className="footer-header"
                      isSearchable
                      name="educationType"
                      options={options3}
                      menuPlacement="top"
                      styles={
                        customStyles
                     
                      }
                      value={options3.find(
                        (option) => option.value === aboutInput.educationType
                      )}
                      onChange={educationType}
                      placeholder="Select"
                    />
                    {error2.educationType && (
                      <span className="text-danger">
                        {error2.educationType}
                      </span>
                    )}
                  </div>
                </div>
                <div className="input-space">
                  <label className="label-name">Skills</label>
                  <div>
                 
                    <CreatableSelect
                      isMulti
                      styles={
                        customStyles
                       
                      }
                      components={{ Placeholder, DropdownIndicator }}
                      noOptionsMessage={() => null}
                      isValidNewOption={() => optionData.length}
                      name="skill"
                      menuPlacement="top"
                      placeholder="Type select"
                      onChange={(e) => handleCreatable(e)}
                      onInputChange={(e) => setSkillCreate(e)}
                      options={optionData}
                      value={skill}
                    />
                   
                    {error2.skill && (
                      <span className="text-danger">{error2.skill}</span>
                    )}
                  </div>
                  <span className="text-danger">{error}</span>
                </div>
              </div>
        
            </div>
            <div className="modalFooter">
              <button
                className="button5"
                onClick={CloseModalClear}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </button>
              {eduId ? (
                <button
                  className="button3"
                  onClick={(e) => submitExperience2(e)}
                >
                  {loading ? <Spinner size={20} /> : "Update"}
                </button>
              ) : (
                <button
                  className="button3"
                  onClick={(e) => submitExperience(e)}
                >
                  {loading ? <Spinner size={20} /> : "Save"}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EducationModal;
