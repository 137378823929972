/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./buttonStyle.css";
import "./jobs.css";
import iExp from "../../images/icons/exp.svg";
import noData from "../../images/NoData.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import iHome from "../../images/icons/home.svg";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import InputGroup from "react-bootstrap/InputGroup";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Image from "../../images/company.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ViewJobApplicant from "./jobApplication";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import CloseIcon from "../../images/CloseIcon.svg";
import Favourite from "../../images/FavouriteSaved.png";
import Favourites from "../../images/Bookmark.png";
import Settings from "../settings";
import FitMe from "./fitme";
import CustomHeaderLayout from "./customHeaderLayout";
import ShimmerLoading from "../interview/shimmerloading";

const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;
const Button2 = styled.div``;
const Button3 = styled.div`
  height:40px;`;
const Button5 = styled.div``;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  cursor: pointer;
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(253, 33, 69, 1);
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // gap: 20px;
  padding: 5px;
`;

const Wrapper = styled.div``;
const JobDetail = styled.div``;

const JobTitle = styled.div``;

const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const QuestionLabel = styled.label`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;

const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 10px 0;
`;

const CardWrapper = styled.div`
  cursor:pointer;
`;

const Text = styled.p`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
  // margin:20px 0 40px 0;
  color: rgba(120, 118, 122, 1);
`;

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});
const JobApplied = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);

  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [savedInterviews, setSavedInterviews] = useState([]);

  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAllJob = () => {
    // setLoading(true);
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/job/applied`)
      .then((response) => {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch((err) => err.error)
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to toggle favorite status
  const toggleFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const togglePopUpFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
      
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  useEffect(() => {
    // getAllFinalJob();
    setLoading(true);
    getAllJob();
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [userAppliedJob, setUserAppliedJob] = useState([]);
  const handleFunctionClose = () => {
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/apply-submit/${selectedId}/${userId.userId}`
      )
      .then((response) => {
        if (response.status === 200) {
          // Update the favorite status in the state
          setUserAppliedJob((prevSavedInterviews) => [
            ...prevSavedInterviews,
            selectedId,
          ]);
        } else {
          console.error("Failed to toggle favorite status");
        }
        setOpen3(false);
        setLoading(false);
        toast.success("Job Applied Successfully...");
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      });
  };
  const handleApplyClick = (id, questionList, applyThrough, urls) => {
    if (applyThrough === "companySite") {
      // Redirect to the specific URL
      window.location.href = urls;
    } else {
      setSelectedId(id); // Set the selected id in the state

      if (
        Array.isArray(questionList) &&
        questionList.length > 0 &&
        questionList.some((q) => q.questionName.trim() !== "")
      ) {
        // If there are questions, open the first modal
        setOpen(true);
        setSelectedQuestionList(questionList);
      } else {
        // If there are no questions, open the second modal
        setOpen2(true);
      }
    }
  };

  const [selectedQuestionList, setSelectedQuestionList] = useState([]);

  const [formData, setFormData] = useState({
    answers: [], // Store answers as an array of objects
  });

  const handleInputChange = (e, questionId) => {
    const newAnswers = { ...formData.answers };
    newAnswers[questionId] = { questionId, answer: e.target.value };
    setFormData({ answers: newAnswers });
  };
  const checkUnansweredMandatoryQuestions = () => {
    const unansweredMandatoryQuestions = selectedQuestionList.filter(
      (question) => {
        const answer = formData.answers[question._id];
        const isMandatory = question.isMandatory === true;
        const isStarred = question.isStarred === true;
        const condition1 = isMandatory && isStarred;
        const condition2 = isMandatory && !isStarred;
        const isUnanswered = !answer || answer.answer.trim() === "";
        return (condition1 || condition2) && isUnanswered;
      }
    );

    return unansweredMandatoryQuestions;
  };
  const handleSubmitOne = async () => {
    try {
      // Create an array of answers based on the questions
      const answers = sortedQuestions.map((question) => ({
        questionId: question._id,
        answer: formData.answers[question._id]?.answer || "",
      }));

      const unansweredMandatoryQuestions = checkUnansweredMandatoryQuestions();

      if (unansweredMandatoryQuestions.length > 0) {
        toast.error("Please answer all mandatory questions.");
      } else {
        axios
          .post(`${process.env.PUBLIC_URL}/xakal/add-answer/${selectedId}`, {
            answers: answers, // Send the answers array
          })
          .then(() => {
            setFormData({
              ...formData,
              answers: Array(formData.answers.length).fill(""),
            });
          })
          .catch((err) => {
            console.error(err);
          });

        // Handle the response here as needed
        setOpen(false);
        setOpen2(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [error2, setError2] = useState("");
  const [file, setFile] = useState(null);
  const handleFileChnage = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ["application/pdf", "application/msword"];

    if (
      selectedFile &&
      selectedFile.size <= fileSizeLimit &&
      allowedFileTypes.includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setError2("");
    } else {
      // Display an error message or take any other action
      setError2(
        "File Size should not exceed 2mb and PDF,Word doc only allowed."
      );
    }
  };
  const handleSubmitTwo = () => {
    // debugger;
    if (error2 === "") {
      const formData1 = new FormData();
      formData1.append("image", file);

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData1)
        .then((res) => {
          if (res.data) {
            const params = {
              url: res.data.url ? res.data.url : "",
            };

            axios
              .post(
                `${process.env.PUBLIC_URL}/xakal/apply/${selectedId}`,
                params
              )
              .then(() => {
                setOpen2(false);
                setOpen3(true);
                // setSelectedId(selectedId);
                fetchData();
              })
              .catch((error) => {
                console.error("Error updating item:", error);
              });
          }
        })
        .catch(() => {});
    } else {
      setError2(
        "File Size should not exceed 2mb and PDF,Word doc only allowed."
      );
    }
  };
  const [dataOne, setDataOne] = useState();
  const [dataOneResume, setDataOneResume] = useState();

  const fetchData = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/jobs/${selectedId}/applicants/questions`
      )
      .then((response) => {
        // Handle the response data here, e.g., set it in state or a variable.
        const questionList = response.data.job.questionList;
        const sortedQuestionList = questionList.sort((a, b) => {
          return b.starred - a.starred;
        });
        setDataOne(sortedQuestionList);
        setDataOneResume(response.data);
        // Continue with the rest of your logic, for example, call setOpen2 and setOpen3.
        setOpen2(false);
        setOpen3(true);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };
  const [open4, setOpen4] = useState(false);
  const [userId, setUser] = useState({});

  useEffect(() => {
    // Define a function to fetch data

    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);

    // Call the fetchData function when the component mounts
  }, []);
  const handleWithdrawClick = async (jobId) => {
    // Make an API request to update the statusOne field to "withdrawn"
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setUserAppliedJob((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(jobId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== jobId
            );
          } else {
            return [...prevSavedInterviews, jobId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      setOpen4(false);
      setData(() => {
        return [];
      });

      setIsOpen(false);
      getAllJob();
      toast.success("Job withdrawn successfully");
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const handleWithdrawPopUpClick = (jobId) => {
    // Make an API request to update the statusOne field to "withdrawn"
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`)
      .then(() => {
        // Handle the response if needed
        getAllJob();
        setOpen4(false);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("Error withdrawing job:", error);
      });
  };
  const [open5, setOpen5] = useState(false);

  const currentDate = new Date();
  const [jobWithdrawId, setWithdrawIdToDelete] = useState(null);

  const toggleUpdateFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      setActiveCard(null);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdatePopFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      setActiveCard(null);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [openSetting, setOpenSetting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);

  const [userApplied, setUserApplied] = useState(false);
  const [favouriteApplied, setFavouriteApplied] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setActiveCard(jobData._id);
    const applicant = jobData.applicants.find(
      (applicant) => applicant.userId === userId.userId
    );
    const userApplied =
      (applicant && applicant.statusOne === "Applied") ||
      (applicant && applicant.statusOne === "shortlist") ||
      (applicant && applicant.statusOne === "engage") ||
      (applicant && applicant.statusOne === "interviewed") ||
      (applicant && applicant.statusOne === "offered");

    // Determine if the user has favorited this job
    const favourite = jobData.favoritedBy.find(
      (fav) => fav.userId === userId.userId
    );
    const favouriteApplied =
      favourite && favourite.statusFavourite === "favourites";

    setUserApplied(userApplied);
    setFavouriteApplied(favouriteApplied);

    setIsOpen(true);
  };
  const sortedQuestions = selectedQuestionList.slice().sort((a, b) => {
    if (a.isMandatory && a.isStarred && (!b.isMandatory || !b.isStarred)) {
      return -1; // A comes before B if A is both mandatory and starred, and B is not
    } else if (
      b.isMandatory &&
      b.isStarred &&
      (!a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is both mandatory and starred, and A is not
    } else if (
      !a.isMandatory &&
      a.isStarred &&
      (b.isMandatory || !b.isStarred)
    ) {
      return -1; // A comes before B if A is not mandatory but starred, and B is mandatory or not starred
    } else if (
      !b.isMandatory &&
      b.isStarred &&
      (a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is not mandatory but starred, and A is mandatory or not starred
    } else {
      return 0; // No change in order for other cases
    }
  });

  const currentYear = currentDate.getFullYear();

  const formattedYear = currentYear.toString();
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const handleSave = (data) => {
    setPopupOpen(false); // Close the dialog if needed
  };
  const [isScrolled, setIsScrolled] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop =
  //       window.pageYOffset || document.documentElement.scrollTop;
  //     setIsScrolled(scrollTop > 170);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const [dataValue, setDataValue] = useState();
  const getOneDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((response) => {
        setDataValue(response.data.aboutOne);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getOneDetail();
  }, []);
  return (
    <div>
      <ToastContainer />
      <CustomHeaderLayout />
      <div className="job-card-container job-card-containers" style={{ paddingBottom: "30px" }}>
        {loading ? (
      <div style={{ top: "160px",
        position: "relative"} }>
           {Array.from({ length: 4 }).map((_, index) => (
           <ShimmerLoading key={index} />
         ))}
        </div>
        ) : data.length > 0 ? (
          data.map((i) => {
            const applicant = i.applicants.find(
              (applicant) => applicant.userId === userId.userId
            );

            // eslint-disable-next-line no-unused-vars
            const userApplied =
              applicant &&
              (applicant.statusOne === "Applied" ||
                applicant.statusOne === "shortlist" ||
                applicant.statusOne === "engage" ||
                applicant.statusOne === "interviewed" ||
                applicant.statusOne === "offered");
            const favourite = i.favoritedBy.find(
              (fav) => fav.userId === userId.userId
            );

            const favouriteApplied =
              favourite && favourite.statusFavourite === "favourites"
                ? true
                : false;
            return (
              <div className="int-rows">
                <CardWrapper
                  className={isOpen ? "job-card-wrap" : "job-card-wrapper"}
                  onClick={() => openPopup(i)}
                >
                  <div
                    className={activeCard === i._id ? "underline" : ""}
                  ></div>
                  <div>
                    <ContentWrapper
                      className="job-content-wrapper"
                      onClick={() => openPopup(i)}
                    >
                      <div
                        className="company-logo-container"
                        onClick={() => openPopup(i)}
                      >
                        {i.companyName && i.companyName.companyLogo ? (
                          <img
                            src={i.companyName && i.companyName.companyLogo}
                            alt="img"
                            className="company-logo"
                            onClick={() => openPopup(i)}
                          />
                        ) : (
                          <img
                            src={Image}
                            alt="img"
                            className="company-logo"
                            onClick={() => openPopup(i)}
                          />
                        )}
                      </div>
                      <Wrapper
                        className="job-title-wrapper"
                        onClick={() => openPopup(i)}
                      >
                        <JobTitle
                          className="job-title"
                          key={i._id}
                          onClick={() => openPopup(i)}
                        >
                          {i.jobTitle}
                        </JobTitle>

                        <companyTitle className="company-title">
                          {i.companyName && i.companyName.value}
                        </companyTitle>
                        <p className="timestamp" onClick={() => openPopup(i)}>
                          <TimeAgo timestamp={i.timestamps?.createdAt} />
                        </p>
                      </Wrapper>
                      <DateWrapper className="bookmark-btn-container date-toggle-wrapper">
                        {favouriteApplied || savedInterviews.includes(i._id) ? (
                          <div onClick={() => toggleUpdateFavoriteOne(i._id)}>
                            <img
                              src={iBookmarkActive}
                              className="bookmark-icon"
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div onClick={() => toggleFavorite(i._id)}>
                            <img
                              src={iBookmark}
                              className="bookmark-icon"
                              alt="icon"
                            />
                          </div>
                        )}
                      </DateWrapper>
                    </ContentWrapper>
                    <HrLines className="" />
                    <div
                      onClick={() => openPopup(i)}
                    >
                      <JobDetail className="job-details2">
                        <IconTitle className="icon-title">
                          <img src={iExp} className="jt-icon" alt="icon" />
                          <span>
                            {" "}
                            Exp{" "}
                            {i.experienceRequired2
                              ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                              : i.experienceRequired
                                ? `${i.experienceRequired} yrs`
                                : ""}{" "}
                          </span>
                        </IconTitle>

                        <IconTitle className="icon-title">
                          <img src={iSalary} className="jt-icon" alt="icon" />
                          <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                        </IconTitle>
                        <IconTitle className="icon-title">
                          <img src={iLocation} className="jt-icon" alt="icon" />
                          <span>
                            {i.location?.substring(0, 8)}
                            {i.location?.length > 8 ? "..." : ""}
                          </span>
                        </IconTitle>

                        <IconTitle className="icon-title">
                          <img src={iHome} className="jt-icon" alt="icon" />
                          <span>{i.workMode}</span>
                        </IconTitle>
                      </JobDetail>
                    </div>
                  </div>
                </CardWrapper>
              </div>
            );
          })
        ) : (
          <img src={noData} className="nodata-center" alt="icon" />
        )}
      </div>
      {isOpen && selectedJobData ? (
        <div className={isScrolled ? "job-app-wrap" : "job-app-wraps"}>
          <DialogContent style={{ padding: "0" }}>
            <ViewJobApplicant
              jobData={selectedJobData}
              setIsOpen={() => {
                setIsOpen(false);
                setActiveCard(null);
              }}
            />
          </DialogContent>
          <div
            className="job-prev-btn"
          >
            {favouriteApplied ||
            savedInterviews.includes(selectedJobData._id) ? (
              <>
                <img
                  src={Favourites}
                  alt="img"
                  style={{
                    width: "30px",
                    height: "30px",
                    padding: "4px",
                    margin: "10px",
                    background: "#fff",
                    border: "1px solid rgba(221, 223, 228, 0.50)",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    toggleUpdatePopFavoriteOne(selectedJobData._id)
                  }
                />
              </>
            ) : (
              <img
                src={Favourite}
                alt="img"
                style={{
                  width: "30px",
                  height: "30px",
                  padding: "4px",
                  margin: "10px",
                  background: "#fff",
                  border: "1px solid rgba(221, 223, 228, 0.50)",
                  borderRadius: "8px",
                }}
                onClick={() => togglePopUpFavorite(selectedJobData._id)}
              />
            )}

            {userApplied || userAppliedJob.includes(selectedJobData._id)? (
              <>
                <Button3
                  className="job-btn"
                  onClick={() => {
                    setWithdrawIdToDelete(selectedJobData._id);
                    setOpen4(true); // Open the modal when Delete is clicked
                  }}
                >
                  Withdraw
                </Button3>
              </>
            ) : (
              <Button3
                onClick={() =>
                  handleApplyClick(
                    selectedJobData._id,
                    selectedJobData.questionList,
                    selectedJobData.applyThrough,
                    selectedJobData.urls
                  )
                }
              >
                Apply
              </Button3>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Assessment Questions
          </PopUpTitle>
        </DialogTitle>
        <DialogContent>
          {sortedQuestions
            .filter((question) => question.questionName.trim() !== "")
            .map((question) => (
              <div key={question.questionId}>
                <div>
                  <QuestionLabel htmlFor={`answer_${question._id}`}>
                    {question.questionName}
                    {question.isMandatory && (
                      <span className="mandatory-star">*</span>
                    )}
                  </QuestionLabel>

                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      id={`answer_${question._id}`}
                      name={`answer_${question._id}`}
                      value={
                        formData.answers[question._id]
                          ? formData.answers[question._id].answer
                          : ""
                      }
                      onChange={(e) => handleInputChange(e, question._id)}
                      style={{
                        borderRadius: "5px",
                        background: "rgba(54, 42, 65, 0.04)",
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}

          <DateWrapperButton>
            <button className="button5" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button
              className="button3"
              onClick={() => handleSubmitOne(selectedId)}
            >
              Next
            </button>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle className="modal-title">
          <PopUpTitle className="modal-title-sub">
            Document Submission
          </PopUpTitle>
        </DialogTitle>
        <DialogContent>
          <PopUpTitle className="modal-title-sub">Resume</PopUpTitle>

          <InputGroup className="mb-3">
            <Form.Control
              className="custom-file-upload"
              type="file"
              onChange={handleFileChnage}
              style={{
                borderRadius: "5px",
                background: "rgba(54, 42, 65, 0.04)",
              }}
            />
          </InputGroup>

          <span className="text-danger">{error2}</span>
          <DateWrapperButton>
            <Button5 className="button5" onClick={() => setOpen2(false)}>
              Cancel
            </Button5>
            <button
              onClick={handleSubmitTwo}
              disabled={!file}
              className="button3"
            >
              Next
            </button>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open3}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <PopUpTitle>Application Preview</PopUpTitle>
        </DialogTitle>
        <DialogContent>
          <div>
            {dataOne ? (
              <>
                {dataOne &&
                  dataOne.map((question, index) => {
                    const userAnswers = question.answers.filter(
                      (answer) => answer.userId === userId.userId
                    );

                    // Check if there are any user answers
                    if (userAnswers.length > 0) {
                      const lastUserAnswer =
                        userAnswers[userAnswers.length - 1];

                      return (
                        <>
                          <PopUpTitle>{`${index + 1}. ${
                            question.questionName
                          }`}</PopUpTitle>
                          <div>
                            <PopUpTitle>{`Answer: ${
                              lastUserAnswer.answer || "No answer provided"
                            }`}</PopUpTitle>
                          </div>
                        </>
                      );
                    }
                  })}
              </>
            ) : (
              ""
            )}
          </div>

          <PopUpTitle>Documents Submitted</PopUpTitle>

          <div>
            <a
              href={dataOneResume ? dataOneResume.resume : "No resume provided"}
              target="_blank"
              rel="noreferrer"
            >
              {`${dataValue && dataValue.firstName} ${
                dataValue && dataValue.lastName
              }_resume_${formattedYear}`}
            </a>
          </div>
          <DateWrapperButton>
            <Button5 onClick={() => setOpen(false)}>Cancel</Button5>
            <Button3 type="submit" onClick={handleFunctionClose}>
              Submit
            </Button3>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open4}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "rgba(255, 218, 224, 1)",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            fontFamily: "Inter-Medium",
          }}
        >
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={DeleteImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            Are you sure you want to withdraw ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                No Thanks
              </Button4>
              <Button6 onClick={() => handleWithdrawClick(jobWithdrawId)}>
                Proceed
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>

        {/* <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <PopUpTitle>Application withdrawal</PopUpTitle>
          <AiOutlineCloseCircle onClick={() => setOpen4(false)} />
          <img src={CloseIcon} alt="img" onClick={() => setOpen4(false)} />
        </DialogTitle>
        <hr />
        <DialogContent>
          <Text>Are you sure you want to withdraw ?</Text>

          <div className="modalFooter" style={{padding:"10px 20px"}}>
            <button
              className="button5"
              onClick={() => setOpen4(false)}
              style={{ cursor: "pointer" }}
            >
              No
            </button>
            <button
              className="button3"
              onClick={() => handleWithdrawClick(jobWithdrawId)}
            >
              Yes
            </button>
          </div>
        </DialogContent> */}
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open5}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <PopUpTitle>Application withdrawal</PopUpTitle>
          <AiOutlineCloseCircle onClick={() => setOpen5(false)} />
        </DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to withdraw your Application?</Text>

          <DateWrapperButton>
            <Button5
              onClick={() => setOpen4(false)}
              style={{ cursor: "pointer" }}
            >
              No
            </Button5>
            <Button3 onClick={() => handleWithdrawPopUpClick(jobWithdrawId)}>
              Yes
            </Button3>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isPopupOpen}
        halfWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span className="mTitle">Job</span>
          {/* <AiOutlineCloseCircle onClick={() => setPopupOpen(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <FitMe onSave={handleSave} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default JobApplied;
