import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Common from "../utils/common";
import { Amplify, Auth } from "aws-amplify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MdOutlineLogout } from "react-icons/md";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import fHome from "../images/mb-home.svg";
import fHomeActive from "../images/mb-home-active.svg";
import fSuitcase from "../images/mb-suitcase.svg";
import fSuitcaseActive from "../images/mb-suitcase-active.svg";
import fSettings from "../images/mb-settings.svg";
import fSettingsActive from "../images/mb-settings-active.svg";
import profile from "../images/profile-image.svg";
import axios from "axios";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});

const MobileFooter = () => {
  const [user, setUsers] = useState("");
  const [openSetting, setOpenSetting] = useState(false);
  const [success, setSuccess] = useState();
  const [jobLink, setjobLink] = useState(false);
  const history = useHistory();
  const [image, setImage] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const logout = () => {
    Common.logout();
  };
  const DateWrapperButton = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  `;
  const Button9 = styled.div`
    background-color: #362a41;
    border-radius: 10px;
    border: 1px solid #362a41;
    font-weight: normal;
    font-size: 18px;
    color: white;
    padding: 13px 45px;
    margin: 15px 0;
    cursor: pointer;
    &:hover {
      background: white;
      color: #362a41;
      border: 1px solid #362a41;
    }
  `;
  const Button8 = styled.div`
    background-color: white;
    border-radius: 10px;
    border: 1px solid #362a41;
    font-weight: normal;
    font-size: 18px;
    color: #362a41;
    padding: 13px 45px;
    margin: 15px 0;
    cursor: pointer;
    &:hover {
      background: #362a41;
      color: white;
      border: 1px solid #362a41;
    }
  `;
  const Text = styled.p`
    font-family: Inter-Medium, sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 29px;
    letter-spacing: 0em;
    position: relative;
    text-align: center;
  `;
  function onSubmit2() {
    setSuccess(true);
  }
  function goChangePassword() {
    setOpenSetting(false);
    history.push("/portal/changepassword");
  }

  const { pathname } = useLocation();
  // function isActive (){
  //  return ['portal/favourite', 'portal/job-applied', 'portal/apply-jobs'].includes(pathname)
  // }

  history.listen((location, action) => {
    // console.log(`The current route is ${location.pathname}`);
    // console.log(location.pathname.split("/"))
    if (
      location.pathname.split("/").includes("applicants") ||
      location.pathname.split("/").includes("applicant-details")
    ) {
      setjobLink(true);
    } else {
      setjobLink(false);
    }
  });

  const getAboutDetail = () => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`
      )
      .then((res) => {
        setImage(res.data.aboutOne.avatar);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAboutDetail();
  }, []);
  return (
    <>
      <div className="mobile-footer o-mobile">
        <div className="mb-row">
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/interview/exploreInterview`,
              }}
              isActive={() =>
                [
                  "/portal/interview/exploreInterview",
                  "/portal/interview/bookedInterview",
                  "/portal/interview/savedInterview",
                ].includes(pathname)
              }
              className="mb-link"
            >
              <img src={fHome} className="mb-link-img default" alt="" />
              <img src={fHomeActive} className="mb-link-img active" alt="" />
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/jobs`,
              }}
              isActive={() =>
                [
                  "/portal/jobs",
                  "/portal/favorites",
                  "/portal/applied-jobs",
                ].includes(pathname)
              }
              className={`${jobLink ? "active" : ""} mb-link`}
            >
              <img src={fSuitcase} className="mb-link-img default" alt="" />
              <img
                src={fSuitcaseActive}
                className="mb-link-img active"
                alt=""
              />
            </NavLink>
          </div>
          <div>
            <NavLink
              style={{ width: "12rem" }}
              to={{
                pathname: `/portal/my-profile`,
              }}
              isActive={() => ["/portal/my-profile"].includes(pathname)}
              className={`${jobLink ? "active" : ""} mb-link`}
            >
              <img
                src={image ? image : profile}
                className="mb-link-img default img-mobile"
                alt=""
              />
              <img
                src={image ? image : profile}
                className="mb-link-img active img-mobile active"
                alt=""
              />
            </NavLink>
          </div>
          <div>
            <button onClick={() => setOpenSetting(true)}>
              <NavLink
                style={{ width: "12rem" }}
                to={{
                  pathname: ``,
                }}
                isActive={() =>
                  [
                    "/portal/changepassword",
                    "/portal/forget-password",
                    "/portal/otp-changed",
                    "/portal/new-password",
                  ].includes(pathname)
                }
                className="mb-link"
              >
                <img src={fSettings} className="mb-link-img default" alt="" />
                <img
                  src={fSettingsActive}
                  className="mb-link-img active"
                  alt=""
                />
              </NavLink>
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <span className="mTitle mt-0">Setting</span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </div>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={goChangePassword}>Change Password</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit2}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      {/*  */}
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogContent>
          <Text className="modal-title-sub">
            Are You Sure You Want To Log Out?
          </Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MobileFooter;
