import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select, { components } from "react-select";
import softskill from "../MyProfile/desgination.json";
import Plus from "../../images/plus.svg";
import "./buttonStyle.css";
import { ToastContainer, toast } from "react-toastify";
import PlacesAutocomplete, {
} from "react-places-autocomplete";
import { Spinner } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
const MainLayout = styled.div`
  // margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  margin: 0rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #362a41;
  font-weight: normal;
  text-transform: capatilize;
  line-height: 17px;
  margin: 10px 0 5px 0;
`;
const options2 = [
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];
const options3 = [
  { value: "Internship", label: "Internship" },
  { value: "PartTime", label: "PartTime" },
  { value: "FullTime", label: "FullTime" },
  { value: "Temporary", label: "Temporary" },
  { value: "Contract", label: "Contract" },
  { value: "Freelance", label: "Freelance" },
];
const CaretDownIcon = () => {
  return (
    <img
      src={Plus}
      alt="img"
      style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
    />
  );
};
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
const FitMe = ({ onSave }) => {
  const [jobTitle, setJobTitle] = useState([]);
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState([]);
  const [workMode, setWorkMode] = useState([]);
  const [employeeType, setEmployeeType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobTitleError, setJobTitleError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [locationError, setLocationError] = useState("");
  const [workModeError, setWorkModeError] = useState("");
  const [employeeTypeError, setEmployeeTypeError] = useState("");

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    // Validation checks
    let isValid = true;

    if (jobTitle.length === 0) {
      setJobTitleError("Please select at least one job title");
      isValid = false;
    } else {
      setJobTitleError("");
    }

    if (!experience || isNaN(experience) || experience <= 0) {
      setExperienceError("Please enter a valid experience");
      isValid = false;
    } else {
      setExperienceError("");
    }

    if (locations.length === 0) {
      setLocationError("Please enter at least one location");
      isValid = false;
    } else {
      setLocationError("");
    }

    if (workMode.length === 0) {
      setWorkModeError("Please select at least one work mode");
      isValid = false;
    } else {
      setWorkModeError("");
    }

    if (employeeType.length === 0) {
      setEmployeeTypeError("Please select at least one employment type");
      isValid = false;
    } else {
      setEmployeeTypeError("");
    }

    // If validation passes, proceed with the API call
    if (isValid) {
      const formData = {
        jobTitle: jobTitle,
        experience: experience,
        location: locations,
        workMode: workMode,
        employeeType: employeeType,
      };

      axios
        .post(`${process.env.PUBLIC_URL}/xakal/find-my-job`, formData)
        .then((response) => {
          if (response.data.success) {
            console.log(response, "add form");
            setJobTitle([...jobTitle]);
            setWorkMode([...workMode]);
            setEmployeeType([...employeeType]);
            setLocation([...location]);
          }
          onSave();
          setLoading(false);
          toast.success(
            "Job fit has been activated. We will get back to you with a perfect job match."
          );
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          toast.error("Error while saving. Please try again.");
        });
    } else {
      setLoading(false);
    }
  };

  const handleEmployeeChnage = (e) => {
    setEmployeeType(Array.isArray(e) ? e.map((x) => x.value) : []);
    setEmployeeTypeError("");
  };
  const handleWorkModeChnage = (e) => {
    setWorkMode(Array.isArray(e) ? e.map((x) => x.value) : []);
    setWorkModeError("");
  };
  const optionsOne = softskill.designation.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const handleExperienceChange = (e) => {
    // Use a regular expression to allow only numbers
    const inputValue = e.target.value;
    const numbersOnly = inputValue.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setExperience(numbersOnly);
    setExperienceError("");
  };
 
  const [locations, setLocations] = useState([]);

  const handleSelect = async (value) => {
    setLocations([...locations, { value }]);
    setLocation([]);
  };

  const handleLocationRemove = (valueToRemove) => {
    const updatedLocations = locations.filter(
      (location) => location.value !== valueToRemove
    );
    setLocations(updatedLocations);
  };
  const handleDesignation = (selectedOptions) => {
    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((x) => x.value)
      : [];
    setJobTitle(selectedValues);
    setJobTitleError("");
    // Not sure where setDirty comes from in your code
  };
  const getDesignationSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getDesignSpec`)
      .then((response) => {
        console.log(JSON.stringify(response), "skilldata");
        const namesArray = response.data.map((item) => item.value);
        setDesignOne(
          namesArray.map((skill) => ({
            value: skill,
            label: skill,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getDesignationSkill();
  }, []);
  const [designOne, setDesignOne] = useState([]);
  // useEffect(() => {
  //   setDesignOne(
  //     designaSkill.map((e) => ({
  //       skillIds: e.skillId,
  //       label: e.label,
  //       value: e.value,
  //     }))
  //   );
  // }, [designaSkill]);
  const [designCreate, setDesignCreate] = useState("");
  const createDesignSkill = () => {
    console.log("Entering createDesignSkill");
    const payload = {
      name: designCreate,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/designationCreate`, payload)
      .then((response) => {
        console.log(JSON.stringify(response.data), "designation");
        setDesignCreate("");
        getDesignationSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCreatable = async (e) => {
    console.log("creaable", e);
    if (e[e?.length - 1]?.__isNew__) {
      await createDesignSkill();
    }
    handleDesignation(e);
    console.log("creatable options", optionsOne);
  };
  return (
    <div>
      <MainLayout>
        <ToastContainer />

        <PageWrapper>
          <div className="modalBody">
            {/* <form onSubmit={handleSubmit}> */}
            <Label>Job Titles<span className="text-danger">*</span></Label>
            <div>
              <CreatableSelect
                isMulti
                components={{ Placeholder, DropdownIndicator }}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    // height:"30px",
                    margin: "2px",
                    padding: "5px 10px",
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                    borderRadius: "8px",
                    width: "100%",
                    height: "max-content",
                  }),
                }}
                name="jobTitle"
                placeholder="Select"
                onChange={(e) => handleCreatable(e)}
                onInputChange={(e) => setDesignCreate(e)}
                options={designOne}
                value={designOne.filter((e) => jobTitle.includes(e.value))}
                isClearable={true}
                className="font"
              />
            </div>
            {jobTitleError && (
              <div className="error-message">{jobTitleError}</div>
            )}
            <Label>Experience<span className="text-danger">*</span></Label>
            <div>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter your experience"
                  className="font"
                  aria-label="Job Title"
                  name="experience"
                  value={experience}
                  onChange={handleExperienceChange}
                  style={{ background: "#F4F4F6", height: "48px" }}
                />
              </InputGroup>
            </div>
            {experienceError && (
              <div className="error-message">{experienceError}</div>
            )}
            <Label>Location</Label>

            <div>
              <PlacesAutocomplete
                value={location}
                onChange={setLocation}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Enter your location of work",
                        className: "location-search-input",
                      })}
                      style={{
                        width: "100%",
                        paddingLeft: "10px",
                      }}
                      className="footer-header font"
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              marginTop: "10px",
                              marginBottom: "10px",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              height: "fit-content",
                              marginTop: "10px",
                              cursor: "pointer",
                              fontSize: "12px",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              style={{
                                color: "#362A41", // Add hover text color
                                padding: "10px",
                                background: "#C6C6C6",
                                borderRadius: "6px",
                                width: "calc(100% - 6px)",
                                height: "fit-content",
                                margin: "0px 0px 0px 0px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <>
                {locations.map((location) => (
                  <div style={{ position: "relative", display: "flex" }}>
                    <span
                      style={{
                        background: "#F4E8FF",
                        borderRadius: "6px",
                        padding: "5px 10px",
                        margin: "5px",
                      }}
                    >
                      {location.value}

                      <button
                        style={{ marginLeft: "5px" }}
                        type="button"
                        onClick={() => handleLocationRemove(location.value)}
                      >
                        x
                      </button>
                    </span>
                  </div>
                ))}
              </>
            </div>

            <Label>Work Mode<span className="text-danger">*</span></Label>
            <div>
              <Select
                className="font"
                components={{ Placeholder, DropdownIndicator }}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    // height:"30px",
                    margin: "0px",
                    padding: "5px 10px",
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                  }),
                }}
                name="workMode"
                value={options2.filter((obj) => workMode.includes(obj.value))}
                onChange={handleWorkModeChnage}
                isSearchable={true}
                options={options2}
                placeholder="Enter the work mode"
                isMulti={true}
              />
            </div>
            {workModeError && (
              <div className="error-message">{workModeError}</div>
            )}
            <Label>Employment Type<span className="text-danger">*</span></Label>
            <div>
              <Select
                className="font"
                components={{ Placeholder, DropdownIndicator }}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    // height:"30px",
                    margin: "0px",
                    padding: "5px 10px",
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                  }),
                }}
                menuPlacement="top"
                name="employeeType"
                value={options3.filter((obj) =>
                  employeeType.includes(obj.value)
                )}
                onChange={handleEmployeeChnage}
                isSearchable={true}
                options={options3}
                isMulti={true}
                placeholder="Enter the employment type"
              />
            </div>
            {employeeTypeError && (
              <div className="error-message">{employeeTypeError}</div>
            )}
            {/* </form> */}
          </div>
        </PageWrapper>
        <div
          className="modalFooter"
          // style={{
          //   display: "flex",
          //   position: "relative",
          //   justifyContent: "flex-end",
          //   gap: "30px",
          //   marginTop: "10px",
          // }}
        >
          <button
            className="button5"
            onClick={() => {
              onSave();
            }}
          >
            Cancel
          </button>
          <button className="button3" onClick={handleSubmit}>
            {loading ? <Spinner /> : "Save"}
          </button>
        </div>
      </MainLayout>
    </div>
  );
};

export default FitMe;
