/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import "./ats.css";
import Dialog from "@mui/material/Dialog";
import { DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import InterviewEvent from "./interviewEvent";
import OfferedEvent from "./offered";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "../../images/company.png";
import Engage from "./engage";
import Reject from "./reject";
import AtsProfile from "./view-ats-profile";
import DownArrow from "../../images/Downarrow.svg";
import Location from "../../images/Group 1530.svg";
import { BsCreditCard2Front } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";
import { Auth } from "aws-amplify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Common from "../../utils/common";
import CloseIcon from "../../images/CloseIcon.svg";
import DesktopHeader from "../desktopHeader";

const customStyle = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    border: "none",
    height: "auto",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    marginTop: "10px",
    fontFamily: "Inter-Medium,sans-serif",
    "@media (min-width: 1320px)": {
      minWidth: "320px",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
    overflowX: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 8px)",
      marginLeft: "4px",
    },
  }),
  // Add more styles as needed
};

const AppCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: -5px;
  cursor: move;
  border-right: 1px solid #1eb4dd;
  border-left: 1px solid #1eb4dd;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
`;
const ShortCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: -5px;
  cursor: move;
  border-right: 1px solid #e5b738;
  border-left: 1px solid #e5b738;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const Card = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: -5px;
  cursor: move;
  border-right: 1px solid #36bf6d;
  border-left: 1px solid #36bf6d;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
`;
const Cards = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: -5px;
  cursor: move;
  border-right: 1px solid #da4452;
  border-left: 1px solid #da4452;
  border-height: 100px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CardText = styled.div`
  width: 125px;
  position: relative;
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #202020;
  background: #f7f7f7;
  border-radius: 12px;
  height: 38px;
  margin-top: 7px;
  padding-top: 7px;
  @media (max-width: 500px) {
    width: 350px;
    background: #f7f7f7;
    position: relative;
    left: 10px;
    justify-content: center;
  }
`;

const DragContainer = styled.div`
  min-height: 80vh;
  height: max-content;
  margin: 0 0px 0px 0px;
  border-radius: 12px;

  @media (max-width: 500px) {
    width: 360px;
    display: flex;
    flex-direction: row;
  }
`;
const Hr = styled.div`
  width: 97px;
  border: 1px solid #f6f6f6;
  position: relative;
  margin-bottom: 7px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 9px;
  justify-content: center;
  margin: auto;
  background: #f6f6f6;
  padding: 0 0px 20px 0px;
  @media (max-width: 500px) {
    display: grid;
    position: relative;
    justify-content: center;
    overflow: hidden;
  }
    @media (max-width:1024px){
      overflow:scroll;
      justify-content: space-around;
    }
`;

const BoxContainer = styled.div`
  width: 140px;
  margin: 0 0 0 10px;
  position: relative;
  @media (max-width: 500px) {
    width: 130px;
    display: "flex-start";
  }
`;

const SectionWrapper = styled.div`
  margin: 20px 0px 20px -5px;
  display: flex;
  justify-content: center;
  gap: 35px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 1460px) {
    margin: 20px 0px 20px -28px;
    gap: 40px;
  }
`;
const HeaderLayout = styled.div`
  position: fixed;
  top: 0;
  right:0px;
  width: 85%;
  border: 1px;
  height: 185px;
  background: white;
  z-index: 999;
  @media (max-width:1024px){
    width:78%;
    right:0;
  }
`;
const SerachSection = styled.div`
  width: 483px;
  height: 50px;
  top: 8px;
  left: 53%;
  border: 1px solid whitesmoke;
  border-radius: 10px;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin-left: 39px;
  color: black;
  font-family: Inter-Medium, sans-serif, sans-serif;
  font-size: 20px;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;
const Text = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 24px;
  color: #362a41;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
`;
const TrackApplicationsComponent = () => {
  const [tasks, setTasks] = useState([]);

  const [interviewPopup, setInterviewPopup] = useState(false);
  const [offeredPopup, setOfferedPopup] = useState(false);
  const [engagePopup, setEngagePopup] = useState(false);
  const [reject, setReject] = useState(false);
  const [data, setData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    getAllApplied();
  }, [setSelectedOption]);

  const onDragStart = (
    evt,
    status,
    jobTitle,
    firstName,
    jobId,
    applicantId,
    jobTitles,
    previousStatus
  ) => {
    let element = evt.currentTarget;
    element.classList.add("dragged");

    // Include jobTitle, firstName, and jobId in the data object
    evt.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        userId: evt.currentTarget.id,
        jobTitle,
        firstName,
        jobId,
        applicantId,
        jobTitles,
        previousStatus,
      })
    );
    evt.dataTransfer.effectAllowed = "move";
  };

  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };

  const onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };

  const onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };

  const onDrop = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));

    const jobId = data.jobId;
    const applicantId = data.applicantId;
    if (data.previousStatus === "Applied") {
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/apply-shortlist/${jobId}/${applicantId}`
        )
        .then((res) => {
          toast.success("Application Shortlisted Successfully");
          getAllApplied();
        })
        .catch((error) => {
          console.error("Shortlist error", error);
          toast.error("Error shortlisting application");
        });
    }

    setData({ applicantId });
  };

  const onDropEngage = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));
    const userId = data.userId;
    const jobId = data.jobId;
    const firstName = data.firstName;
    const applicantId = data.applicantId;
    const jobTitle = data.jobTitle;
    if (data.previousStatus === "shortlist") {
      setEngagePopup(true);
    }

    setData({ jobId, applicantId, userId, firstName, jobTitle });
  };
  const onDropInterviewed = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));
    const userId = data.userId;
    const jobId = data.jobId;
    const applicantId = data.applicantId;
    const jobTitles = data.jobTitles;
    if (data.previousStatus === "engage") {
      setInterviewPopup(true);
    }

    setData({ jobId, applicantId, userId, jobTitles });
  };
  const onDropOffered = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));
    const userId = data.userId;
    const jobId = data.jobId;
    const applicantId = data.applicantId;
    const jobTitles = data.jobTitles;
    const firstName = data.firstName;

    if (data.previousStatus === "interviewed") {
      setOfferedPopup(true);
    }
    setData({ jobId, applicantId, userId, firstName, jobTitles });
  };
  const onDropHired = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));
    const jobIds = data.jobId;
    const applicantIds = data.applicantId;
    const jobTitles = data.jobTitles;

    if (data.previousStatus === "offered") {
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/apply-hired/${jobIds}/${applicantIds}`,
          { jobTitles: jobTitles }
        )
        .then((res) => {
          toast.success("Application Hired Successfully");
          getAllApplied();
        })
        .catch((error) => {
          console.error("Shortlist error", error);
          toast.error("Error shortlisting application");
        });
    }
  };
  const onDropReject = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = JSON.parse(evt.dataTransfer.getData("text/plain"));
    const userId = data.userId;
    const jobId = data.jobId;
    const applicantId = data.applicantId;
    const jobTitles = data.jobTitles;
    const firstName = data.firstName;
    if (data.previousStatus === "engage") {
      setReject(true);
    } else if (data.previousStatus === "shortlist") {
      setReject(true);
    } else if (data.previousStatus === "interviewed") {
      setReject(true);
    } else if (data.previousStatus === "offered") {
      setReject(true);
    } else if (data.previousStatus === "Applied") {
      setReject(true);
    }
    setData({ jobId, applicantId, userId, firstName, jobTitles });
  };
  const [atsOpen, setAtsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [success, setSuccess] = useState(false);
  const [user, setUsers] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openSetting, setOpenSetting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        setUsers(null);
      });
  }, []);
  const logout = () => {
    Common.logout();
  };
  function onSubmit() {
    setSuccess(true);
  }
  const [tagNames, setTagNames] = useState([]);
  const filterOption = tasks.map((company) => ({
    value: company.jobTitle,
    label: company.jobTitle,
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [selectedTitle, setSelectedTitle] = useState("Select designations");
  const getAllApplied = useCallback(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-app-all`)
      .then((response) => {
        if (response.data) {
          const allJobs = response.data.jobs;

          // Use functional form of setSelectedOption to ensure latest state value
          setSelectedOption((prevSelectedOption) => {
            const selectedCompany = prevSelectedOption?.value;

            const filteredJobs = allJobs.filter(
              (job) => job.companyName.value === selectedCompany
            );
            setTasks(filteredJobs);
            const allApplicants = allJobs.flatMap((job) => job.applicants);
            const allTags = allApplicants.reduce((tags, applicant) => {
              tags.push(applicant.tag);
              return tags;
            }, []);
            const uniqueTags = Array.from(new Set(allTags));

            const tagOptions = uniqueTags
              .filter((tagName) => tagName !== undefined)
              .map((tagName) => ({
                value: tagName,
                label: tagName,
              }));

            setTagNames(tagOptions);

            // Return the updated selectedOption
            return prevSelectedOption;
          });
        }
      })
      .catch((err) => console.error(err));
  }, [setSelectedOption]);

  // Usage of getAllApplied remains the same

  // eslint-disable-next-line no-unused-vars
  const [selectedValue, setSelectedValue] = useState("All");
  const [selectedTag, setSelectedTag] = useState("Tags");

  const handleTagChange = (selectedOption) => {
    setSelectedTag(selectedOption.value);
  };
  const handleTitleChange = (selectedOption) => {
    setSelectedTitle(selectedOption.value);
  };
  const tagNamesWithAll = [
    { value: "Tags", label: "Tags" },
    { value: "All", label: "All" },
    ...tagNames,
  ];
  const tagNamesWithAllTest = [{ value: "All", label: "All" }, ...filterOption];
  const handleEngage = () => {
    setEngagePopup(false);
    getAllApplied();
  };
  const handleReject = () => {
    setReject(false);
    getAllApplied();
  };
  const handleInterviwe = () => {
    setInterviewPopup(false);
    getAllApplied();
  };
  const handleOffered = () => {
    setOfferedPopup(false);
    getAllApplied();
  };
  const [companyDetail, setCompanyDetail] = useState([]);
  const getOnePart = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company-filter`)
      .then((res) => {
        setCompanyDetail(res.data.jobs);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getOnePart();
  }, []);
  const options = companyDetail
    .sort((a, b) => a.companyName.value?.localeCompare(b.companyName.value))
    .map((company) => ({
      value: company.companyName.value,
      label: company.companyName.label,
      companyLogo: company.companyName.companyLogo,
    }));

  const [jobApply, setJobApply] = useState(false);
  const { state } = useLocation();
  useEffect(() => {
    if (state && state.selectedCompany) {
      setSelectedOption(state.selectedCompany);
    }
  }, [state]);
  const onClick = (jobTitleData, jobApply = false) => {
    setSelectedUserId(jobTitleData);
    setAtsOpen(true);
    setJobApply(jobApply);
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (options.length > 0 && !selectedOption) {
      setSelectedOption(options[0]);
    }
  }, [options, selectedOption]);
  const searchedKey = () => {};
  useEffect(() => {
    document.title = "ATS | Xakal";
  }, []);
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={props.data.companyLogo ? props.data.companyLogo : Profile}
            alt="Company Logo"
            style={{ width: "24px", marginRight: "8px" }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={props.data.companyLogo ? props.data.companyLogo : Profile}
          alt="Company Logo"
          style={{ width: "24px", borderRadius: "50%", marginRight: "8px" }}
        />
        {children}
      </div>
    </components.SingleValue>
  );
  const [pendingFeedbackData, setPendingFeedback] = useState([]);
  const getAllPending = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-pending-feedback`)
      .then((res) => {
        setPendingFeedback(res.data.book);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const provideFeedback = () => {
    history.push("/portal/pending-feedback-interview");
  };
  const [openPending, setOpenPending] = useState(false);
  useEffect(() => {
    getAllPending();
  }, []);
  useEffect(() => {
    if (pendingFeedbackData.length > 0) {
      setOpenPending(true);
    }
  }, [pendingFeedbackData]);
  return (
    <div>
      <ToastContainer />
      <Dialog
        open={atsOpen}
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div className="mTitle fw">Applicant Details</div>
          <img src={CloseIcon} alt="img" onClick={() => setAtsOpen(false)} />
        </div>
        <DialogContent>
          <AtsProfile
            closePopup={() => setAtsOpen(false)}
            data={selectedUserId}
            open={() => setAtsOpen(true)}
            jobApply={jobApply}
            selectedCompany={selectedOption}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={engagePopup}
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader mheader">
          <div className="mTitle fw">Engage</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setEngagePopup(false)}
          />
        </div>
        <DialogContent>
          <Engage
            closePopup={handleEngage}
            data={data}
            options={filterOption}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={reject}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "450px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader mheader">
          <div className="mTitle fw">Reject</div>
          <img src={CloseIcon} alt="img" onClick={() => setReject(false)} />
        </div>
        <DialogContent>
          <Reject
            closePopup={handleReject}
            data={data}
            close={() => setReject(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={interviewPopup}
        PaperProps={{
          style: {
            borderRadius: "24px",
            maxWidth: "430px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div className="mTitle fw">Schedule an interview</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setInterviewPopup(false)}
          />
        </div>
        <DialogContent>
          <InterviewEvent closePopup={handleInterviwe} data={data} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={offeredPopup}
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className="modalHeader">
          <div className="mTitle fw">Offer a job for a selected candidate</div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setOfferedPopup(false)}
          />
        </div>
        <DialogContent>
          <OfferedEvent closePopup={handleOffered} data={data} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "280px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/portal/myRequests")}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p> My Requests</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/portal/pendingRequest")}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p> Pending Request</p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>

      <div>
        <HeaderLayout>
          <DesktopHeader title={"ATS"} searchedKey={searchedKey} />

          <SectionWrapper>
            <Select
              options={tagNamesWithAllTest}
              placeholder="Select designation"
              onChange={handleTitleChange}
              value={
                selectedTitle
                  ? { value: selectedTitle, label: selectedTitle }
                  : null
              }
              styles={customStyle}
              components={{ Placeholder, DropdownIndicator }}
            />
            <Select
              name="Engage"
              search={true}
              // options={mycalendarProxy.tags}
              placeholder="Tags"
              styles={customStyle}
              components={{ Placeholder, DropdownIndicator }}
              isMulti={false}
              onChange={handleTagChange}
              options={tagNamesWithAll}
              value={
                selectedTag ? { value: selectedTag, label: selectedTag } : null
              }
            />

            <Select
              value={selectedOption}
              onChange={(selectedOption) => {
                setSelectedOption(selectedOption);
                getAllApplied();
              }}
              options={options}
              styles={customStyle}
              components={{
                Placeholder,
                DropdownIndicator,
                Option,
                SingleValue,
              }}
              placeholder="Select a company..."
            />
          </SectionWrapper>
        </HeaderLayout>
      </div>
      {/* Need this for future enchacement  */}

      {/* {openPending && (
        <div className="pending-flex">
          <p>
            You have {pendingFeedbackData.length} pending feedback. Please
            provide your feedback to receive the interview fees.
          </p>
          <button onClick={provideFeedback}>Provide Feedback</button>
          <button onClick={() => setOpenPending(false)}>X</button>
        </div>
      )} */}
      <div className="ats-card-wrap">
        <Container>
          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText>
                    {"Application"}
                    {/* <img src={Icon} alt="img"  /> */}
                  </CardText>

                  <div className="drag_row">
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "Applied" ||
                              applicant.previousStatusOne === "Applied") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <>
                            <div
                              className="App-Card-out"
                              onClick={() =>
                                onClick({
                                  jobTitleData,
                                  applicant,
                                  jobApply: true,
                                })
                              }
                            >
                              <div className="Card-outer">
                                <AppCard
                                  key={applicant?.firstName}
                                  id={jobTitleData?.createdBy.userId}
                                  draggable={
                                    applicant.statusOne !== "withdrawn" ||
                                    (applicant.statusOne === "withdrawn" &&
                                      applicant.previousStatusOne === "Applied")
                                  }
                                  className={`card-wrapper${
                                    applicant.statusOne !== "withdrawn"
                                      ? " visible"
                                      : ""
                                  }`}
                                  onDragStart={(e) =>
                                    onDragStart(
                                      e,
                                      "Applied",
                                      jobTitleData?.jobTitle,
                                      applicant?.firstName,
                                      jobTitleData?._id,
                                      applicant?._id,
                                      jobTitleData?.jobTitles,
                                      applicant.statusOne
                                    )
                                  }
                                  onDragEnd={(e) => onDragEnd(e)}
                                >
                                  <div className="card_right">
                                    <img
                                      src={
                                        applicant.aboutId.avatar
                                          ? applicant.aboutId.avatar
                                          : Profile
                                      }
                                      alt="img"
                                      className="profile-img"
                                    />
                                    <div
                                      className="status"
                                      title={applicant?.firstName}
                                    >
                                      {applicant?.firstName?.substring(0, 10)}
                                      {applicant?.firstName?.length > 10
                                        ? "..."
                                        : ""}
                                    </div>
                                    <p
                                      className="ats-job-title"
                                      title={jobTitleData?.jobTitle}
                                    >
                                      {jobTitleData?.jobTitle?.substring(0, 10)}
                                      {jobTitleData?.jobTitle?.length > 10
                                        ? "..."
                                        : ""}
                                    </p>

                                    <div
                                      className="ats-location"
                                      title={
                                        applicant.aboutId.primaryAddress?.city
                                      }
                                    >
                                      <img src={Location} alt="img" />
                                      {applicant.aboutId.primaryAddress?.city?.substring(
                                        0,
                                        10
                                      )}
                                      {applicant.aboutId.primaryAddress?.city
                                        ?.length > 10
                                        ? "..."
                                        : ""}
                                    </div>
                                  </div>
                                  <div></div>
                                </AppCard>
                              </div>
                            </div>
                          </>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>

          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDrop(e, false, "shortlist")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText>
                    {"Shortlist"}
                    {/* <img src={Icon} alt="img" style={{ marginLeft: "5px" }} /> */}
                  </CardText>
                  <div className="drag_row">
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "shortlist" ||
                              applicant.previousStatusOne === "shortlist") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="Short-Card-out"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <ShortCard
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne === "shortlist")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "shortlist",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant.statusOne
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                <div className="card_right">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </ShortCard>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>
          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDropEngage(e, false, "engage")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText>{"Engage"}</CardText>
                  <div className="drag_row">
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "engage" ||
                              applicant.previousStatusOne === "engage") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (selectedTag === "Tags" ||
                              selectedTag === "All" ||
                              applicant.tag === selectedTag) &&
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="App-Card-out"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <AppCard
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne === "engage")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "engage",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant.statusOne
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                {/* Render the card content for Interviewed status */}
                                <div className="card_right">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </AppCard>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>
          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDropInterviewed(e, false, "interviewed")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText>{"Interviewed"}</CardText>
                  <div className="drag_row">
                    {/* Mapping the interviewed applicants */}
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "interviewed" ||
                              applicant.previousStatusOne === "interviewed") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (selectedTag === "Tags" ||
                              selectedTag === "All" ||
                              applicant.tag === selectedTag) &&
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="Short-Card-out"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <ShortCard
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne ===
                                      "interviewed")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "interviewed",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant?.statusOne
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                {/* Render the card content for Interviewed status */}
                                <div className="card_right">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </ShortCard>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>
          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDropOffered(e, false, "offered")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText>{"Offered"}</CardText>
                  <div className="drag_row">
                    {/* Mapping the interviewed applicants */}
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "offered" ||
                              applicant.previousStatusOne === "offered") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (selectedTag === "Tags" ||
                              selectedTag === "All" ||
                              applicant.tag === selectedTag) &&
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="App-Card-out"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <AppCard
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne === "offered")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "offered",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant.statusOne
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                {/* Render the card content for Interviewed status */}
                                <div className="card_right">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </AppCard>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>

          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDropHired(e, false, "hired")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText style={{ background: "#E7EFE3" }}>
                    {"Hired"}
                  </CardText>
                  <div className="drag_row">
                    {/* Mapping the hired applicants */}
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "hired" ||
                              applicant.previousStatusOne === "hired") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (selectedTag === "Tags" ||
                              selectedTag === "All" ||
                              applicant.tag === selectedTag) &&
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="Card-out"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <Card
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne === "hired")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "hired",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant.statusOne
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                {/* Render the card content for Hired status */}
                                <div className="card_right">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </Card>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>
          <div
            className="order small-box"
            onDragLeave={(e) => onDragLeave(e)}
            onDragEnter={(e) => onDragEnter(e)}
            onDragEnd={(e) => onDragEnd(e)}
            onDragOver={(e) => onDragOver(e)}
            onDrop={(e) => onDropReject(e, false, "hired")}
          >
            <DragContainer>
              <BoxContainer>
                <div className="drag_column">
                  <CardText style={{ background: "#FFE4E4" }}>
                    {"Reject"}
                  </CardText>
                  <div className="drag_row">
                    {/* Mapping the hired applicants */}
                    {tasks.map((jobTitleData) =>
                      jobTitleData.applicants
                        .filter(
                          (applicant) =>
                            (applicant.statusOne === "reject" ||
                              applicant.previousStatusOne === "reject") &&
                            (selectedValue === "All" ||
                              applicant.statusOne === selectedValue) &&
                            (selectedTitle === "Select designations" ||
                              selectedTitle === "All" ||
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(selectedTitle.toLowerCase())) && // Filter by job title
                            (selectedTag === "Tags" ||
                              selectedTag === "All" ||
                              applicant.tag === selectedTag) &&
                            (applicant.firstName
                              .toLowerCase()
                              .includes(searchKeyword.toLowerCase()) || // Search by candidate first name
                              jobTitleData.jobTitle
                                .toLowerCase()
                                .includes(searchKeyword.toLowerCase()) || // Search by job title
                              (jobTitleData.jobTitles &&
                                jobTitleData.jobTitles
                                  .toLowerCase()
                                  .includes(searchKeyword.toLowerCase()))) // Check if jobTitles is defined before searching
                        )
                        .map((applicant) => (
                          <div
                            className="Card-outs"
                            onClick={() =>
                              onClick({
                                jobTitleData,
                                applicant,
                                jobApply: true,
                              })
                            }
                          >
                            <div className="Card-outer">
                              <Cards
                                key={applicant?.firstName}
                                id={applicant?.userId}
                                draggable={
                                  applicant.statusOne !== "withdrawn" ||
                                  (applicant.statusOne === "withdrawn" &&
                                    applicant.previousStatusOne === "reject")
                                }
                                className={`card-wrapper${
                                  applicant.statusOne !== "withdrawn"
                                    ? " visible"
                                    : ""
                                }`}
                                onDragStart={(e) =>
                                  onDragStart(
                                    e,
                                    "hired",
                                    jobTitleData?.jobTitle,
                                    applicant?.firstName,
                                    jobTitleData?._id,
                                    applicant?._id,
                                    jobTitleData?.jobTitles,
                                    applicant.statusOnes
                                  )
                                }
                                onDragEnd={(e) => onDragEnd(e)}
                              >
                                {/* Render the card content for Hired status */}
                                <div className="card_rights">
                                  <img
                                    src={
                                      applicant.aboutId.avatar
                                        ? applicant.aboutId.avatar
                                        : Profile
                                    }
                                    alt="img"
                                    className="profile-img"
                                  />
                                  <div
                                    className="status"
                                    title={applicant?.firstName}
                                  >
                                    {applicant?.firstName?.substring(0, 10)}
                                    {applicant?.firstName?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                  <p
                                    className="ats-job-title"
                                    title={jobTitleData?.jobTitle}
                                  >
                                    {jobTitleData?.jobTitle?.substring(0, 10)}
                                    {jobTitleData?.jobTitle?.length > 10
                                      ? "..."
                                      : ""}
                                  </p>

                                  <div
                                    className="ats-location"
                                    title={
                                      applicant.aboutId.primaryAddress?.city
                                    }
                                  >
                                    <img src={Location} alt="img" />
                                    {applicant.aboutId.primaryAddress?.city?.substring(
                                      0,
                                      10
                                    )}
                                    {applicant.aboutId.primaryAddress?.city
                                      ?.length > 10
                                      ? "..."
                                      : ""}
                                  </div>
                                </div>
                              </Cards>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </BoxContainer>
            </DragContainer>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default TrackApplicationsComponent;
