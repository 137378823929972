/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MobileHeader from "../mobileHeader";
import DesktopHeader from "../desktopHeader";
import "../Jobs/jobs.css";
import companyLogo from "../../images/company.png";
import "./interview.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import CustomHeaderInterview from "./customHeaderInterview";
import styled from "styled-components";
import noData from "../../images/NoData.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "react-bootstrap/Spinner";
import InterviewPopup from "../MyProfile/interviewPopup";
import ShimmerLoading from "./shimmerloading";
import iClock from "../../images/Alarm-icon.svg";
const Time = styled.div`
  margin-left: auto;
  font-size:14px;
  margin-top:5px;
`;

const PendingInterview = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const availableSkills = [
    "Sql",
    "UI Design",
    "UX Design",
    "Web Development",
    "Wireless Communications and Networks",
    "React JS",
    "Recruiter",
    "Sales",
    "Scaled Agile",
    "Scrum",
    "SEO",
    "snowflake",
    "Spring Boot",
    "Spring Framework",
    "NLP",
    "Node js",
    "NumPy",
    "Object-Oriented Programming (OOP)",
    "PCB Design",
    "Persuasion",
    "Postgre Sql",
    "Prompt tuning",
    "Python",
    "Hardware Development",
    "Hardware Product Development",
    "HTML",
    "Internet of Things",
    "Java Script",
    "Java",
    "Machine Learning",
    "Marketing",
    "MERN",
    "Negotiation",
    "net",
    "Docker Products and Business Requirements",
    "Electronic Circuit Design",
    "Electronics Hardware Design",
    "Embedded Systems",
    "ETL",
    "Figma",
    "finance",
    "Graphic Designing",
    "Azure DevOps",
    "Big Data",
    "Circuit Design",
    "Circuit Designer Entry Level",
    "Coaching",
    "Communication",
    "Core Java",
    "CSS",
    "Data Analytics",
    "Data Engineer",
    "Data Modeling",
    "Design",
    "Accounting",
    "Adobe",
    "Agile Methodologies",
    "AI",
    "Angular",
    "AWS",
  ];

  const settings = {
    autoplay: true,
    arrows: true, // Set arrows to true to make them visible
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
  };
  const [loading, setLoading] = useState(true);
  const [exhausted, setExhausted] = useState(true);
  const [activeCard, setActiveCard] = useState(null);
  
  const handlePlayClick = (index) => {
    const video = document.querySelector(`#video-${index}`);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const markAsActive = (id) => {
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/get-active-interview/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setData((prevData) =>
            prevData.filter((interview) => interview._id !== id)
          );
          setLoading(true); // Show loading when refreshing data
          getAllPendingInterview(true);
        }
      });
  };

  useEffect(() => {
    document.title = "Interviews | Xakal";
  }, []);

  useEffect(() => {
    getAllPendingInterview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [pagination, setPagination] = useState(1);
  const getAllPendingInterview = (refresh = false) => {
    let page = refresh ? 1 : pagination;
    const url = `${process.env.PUBLIC_URL}/xakal/get-all-pending-interview?p=${page}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          if (response.data.interview.length === 0) {
            setLoading(false);
            setExhausted(false);
          } else {
            setPagination(response.data.pageCount);
            const uniqueinterview = response.data.interview.filter(
              (job) =>
                !data.some(
                  (existinginterview) => existinginterview._id === job._id
                )
            );
            setData((current) => {
              if (current) {
                return [...current, ...uniqueinterview];
              } else {
                return [];
              }
            });
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        console.log(currentTime, "currentTime");
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
      const intervalId = setInterval(calculateTimeAgo, 60000);

      return () => {
        clearInterval(intervalId); // Cleanup interval on component unmount
      };
    }, [timestamp]);

    return <Time>{timeAgo}</Time>;
  };

  const [selectedData, setSelectedData] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const closeForm = () => {
    setOpenForm(false);
    setActiveCard(null);
  };
  const handleOpen = (data) => {
    setOpenForm(true);
    setSelectedData(data);
    setActiveCard(data._id);
  };
  const [button, setButton] = useState(false);
  useEffect(() => {
    setButton(true);
  }, []);
  return (
    <div>
      {openForm ? (
        <div className="int-previews">
          <InterviewPopup
            onPopupData={selectedData}
            onClose={closeForm}
            closeButton={button}
          />
        </div>
      ) : (
        ""
      )}
      <div className="header-layout">
        {/* Start of Mobile Header  */}
        <MobileHeader hTitle="Interviews" />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <DesktopHeader title={"Interviews"} />
        {/* End of desktop Header  */}
      </div>
      {/* {searchInputVal?.length == 0 ? ( */}
      <>
        <CustomHeaderInterview />

        {loading ? (
          <div style={{ top: "160px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : data && data?.length > 0 ? (
          <InfiniteScroll
            dataLength={data.length}
            next={getAllPendingInterview}
            hasMore={exhausted}
            loader={
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "50px",
                }}
              >
                <Spinner />
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div
              className="job-card-container int-rows"
              style={{ paddingBottom: "30px" }}
            >
              {data &&
                data.map((i, index) => {
                  return (
                    <div
                      className={
                        openForm
                          ? "job-card-wrap job-card-wrappers "
                          : "job-card-wrapper"
                      }
                    >
                      <div
                              className={
                                activeCard === i._id ? "underline underlines" : ""
                              }
                            ></div>
                      <div>
                        <div className="int-content-container">
                          <div className="int-title-row int-titles">
                            {/* <div className="pen-int-creat"> */}
                              <div className="int-creator">
                                {" "}
                                {i.createdBy && i.createdBy?.logo ? (
                                  <img
                                    src={i.createdBy.logo}
                                    alt="img"
                                    className="int-creator-img img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={companyLogo}
                                    alt="img"
                                    className="int-creator-img img-fluid"
                                  />
                                )}
                              </div>
                              <div className="int-tit-save">
                              <div className="int-details">
                                <h5
                                  className="title"
                                  onClick={() => handleOpen(i)}
                                >
                                  <div className="profile-name">
                                      <span className="pro-name">
                                        {" "}
                                        {i.interviewTitle?.substring(0, 15)}
                                        {i.interviewTitle?.length > 15 ? "..." : ""}
                                      </span>
                                      {i.interviewTitle?.length > 12 ? (
                                        <p className="prof-name">{i.interviewTitle}</p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                </h5>

                                <div>
                                  <span className="int-cre-lite">by</span>
                                  <span
                                    className="int-cre-title"
                                    onClick={() =>
                                      history.push(
                                        `/portal/other-profile/${i?.createdBy?.userId}`
                                      )
                                    }
                                  >
                                    {i?.createdBy?.firstName}
                                  </span>
                                  {/* <TimeAgo timestamp={i.timestamps.createdAt} /> */}
                                </div>
                                
                              </div>
                              <div className="ver-line"></div>
                              <div className="b-slot">
                                  <div>
                                    {i?.inr ? (
                                      <p className="b-price">
                                        <i className="fa fa-rupee"></i>{" "}
                                        {new Intl.NumberFormat("en-IN", {}).format(
                                          i?.inr
                                        )}
                                      </p>
                                    ) : (
                                      <p className="b-price">Free</p>
                                    )}

                                    
                                  </div>
                                  <div className="text-center">
                                    <span className="timer">
                                      <span>{i?.duration}</span>
                                      <span>
                                        {" "}
                                        <img
                                          src={iClock}
                                          className="clock-icon"
                                          alt="icon"
                                        />
                                      </span>
                                    </span>
                                  </div> 
                                </div>
                              </div>
                          </div>

                          {i?.designation.length ? (
                            <div className="prefer-wrap">
                              <div className="prefer-wraps">
                                <div className="job-preferences">
                                  Designation :
                                </div>
                                {i?.designation
                                  ?.slice(0, 3)
                                  .map((item, index) => (
                                    <div className="job-preference-pill">
                                      {item}
                                    </div>
                                  ))}
                                {i.designation.length > 3 ? (
                                  <div
                                    className="job-preference-pill"
                                    onClick={() => handleOpen(i)}
                                  >
                                    +{i.designation.length - 3}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn job-btn w-100 bs-btn"
                                  onClick={() => {
                                    markAsActive(i._id);
                                  }}
                                >
                                  Mark as Active
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </InfiniteScroll>
        ) : (
          <img src={noData} className="nodata-center" alt="icon" />
        )}
      </>
    </div>
  );
};

export default PendingInterview;
