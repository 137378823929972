import React, { useEffect, useState } from "react";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import styled from "styled-components";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../Jobs/buttonStyle.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Plus from "../../images/Normal_State_Plus.svg";
import PlusHover from "../../images/Hover_State_Plus.svg";
import Edit from "../../images/pencil.png";
import EditIcons from "../../images/edit.svg";
import { ReactComponent as BackBtn } from "../../images/backBtn.svg";
import Spinner from "react-bootstrap/Spinner";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CiSettings } from "react-icons/ci";
import Settings from "../settings";
import CloseIcon from "../../images/CloseIcon.svg";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import Delete from "../../images/delete.svg";
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
`;
const ButtonOne = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: white;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  background: #fd2145;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  height: 40px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 1px solid #764f90;
  }
`;
const Button2 = styled.div`
  border: 1px solid rgba(54, 42, 65, 0.1);
  background-color: #f6f6f6;
  border-radius: 10px;
  font-weight: normal;
  font-size: 16px;
  color: #362a41;
  padding: 13px 45px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 43px;
  margin: 1rem 10px;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Wrappers = styled.div`
  display: flex;
  gap: 20px;
  margin-left: -10px;
`;
const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba(79, 73, 83, 1);
  margin: 20px 0 0 50px;
`;
const SuperAdmin = styled.div`
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(79, 73, 83, 1);
  margin: 0 0 10px 0;
`;
const MainLayout = styled.div`
  margin: 150px 50px 30px 60px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;

const HeaderLayout = styled.div`
  position: absolute;
  top: 0;
  margin-left: 5px;
  width: calc(100% - 226px);
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;

const MainHeader = styled.div`
  font-weight: normal;
  //position: relative;
  display: flex;
  justify-content: flex-start;
  top: 40px;
  margin: auto;
  margin-left: 39px;
  color: #362a41;
  //font-family:
  //  Inter-Medium,
  //  sans-serif;
  font-size: 24px;
`;

const Heading = styled.p`
  font-size: 16px;
  font-weight: bolder;
  color: #4f4953;
`;

const Profile = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0px;
  width:48%;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 24px;
  margin: 1rem 0rem;
  padding: 0 20px;
  min-height: 100px;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  border-top: 2px solid #f7f7f7;
  padding: 12px 0;
`;
const Section = styled.div`
  display: flex;
  width:100%;
  justify-content: space-between;
  // grid-template-columns: 1fr 1fr 1fr;
  padding-top: 18px;
`;
const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 16px;
  color: white;
  padding: 13px;
  display: flex;
  justify-content: center;
  // margin: 15px 0;
  cursor: pointer;
  width: 100px;
  height: 43px;
  margin: 1rem;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;

const Label = styled.div`
  color: #4f4953;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 16.8px */
  text-transform: capitalize;
  margin: 30px 0 5px 0;
`;
const Header = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  margin-top: 15px;
`;
const DeActHead = styled.div`
  color: #362a41;
  font-family:
    Inter-Medium,
    sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  text-transform: capitalize;
`;
const DeActContent = styled.div`
  color: #4f4953;
  font-family:
    Inter-Medium,
    sans-serif;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 160%; /* 25.6px */
  margin: 20px;
`;
const JobTitle = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 0px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #40444f;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const Button5 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family:
    Inter-Medium,
    sans-serif;
  color: #2d3139;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  font-weight: normal;
  font-size: 14px;
  color: #2d3139;
  border: 1px solid #1213171a;
  padding: 12px 22px;
  width: 94px;
  height: 40px;
  // position: relative;
  top: 3px;
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;

const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fd2145;
  // position: relative;
  top: 3px;
  font-family:
    Inter-Medium,
    sans-serif;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  padding: 12px 16px;
  /* margin: 15px 0; */
  cursor: pointer;
  // &:hover {
  //   background: black;
  //   color: #764f90;
  //   border: 1px solid #764f90;
  // }
`;
const ManageAdmin = (props) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employee, setEmployee] = useState([]);
  const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useState();
  const history = useHistory();

  const getCurrentEmployees = async (id) => {
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/manage/employee`, { website: id })
      .then((response) => {

        const employeeOptions = response?.data.map((employee) => {
          const user = employee.user;
          const experience = employee.experience[0]; // Assuming you're interested in the first experience entry

          return {
            value: `${user.firstName} ${user.middleName} ${user.lastName} `,
            label: `${user.firstName} ${user.middleName} ${user.lastName} `,
            comId: experience.company.comID,
            email: user.email,
            userID: user.userId,
          };
        });

        setEmployee(employeeOptions);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    const company = props.location.state;
    getCurrentEmployees(company.website);
  }, [props]);

  const [loginId, setLoginId] = useState();
  console.log(loginId);
  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setLoginId(response.data.userId);
      })
      .catch((err) => err.error);
  };
  const [success, setSuccess] = useState(false);
  const [addAdmin, setaddAdmin] = useState(false);
  const [successOne, setSuccessOne] = useState(false);
  const handleOpen = () => {
    setaddAdmin(true);
    setUserType("");
    setSelectedEmployee("");
  };
  const [editId, setEditId] = useState(null);

  const [userType, setUserType] = useState();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedEmployee || !userType || !companyId) {
      // Display toaster message for mandatory fields
      // You can use your preferred toaster library or create your own logic to display a message
      toast.error("Please enter all mandatory details");
      return;
    }
    const formData = {
      company: selectedEmployee,
      userType: userType,
      companyId: companyId,
      userId: selectedEmployee.userID,
    };

    // If editId doesn't exist, create a new admin
    axios
      .post(
        `${process.env.PUBLIC_URL}/xakal/manage-create-admin/${companyId}`,
        formData
      )
      .then((response) => {
        console.log(response, "create admin");
        setaddAdmin(false);
        setUserType("");
        setSelectedEmployee("");
        // getAllCompanyAdmin(props.location.state.companyId);
        getOneCompanydetail(props.location.state.companyId);
        // Add the new admin to the admin list
        setLoading(true);
        // Assuming the response contains the new admin data
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  const deactivateCompany = () => {
    const checkbox = document.getElementById("confirmationCheckbox");
    if (!checkbox.checked) {
      toast.error("Please confirm deactivation by checking the checkbox");

      return;
    }
    console.log("Before axios request");
    axios
      .put(`${process.env.PUBLIC_URL}/xakal/deactivate-company/${selectedId}`)
      .then((response) => {
        console.log(response, "deactivated");
        setSuccess(false);
        GetAllCompany();
        toast.success("Company is Successfully Deactivated");
      })
      .catch((err) => {
        console.error(err); // Log any errors to the console
      });
    console.log("After axios request");
  };
  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    const formData = {
      company: selectedEmployee,
      userType: userType,
      companyId: companyId,
      userId: selectedEmployee.userID,
    };

    // If editId exists, update the existing admin's role
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/company-admin-update/${companyId}/${editId}`,
        formData
      )
      .then((response) => {
        console.log(response, "update admin");
        setSuccess(false);
        setUserType("");
        setSelectedEmployee("");
        getOneCompanydetail(props.location.state.companyId);
        setLoading(false);
        // Update the state with the updated admin's data
      })
      .catch((err) => {
        console.log(err.error);
      });
  };
  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const [admin, setAdminList] = useState([]);
  const getAllCompanyAdmin = (companyId) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/companyList/${companyId}`)
      .then((response) => {
        console.log(JSON.stringify(response), "adminemployee");
        // setAdminList(response.data.companyDomains);
        setEditId(response.data.companyDetail._id);
      })
      .catch((err) => err.error);
  };

  console.log(companyId, "props companyId");
  console.log(JSON.stringify(admin, "admin"));
  console.log(editId, "setEDitId");
  const handleEdit = (id) => {
    setSuccess(true);
    setEditId(id);
    GetOneManageAdmin(companyId, id);
    getOneCompanydetail(companyId);
  };
  const GetOneManageAdmin = (companyId, editId) => {
    axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/company-one-admin/${companyId}/${editId}`
      )
      .then((response) => {
        console.log(response, "hetOne");
        const adminData = response.data; // Assuming the response contains data for the admin to edit
        console.log(adminData, "adminData");
        // Set selectedEmployee and userType based on the adminData
        const selectedEmployeeData = {
          value: `${adminData.company.value}`,
          label: `${adminData.company.label} `,
          userId: `${adminData.userId}`,
        };
        console.log(selectedEmployeeData, "selectedEmployeeData");
        setSelectedEmployee(selectedEmployeeData);

        // Set userType based on the adminData role
        if (adminData.role.superAdmin) {
          setUserType("superAdmin");
        } else if (adminData.role.admin) {
          setUserType("admin");
        }
        // setAdminList(adminData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const DeleteManageDomain = (id) => {
    axios
      .delete(
        `${process.env.PUBLIC_URL}/xakal/company-delete/${companyId}/${id}`
      )
      .then((response) => {
        console.log(response, "response delete");
        getAllCompanyAdmin(props.location.state.companyId);
        setOpen(false)
        toast.success("Manage Admin Deleted Successfully");
      })
      .catch((err) => err.error);
  };
  const [selectedId, setSelectedId] = useState(null);
  const [dataValue, setDataValue] = useState();

  const getOneCompanydetail = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
      .then((response) => {
        console.log(response, "dataValue");
        setDataValue(response.data.data);
        setSelectedId(response.data.data.companyId);
        setAdminList(response.data.data);
      })
      .catch((err) => err.error);
  };


  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState();
  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getusercompany`)
      .then((response) => {
        console.log(JSON.stringify(response), "company value");
        setCompany(response.data.company);
      })
      .catch((err) => err.error);
  };

  useEffect(() => {
    getUser();
    GetAllCompany();
  }, []);

  const [openSetting, setOpenSetting] = useState(false);
  const openSubmit = () => {
    setOpenSetting(true);
  };

  useEffect(() => {
    const company = props.location.state.companyId;

    getOneCompanydetail(company);
  }, [props]);
  useEffect(() => {
    const company = props.location.state.companyId;
    getAllCompanyAdmin(company);

    getUser();
    setCompanyId(props.location.state.companyId);
  }, [props.location.state.companyId]);

  console.log("adminData", admin);
  const isAdmins = admin?.companyDomain?.some(
    (domain) => domain.userId === loginId && domain.role.superAdmin
  );
  const isAdminRole = admin?.companyDomain?.some(
    (domain) => domain.userId === loginId && domain.role.admin
  );
  const [adminDelete, setAdminDeleteId] = useState();
  console.log(adminDelete, "adminDelete")

  return (
    <>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >

        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />

        </DialogContent>
      </Dialog>
      <HeaderLayout>
        <MainHeader>
          {" "}
          <BackBtn
            style={{ marginRight: "24px", cursor: "pointer" }}
            onClick={() => {
              console.log("hit");
              history.push(
                `/portal/edit-companyview/${dataValue && dataValue.companyId}`
              );
            }}
          />
          {dataValue && dataValue.companyName}
        </MainHeader>
        <SeparteDiv>
          <div
            style={{ position: "relative", margin: "auto 0", right: "90px" }}
          >
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>
      <ToastContainer />

      <MainLayout>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Header>Manage Admin</Header>
          </div>

          <div>
            <button className="button2" onClick={handleOpen}>
              <img
                src={PlusHover}
                alt="img"
                className="plus-icon2"
                style={{ margin: "0 5px 0 0px" }}
              />
              <img
                src={Plus}
                alt="img"
                className="plus-icon"
                style={{ margin: "0 5px 0 0px" }}
              />
              Add Admin
            </button>
          </div>
        </div>
        <PageWrapper>
          <Section>
            <div>
              <Heading>Profile</Heading>
            </div>
            <div>
              <Heading style={{ marginLeft: "2.5rem" }}>Roles</Heading>
            </div>
            <div>
              <Heading style={{ marginRight: "3rem" }}>Actions</Heading>
            </div>
          </Section>

          {admin &&
            admin.companyDomain?.map((admin, index) => {
              const isSuperAdmin = admin?.role?.superAdmin === true;
              const isAdmin = admin?.role?.admin === true;

              return (
                <Wrapper key={index}>
                  <Profile>
                    <div>
                      <Label>{admin?.company?.value}</Label>
                    </div>
                  </Profile>
                  <div style={{ width: "42%" }}>
                    {isSuperAdmin && <Label>Super Admin</Label>}
                    {isAdmin && !isSuperAdmin && <Label>Admin</Label>}
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    <>
                      {isAdmins && (
                        <>
                          <img src={Delete} alt="img"
                            // style={{ fontSize: 24 }}
                            onClick={() => {
                              setAdminDeleteId(admin._id)
                              setOpen(true)
                            }}
                          />
                          <img src={EditIcons} alt="img"
                            style={{ marginLeft: "10px" }}
                            onClick={() => handleEdit(admin._id)}
                          />
                        </>
                      )}

                      {isAdminRole && !isAdmins && !admin?.role?.superAdmin && (
                        <>
                          <img src={Delete} alt="img"
                            // style={{ fontSize: 24 }}
                            onClick={() => {
                              setAdminDeleteId(admin._id)
                              setOpen(true)
                            }}
                          />
                          <img src={Edit} alt="img"
                            // style={{ fontSize: 24 }}
                            onClick={() => handleEdit(admin._id)}
                          />
                        </>
                      )}
                    </>
                  </div>
                </Wrapper>
              );
            })}
        </PageWrapper>
      </MainLayout>

      <Dialog open={addAdmin} fullWidth maxWidth="sm">
        <DialogTitle
          className=" modalHeader"
          style={{ margin: "20px 20px 0px 20px" }}

        >
          <div className="mTitle" style={{ marginTop: "0px" }}>
            Add Admin
          </div>
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setaddAdmin(false)}
            style={{ cursor: "pointer" }}
          />
        </DialogTitle>

        <DialogContent>
          <div className="modalBody" style={{ paddingTop: "0px" }}>
            {" "}
            <Label>Select Employee</Label>
            <div>
              <Select
                class="footer-header  mb-3"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    width: "100%",
                    height: "48px",
                    backgroundColor: "#F4F4F6",
                    border: "1px solid #F4F4F6",
                    borderRadius: "8px",
                    fontSize: "12px",
                  }),
                }}
                name="employeeName"
                placeholder="Select employee"
                options={employee}
                value={selectedEmployee}
                onChange={(selectedOption) => {
                  setSelectedEmployee(selectedOption);
                  // Set the selected company ID when an option is selected
                }}
              />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px 0 10px 0",
                }}
              >
                <FormControlLabel
                  label="Super Admin"
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      id="superAdmin"
                      name="userType"
                      value="superAdmin"
                      checked={userType === "superAdmin"}
                      onChange={handleUserTypeChange} />} />
              </div>
              <Wrappers>
                <Dot style={{ width: "9px", height: "5px" }}></Dot>
                <SuperAdmin>
                  Super admin have full control over xakal page, Including the
                  ability to add and remove other admin and edit all page
                  settings.
                </SuperAdmin>
              </Wrappers>
              <Wrappers>
                <Dot style={{ marginTop: "10px" }}></Dot>
                <SuperAdmin>They can deactivate the college page </SuperAdmin>
              </Wrappers>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <FormControlLabel
                  label="Admin"
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      id="admin"
                      name="userType"
                      value="admin"
                      checked={userType === "admin"}
                      onChange={handleUserTypeChange} />} />
              </div>
              <Wrappers>
                <Dot style={{ width: "9px", height: "5px" }}></Dot>
                <SuperAdmin>
                  Admin have full control over xakal page, Including the ability
                  to add and remove other admin and edit all page settings.
                </SuperAdmin>
              </Wrappers>
              <Wrappers>
                <Dot style={{ marginTop: "10px" }}></Dot>
                <SuperAdmin>They can’t deactivate the college page </SuperAdmin>
              </Wrappers>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonWrapper>
            <Button2 onClick={() => setaddAdmin(false)}>Cancel</Button2>
            <Button3 onClick={handleSubmit}>
              {" "}
              {loading ? <Spinner /> : "Save"}
            </Button3>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
      <Dialog open={success} fullWidth maxWidth="sm">
        <div
          className="modalHeader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            fontSize: "18px",
            fontweight: "normal",
            color: "#362A41",
            position: "relative",
            padding: "20px",
            borderBottom: "0.5px solid #F6F6F6",
          }}
        >
          Edit Admin
          <img
            src={CloseIcon}
            alt="img"
            onClick={() => setSuccess(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {companyId && editId && (
          <>
            <DialogContent>
              <div className="modalBody" style={{ paddingTop: "0px" }}>
                {" "}
                <Label>Select Employee</Label>
                <div>
                  <Select
                    class="footer-header  mb-3"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        width: "100%",
                        height: "48px",
                        backgroundColor: "#F4F4F6",
                        border: "1px solid #F4F4F6",
                        borderRadius: "8px",
                        fontSize: "12px",
                      }),
                    }}
                    name="employeeName"
                    placeholder="Select employee"
                    options={employee}
                    value={selectedEmployee}
                    onChange={(selectedOption) => {
                      setSelectedEmployee(selectedOption);
                      // Set the selected company ID when an option is selected
                    }}
                  />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "20px 0 10px 0",
                    }}
                  >
                    <FormControlLabel
                      label="Super Admin"
                      control={
                        <Checkbox
                          inputProps={{ 'aria-label': 'controlled' }}
                          id="superAdmin"
                          name="userType"
                          value="superAdmin"
                          checked={userType === "superAdmin"}
                          onChange={handleUserTypeChange} />} />
                  </div>
                  <Wrappers>
                    <Dot style={{ width: "9px", height: "5px" }}></Dot>
                    <SuperAdmin>
                      Super admin have full control over xakal page, Including
                      the ability to add and remove other admin and edit all
                      page settings.
                    </SuperAdmin>
                  </Wrappers>
                  <Wrappers>
                    <Dot style={{ marginTop: "10px" }}></Dot>
                    <SuperAdmin>
                      They can deactivate the college page{" "}
                    </SuperAdmin>
                  </Wrappers>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <FormControlLabel
                      label="Admin"
                      control={
                        <Checkbox
                          inputProps={{ 'aria-label': 'controlled' }}
                          id="admin"
                          name="userType"
                          value="admin"
                          checked={userType === "admin"}
                          onChange={handleUserTypeChange} />} />
                  </div>
                  <Wrappers>
                    <Dot></Dot>
                    <SuperAdmin>
                      Admin have full control over xakal page, Including the
                      ability to add and remove other admin and edit all page
                      settings.
                    </SuperAdmin>
                  </Wrappers>
                  <Wrappers>
                    <Dot style={{ marginTop: "10px" }}></Dot>
                    <SuperAdmin>
                      They can’t deactivate the college page{" "}
                    </SuperAdmin>
                  </Wrappers>
                </div>
              </div>
            </DialogContent>

            <div className="modalFooter" style={{ padding: "0 20px" }}>
              <>
                <Button2 onClick={() => setSuccess(false)}>Cancel</Button2>
                <Button3 onClick={handleSubmitUpdate}>
                  {loading ? <Spinner /> : "Update"}
                </Button3>
              </>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        open={successOne}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { borderRadius: "20px", margin: "0px", width: "auto" },
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            color: "#362A41",
            fontSize: "18px",
            fontweight: "normal",
          }}
        >
          Deactivate Page
          {/* <AiOutlineCloseCircle onClick={() => setSuccess(false)} /> */}
          <img src={CloseIcon} alt="img" onClick={() => setSuccessOne(false)} />
        </DialogTitle>

        <DialogContent>
          <div style={{ padding: "20px" }}>
            <DeActHead>We're really sorry for your decision</DeActHead>
            <DeActContent>
              Deactivation will remove the page entirely from Xakal and your
              company admins will no longer will have the access to the page
            </DeActContent>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "20px",
              position: "relative",
              marginBottom: "32px",
              gap: "10px",
            }}
          >
            <FormControlLabel
              label="I hereby confirm that I deactivate the Company page"
              control={
                <Checkbox
                  id="confirmationCheckbox" />} />
          </div>
        </DialogContent>
        <DialogActions
          style={{ boxShadow: "0px -4px 16px 0px rgba(0, 0, 0, 0.08)" }}
        >
          <div
            style={{
              position: "relative",
              display: " flex",
              justifyContent: "right",
              gap: "20px",
              marginTop: "20px",
              marginRight: "16px",
            }}
          >
            <button onClick={() => setSuccessOne(false)} className="button5">
              Cancel
            </button>
            <ButtonOne onClick={() => deactivateCompany(selectedId)}>
              Deactivate
            </ButtonOne>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        PaperProps={{
          style: {
            borderRadius: "16px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#FFDAE0",
            padding: "5px 10px",
            // height: "56px",
          },
        }}
        halfWidth
        maxWidth="md"
      // className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            // position: "relative",
            left: "5px",
            top: "-3px",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img src={DeleteImg} alt="img" style={{ marginLeft: "7px" }} />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
              position: "relative",
              top: "-6px",
              color: "#40444F",
            }}
          >
            Are you sure you want to delete this role?
          </JobTitle>
          <DialogActions>
            <div style={{ position: "relative", display: "flex", gap: "10px" }}>
              <Button5
                onClick={() => setOpen(false)}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Button5>
              <Button6 onClick={() => DeleteManageDomain(adminDelete)}>
                Yes,Delete
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ManageAdmin;
