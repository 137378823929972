import * as React from "react";
import { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
const StatusOne = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  img {
    width: 29px;
    height: 25px;
    cursor: pointer;
  }
`;
const InterviewList = ({ totalCount, newCount, data, yesterday }) => {
  const [filterDate, setFilterDate] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const convertToCustomFormat = (isoDate) => {
    const parts = isoDate.split("-");
    const year = parts[0].slice(-2);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (filterDate) {
      const formattedFilterDate = convertToCustomFormat(filterDate);
      console.log(formattedFilterDate, "formattedFilterDate");
      const filtered = data.filter((item) => {
        const itemDate = item?.timestamps?.createdAt.split(",")[0];

        return itemDate === formattedFilterDate;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [filterDate, data]);

  const columns = [
    {
      field: "serviceName",
      headerName: "Interview Name",
      width: 200,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      width: 200,
      renderCell: (params) => <div>{params.value ? params.value : "-"}</div>,
    },
    {
      field: "paid",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <StatusOne>
          <div className={params.value === "paid" ? "sta-green" : "sta-red"}>
            {params.value === "paid" ? "Completed" : "Failed"}
          </div>
        </StatusOne>
      ),
    },
    {
      field: "timestamps",
      headerName: "Date",
      width: 200,
      renderCell: (params) => (
        <div>{params.value ? params.value.createdAt.split(",")[0] : "-"}</div>
      ),
    },
  ];

  return (
    <div className="interview-list-container">
      <div>
        <div className="date-filter">
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </div>
        <DataGrid
          sx={{
            height: "50vh",
            borderRadius: "10px",
            color: "#49454F",
            fontSize: "14px",
            width: "75%%",
            marginLeft: "3rem",
          }}
          md={{ color: "#49454F", fontSize: "14px" }}
          getRowId={(row) => row._id}
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </div>
      <div>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: totalCount, label: "Total Slot Book" },
                { id: 1, value: newCount, label: "New Slot" },
                { id: 2, value: yesterday, label: "Yesterday Slot" },
              ],
            },
          ]}
          width={400}
          height={200}
        />
      </div>
    </div>
  );
};

export default InterviewList;
