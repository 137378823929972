/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import "../../styles/myprofile.css";
import companyLogo from "../../images/company.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Common from "../../utils/common";
import Back from "../../images/Back.svg";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import Calender from "../../images/Calendar.svg";
import Download from "../../images/Download Minimalistic.svg";
import Video from "../../images/Videocamera.svg";
import Clock from "../../images/clock-circle.svg";
import { toast } from "react-toastify";
import { debounce } from "lodash";
const Name = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
  margin-top: 8px;
  width: 180px;
  display: flex;
  flex-wrap: wrap;
`;
const Host = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #a8a1ac;
`;
const Duration = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #4f4953;
  margin-top: 10px;
`;
const Button = styled.div`
  width: 224px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #c6cad2;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: #362a41;
  font-weight: normal;
  margin-top: 10px;
`;
const SlotInterview = ({
  openFirst,
  slotId,
  close,
  inputId,
  jobId,
  jobApply,
}) => {
  const history = useHistory();
  const [desc, setDesc] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("");
  const [time, setTime] = useState();
  const [toTime, setToTime] = useState([]);
  const [resumeUrl, setResumeUrl] = useState("");
  const [useProfileResume, setUseProfileResume] = useState(!!resumeUrl);
  useEffect(() => {
    // Check if resumeUrl is available and update the useProfileResume state
    setUseProfileResume(!!resumeUrl);
  }, [resumeUrl]);

  // ... (existing code)

  const handleCheckboxChange = () => {
    setUseProfileResume(!useProfileResume);
    // If unchecking the checkbox, keep the resumeUrl value
    if (!useProfileResume) {
      setFile(null); // Clear the file state when unchecking the checkbox
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [serId, setServId] = useState();
  useEffect(() => {
    if (slotId) {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-one-booking/${slotId}`)
        .then((response) => {
          setTime(response.data.bookingSlot?.time);
          setName(response.data.bookingSlot.serviceName);
          setServId(response.data.bookingSlot?.serviceId);
          setDuration(response.data.bookingSlot.duration);
          setDesc(response.data.bookingSlot.description);
          if (response.data && response.data.bookingSlot.unselectedTimeSlots) {
            setToTime(response.data.bookingSlot.unselectedTimeSlots);
          }
          const isoDate = response.data.bookingSlot.date;

          // Convert ISO 8601 date to JavaScript Date object
          const dateObject = new Date(isoDate);

          // Format the date as "MMM DD YYYY"
          const formattedDate = dateObject.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
          setDate(formattedDate);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [slotId]);
  const [input, setInput] = useState();
  const prefillValue = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${id}`)
      .then((response) => {
        if (response && response.data && response.data.success) {
          setInput({
            ...input,
            userId: response.data.aboutOne.userId,
            id: response.data.aboutOne._id,
            firstName: response.data.aboutOne.firstName,
            lastName: response.data.aboutOne.lastName,
            middleName: response.data.aboutOne.middleName,
            email: response.data.aboutOne.email,
            bio: response.data.aboutOne.bio,
            city: response.data.aboutOne.city,
            state: response.data.aboutOne.state,
            avatar: response.data.aboutOne.avatar,
            country: response.data.aboutOne.country,
            language: response.data.aboutOne.language,
            proficiency: response.data.aboutOne.proficiency,
            about: response.data.aboutOne.about,
            gender: response.data.aboutOne.gender,
            workProfile: response.data.aboutOne.workProfile,
            urlLink: response.data.aboutOne.urlLink,
            phoneNumber: response.data.aboutOne.phoneNumber,
            inputList: response.data.aboutOne.inputList,
            inputList2: response.data.aboutOne.inputList2,
            inputList3: response.data.aboutOne.inputList3,
            createdDate: response.data.aboutOne.createdDate,
          });
        }
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.PUBLIC_URL
          }/xakal/get-about-detail/${Common.loggedIn()}`
        );
        const aboutOne = response.data.aboutOne;

        if (aboutOne && aboutOne.inputList3 && aboutOne.inputList3.length > 0) {
          const resumeUrlFromApi = aboutOne.inputList3[0].resume;
          setResumeUrl(resumeUrlFromApi);
        } else {
          setResumeUrl(""); // Set to an empty string if no resumeUrl is available
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [input?.userId]);

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile.size > 5 * 1024 * 1024) {
      toast.error("File size exceeds 5MB");
      return;
    }

    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ]; // Supported file types: doc, docx, pdf // Add supported file types here
    if (!allowedFileTypes.includes(selectedFile.type)) {
      toast.error("Unsupported file format");
      return;
    }

    setFile(selectedFile);
    setUseProfileResume(false); // Uncheck the checkbox when a new file is selected
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const updateValue = async () => {
    if (file) {
      let selectedFileUrl = "";

      const formData = new FormData();
      formData.append("image", file);

      // Upload the file
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/files/`,
        formData
      );

      selectedFileUrl = response.data.url;
      const payload = {
        description: desc,
        resume: selectedFileUrl,
      };

      try {
        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/get-update-time/${slotId}`,
          payload
        );

        if (response.data.success) {
          console.log(response, "respo");
          const orderSlot_id = response.data.bookingSlotUpdate._id;

          history.push({
            pathname: `/portal/order-summary/${orderSlot_id}`,
            state: { jobApply: jobApply, jobId: jobId, input: input },
          });
          close();
        }

        console.log(response, "response");
      } catch (err) {
        console.log(err);
      }
    } else {
      const payload = {
        description: desc,
        resume: resumeUrl,
      };

      try {
        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/get-update-time/${slotId}`,
          payload
        );

        if (response.data.success) {
          console.log(response, "respo");
          const orderSlot_id = response.data.bookingSlotUpdate._id;
          // history.push(
          //  `/portal/order-summary/${orderSlot_id}`,

          // );
          history.push({
            pathname: `/portal/order-summary/${orderSlot_id}`,
            state: { jobApply: jobApply, jobId: jobId, input: input },
          });
          close();
        }

        console.log(response, "response");
      } catch (err) {
        console.log(err);
      }
    }

    // Update the values in the database
  };
  useEffect(() => {
    prefillValue(inputId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const googleDocsViewerUrl = "https://docs.google.com/viewer?url=";
 
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    if (file) {
      setIframeSrc(URL.createObjectURL(file));
    } else if (resumeUrl) {
      setIframeSrc(resumeUrl);
    }
  }, [file, resumeUrl]);
  return (
    <>
      <ToastContainer />
      <div className="modalHeader" style={{ justifyContent: "flex-start" }}>
        <div style={{ padding: "20px" }}>
          <img
            src={Back}
            alt="img"
            onClick={openFirst}
            style={{ position: "absolute", left: "10px" }}
          />
          <div
            className="mTitle"
            style={{ position: "absolute", left: "50px", top: "5px" }}
          >
            {name} Interview
          </div>
        </div>
      </div>
      <div className="modalBody">
        <div>
          <div className="imageProd">
            <img
              src={input?.avatar ? input?.avatar : companyLogo}
              alt="ava"
              className="imageData"
              style={{ width: "48px", height: "48px" }}
            />
            <div>
              <Name>
                {" "}
                {`${input?.firstName ? input?.firstName : ""} ${
                  input?.middleName ? input?.middleName : ""
                } ${input?.lastName ? input?.lastName : ""}`}
              </Name>
              <Host>Interviewer</Host>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          <div style={{ marginTop: "10px" }}>
            <div className="icon-part">
              {/* <IoIosTime size={30} color="grey" /> */}
              <img src={Clock} alt="img" style={{ marginTop: "5px" }} />
              <Duration>{duration}</Duration>
            </div>
            <div className="icon-part">
              {/* <IoIosVideocam size={30} color="grey" /> */}
              <img src={Video} alt="img" style={{ marginTop: "5px" }} />
              <Duration>Video Call Interview</Duration>
            </div>
            <div className="icon-part">
              {/* <MdOutlineCalendarMonth size={30} color="grey" /> */}
              <img src={Calender} alt="img" style={{ marginTop: "5px" }} />
              <Duration>{`${time}`}</Duration>
              {toTime.length > 0 ? (
                <Duration> {`${toTime[0]}`}</Duration>
              ) : (
                <Duration>-</Duration>
              )}
              <Duration>{`${date}`}</Duration>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "-55px",
              border: "1px solid #f4f4f6",
              borderRadius: "8px",
              padding: "5px 10px 5px 5px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <iframe
                  src={iframeSrc}
                  title="Resume"
                  style={{
                    width: "140px",
                    height: "180px",
                    margin: "10px",
                    background: "#F4F4F4",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                />
              </div>
              <div style={{ margin: "5px 0 15px 15px" }}>
                <Duration style={{ marginLeft: "15px" }}>
                  Add a resume for the interviewer
                </Duration>
                <Duration
                  style={{
                    margin: "15px 0 0 20px",
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={useProfileResume}
                    onChange={handleCheckboxChange}
                    disabled={!resumeUrl}
                    style={{ width: "24px", height: "24px" }}
                  />{" "}
                  <span>Use resume in my profile</span>
                </Duration>
                <div
                  style={{
                    margin: "15px 70px 0 70px",
                    color: "#c6cad2",
                    fontFamily: "Inter-Medium,sans-serif",
                    fontStyle: "italic",
                    fontweight: "normal",
                  }}
                >
                  -------or-------
                </div>
                <div>
                  <Button
                    type="button"
                    onClick={handleButtonClick}
                    style={{ cursor: "pointer" }}
                  >
                    {/* <AiOutlineUpload />  */}
                    <img src={Download} alt="img" />
                    Upload Resume
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    accept=".pdf, .doc, .docx"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Duration>
            Please share anything that will help the interview prepare for the
            interview
          </Duration>
        </div>
        <textarea
          value={desc}
          id="description"
          name="description"
          onChange={(e) => setDesc(e.target.value)}
          style={{
            background: "#F4F4F6",
            borderRadius: "8px",
            width: "100%",
            height: "90px",
            padding: "10px",
          }}
        />
      </div>

      <div className="modalFooter">
        <button className="button5" onClick={() => close()}>
          Cancel
        </button>

        <button
          className="button3"
          onClick={() => updateValue()}
          disabled={!useProfileResume && !file}
          style={{ opacity: !useProfileResume && !file ? 0.5 : 1 }}
        >
          Checkout
        </button>
      </div>
    </>
  );
};

export default SlotInterview;
