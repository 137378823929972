import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { components } from "react-select"
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import axios from "axios";
import { Dialog } from '@mui/material';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "93%",
    border: "none",
    height: "48px",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    margin:"10px 20px",
    fontFamily: "Inter-Medium,sans-serif",
    color: "rgba(198, 202, 210, 1)",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
    overflowX: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 8px)",
      marginLeft: "4px",
    },
  }),
  // Add more styles as needed
};
const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px;
  margin: 15px 0;
  cursor: pointer;
  display:flex;
  justify-content:center;
  width:100px;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const EngageHead = styled.div`
color: #362A41;
font-family: Inter-Medium,sans-serif;
text-align:left;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 19.2px */
padding-left:20px;
span{
  display:block;
}
`
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Tag = styled.div`
color: #4F4953;
font-family: Inter-Medium,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 16.8px */
text-transform: capitalize;
`
const Text = styled.div`
font-family: Inter-Medium;
font-size: 24px;
font-weight: normal;
line-height: 29px;
letter-spacing: 0em;
position: relative;
text-align: left;
`;
const DateWrapperButton = styled.div`
display: flex;
justify-content: flex-end;
gap: 20px;
margin-top:20px;
`;

const Engage = ({ data, closePopup, options }) => {
  const [select, setSelect] = useState(data.jobTitle);
  const [tag, setTags] = useState("");

  const handleChangeTag = (e) => {
    setTags(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      userId: data.userId,
      jobTitle: select,
      tag: tag,
    };

    axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/engage/${data.jobId}`, payload)
      .then((res) => {
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-engage/${data.jobId}/${data.applicantId}`,
            { jobTitle: select }
          )
          .then((res) => {
            toast.success("Applicant status updated to engage");
            closePopup();
          })
          .catch((error) => {
            console.error("Error updating status to Interviewed", error);
            toast.error("Error updating applicant status to engage");
          });
      });
  };

  const handleOneSubmit = (e) => {
    e.preventDefault();

    const payload = {
      userId: data.userId,
      jobTitle: select,
      tag: tag,
    };

    axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/engage/${data.jobId}`, payload)
      .then((res) => {
        axios
          .put(
            `${process.env.PUBLIC_URL}/xakal/apply-thanks-engage/${data.jobId}/${data.applicantId}`,
            { jobTitle: select }
          )
          .then((res) => {
            toast.success("Applicant status updated to engage");
            closePopup();
          })
          .catch((error) => {
            console.error("Error updating status to Interviewed", error);
            toast.error("Error updating applicant status to engage");
          });
      });
    closePopup();
  };
  const handleLocationChange = (event) => {
    const selectedValue = event.value;

    setSelect(selectedValue);
  };
  const [open, setOpen] = useState(false);
  const openHandle = () => {
    setOpen(true);
  };
  const [datas, setDatas] = useState([]);
  const getAllJob = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-jobs`)
      .then((response) => {
        if (response.data) {
          if (response.data) {
            const extractedJobTitles = response.data.job.map((job) => ({
              value: job.jobTitle,
              label: job.jobTitle,
            }));
            setDatas(extractedJobTitles);
          }
        }
      })
      .catch((err) => err.error);
  };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <></>
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    getAllJob();
    // Set the initial value of 'select' when component mounts
    setSelect(data.jobTitle);
  }, [data.jobTitle]);
  return (
    <div>
      <ToastContainer />
      {/* <div>Engage</div> */}
      <div className='modalBody'>
      <EngageHead>You are set to engage {`${data.firstName}`}</EngageHead>

      <div style={{ margin: "20px" }}>
        <Tag>
          <label>Tag</label>
        </Tag>
        <div>
          <input
            type="text"
            name="tag"
            onChange={handleChangeTag}
            placeholder='Enter your tag'
            className='footer-header mheader'
            required
          />
        </div>
      </div>
      <div>
        <EngageHead>
          {`${data.firstName}`} is being shortlisted for the following{" "}
          <span>Job position</span>
        </EngageHead>
        <Select
          className="mb-3"
          styles={customStyles}
          components={{ Placeholder, DropdownIndicator }}
          name="jobTitle"
          value={{ value: select, label: select }}
          onChange={handleLocationChange}
          isSearchable={true}
          options={datas}
          menuPlacement='top'
        />
      </div>
      </div>
      <div className='modalFooter'>
        <button onClick={openHandle} className='button3' >Engage</button>
      </div>

      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        
        <div className='modalBody'>
          <Text>  Do you want to Notify the applicant?</Text>

          <DateWrapperButton>
            <Button8 onClick={handleOneSubmit}>No,Thanks</Button8>

            <Button9 onClick={handleSubmit}>Sure</Button9>
          </DateWrapperButton>
        </div>
      </Dialog>
    </div>
  );
};

export default Engage;
