/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "../../styles/myprofile.css";

import styled from "styled-components";
import DownArrow from "../../images/Downarrow.svg";
import Select, { components } from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { BsUpload } from "react-icons/bs";
import "./service.Popup.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CloseIcons from "../../images/solar_close-circle-bold-duotone.svg";
const ButtonLink = styled.div`
  width: 106px;
  height: 48px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  cursor: pointer;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
  img {
    width: 16px;
    height: 16px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;
const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "fit-content",
    minHeight: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const JobPreference = styled.div`
  // color: black;
  background: #f4e8ff;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  line-height: 17px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0;
  span {
    color: #ff4873;
    margin-left: 2px;
  }
`;
const DataDiv = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`;
const durationOptions = [{ value: "30 minutes", label: "30 minutes" }];
const UpdateServicePopup = ({
  onPopup,
  id,
  onCloseAll,
  previewInterviewId,
  previewId,
  onClick,
}) => {
  const [user, setUser] = useState();
  const [skill, setSkill] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [service, setService] = useState("");
  const [description, setDescription] = useState("");
  const [isService, setIsService] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataId, setIdData] = useState(null);

  const [imageURL, setImageURL] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    interviewTitle: "",
    duration: "",
    service: "",
  });

  const handleChangeTitle = (e) => {
    setTitle(e.target.value);
    setValidationErrors({ ...validationErrors, interviewTitle: "" });
  };

  const handleChangeDuration = (selectedOption) => {
    const selectedValue = selectedOption.value;
    setDuration(selectedValue);
    setValidationErrors({ ...validationErrors, duration: "" });
  };

  const handleChangeService = (e) => {
    if (!isService) {
      const enteredValue = e.target.value;

      // Check if the entered value is an integer
      const isInteger = /^\d+$/.test(enteredValue);

      if (isInteger) {
        setService(parseInt(enteredValue, 10)); // Convert string to integer
        setValidationErrors({ ...validationErrors, service: "" });
      } else {
        setValidationErrors({
          ...validationErrors,
          service: "Please enter a valid integer.",
        });
      }
    }
  };
  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const getUser = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/getuser`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getUser();
  }, []);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleAddInput = () => {
    if (designation.length < 25 && inputValue) {
      setDesignation([...designation, inputValue]);
      setInputValue("");
    } else if (designation.length >= 25) {
    }
  };
  const handleRemoveInput = (index) => {
    const updatedValues = [...designation];
    updatedValues.splice(index, 1);
    setDesignation(updatedValues);
  };
  const handleCheckboxChange = () => {
    setIsService(!isService);
    setService("");
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-one-interview/${id}`)
        .then((response) => {
          const responseData = response.data.interview;
          setIdData(responseData._id);
          setTitle(responseData.interviewTitle);
          setDuration(responseData.duration);
          setService(responseData.inr);
          setDescription(responseData.description);
          setIsService(responseData.inrService);
          const previewFilesData = responseData.image.map((file) => ({
            url: file.url,
            fileType: file.fileType,
          }));
          setPreviewFiles(previewFilesData);
          setImageURL(responseData.image);
          if (responseData && responseData.skill.length > 0) {
            const formattedSkills = responseData.skill.map((e) => ({
              skillIds: e.skillIds,
              label: e.label,
              value: e.value,
              ratings: e.ratings,
              averageSkillRating: e.averageSkillRating,
            }));

            setSkill(formattedSkills);
          }
          if (responseData && responseData.designation.length > 0) {
            setDesignation(responseData.designation);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);
  useEffect(() => {
    if (previewInterviewId) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-one-interview/${previewInterviewId}`
        )
        .then((response) => {
          const responseData = response.data.interview;
          setIdData(responseData._id);
          setTitle(responseData.interviewTitle);
          setDuration(responseData.duration);
          setService(responseData.inr);
          setDescription(responseData.description);
          setIsService(responseData.inrService);

          const previewFilesData = responseData.image.map((file) => ({
            url: file.url,
            fileType: file.fileType,
          }));
          setPreviewFiles(previewFilesData);
          setImageURL(responseData.image);
          if (responseData && responseData.skill.length > 0) {
            const formattedSkills = responseData.skill.map((e) => ({
              skillIds: e.skillIds,
              label: e.label,
              value: e.value,
              ratings: e.ratings,
              averageSkillRating: e.averageSkillRating,
            }));

            setSkill(formattedSkills);
          }
          if (responseData && responseData.designation.length > 0) {
            setDesignation(responseData.designation);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const isFormValid = () => {
    const isValid = title && duration;
    const isOne = designation.length > 0 || inputValue.trim() !== "";
    if (!isValid) {
      setValidationErrors({
        interviewTitle: title ? "" : "Please enter headline",
        duration: duration ? "" : "Please select duration",
        service: service ? "" : "Please enter service fee",
        designation: isOne ? "" : "Please enter the designation",
      });
    }

    return isValid;
  };

  const handleUpdate = async () => {
    try {
      // Prepare payload with updated images
      const payload = {
        interviewTitle: title,
        designation: designation,
        skill: skill,
        duration: duration,
        inr: service,
        inrService: isService,
        description: description,
        interviewerUserId: user?.userId,
      };

      // If there are new images, handle them
      if (imageURL.length > 0) {
        const formData = new FormData();

        // Append existing images
        imageURL.forEach((image) => {
          if (image._id) {
            formData.append("image", JSON.stringify({ _id: image._id }));
          } else {
            // Handle new images
            formData.append("image", image);
          }
        });

        // Make API call to upload new images and get their URLs
        const uploadResponse = await axios.post(
          `${process.env.PUBLIC_URL}/xakal/files/multi`,
          formData
        );

        const uploadedImageURLs = uploadResponse.data.urls;

        // Prepare updated image array with both existing and newly uploaded images
        const updatedImages = imageURL.map((image) => {
          if (image._id) {
            return image; // Keep existing images as they are
          } else {
            // Replace new images with uploaded URLs
            const uploadedURL = uploadedImageURLs.shift();
            return { url: uploadedURL, fileType: image.type };
          }
        });

        payload.image = updatedImages;
      }

      // Make API call to update the interview
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-one-interview/${dataId}`,
        payload
      );

      const updatedInterviewId = response.data.interviewUpdate._id;

      // Handle further actions after update
      onPopup();
      onCloseAll();
      previewId(updatedInterviewId.toString());
    } catch (error) {
      console.error("Error in handleUpdate:", error);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    // Make sure indexToRemove is a valid index
    if (indexToRemove >= 0 && indexToRemove < previewFiles.length) {
      const imageIdToRemove = imageURL[indexToRemove]._id; // Assuming _id is the image ID
      deleteImage(imageIdToRemove);
  
      // Create a copy of previewFiles and imageURL
      const updatedPreviewFiles = [...previewFiles];
      const updatedImageURL = [...imageURL];
  
      // Remove the file and corresponding URL at indexToRemove
      updatedPreviewFiles.splice(indexToRemove, 1);
      updatedImageURL.splice(indexToRemove, 1);
  
      // Update the state with the modified arrays
      setPreviewFiles(updatedPreviewFiles);
      setImageURL(updatedImageURL);
    }
  };
  const deleteImage = (imageId) => {
    axios
      .delete(
        `${process.env.PUBLIC_URL}/xakal/interview-image/${dataId}/image/${imageId}`
      )
      .then(() => {})
      .catch(() => {});
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const maxSize = 5 * 1024 * 1024;
    const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const allowedVideoTypes = ["video/mp4", "video/mov"];

    const validFiles = Array.from(files).filter((file) => {
      const isImage = allowedImageTypes.includes(file.type);
      const isVideo = allowedVideoTypes.includes(file.type);
      const isSizeValid = file.size <= maxSize;

      if (!isSizeValid) {
        toast.error("File size exceeds 5MB");
      } else if (!(isImage || isVideo)) {
        toast.error(`Unsupported file format - ${file.type}`);
      }

      return isSizeValid && (isImage || isVideo);
    });

    // Clear the existing preview files if there are any errors
    if (validFiles.length !== files.length) {
      setPreviewFiles([]);
      setImageURL([]);
      e.target.value = null; // Reset the input field to allow re-selection of files
      return; // Exit the function early to prevent adding invalid files
    }

    // Process valid files
    for (let i = 0; i < validFiles.length; i++) {
      const file = validFiles[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewFiles((prevImages) => [
          ...prevImages,
          {
            url: reader?.result,
            fileName: file?.name,
          },
        ]);
      };
      reader.readAsDataURL(file);
    }

    // Add the new valid files to the imageURL array
    setImageURL((prevImages) => [...prevImages, ...validFiles]);
    e.target.value = null; // Reset the input field after successful file upload
  };

  const showInvalidFileToast = (fileType, fileSize) => {
    toast.error(`Invalid file: Type - ${fileType}, Size - ${fileSize}.`);
  };
  const [skillData, setSkillData] = useState([]);

  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const companyValue = response.data.map((e) => ({
          skillIds: e.skillId,
          label: e.label,
          value: e.value,
          ratings: e.ratings,
          averageSkillRating: e.averageSkillRating,
        }));

        setSkillData(companyValue);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSkillChnage = (selectedOptions) => {
    const formattedSkills = selectedOptions.map((e) => ({
      skillIds: e.skillIds,
      label: e.label,
      value: e.value,
      ratings: e.ratings,
      averageSkillRating: e.averageSkillRating,
    }));

    setSkill(formattedSkills);
  };
  useEffect(() => {
    getSkill();
  }, []);

  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  return (
    <div>
      <ToastContainer />
      <div className="modalBody" style={{ paddingTop: "0px" }}>
        <Label>
          Headline<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter headline"
            className="font"
            aria-label="Job Title"
            name="interviewTitle"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              height: "48px",
            }}
            value={title}
            onChange={handleChangeTitle}
          />
        </InputGroup>
        {validationErrors.interviewTitle && (
          <p style={{ color: "red" }}>{validationErrors.interviewTitle}</p>
        )}

        <Label>Designation Interviewing For</Label>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <InputGroup className="">
              <Form.Control
                placeholder="Enter Designations"
                className="font"
                aria-label="Job Title"
                name="designation"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "8px",
                  background: "#F4F4F6",
                  height: "48px",
                  position: "relative",
                  width: "250px",
                  fontweight: "normal",
                  // fontStyle: "italic",
                  color: "#362A41",
                }}
                // styles={customStyle}
                value={inputValue}
                onChange={handleInputChange}
              />
            </InputGroup>
          </div>
          <div>
            <ButtonLink
              onClick={handleAddInput}
              style={{
                cursor: "pointer",
                // opacity: inputValue ? 1 : 0.5,
                transition: "opacity 0.3s ease",
                margin: "0px 0 0 10px",
              }}
            >
              {/* <img src={Addcircle} alt="img" /> */}
              Add Designation
            </ButtonLink>
          </div>
        </div>
        {designation.map((input, index) => (
          <div
            key={index}
            style={{
              display: "inline-flex",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            <JobPreference style={{ color: "#362A41" }}>
              {input}
              {input !== "" ? (
                <button
                  onClick={() => handleRemoveInput(index)}
                  style={{ marginLeft: "10px" }}
                >
                  X
                </button>
              ) : (
                ""
              )}
            </JobPreference>
          </div>
        ))}

        <Label>Skill To be Evaluated</Label>

        <div>
          <Select
            className="font mb-3"
            placeholder="Enter skills"
            components={{ Placeholder, DropdownIndicator }}
            isMulti={true}
            name="skill"
            styles={customStyle}
            isClearable={false}
            //  defaultValue={skill}
            value={skill}
            onChange={handleSkillChnage}
            options={skillData}
          />
        </div>

        <Label>
          Duration<span>*</span>
        </Label>
        <Select
          value={durationOptions.find((option) => option.value === duration)}
          onChange={handleChangeDuration}
          components={{ Placeholder, DropdownIndicator }}
          options={durationOptions}
          placeholder="Select duration"
          className="font"
          styles={customStyle}
        />
        {validationErrors.duration && (
          <div className="f12">{validationErrors.duration}</div>
        )}

        <Label>
          Service Fee<span>*</span>
        </Label>
        <InputGroup className="mb-3">
          {isService ? null : (
            <div style={{ display: "flex", width: "100%" }}>
              <InputGroup.Text
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  fontFamily: "Inter-Medium,sans-serif",
                  fontStyle: "italic",
                  fontSize: "12px",
                  fontweight: "normal",
                  color: "#362A41",
                  background: "#F6F6F6",
                  border: "none",
                }}
              >
                INR{" "}
                <span
                  style={{
                    width: "1px",
                    fontweight: "normal",
                    padding: "0px 10px",
                  }}
                >
                  |
                </span>
              </InputGroup.Text>
              <Form.Control
                placeholder="Enter your service fee"
                className="font"
                aria-label="Job Title"
                name="inr"
                aria-describedby="basic-addon1"
                style={{
                  borderRadius: "0px 8px 8px 0px",
                  background: "#F4F4F6",
                  height: "48px",
                }}
                value={service}
                onChange={handleChangeService}
              />
            </div>
          )}
        </InputGroup>
        {!isService && validationErrors.service && (
          <p style={{ color: "red" }}>{validationErrors.service}</p>
        )}

        <DataDiv>
          <div style={{ marginTop: "4px" }}>
            <input
              type="checkbox"
              checked={isService}
              onChange={handleCheckboxChange}
            />
          </div>
          <div>
            <p> Free Service</p>
          </div>
        </DataDiv>
        <Label>Thumbnail</Label>

        <div
          style={{
            border: "2px dotted #000",
            borderRadius: "8px",
            padding: "10px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "94px",
          }}
        >
          <label style={{ position: "relative" }}>
            <div style={{ margin: "0px 0px" }}>
              <div style={{ textAlign: "center" }}>
                {/* Center the BsUpload icon */}
                <BsUpload size={24} />
                <div
                  style={{
                    margin: "0px 0",
                    color: "rgba(198, 202, 210, 1)",
                    fontFamily: "Inter-Medium,sans-serif",
                    fontStyle: "Italic",
                    fontSize: "12px",
                  }}
                >
                  Upload as png, jpg, jpeg and video mp4
                </div>
              </div>
            </div>
            <input
              type="file"
              accept="image/*, video/*"
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                opacity: "0",
                cursor: "pointer",
              }}
              className="font"
              onChange={handleImageChange}
              multiple
            />
          </label>
        </div>
        {imageURL && imageURL.length > 0 && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              background: "#F4F4F6",
              borderRadius: "8px",
              marginTop: "10px",
            }}
          >
            {imageURL.map((url, index) => (
              <div
                key={index}
                style={{
                  maxWidth: "120px",
                  height: "fit-content",
                  marginTop: "10px",
                  padding: "10px",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <label>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={previewFiles[index]?.url}
                      alt={`media-${index}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <span
                    style={{ position: "absolute", right: "0px", top: "0px" }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <img
                      src={CloseIcons}
                      alt="img"
                      style={{ cursor: "pointer" }}
                      className="thumb-img"
                    />
                  </span>
                </label>
              </div>
            ))}
          </div>
        )}

        <Label>Description</Label>
        <InputGroup className="mb-3">
          <Form.Control
            placeholder="Enter description"
            aria-label="Job Title"
            name="decription"
            aria-describedby="basic-addon1"
            style={{
              borderRadius: "8px",
              background: "#F4F4F6",
              height: "93px",
              color: "#C6CAD2",
              fontSize: "12px",
              padding: "10px",
              display: "flex",
              alignItems: "flex-start",
            }}
            value={description}
            onChange={handleChangeDescription}
          />
        </InputGroup>
        <div
          style={{
            fontFamily: "Inter-Medium,sans-serif",
            fontStyle: "italic",
            fontSize: "12px",
            color: "#C6CAD2",
            position: "relative",
            left: "230px",
            bottom: "40px",
          }}
        >
          max 1000 characters
        </div>
      </div>
      <div className="modalFooter">
        <button
          className="button5"
          style={{ width: "85px", height: "43px", padding: "12px" }}
          onClick={() => onPopup()}
        >
          Back
        </button>
        {previewInterviewId ? (
          <button className="service-btn2" onClick={handleUpdate}>
            Update
          </button>
        ) : id ? (
          <button
            className="button2"
            style={{ width: "85px", height: "43px", padding: "12px" }}
            onClick={handleUpdate}
          >
            Update
          </button>
        ) : (
          <button className="service-btn2">Submit</button>
        )}
      </div>
    </div>
  );
};

export default UpdateServicePopup;
