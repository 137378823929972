import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
const CreateAptitude = ({ callservice, close }) => {
  const [questionName, setQuestionName] = useState();
  const [optionA, setOptionA] = useState();
  const [optionB, setOptionB] = useState();
  const [optionC, setOptionC] = useState();
  const [optionD, setOptionD] = useState();
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(false);
  const createAptitude = () => {
    if (!questionName || !optionA || !optionB || !optionC || !optionD || !answer) {
      toast.error("Please fill the mandatory detail");
      return;
    }
    setLoading(true);
    const payload = {
      questionName: questionName,
      optionA: optionA,
      optionB: optionB,
      optionC: optionC,
      optionD: optionD,
      answer: answer,
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/create-aptitude`, payload)
      .then(() => {
        callservice();
        close();
        setLoading(false);
        toast.success("Question created successfully");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div>
      <ToastContainer />
      <div
        className="modalBody"
        style={{ overflowX: "hidden", height: "500px" }}
      >
        <div>
          <label className="label-name">
            Question Name<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="codeName"
              onChange={(e) => setQuestionName(e.target.value)}
              value={questionName}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            Option A <span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="optionA"
              onChange={(e) => setOptionA(e.target.value)}
              value={optionA}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            Option B<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="optionB"
              onChange={(e) => setOptionB(e.target.value)}
              value={optionB}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            Option C<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="optionC"
              onChange={(e) => setOptionC(e.target.value)}
              value={optionC}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            Option D<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="optionD"
              onChange={(e) => setOptionD(e.target.value)}
              value={optionD}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
        <div>
          <label className="label-name">
            Answer<span>*</span>
          </label>
          <InputGroup className="footer-header">
            <Form.Control
              type="text"
              name="answer"
              onChange={(e) => setAnswer(e.target.value)}
              value={answer}
              aria-describedby="basic-addon1"
              style={{
                width: "50%",
                fontSize: "12px",
              }}
              className="footer-header"
            />
          </InputGroup>
        </div>
      </div>
      <div className="open-button" style={{ top: "0" }}>
        <button className="button5" onClick={close}>
          Cancel
        </button>

        <button className="button3" onClick={createAptitude}>
          {loading ? <Spinner /> : "Create"}
        </button>
      </div>
    </div>
  );
};

export default CreateAptitude;
