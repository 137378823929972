import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import softskill from "../MyProfile/industry.json";
import size from "./organiType.json";
import types from "./typesOrgi.json";
import service from "./service.json";
import "../Jobs/buttonStyle.css";
import { BsUpload } from "react-icons/bs";
const MainLayout = styled.div`
  // margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  // padding-bottom: 30px;
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;
  margin: 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  font-family:
    Inter-Medium,
    sans-serif;
  font-weight: normal;
  margin: 20px 0 10px 0;
`;


const AboutPopupModal = ({ id, onClosfun, mainId }) => {
  const optionsService = service.service.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const startYear = 1900; // The starting year for your dropdown
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const optionYear = years.map((year) => ({
    value: year,
    label: year,
  }));
  const optionsOne = softskill.industry.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsSize = size.size.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const optionsType = types.type.map((skill) => ({
    value: skill,
    label: skill,
  }));
  const [company, setCompany] = useState("");
  const [xakalAddress, setXakalAddress] = useState("");
  const [gst, setGst] = useState("");

  const [industry, setIndustry] = useState("");
  const [organisationType, setOrganisationType] = useState("");
  const [organisationSize, setOrganisationSize] = useState("");
  const [serviceProvider, setServiceProvider] = useState("");
  const [establish, setEstablish] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [description, setDescription] = useState("");

  const [error, setError] = useState({
    company: "",
    xakalAddress: "",
    website: "",
  });

  const handleIndustryChnage = (event) => {
    const selectedValue = event.value;
    console.log(event.value);
    setIndustry(selectedValue);
  };
  const handleServiceChange = (event) => {
    const selectedValue = event.value;

    setServiceProvider(selectedValue);
  };
  const handleEstablishChange = (event) => {
    const selectedValue = event.value;

    setEstablish(selectedValue);
  };
  const handleOrganisationTypeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationType(selectedValue);
    console.log(selectedValue);
  };
  const handleOrganisationSizeChange = (event) => {
    const selectedValue = event.value;

    setOrganisationSize(selectedValue);
  };

  const [industryNameTouched, setIndustryNameTouched] = useState(false);
  const [serviceProviderTouched, setServiceProviderTouched] = useState(false);
  const [establishTouched, setEstablishTouched] = useState(false);

  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "company":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter the Name of the Company!";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [organisationTypeTouched, setOrganisationTypeTouched] = useState(false);
  const [organisationSizeTouched, setOrganisationSizeTouched] = useState(false);

  const [imageURL, setImageURL] = useState(null);
  const [errorOne, setErrorOne] = useState();
  const submitSignup = (e) => {
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/validategstemail`, {
        gst: gst,
      })
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          setErrorOne("GST number already exists!");

          console.log(response.data.message);
        } else if (response.data.success === false) {
          // Xakal address already exists
          setErrorOne(null);
        } else {
          // Handle other cases if needed
          setErrorOne(null);
        }
      })
      .catch((err) => console.log(err.error));
  };
  const getOneCompanydetail = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/company/details/${id}`)
      .then((response) => {
        console.log(response, "companyOneDetail");

        setCompany(response.data.data.companyName);
        setXakalAddress(response.data.data.xakalAddress);
        setGst(response.data.data.gst);
        setWebsite(response.data.data.website);
        setOrganisationSize(response.data.data.organisationSize);
        setOrganisationType(response.data.data.organisationType);
        setIndustry(response.data.data.industry);
        setServiceProvider(response.data.data.servicesProvided);
        setEstablish(response.data.data.establishedInYear);
        setImageURL(response.data.data.companyLogo);
        setDescription(response.data.data.companyDescription);
        setTagline(response.data.data.companyTagline);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    getOneCompanydetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSumit = (e) => {
    debugger;
    e.preventDefault();
    const payload = {
      companyName: company,
      xakalAddress: xakalAddress,
      website: website,
      gst: gst,
      industry: industry,
      organisationSize: organisationSize,
      organisationType: organisationType,
      servicesProvided: serviceProvider,
      establishedInYear: establish,
      companyLogo: imageURL,
      companyTagline: tagline,
      companyDescription: description,
    };

    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/update-company/update/${mainId}`,
        payload
      )
      .then((response) => {
        console.log("company update", response);
        onClosfun();
        setOrganisationSize({ ...organisationSize });
      })
      .catch((err) => err.error);
    // history.push("/portal/company-contact");
  };
  // eslint-disable-next-line no-unused-vars
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("image", file);
       await axios
        .post(`${process.env.PUBLIC_URL}/xakal/files/`, formData)
        .then((res) => {
          console.log("file upload res", res.data.url);
          setImageURL(res.data.url);
        });
    }
  };
  const [errorGst, setErrorGst] = useState();

  const handleGstChange = (e) => {
    const input = e.target.value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    const capitalizedInput = input
      .split("")
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join("");

    const truncatedInput = capitalizedInput.substring(0, 15);

    setGst(truncatedInput);

    if (truncatedInput.length < 15) {
      setErrorGst("Please check the GST number you have entered!   Allowed characters: letters (A-Z) and numbers.");
      setErrorOne("")

    } else {
      setErrorGst("");
      setErrorOne("")
    }
  };

  return (
    <div>
      <MainLayout>
        <PageWrapper>
          <form onSubmit={handleSumit}>
            <div className="modalBody">
              <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <Label>
                    Company Page Name<span className="text-danger">*</span>
                  </Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter your Organisation's Name"
                      aria-label="Job Title"
                      name="company"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setCompany(e.target.value)}
                      value={company}
                      style={{
                        borderRadius: "5px",
                        background: "rgba(54, 42, 65, 0.04)",
                        fontSize: "12px",
                        height: "48px",
                      }}
                    />
                  </InputGroup>
                  {error.jobTitle && (
                    <span className="text-danger">{error.jobTitle}</span>
                  )}
                </div>
                <div style={{ width: "50%" }}>
                  <Label>
                    Xakal Address<span className="text-danger">*</span>
                  </Label>
                  <div style={{ display: "flex" }}>
                    <div className="xakal-address">www.xakal.com/</div>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="xakal/codeupglobal.com"
                        aria-label="Job Title"
                        name="xakalAddress"
                        onBlur={validateInput}
                        value={xakalAddress}
                        aria-describedby="basic-addon1"
                        onChange={(e) => setXakalAddress(e.target.value)}
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                          fontSize: "12px",
                          height: "48px",
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <Label>
                    Website / Domain<span className="text-danger">*</span>{" "}
                  </Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Enter the link of your Company's website / domain"
                      aria-label="Job Title"
                      name="website"
                      value={website}
                      onBlur={validateInput}
                      aria-describedby="basic-addon1"
                      onChange={(e) => setWebsite(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        background: "rgba(54, 42, 65, 0.04)",
                        fontSize: "12px",
                        height: "48px",
                      }}
                    />
                  </InputGroup>
                </div>
                <div style={{ width: "50%" }}>
                  <Label>Industry</Label>
                  <div>
                    <Select
                      class="footer-header  mb-3"
                      styles={{
                        height: "40px",
                        control: (base, state) => ({
                          ...base,
                          width: "100%",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          fontSize: "12px",
                          height: "48px",
                        }),
                      }}
                      name="industry"
                      value={optionsOne.find(
                        (option) => option.value === industry
                      )}
                      onBlur={() => setIndustryNameTouched(true)}
                      onChange={handleIndustryChnage}
                      options={optionsOne}
                      isSearchable={true}
                      placeholder="Enter Company's Industry"
                    />

                    {industryNameTouched && industryNameTouched.industry && (
                      <span className="text-danger">
                        {industryNameTouched.industry}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <Label>Organisation size</Label>
                  <div>
                    <Select
                      class="footer-header  mb-3"
                      styles={{
                        height: "40px",
                        control: (base, state) => ({
                          ...base,
                          width: "100%",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          fontSize: "12px",
                          height: "48px",
                        }),
                      }}
                      name="organisationSize"
                      value={optionsSize.find(
                        (option) => option.value === organisationSize
                      )}
                      onBlur={() => setOrganisationSizeTouched(true)}
                      onChange={handleOrganisationSizeChange}
                      isSearchable={true}
                      options={optionsSize}
                      placeholder="Enter the size of the Organisation"
                    />

                    {organisationSizeTouched &&
                      organisationSizeTouched.organisationSize && (
                        <span className="text-danger">
                          {organisationSizeTouched.organisationSize}
                        </span>
                      )}
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <Label>Organisation type</Label>
                  <div>
                    <Select
                      class="footer-header  mb-3"
                      styles={{
                        height: "40px",
                        control: (base, state) => ({
                          ...base,
                          width: "100%",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          fontSize: "12px",
                          height: "48px",
                        }),
                      }}
                      name="organsiationType"
                      value={optionsType.find(
                        (option) => option.value === organisationType
                      )}
                      onBlur={() => setOrganisationTypeTouched(true)}
                      onChange={handleOrganisationTypeChange}
                      isSearchable={true}
                      options={optionsType}
                      placeholder="Enter the type of the Organisation"
                    />

                    {organisationTypeTouched &&
                      organisationTypeTouched.organisationType && (
                        <span className="text-danger">
                          {organisationTypeTouched.organisationType}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <Label>Services Provided</Label>
                  <div>
                    <Select
                      class="footer-header  mb-3"
                      styles={{
                        height: "40px",
                        control: (base, state) => ({
                          ...base,
                          width: "100%",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          fontSize: "12px",
                          height: "48px",
                        }),
                      }}
                      name="serviceProvider"
                      value={optionsService.find(
                        (option) => option.value === serviceProvider
                      )}
                      onBlur={() => setServiceProviderTouched(true)}
                      onChange={handleServiceChange}
                      isSearchable={true}
                      options={optionsService}
                      placeholder="Enter the services provided by the Organisation"
                    />

                    {serviceProviderTouched &&
                      serviceProviderTouched.serviceProvider && (
                        <span className="text-danger">
                          {serviceProviderTouched.serviceProvider}
                        </span>
                      )}
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  <Label>Establishment Year</Label>
                  <div>
                    <Select
                      class="footer-header  mb-3"
                      styles={{
                        height: "40px",
                        control: (base, state) => ({
                          ...base,
                          width: "100%",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          fontSize: "12px",
                          height: "48px",
                        }),
                      }}
                      name="establish"
                      value={{ value: establish, label: establish }}
                      onBlur={() => setEstablishTouched(true)}
                      onChange={handleEstablishChange}
                      isSearchable={true}
                      options={optionYear}
                      placeholder="Enter the Establishment year"
                    />

                    {establishTouched && establishTouched.establish && (
                      <span className="text-danger">
                        {establishTouched.establish}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Label>Gst</Label>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Enter your gst number"
                  aria-label="Job Title"
                  name="gst"
                  className="font"
                  onBlur={submitSignup}
                  aria-describedby="basic-addon1"
                  onChange={handleGstChange}
                  value={gst}
                  style={{
                    height: "48px",
                    borderRadius: "12px",
                    background: "rgba(54, 42, 65, 0.04)",
                    color: "#40444F",
                    fontweight: "normal",
                  }}
                />
              </InputGroup>
              {errorGst && <span className="text-danger">{errorGst}</span>}
              {errorOne && <span className="text-danger">{errorOne}</span>}

              <Label>Logo</Label>
              {imageURL ? (
                <div
                  style={{
                    border: "2px dotted #000",
                    padding: "10px",
                    margin: "10px",
                    cursor: "pointer",
                    display: "flex", // Add this line
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    borderRadius: "8px",
                  }}
                >
                  <label style={{ position: "relative" }}>
                    <div>
                      <div style={{ textAlign: "center", alignItems: "center" }}>
                        {" "}
                        {/* Center the BsUpload icon */}
                        <img
                          src={imageURL}
                          alt="img"
                          style={{ width: "50px", height: "50px", }}
                        />
                      </div>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        cursor: "pointer",
                      }}
                      onChange={handleImageUpload}
                    />
                  </label>
                </div>
              ) : (
                <div
                  style={{
                    border: "2px dotted #000",
                    padding: "10px",
                    margin: "10px",
                    cursor: "pointer",
                    display: "flex", // Add this line
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    borderRadius: "8px",
                  }}
                >
                  <label style={{ position: "relative" }}>
                    <div>
                      <div style={{ textAlign: "center" }}>
                        {" "}
                        {/* Center the BsUpload icon */}
                        <BsUpload size={50} />
                        <div>Upload as png, jpg, jpeg</div>
                      </div>
                    </div>
                    <inputS
                      type="file"
                      accept="image/*"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        opacity: "0",
                        cursor: "pointer",
                      }}
                      onChange={handleImageUpload}
                    />
                  </label>
                </div>
              )}
              {/* <div style={{display:"flex",gap:"20px",width:"100%"}}> */}
              <div style={{ width: "100%" }}>
                <Label>Tag line</Label>
                <InputGroup className="">
                  <Form.Control
                    placeholder=" Write an impressive tagline for your Company"
                    aria-label="Job Title"
                    name="companyTagline"
                    onBlur={validateInput}
                    value={tagline}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setTagline(e.target.value)}
                    maxLength={250}
                    style={{
                      borderRadius: "5px",
                      background: "rgba(54, 42, 65, 0.04)",
                      fontSize: "12px",
                      height: "48px",
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom: "20px",
                    right: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(198, 202, 210, 1)",
                      fontSize: "10px",
                      fontweight: "normal",
                      fontFamily: "Inter-Medium,sans-serif",
                      marginTop: "7px",
                    }}
                  >
                    {tagline === undefined ? "0" : tagline.length}/250 Characters
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Label>Company Description</Label>
                <InputGroup className="">
                  <Form.Control
                    placeholder="Enter company description"
                    aria-label="Job Title"
                    name="companyDescription"
                    onBlur={validateInput}
                    value={description}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={1000}
                    style={{
                      borderRadius: "5px",
                      background: "rgba(54, 42, 65, 0.04)",
                      fontSize: "12px",
                      height: "96px",
                    }}
                  />
                </InputGroup>
                <div
                  style={{
                    position: "relative",
                    bottom: "20px",
                    right: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <p
                    style={{
                      color: "rgba(198, 202, 210, 1)",
                      fontSize: "10px",
                      fontweight: "normal",
                      fontFamily: "Inter-Medium,sans-serif",
                    }}
                  >
                    {description === undefined ? "0" : description.length}
                    /1000 Characters
                  </p>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="modalFooter">
              {/* <Button3 type="submit" disabled={!enable} style={{opacity: enable ? "100%" : "50%"}}>  */}
              <button type="submit" className="button2">
                Save / Next
              </button>
              {/* </Button3> */}
            </div>
          </form>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default AboutPopupModal;
