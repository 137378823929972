import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "../../images/DelIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import CloseIcon from "../../images/CloseIcon.svg";
import Addcircle from "../../images/Add Circle.svg";
import { Spinner } from "react-bootstrap";
import Calender from "../../images/Calendar.svg";

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 0px 30px 30px 30px;
  margin: 0rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const HeaderText = styled.div``;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;
const Label = styled.div`
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  color: #362a41;
  font-weight: normal;
  margin: 10px 0 10px 0;
`;
const DateWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  // position:absolute;
  // bottom:35px;
`;
const CalenderWrapper = styled.div`
  // position: relative;
  // right: 150px;
  top: 0px;
`;

const PageWrapper2 = styled.div`
  background: white;
  // opacity:0.8;
  border-radius: 10px;
  padding: 0px;
  margin: 0rem 0 0rem 0;
  z-index: 2;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const JobTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const Wrapper2 = styled.div`
    display:flex;
    gap:10px;
    position:relative:
    // top:-30px;
`;
const DateWrappers = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 10px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  button {
    width: 410px;
    height: 40px;
    margin-top: 10px;
    padding: 7px, 142px, 9px, 131px;
    border-radius: 8px;
    border: 0.5px solid rgba(221, 223, 228, 0.5);
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
const DraftSave = ({
  match,
  jobId,
  previewJobId,
  previewSaveJobId,
  goToJobOne,
  closePopups,
  openSaves,
}) => {
  const calculateInitialStarredCount = (questions) => {
    return questions.filter((question) => question.isStarred).length;
  };
  const [enable, setEnable] = useState(false);
  const [open, setOpen] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [starredQuestionsCount, setStarredQuestionsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    jobDesc: "",
    applyThrough: "",
    urls: "",
    resume: "",
  });
  const [error, setError] = useState({
    jobDesc: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const updateOne = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${jobId}`)
      .then((response) => {
        console.log(response, "United Kingdom");
        if (response.data.updateJob.questionList) {
          const initialStarredCount = calculateInitialStarredCount(
            response.data.updateJob.questionList
          );
          setStarredQuestionsCount(initialStarredCount);
        }
        setInput({
          ...input,
          userId: response.data.updateJob._id,
          jobDesc: response.data.updateJob.jobDesc,
          applyThrough: response.data.updateJob.applyThrough,
          urls: response.data.updateJob.urls,
        });
        if (
          response.data.updateJob.questionList &&
          response.data.updateJob.questionList.length > 0
        ) {
          setInputList(response.data.updateJob.questionList);
          setEnable2(true);
        } else {
          setEnable2(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    updateOne();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostSubmit = () => {
    setLoading(true);

    // Format the date as 'dd-mm-yyyy'
    const formattedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
      : null;

    const data = {
      jobDesc: input.jobDesc,
      applyThrough: input.applyThrough,
      urls: input.urls,
      resume: input.resume,
      scheduleDate: formattedDate,
    };

    // Check if jobDesc is empty and set the error message
    if (!input || !input.jobDesc || input.jobDesc.trim() === "") {
      setError({ jobDesc: "Please Enter Job description" });
      setLoading(false);
      return; // Exit the function early if there is an error
    }

    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-second/${jobId}`, data)
      .then((response) => {
        console.log("Item updated successfully:", response);
        const updateId = response.data.updateJob._id;
        // window.localStorage.setItem("updateId", updateId);
        previewJobId(updateId.toString());
        setInput({ jobDesc: "" });
        setSelectedDate(null);
        handleQuestion();
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSave = () => {
    // setLoading(true);

    // Format the date as 'dd-mm-yyyy'
    const formattedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
      : null;

    const data = {
      jobDesc: input.jobDesc,
      applyThrough: input.applyThrough,
      urls: input.urls,
      resume: input.resume,
      scheduleDate: formattedDate,
    };

    // Check if jobDesc is empty and set the error message
    if (!input || !input.jobDesc || input.jobDesc.trim() === "") {
      setError({ jobDesc: "Please Enter Job description" });
      setLoading(false);
      return; // Exit the function early if there is an error
    }

    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-second/${jobId}`, data)
      .then((response) => {
        console.log("Item updated successfully:", response);
        // const updateId = response.data.updateJob._id;
        // window.localStorage.setItem("updateId", updateId);
        // previewJobId(updateId.toString());
        openSaves();
        setOpenSave(false);
        setSelectedDate(null);
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      });
  };

  const [inputList, setInputList] = useState([
    { questionName: "", isMandatory: false, isStarred: false },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const [error2, setError2] = useState(null);
  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length < 25) {
      setInputList([
        ...inputList,
        { questionName: "", isMandatory: false, isStarred: false },
      ]);
    } else {
      // You can display an error message or handle the situation as needed
      setError2("You have reached the maximum limit of 25 questions.");
    }
  };
  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    setInputList(list);
  };
  const handleCheckboxChanges = (e, i) => {
    const { name, checked } = e.target;
    const updatedInputList = [...inputList];

    if (name === "isStarred" && checked) {
      // Check if the limit of 5 starred questions has been reached
      if (starredQuestionsCount >= 5) {
        // Prevent further checkboxes from being checked
        return;
      }
      // Increment the starred questions count
      setStarredQuestionsCount(starredQuestionsCount + 1);
    } else if (name === "isStarred" && !checked) {
      // Decrement the starred questions count when unchecking
      setStarredQuestionsCount(starredQuestionsCount - 1);
    }

    updatedInputList[i][name] = checked;
    setInputList(updatedInputList);
  };
  const [openSave, setOpenSave] = useState(false);

  const handleAddQuestionsClick = (id) => {
    setEnable2(true);
  };
  const handleQuestion = () => {
    const data = {
      jobManageId: input.userId,
      questionList: inputList,
    };
    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/create-question/${input.userId}`,
        data
      )
      .then((response) => {
        if (response && response.data) {
          setEnable(false);
          setInputList([...inputList]);
        }
      })
      .catch((erroe) => error.error);
  };

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
    <div className="custom-datepicker-header">
      <button onClick={decreaseMonth}>{"<"}</button>
      <span>{date.toLocaleDateString()}</span>
      <button onClick={increaseMonth}>{">"}</button>
      <div style={{ display: "flex", gap: "20px", left: "10px" }}>
        <button onClick={() => setOpen(false)} style={{ fontSize: "20px" }}>
          Back
        </button>
        {/* <button onClick={handlePostSubmit} style={{ fontSize: "20px" }}>
          Submit
        </button> */}
      </div>
    </div>
  );

  var currentDate = new Date();
  var minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  var maxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 20
  );

  const [textAreaCount, setTextAreaCount] = useState(0);
  const quillRef = useRef();
  const handleJobDescChange = (content, delta, source, editor) => {
    if (content.length <= 1000) {
      setInput({ ...input, jobDesc: content });
      setTextAreaCount(content.length);
    } else {
      console.error("Input exceeds 1000 characters");
      // If the input exceeds the limit, you may want to prevent further input or notify the user.
      // Additionally, you can handle the case where a user tries to paste content that exceeds the limit.
    }
  };
  const [dateEnable, setDateEnable] = useState(false);
  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quill) {
      const updateTextAreaCount = () => {
        setTextAreaCount(quill.getLength());
      };
      quill.on("text-change", updateTextAreaCount);
      return () => {
        quill.off("text-change", updateTextAreaCount);
      };
    }
  }, []);
  return (
    <div>
      <ToastContainer />

      {!enable ? (
        <div>
          <PageWrapper
            className={dateEnable ? "job-info-wrap" : "job-info-wraps"}
          >
            <div className="modalHeader mheader">
              <HeaderText className="mTitle">
                {"Add Job Information"}
              </HeaderText>
              <img
                src={CloseIcon}
                alt="img"
                onClick={() => setOpenSave(true)}
              />
            </div>
            <div className="modalBody">
              {/* <form> */}
              <Label style={{ marginTop: "0px" }}>
                Job Description & Benefits<span className="text-danger">*</span>
              </Label>
              <ReactQuill
                theme="snow" // You can choose different themes like 'snow', 'bubble', etc.
                value={input.jobDesc}
                onChange={handleJobDescChange}
                placeholder="Type here"
                style={{
                  fontSize: "12px",
                  fontFamily: "Inter-Medium,sans-serif",
                  background: "#F6F6F6",
                  border: "none",
                  borderRadius: "8px",
                  marginBottom: "5px",
                }}
                ref={quillRef}
              />
              <div>
                <p
                  style={{
                    fontFamily: "Inter-Medium,sans-serif",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {`${Math.max(textAreaCount - 1, 0)}/10000`}
                </p>
              </div>
              <div>
                <Label style={{ margin: "70px 0 30px 0px" }}>
                  Questions to be asked
                </Label>
                {enable2 ? (
                  <PageWrapper2>
                    {inputList.map((input, i) => {
                      return (
                        <div className="">
                          <Label>Question </Label>
                          <InputGroup className="">
                            <Form.Control
                              name="questionName"
                              placeholder="Type here"
                              className="footer-header font"
                              value={input.questionName}
                              onChange={(e) => handleInputChange(e, i)}
                              style={{ backgroundColor: "#F4F4F6" }}
                            />
                          </InputGroup>

                          <input />

                          <Wrapper2>
                            <input
                              type="checkbox"
                              name="isMandatory"
                              checked={input.isMandatory}
                              onChange={(e) => handleCheckboxChange(e, i)}
                            />
                            <Label>Mandatory</Label>
                            <input
                              type="checkbox"
                              name="isStarred"
                              checked={input.isStarred}
                              onChange={(e) => handleCheckboxChanges(e, i)}
                            />
                            <Label>Starred Question</Label>
                          </Wrapper2>

                          <DateWrappers>
                            {inputList.length !== 1 && (
                              <button
                                className=""
                                onClick={() => handleRemoveClick(i)}
                              >
                                <img
                                  src={Delete}
                                  alt="img"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid grey",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    marginTop: "-60px",
                                  }}
                                />
                              </button>
                            )}
                          </DateWrappers>
                          <hr />
                          {inputList.length - 1 === i && (
                            <ButtonWrapper>
                              <button
                                className=""
                                style={{ marginBottom: "20px" }}
                                onClick={handleAddClick}
                              >
                                <img src={Addcircle} alt="img" />
                                Add Question
                              </button>
                              {/* <button className="button5" onClick={handleQuestion}>Submit</button> */}
                            </ButtonWrapper>
                          )}
                        </div>
                      );
                    })}
                    <p>
                      Starred Questions: {starredQuestionsCount}/{5}
                    </p>
                    {error2 && <span className="text-danger">{error2}</span>}
                  </PageWrapper2>
                ) : (
                  <ButtonWrapper>
                    <button
                      className=""
                      onClick={() => handleAddQuestionsClick(input.userId)}
                    >
                      <img src={Addcircle} alt="img" />
                      Add Question
                    </button>
                  </ButtonWrapper>
                )}
              </div>

              <Label>Apply Through</Label>
              <div>
                <select
                  className="footer-header  mb-4"
                  style={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "5px",
                    background: "rgba(54, 42, 65, 0.04)",
                    border: "none",
                  }}
                  value={input.applyThrough}
                  name="applyThrough"
                  onChange={handleChange}
                  // value={aboutInput.university}
                >
                  <option>Select</option>
                  <option value="companySite">Company Website</option>
                  <option>Xakal</option>
                </select>
              </div>
              {input.applyThrough === "companySite" && (
                <>
                  <Label>URL</Label>
                  <InputGroup className="mb-4">
                    <Form.Control
                      placeholder="Enter your URL"
                      aria-label="Job Description"
                      name="urls"
                      onChange={handleChange}
                      value={input.urls}
                      aria-describedby="basic-addon1"
                      style={{
                        borderRadius: "8px",
                        background: "rgba(54, 42, 65, 0.04)",
                      }}
                    />
                  </InputGroup>
                </>
              )}
            </div>
          </PageWrapper>
        </div>
      ) : (
        ""
      )}
    

      <div className="modalFooter modalfooter">
        <CalenderWrapper>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm aa"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            open={open}
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            renderCustomHeader={renderCustomHeader}
            minDate={minDate}
            maxDate={maxDate}
            readonly={true}
          />
        </CalenderWrapper>

        <div className={open ? "schedule-foot" : "schedule-footer"}>
          <button
            className="button5"
            style={{ width: "190px", display: "flex", gap: "10px" }}
            onClick={() => {
              setOpen(true);
              setDateEnable(true);
            }}
          >
            <img
              src={Calender}
              alt="img"
              style={{ width: "30px", marginTop: "-8px" }}
            />
            <span>Schedule</span>
          </button>

          <button
            className="button5"
            onClick={() =>
              // history.push(`/portal/addnew-jobs/${id}`)
              goToJobOne()
            }
          >
            Back
          </button>

          <button className="button3" onClick={handlePostSubmit}>
            {loading ? <Spinner /> : "Next"}
          </button>
        </div>
      </div>
      <Dialog
        open={openSave}
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#F4F4F6",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
        halfWidth
        maxWidth="md"
        className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img
              src={QuestionImg}
              style={{ marginTop: "2px", width: "24px", height: "24px" }}
              alt="img"
            />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            Save Changes As Draft ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => {
                  setOpenSave(false);
                  closePopups();
                }}
                style={{ cursor: "pointer" }}
              >
                No
              </Button4>
              <Button6
                onClick={() => {
                  handleSave();
                  closePopups();
                }}
                style={{ cursor: "pointer" }}
              >
                Yes, Save
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DraftSave;
