import React from "react";
import "./certificate.css";
import xalogo from "../../images/xakallogo.png";

const Certificate = ({ values }) => {
  const fullName = `${values?.firstName} ${
    values?.middleName ? values?.middleName : ""
  } ${values?.lastName}`;

  return (
    <div class="containers">
      <div class="certificate">
        <img src={xalogo} alt="img" className="set-image" />
        {/* <div className="stripe-top-left"></div>
        <div class="stripe-bottom-right"></div> */}

        <div className="height-contain">
          <h1>CERTIFICATE</h1>
          <h2>OF ACHIEVEMENT</h2>
          <p>This certificate is presented to:</p>
          <h2 class="name">{fullName}</h2>
          <p>
            has successfully participated in the program and Mock Interviews
            conducted by Xakal. Your dedication, hard work, and active
            involvement throughout the program have been commendable. We
            appreciate your commitment to enhancing your skills and preparing
            for a successful career in the tech industry.
          </p>
          <div class="signature">
            <div class="person"></div>
            <div class="person">
              Aravind Kumaran
              <div class="title">Director</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
