/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DownArrow from "../../images/Downarrow.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select, { components } from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "../Jobs/buttonStyle.css";
import CreatableSelect from "react-select/creatable";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CloseIcon from "../../images/CloseIcon.svg";
import { Spinner } from "react-bootstrap";
import Addcircle from "../../images/Add Circle.svg";

const customStyle = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    height: "48px",
    border: "none",
    background: "#F6F6F6",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "Inter-Medium,sans-serif",
  }),
  menu: (provided) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #c6c6c6",
    borderRadius: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#362A41",
    background: state.Selected ? "#362A41" : "#FFF",
    borderRadius: "8px",
    padding: "8px 12px",
    "&:hover": {
      color: "#362A41", // Add hover text color
      padding: "10px",
      background: "#C6C6C6",
      borderRadius: "8px",
      width: "calc(100% - 6px)",
      marginLeft: "5px",
    },
  }),
  // Add more styles as needed
};
const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  p {
    font-size: 22px;
    cursor: pointer;
  }
`;
const ButtonLink = styled.div`
  width: 146px;
  height: 48px;
  border-radius: 8px;
  // padding: 16px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid rgba(221, 223, 228, 0.5);
  // gap: 8px;
  color: white;
  cursor: pointer;
  // margin-right: 4px;
  display: flex;
  gap: 5px;
  color: #362a41;
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 120%; /* 14.4px */
  text-transform: capitalize;
  p {
    width: 24px;
    height: 24px;
    // flex-shrink: 0;
    background: #1c274c;
    border-radius: 50%;
    // font-size:24px;
    padding: 0px 0px 5px 10px;
    color: white;
    margin-top: 15px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;
  margin: 0rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const Label = styled.div`
  font-size: 14px;
  color: #4f4953;
  line-height: 17px;
  font-family: Inter-Medium, sans-serif;
  font-weight: normal;
  margin: 25px 0 5px 0;
  span {
    color: #ff4873;
    font-size: 18px;
    // margin-left: 2px;
  }
`;

const JobTitle = styled.div`
  color: #40444f;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%; /* 19.6px */

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const options2 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];
const options7 = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
];

const options5 = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Internship", label: "Internship" },
];
const options6 = [
  { value: "Hybrid", label: "Hybrid" },
  { value: "Onsite", label: "Onsite" },
  { value: "Remote", label: "Remote" },
];
const JobPreference = styled.div`
  background: #f4e8ff;
  padding: 10px;
  border-radius: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
  display: flex;
  gap: 10px;
`;
const FieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 600px) {
    display: block;
  }
`;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;
const PrefillNewForm = ({
  closeAll,
  jobId,
  shouldUpdate,
  closeJobOne,
  jobArchivedId,
  setOpens,
}) => {
  const history = useHistory();
  const [companyData, setCompanyData] = useState([]);
  const [closemodal, setCloseModal] = useState(false);
  const [input, setInput] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
    employeeType: "",
    workMode: "",
  });

  const [skill, setSkill] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [experienceRequired, setExperienceRequired] = useState("");
  const [location, setLocation] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [workMode, setWorkMode] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [preference, setPreference] = useState([]);

  const [error, setError] = useState({
    jobTitle: "",
    companyName: "",
    experienceRequired: "",
    location: "",
    preference: "",
    salaryFrom: "",
    salaryTo: "",
  });
  const [verifyId, setVerifyId] = useState("");
  const [verifyTrue, setVerifyTrue] = useState(false);
  const [experienceRequired2, setExperienceRequired2] = useState("");
  useEffect(() => {
    if (jobId) {
      // Fetch job data based on the jobId using an API call
      // Example API call:
      axios
        .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${jobId}`)
        .then((response) => {
          const jobData = response.data.updateJob;
          setJobId(response.data.updateJob._id);
          // Set the initial state with the fetched job data
          setVerifyId(jobData.verifyId);
          setVerifyTrue(jobData.isVerifiedEmail === true);
          setInput({
            jobTitle: jobData.jobTitle,
            companyName: jobData.companyName,
            experienceRequired: jobData.experienceRequired,
            location: jobData.location,
            salaryFrom: jobData.salaryFrom,
            salaryTo: jobData.salaryTo,
          });
          setCompanyName({
            value: jobData.companyName?.value,
            label: jobData.companyName?.label,
            comID: jobData.companyName?.comID,
            companyLogo: jobData.companyName?.companyLogo,
            website: jobData.companyName?.website,
            websites: jobData.companyName?.websites,
          });
          setExperienceRequired(jobData.experienceRequired);
          setExperienceRequired2(jobData.experienceRequired2);
          setSkill(jobData.skill);
          setWorkMode(jobData.workMode);
          setEmployeeType(jobData.employeeType);
          setLocation(jobData.location);
          // Set skill and preference using the fetched data
          setPreference(jobData.preference);
        });
    } else if (jobArchivedId) {
      axios
        .get(
          `${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${jobArchivedId}`
        )
        .then((response) => {
          const jobData = response.data.updateJob;
          setJobId(response.data.updateJob._id);
          // Set the initial state with the fetched job data
          setVerifyId(jobData.verifyId);
          setVerifyTrue(jobData.isVerifiedEmail === true);
          setInput({
            jobTitle: jobData.jobTitle,
            companyName: jobData.companyName,
            experienceRequired: jobData.experienceRequired,
            location: jobData.location,
            salaryFrom: jobData.salaryFrom,
            salaryTo: jobData.salaryTo,
          });
          setCompanyName({
            value: jobData.companyName?.value,
            label: jobData.companyName?.label,
            comID: jobData.companyName?.comID,
            companyLogo: jobData.companyName?.companyLogo,
            website: jobData.companyName?.website,
            websites: jobData.companyName?.websites,
          });
          setExperienceRequired(jobData.experienceRequired);
          setExperienceRequired2(jobData.experienceRequired2);
          setSkill(jobData.skill);
          setWorkMode(jobData.workMode);
          setEmployeeType(jobData.employeeType);
          setLocation(jobData.location);
          // Set skill and preference using the fetched data
          setPreference(jobData.preference);
        });
    }
  }, [jobId, jobArchivedId]);
  const handleSkillChnage = (e) => {
    setSkill(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleExperienceChange = (event) => {
    const selectedValue = event.value;

    setExperienceRequired(selectedValue);
  };
  const handleExperience2Change = (event) => {
    const selectedValue = event.value;

    setExperienceRequired2(selectedValue);
  };
  const handleEmployementChange = (event) => {
    const selectedValue = event.value;

    setEmployeeType(selectedValue);
  };
  const handleWorkChange = (event) => {
    const selectedValue = event.value;

    setWorkMode(selectedValue);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddInput = () => {
    if (inputValue.trim() !== "") {
      setPreference([...preference, inputValue]);
      setInputValue(""); // Clear inputValue after adding a new preference
      setPreferenceRequiredTouched("");
    }
  };

  const handleRemoveInput = (index) => {
    const updatedValues = [...preference];
    updatedValues.splice(index, 1);
    setPreference(updatedValues);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const validateInput = (e) => {
    let { name, value } = e.target;

    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "jobTitle":
          //   const regex = /^[a-zA-Z]+$/.test(value);
          if (value === "") {
            stateObj[name] = "Please enter job title";
          }
          break;

        case "companyName":
          if (value === "") {
            stateObj[name] = "Please enter company name";
          } else {
            stateObj[name] = "";
          }

          break;

        case "experienceRequired":
          //   const regex2 = /^[a-zA-Z]+$/.test(value);
          if (!value) {
            stateObj[name] = "Please enter experience required";
          }
          break;
        case "location":
          if (!value) {
            stateObj[name] = "Please enter location";
          }
          break;
        case "salaryFrom":
          const regex3 = /^[0-9,.]+$/.test(value);
          if (input.salaryFrom === "") {
            stateObj[name] = "Please enter salary From";
          } else if (!regex3) {
            stateObj[name] = "Please enter valid salary from";
          }
          break;
        case "salaryTo":
          const Difference = input.salaryTo - input.salaryFrom;
          if (Difference < 0 && input.salaryTo !== "") {
            stateObj[name] = "Salary To  Should be Greater than Salary From";
          } else if (input.salaryTo === "") {
            stateObj[name] = "";
          }

          break;

        default:
          break;
      }
      return stateObj;
    });
    // handleInputBlur()
  };
  const [experienceRequiredTouched, setExperienceRequiredTouched] =
    useState(false);
  const [prefrenceRequiredTouched, setPreferenceRequiredTouched] =
    useState(false);
  const [locationRequiredTouched, setLocationRequiredTouched] = useState(false);
  const [jobIds, setJobId] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCompany = (selectedOption) => {
    const value = selectedOption ? selectedOption : "";
    setInput({ ...input, companyName: value });
    setError({
      ...error,
      companyName: value ? "" : "Please Enter Company Name",
    });
  };
  const newJobs = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please Enter Job Title";
    }

    if (!input.companyName) {
      errors.companyName = "Please Enter Company Name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please Select Experience";
    }

    if (!location) {
      errors.location = "Please Select Location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please Enter Valid Salary From";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary To should be greater than Salary From";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/update-manage-job/${jobIds}`,
          data
        );

        if (response.data.success) {
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setJobId(response.data.updateJob._id);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      if (verifyTrue) {
        closeJobOne(jobIds);
      } else {
        closeJobOne(jobArchivedId);
      }
    } else {
      setError(errors);
      setPreferenceRequiredTouched({
        preference:
          !preference.length ||
          (inputValue.trim() !== "" &&
            preference.indexOf(inputValue.trim()) === -1)
            ? "Please enter preference"
            : "",
      });
      setLocationRequiredTouched({
        location: location === "" ? "Please Enter Location" : "",
      });
    }
  };
  const newJobsArchived = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};

    if (!input.jobTitle) {
      errors.jobTitle = "Please Enter Job Title";
    }

    if (!input.companyName) {
      errors.companyName = "Please Enter Company Name";
    }

    if (!experienceRequired) {
      errors.experienceRequired = "Please Select Experience";
    }

    if (!location) {
      errors.location = "Please Select Location";
    }

    const regex3 = /^[0-9,.]+$/.test(input.salaryFrom);
    if (!input.salaryFrom || !regex3) {
      errors.salaryFrom = "Please Enter Valid Salary From";
    }

    const Difference = input.salaryTo - input.salaryFrom;
    if (Difference < 0 && input.salaryTo !== "") {
      errors.salaryTo = "Salary To should be greater than Salary From";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const data = {
          jobTitle: input.jobTitle,
          companyName: input.companyName,
          experienceRequired: parseInt(experienceRequired),
          experienceRequired2: parseInt(experienceRequired2),
          companyId: companyName.comId,
          location: location,
          salaryFrom: input.salaryFrom,
          salaryTo: input.salaryTo,
          employeeType: employeeType,
          workMode: workMode,
          skill: skill,
          preference: preference,
        };

        const response = await axios.put(
          `${process.env.PUBLIC_URL}/xakal/get-update-archived/${jobArchivedId}`,
          data
        );
        if (response.data.success) {
          setInput({
            jobTitle: "",
            companyName: "",
            experienceRequired: "",
            location: "",
            salaryFrom: "",
            salaryTo: "",
            employeeType: "",
            workMode: "",
          });
          setExperienceRequired("");
          setExperienceRequired2("");
          setSkill([]);
          setPreference([]);
          setLocation("");
          setWorkMode("");
          setEmployeeType("");
        }

        setJobId(response.data.updateJob._id);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      if (verifyTrue) {
        closeJobOne(jobIds);
      } else {
        closeJobOne(jobArchivedId);
      }
    } else {
      setError(errors);
      setPreferenceRequiredTouched({
        preference:
          !preference.length ||
          (inputValue.trim() !== "" &&
            preference.indexOf(inputValue.trim()) === -1)
            ? "Please enter preference"
            : "",
      });
      setLocationRequiredTouched({
        location: location === "" ? "Please Enter Location" : "",
      });
    }
  };
  const newJobs2 = async () => {
    try {
      const data = {
        jobTitle: input.jobTitle,
        companyName: input.companyName,
        experienceRequired: parseInt(experienceRequired),
        experienceRequired2: parseInt(experienceRequired2),
        companyId: input.companyName.comId,
        location: location,
        salaryFrom: input.salaryFrom,
        salaryTo: input.salaryTo,
        employeeType: employeeType,
        workMode: workMode,
        skill: skill,
        preference: preference,
      };

      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-post-draft/${jobIds}`,
        data
      );

      if (response.data.success) {
        setInput({
          jobTitle: "",
          companyName: "",
          experienceRequired: "",
          location: "",
          salaryFrom: "",
          salaryTo: "",
          employeeType: "",
          workMode: "",
        });
        setExperienceRequired("");
        setExperienceRequired2("");
        setSkill([]);
        setPreference([]);
        setLocation("");
        setWorkMode("");
        setEmployeeType("");
      }

      setJobId(response.data.updateJob._id);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const [error4, setError4] = useState("");
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(enteredEmail)) {
      setError4("Please enter a valid email address.");
    } else if (enteredEmail.toLowerCase().includes("gmail.com")) {
      setError4("Please enter your organisation ID");
    } else {
      setError4("");
    }
    setEmail(enteredEmail);
  };
  const DiscardChanges = () => {
    setInput({
      jobTitle: "",
      companyName: "",
      experienceRequired: "",
      location: "",
      salaryFrom: "",
      salaryTo: "",
      employeeType: "",
      workMode: "",
    });
    setSkill("");
    setPreference([""]);
    setInputValue("");
    setCloseModal(false);
    history.push("/portal/jobs");
  };

  const GetAllCompany = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-company`)
      .then((response) => {
        const companyValue = response.data.company.map((e) => ({
          label: e.companyName,
          value: e.companyName,
          website: e.website,
          comID: e.companyId,
          companyLogo: e.companyLogo,
        }));
        setCompanyData(companyValue);
      })
      .catch((err) => err.error);
  };
  const [insitituteData, setInstitueData] = useState([]);
  const GetAllInstitute = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-institute`)
      .then((response) => {
        const companyValue = response.data.company.map((e) => ({
          label: e.collegeName,
          value: e.collegeName,
          websites: e.website,
          comID: e.instituteId,
          companyLogo: e.companyLogo,
        }));
        setInstitueData(companyValue);
      })
      .catch((err) => err.error);
  };
  useEffect(() => {
    GetAllCompany();
    GetAllInstitute();
  }, []);
  const [organisation, setOrganisation] = useState([]);
  const mergeCompanyAndInstituteData = () => {
    const mergedData = [...companyData, ...insitituteData];
    // Use mergedData as needed (e.g., set state for dropdown options)
    // Example: setDropdownOptions(mergedData);
    setOrganisation(mergedData);
  };
  useEffect(() => {
    if (companyData.length > 0 && insitituteData.length > 0) {
      mergeCompanyAndInstituteData();
    }
  }, [companyData, insitituteData]);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [errorOtpOne, setErrorOtpOne] = useState("");

  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const sendEmailOtp = async () => {
    setIsResendDisabled(true);
    setResendTimer(60);
    setIsSendOtpClicked(true);
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        website: companyName.website,
        jobId: jobIds,
        verifyId: verifyId,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/send/domain`, payload)
        .then((response) => {
          toast.success("OTP sent to Mail", {
            autoClose: 5000,
            position: "top-right",
          });
          setErrorMessage("Enter the Otp that has send to your Mail Id!");
          startResendTimer();
        })
        .catch((err) => {
          toast.error("Failed to send OTP", {
            autoClose: 5000,
            position: "top-right",
          });
        });
    }
  };
  const startResendTimer = () => {
    const timerInterval = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      setIsResendDisabled(false);
      setResendTimer(0);
      clearInterval(timerInterval);
    }, 60000);
  };
  const formatTimer = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const verifyOtp = async () => {
    if (companyName) {
      setErrorOtp("");
      setErrorOtpOne("");
      const payload = {
        emailId: email,
        otp: +otp,
        website: companyName.website,
        jobId: jobIds,
        verifyId: verifyId,
      };
      await axios
        .post(`${process.env.PUBLIC_URL}/xakal/verify/domain`, payload)
        .then((response) => {
          if (response && response.data && response.data.success === true) {
            setTimeout(() => {
              setOpen(false);
              if (jobArchivedId) {
                closeJobOne(jobArchivedId);
              } else {
                closeJobOne(jobIds);
              }
              // Redirect to a different page
              // history.push(`/portal/adddraft-save/${jobIds}`);
            }, 1000);
            setErrorOtp(response.data.message);
          } else if (
            response &&
            response.data &&
            response.data.success === false
          ) {
            // Set the error message for an invalid OTP
            setErrorOtpOne("Invalid OTP");
          }
          // history.push(`./addnew-jobinfo/${jobIds}`);
        })
        .catch((err) => err.error);
    }
  };
  const errorHandle = (e) => {
    const enteredValue = e.target.value;
    if (/^\d{0,4}$/.test(enteredValue)) {
      // If it's a four-digit number or less, update the state
      setOtp(enteredValue);
    }
  };
  const [shouldSave, setShouldSave] = useState(false);
  useEffect(() => {
    GetAllCompany();
  }, []);
  const cancelJob = () => {
    setOpen(false);
    setShouldSave(true);
  };
  const [skillData, setSkillData] = useState([]);
  const [optionsOne, setOptionsOne] = useState([]);
  const getSkill = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/skills`)
      .then((response) => {
        const namesArray = response.data.map((item) => item.value);
        setSkillData(namesArray);
        setOptionsOne(
          namesArray.map((skill) => ({
            value: skill,
            label: skill,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSkill();
  }, []);

  const [skillcreate, setSkillCreate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [rating, setRating] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userId, setUserId] = useState("");

  const createSkill = () => {
    const payload = {
      name: skillcreate,
      rating: rating,
      userId: [userId],
    };
    axios
      .post(`${process.env.PUBLIC_URL}/xakal/skill`, payload)
      .then((response) => {
        setSkillCreate("");
        getSkill();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setOptionsOne(
      skillData.map((skill) => ({
        value: skill,
        label: skill,
      }))
    );
  }, [skillData]);

  const handleCreatable = async (e) => {
    if (e[e?.length - 1]?.__isNew__) {
      await createSkill();
    }
    handleSkillChnage(e);
  };
  // eslint-disable-next-line no-unused-vars
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setLocation(value);
    setCoordinates(ll);
  };
  const CaretDownIcon = () => {
    return (
      <img
        src={DownArrow}
        alt="img"
        style={{ width: "30px", background: "#F4F4F6", padding: "0" }}
      />
    );
  };
  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };
  return (
    <div>
      {closemodal ? (
        <div
          className="wrappers2"
          style={{ marginTop: "-80px", height: "170vh" }}
        >
          <div
            className="containerUpload"
            style={{ padding: "20px", marginTop: "700px" }}
          >
            <h4 className="text-name" style={{ color: "black" }}>
              Discard Changes
            </h4>
            <p
              onClick={() => setCloseModal(false)}
              className="close-icon2"
              style={{ top: "700px" }}
            >
              X
            </p>
            <div className="upload-camera">
              <p>Are you sure you want to discard the changes you made?</p>
            </div>
            <div className="uploadButon">
              <h4
                onClick={() => setCloseModal(false)}
                style={{ margin: "5px 20px 0 0", color: "#764F90" }}
              >
                Go Back
              </h4>
              <button className="button2" onClick={DiscardChanges}>
                Discard
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/*<MainLayout>*/}
      <ToastContainer />

      {open ? (
        <div>
          <Label>Enter your Company Mail ID*</Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              type="text"
              name="email"
              style={{
                borderRadius: "5px",
                border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={handleEmailChange}
              placeholder="Enter your company mail ID"
            />
            <button
              className="button4"
              disabled={!email || error4}
              onClick={sendEmailOtp}
            >
              Send Otp
            </button>
          </div>
          {error4 && <p style={{ color: "red" }}>{error4}</p>}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span style={{ color: "green" }}>{errorMessage}</span>
            </div>
            <div>
              {isSendOtpClicked && (
                <span>
                  {resendTimer > 0
                    ? `Resend OTP in ${formatTimer(resendTimer)}`
                    : !isResendDisabled && (
                        <span onClick={sendEmailOtp}>Resend OTP</span>
                      )}
                </span>
              )}
            </div>
          </div>
          <Label>Enter Otp</Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              name="otp"
              value={otp}
              style={{
                borderRadius: "5px",
                border: "1px solid black",
                background: "rgba(54, 42, 65, 0.04)",
                width: "90%",
                height: "40px",
                padding: "20px",
              }}
              onChange={errorHandle}
              placeholder="Enter the OTP sent to the entered MAIL ID"
            />
            <button
              className="button4"
              disabled={!otp}
              onClick={() => verifyOtp()}
            >
              Verify
            </button>
          </div>
          <div>
            <span style={{ color: "green" }}>{errorOtp}</span>
          </div>
          <div>
            <span style={{ color: "red" }}>{errorOtpOne}</span>
          </div>
        </div>
      ) : (
        <PageWrapper>
          {/*<HeaderText>{"Add New Job"}</HeaderText>*/}
          <div className="modalHeader" style={{ paddingTop: "0px" }}>
            <span
              style={{
                // width: "347px",
                // height: "50px",
                // padding: "19px, 16px, 19px, 25px",
                border: "0px, 0px, 1px, 0px",
                // borderBottom: "1px solid #F4F4F6",
                fontSize: "18px",
                fontFamily: "Inter-Medium,sans-serif",
                fontweight: "normal",
                color: "#362A41",
              }}
            >
              Add New Job
            </span>
            <img
              src={CloseIcon}
              alt="img"
              onClick={() => setShouldSave(true)}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div className="modalBody" style={{ padding: "0px 20px 80px 20px" }}>
            <Label>
              Job Title<span>*</span>
            </Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter Job Title"
                className="footer-header font"
                aria-label="Job Title"
                name="jobTitle"
                value={input.jobTitle}
                onBlur={validateInput}
                aria-describedby="basic-addon1"
                onChange={handleChange}
                style={{
                  borderRadius: "5px",
                  background: "rgba(54, 42, 65, 0.04)",
                }}
              />
            </InputGroup>
            {error.jobTitle && (
              <span className="text-danger">{error.jobTitle}</span>
            )}
            <Label>
              Company Name <span>*</span>
            </Label>

            <FieldWrapper>
              <div style={{ width: "100%" }}>
                <Select
                  // className="footer-header mb-3"
                  value={input.companyName}
                  isSearchable={true}
                  name="companyName"
                  isClearable={false}
                  placeholder="Enter your company name "
                  components={{ Placeholder, DropdownIndicator }}
                  onBlur={() =>
                    validateInput({
                      target: {
                        name: "companyName",
                        value: input.companyName,
                      },
                    })
                  }
                  onChange={handleCompany}
                  options={organisation}
                  className="font"
                  styles={customStyle}
                />
              </div>

              <div>
                <ButtonLink onClick={() => history.push("/portal/company")}>
                  <img src={Addcircle} alt="img" />
                  New Company
                </ButtonLink>
              </div>
            </FieldWrapper>

            <Label>
              Experience<span>*</span>
            </Label>
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "-20px",
                width: "100%",
              }}
            >
              <div style={{ width: "50%" }}>
                <Label>Min</Label>
                <Select
                  class="footer-header  mb-3"
                  styles={customStyle}
                  name="experienceRequired"
                  components={{ Placeholder, DropdownIndicator }}
                  value={options2.find(
                    (option) => option.value === experienceRequired
                  )}
                  onBlur={() => setExperienceRequiredTouched(true)}
                  onChange={handleExperienceChange}
                  options={options2}
                  isSearchable={true}
                />

                {experienceRequiredTouched &&
                  experienceRequiredTouched.experienceRequired && (
                    <span className="text-danger">
                      {experienceRequiredTouched.experienceRequired}
                    </span>
                  )}
              </div>

              <div style={{ width: "50%" }}>
                <Label>Max</Label>
                <Select
                  class="footer-header  mb-3"
                  styles={customStyle}
                  name="experienceRequired2"
                  components={{ Placeholder, DropdownIndicator }}
                  value={options7.find(
                    (option) => option.value === experienceRequired2
                  )}
                  // onBlur={() => setExperienceRequiredTouched(true)}
                  onChange={handleExperience2Change}
                  options={options7}
                  isSearchable={true}
                />

                {experienceRequiredTouched &&
                  experienceRequiredTouched.experienceRequired2 && (
                    <span className="text-danger">
                      {experienceRequiredTouched.experienceRequired2}
                    </span>
                  )}
              </div>
            </div>
            <Label>
              Location<span>*</span>
            </Label>
            <div>
              <PlacesAutocomplete
                value={location}
                onChange={setLocation}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: "footer-header font",
                      })}
                      style={{ width: "100%" }}
                      className="footer-header font"
                      styles={{
                        height: "40px",
                        control: (base) => ({
                          ...base,
                          width: "100%", // Set the width to 100%
                          height: "48px",
                          backgroundColor: "#F4F4F6",
                          border: "1px solid #F4F4F6",
                          borderRadius: "8px",
                          marginBottom: "5px",
                        }),
                      }}
                    />
                    <div
                      className="autocomplete-dropdown-container"
                      style={{ width: "100%" }}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              marginTop: "20px",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              marginTop: "20px",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              style={{
                                color: "#362A41", // Add hover text color
                                padding: "5px",
                                background: "#C6C6C6",
                                borderRadius: "8px",
                                width: "calc(100% - 6px)",
                                margin: "40px 10px 10px 10px",
                              }}
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {locationRequiredTouched && locationRequiredTouched.location && (
                <span className="text-danger">
                  {locationRequiredTouched.location}
                </span>
              )}
            </div>

            <Label>Salary Range</Label>
            <Wrapper>
              <Label style={{ width: "50%", marginTop: "5px" }}>
                From<span>*</span>
              </Label>
              <Label style={{ width: "50%", marginTop: "5px" }}>To</Label>
            </Wrapper>
            <Wrapper style={{ width: "100%", marginTop: "5px" }}>
              <InputGroup
                // className="mb-3"
                style={{ width: "50%", borderRadius: "5px" }}
              >
                <Form.Control
                  placeholder="₹ ------ L"
                  type="number"
                  aria-label="Job Title"
                  className="footer-header font"
                  name="salaryFrom"
                  value={input.salaryFrom}
                  onBlur={validateInput}
                  onChange={handleChange}
                  style={{
                    background: "rgba(54, 42, 65, 0.04)",
                    height: "48px",
                  }}
                />
              </InputGroup>

              <InputGroup
                className="mb-1"
                style={{ width: "50%", borderRadius: "5px" }}
              >
                <Form.Control
                  placeholder="₹ ------ L"
                  type="number"
                  aria-label="Job Title"
                  name="salaryTo"
                  value={input.salaryTo}
                  aria-describedby="basic-addon1"
                  className="footer-header font"
                  style={{
                    background: "rgba(54, 42, 65, 0.04)",
                    height: "48px",
                  }}
                  onBlur={validateInput}
                  onChange={handleChange}
                />
              </InputGroup>
            </Wrapper>
            {error.salaryFrom && (
              <span className="text-danger f12">{error.salaryFrom}</span>
            )}
            {error.salaryTo && (
              <span className="text-danger" style={{}}>
                {error.salaryTo}
              </span>
            )}
            <Label>Employement Type</Label>
            <div>
              <Select
                class="footer-header  mb-3"
                styles={customStyle}
                name="employeeType"
                components={{ Placeholder, DropdownIndicator }}
                value={options5.find((option) => option.value === employeeType)}
                onBlur={validateInput}
                onChange={handleEmployementChange}
                isSearchable={true}
                options={options5}
              />
            </div>

            <Label>Work Mode</Label>
            <div>
              <Select
                class="footer-header  mb-3"
                styles={customStyle}
                name="workMode"
                components={{ Placeholder, DropdownIndicator }}
                value={options6.find((option) => option.value === workMode)}
                onBlur={validateInput}
                onChange={handleWorkChange}
                isSearchable={true}
                options={options6}
                placeholder="Type/Select"
              />
            </div>

            <Label>Preference</Label>

            <FieldWrapper>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                name="preference"
                onBlur={() => setPreferenceRequiredTouched(true)}
                className="footer-header font"
                style={{
                  padding: "20px",
                }}
              />
              <ButtonLink onClick={handleAddInput}>
                <img src={Addcircle} alt="img" />
                Add Preference
              </ButtonLink>
            </FieldWrapper>
            {preference.map((input, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  marginRight: "10px",
                  marginTop: "10px",
                }}
              >
                <JobPreference>
                  {input}
                  {input !== "" ? (
                    <button onClick={() => handleRemoveInput(index)}>X</button>
                  ) : (
                    ""
                  )}
                </JobPreference>
              </div>
            ))}

            {prefrenceRequiredTouched &&
              prefrenceRequiredTouched.preference && (
                <span className="text-danger">
                  {prefrenceRequiredTouched.preference}
                </span>
              )}
            <Label>Skills</Label>
            <div>
              <CreatableSelect
                isMulti
                styles={customStyle}
                name="skill"
                components={{ Placeholder, DropdownIndicator }}
                placeholder="Select your skills"
                onChange={(e) => handleCreatable(e)}
                onInputChange={(e) => setSkillCreate(e)}
                options={optionsOne}
                value={optionsOne.filter((e) => skill.includes(e.value))}
              />
            </div>
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={() => cancelJob()}>
              Cancel
            </button>
            {jobArchivedId ? (
              <button
                type="submit"
                onClick={newJobsArchived}
                className="button3"
              >
                {loading ? <Spinner /> : "Next"}
              </button>
            ) : (
              <button type="submit" onClick={newJobs} className="button3">
                {loading ? <Spinner /> : "Next"}
              </button>
            )}
          </div>
        </PageWrapper>
      )}
      <Dialog
        open={shouldSave}
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#F4F4F6",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
        halfWidth
        maxWidth="md"
        className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img
              src={QuestionImg}
              style={{ marginTop: "2px", width: "24px", height: "24px" }}
              alt="img"
            />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            Save Changes As Draft ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => {
                  closeAll();
                  setOpens();
                }}
                style={{ cursor: "pointer" }}
              >
                No
              </Button4>
              <Button6
                onClick={(e) => {
                  newJobs2(e);
                  closeAll();
                  setOpens();
                }}
                style={{ cursor: "pointer" }}
              >
                Yes, Save
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/*</MainLayout>*/}
    </div>
  );
};

export default PrefillNewForm;
