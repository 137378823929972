import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaTrash } from "react-icons/fa"
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import "../Jobs/buttonStyle.css";
import "react-toastify/dist/ReactToastify.css";
import { CiSettings } from "react-icons/ci";
import { BsCreditCard2Front, BsFillPencilFill } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import Table from 'react-bootstrap/Table';
import ClassPopup from "./ClassPopup";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      //  redirectSignIn: "http://localhost:3000/",
      //     redirectSignOut: "http://localhost:3000/",,
      redirectSignIn: "https://thexakal.com/login",
      redirectSignOut: "https://thexakal.com/login",

      responseType: "token",
    },
  },
});
const MainLayout = styled.div`
  margin: 150px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderLayout = styled.div`
  position: absolute;
  top:0;
  margin-left: 5px;
  width: 1200px;
  left: 226px;
  border: 1px;
  height: 105px;
  background: white;
`;
const SeparteDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const MainHeader = styled.div`
  font-weight: normal;
  position: relative;
  // display: flex;
  // justify-content: flex-start;
  top: 10px;
  // margin-left: 39px;
  color: #362A41;
  font-family: "Inter-Medium,sans-serif";
  font-size: 24px;
`;
const Wrappers = styled.div`
  display: flex;
  justify-content:space-between;
  margin-bottom:40px;
  p {
    font-size: 22px;
    cursor: pointer;
  }
`;

const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  // padding: 30px;
  margin: 1rem 0rem;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  position:relative;
  justify-content: flex-end;
  
  
`;

const Button9 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button8 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const Text = styled.p`
  font-family: Inter-Medium;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const ClassesInstitute = ({ match }) => {
  const history = useHistory()
  const [success, setSuccess] = useState(false);

  function onSubmit() {
    setSuccess(true);
  }
  const [openSetting, setOpenSetting] = useState(false);
  const openSubmit = () => {
    setOpenSetting(true);
  };

  const logout = () => {
    Common.logout();
  };
  const [user, setUsers] = useState("");
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const [createId, setCreateId] = useState(null)
  const [classes, setClasses] = useState([])
  const getCompanyDetails = async () => {
    return await axios
      .get(`${process.env.PUBLIC_URL}/xakal/institute/details-classses/` + match.params.id)
      .then((response) => {
        console.log("company details by id", response.data);
        setCreateId(response.data.data.instituteId)
        setClasses(response.data.data.classes)
      });
  };

  useEffect(() => {
    getCompanyDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [open, setOpen] = useState(false)
  const openForm = () => {
    setOpen(true)
  }
  const [editingData, setEditingData] = useState({
    id: "",
    // Add other fields here that need to be edited
    // Initialize them with default values
    departmentName: '',
    degreeName: '',
    branch: '',
    batch: '',
    year: '',
    regulation: '',
    classNo: '',
    sectionNo: '',
  });
  const handleSave = (data) => {
    setOpen(false); // Close the dialog if needed
    getCompanyDetails();
  };

  const [editableRowId, setEditableRowId] = useState(null); // Track the ID of the row being edited

  // Function to set the ID of the row to be edited
  const handleRowEditClick = (id) => {
    setEditableRowId(id);

    // Find the data of the row being edited based on its ID and set it in editingData
    const rowToEdit = classes.find((x) => x._id === id);
    if (rowToEdit) {
      setEditingData({
        id: rowToEdit._id,
        departmentName: rowToEdit.departmentName,
        degreeName: rowToEdit.degreeName,
        branch: rowToEdit.branch,
        batch: rowToEdit.batch,
        year: rowToEdit.year,
        regulation: rowToEdit.regulation,
        classNo: rowToEdit.classNo,
        sectionNo: rowToEdit.sectionNo,
      });
    }
  };

  // Function to handle changes in input fields while editing
  const handleInputChange = (event, fieldName) => {
    setEditingData({
      ...editingData,
      [fieldName]: event.target.value,
    });
  };


  const handleEdit = () => {

    axios.put(`${process.env.PUBLIC_URL}/xakal/institute/update/${editingData.id}`, editingData).then((response) => {
      console.log(response)
      getCompanyDetails()
    }).catch((err) => {
      console.log(err)
    })
    setEditableRowId(null);
  }

  const handledelete = (id) => {
    axios.delete(`${process.env.PUBLIC_URL}/xakal/institute-delete/${id}`)
      .then((response) => {

        getCompanyDetails()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderLayout>

        <SeparteDiv>
          <div
            style={{ position: "relative", marginTop: "12px", right: "90px" }}
          >
            <CiSettings
              size={30}
              onClick={openSubmit}
              style={{ cursor: "pointer" }}
            />
          </div>
        </SeparteDiv>
      </HeaderLayout>

      <MainLayout>
        <Wrappers>
          <MainHeader>Add Classes</MainHeader>

        </Wrappers>

        <PageWrapper>
          <div id="manageClass">


          </div>
          <DateWrapper onClick={openForm}>
            <button className="button2">
              +Add
            </button>
          </DateWrapper>
          <div style={{ padding: "20px" }}>
            <Table striped bordered hover>
              <thead>
                <tr style={{ color: "#4F4953", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                  <th style={{ width: '10%' }}>Department Name</th>
                  <th style={{ width: '10%' }}>Degree Name</th>
                  <th style={{ width: '10%' }}>Branch</th>
                  <th style={{ width: '10%' }}>Batch</th>
                  <th style={{ width: '10%' }}>Year</th>
                  <th style={{ width: '10%' }}>Regulation</th>
                  <th style={{ width: '10%' }}>Class No</th>
                  <th style={{ width: '10%' }}>Section</th>
                  <th style={{ width: '10%' }}>Edit</th>
                  <th style={{ width: '10%' }}>Delete</th>
                </tr>
              </thead>
              {classes.map((x, i) => {
                const isRowEditable = x._id === editableRowId;
                return (
                  <tbody key={i}>
                    <tr style={{ color: "#362A41", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px", fontweight: "normal" }}>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.departmentName}
                            onChange={(e) => handleInputChange(e, 'departmentName')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.departmentName
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.degreeName}
                            onChange={(e) => handleInputChange(e, 'degreeName')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.degreeName
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.branch}
                            onChange={(e) => handleInputChange(e, 'branch')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.branch
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.batch}
                            onChange={(e) => handleInputChange(e, 'batch')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.batch
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.year}
                            onChange={(e) => handleInputChange(e, 'year')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.year
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.regulation}
                            onChange={(e) => handleInputChange(e, 'regulation')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.regulation
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.classNo}
                            onChange={(e) => handleInputChange(e, 'classNo')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.classNo
                        )}
                      </td>
                      <td>
                        {isRowEditable ? (
                          <input
                            value={editingData.sectionNo}
                            onChange={(e) => handleInputChange(e, 'sectionNo')}
                            className="manageClass-edit"
                          />
                        ) : (
                          x.sectionNo
                        )}
                      </td>
                      <td>{isRowEditable ? <button onClick={() => handleEdit(x._id)}>save</button> : <BsFillPencilFill onClick={() => handleRowEditClick(x._id)} />}</td>
                      <td><FaTrash onClick={() => handledelete(x._id)} /></td>


                    </tr>
                  </tbody>
                )
              })}

            </Table>
            <div style={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px"
            }}>
              <Button9 onClick={() => history.push("/portal/view-company")}>Skip For Now</Button9>
              <Button9 onClick={() => history.push("/portal/view-company")}>Submit</Button9>

            </div>
          </div>



        </PageWrapper>
      </MainLayout>

      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium,sans-serif",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button8 onClick={() => setSuccess(false)}>No</Button8>
            {user ? (
              <Button9
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button9>
            ) : (
              <Button9 onClick={logout}>Yes</Button9>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        > <AiOutlineCloseCircle onClick={() => setOpen(false)} /></DialogTitle>
        <DialogContent>
          <ClassPopup save={handleSave} id={createId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClassesInstitute;
