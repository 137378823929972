import React from "react";

import Alaram from "../../images/Alarm.svg";
import "../MyProfile/service.Popup.css";

const FooterInterviewPopup = ({ data }) => {
  return (
    <div
      className="modalFooter modalfooter"
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: "#F3EEF8",
      }}
    >
      <div className="duration-box">
        <img src={Alaram} alt="img" />
        {data?.duration}
      </div>
      <div style={{ color: "#362A41", fontSize: "24px" }}>
        {data?.inrService ? (
          <button>Free</button>
        ) : (
          <button className="">
            INR
            {data && new Intl.NumberFormat("en-IN", {}).format(data?.inr)}
          </button>
        )}
      </div>
    </div>
  );
};

export default FooterInterviewPopup;
