import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import { Colors } from "../utilities/colors";
import companyLogo from "../../images/company.png";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import { Spinner } from "reactstrap";
import axios from "axios";
import "./admission.css";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import uni from "../MyProfile/universities.json"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MainLayout = styled.div`
  margin: 80px 50px 30px 300px;
  overflow-x: hidden;
  padding-bottom: 30px;
`;
const HeaderText = styled.h2`
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  color: black;
  margin: 10px 0 10px 0;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;
const Checkbox = styled.div`
  display: flex;
  gap:20px;
  justify-content: flex-end;
  position: relative;
  right: 50px;
  cursor:pointer;
`;
const Plus = styled.div`
  font-size:40px;
  color:white;
  background:black;
  border-radius:50%;
  position:relative;
  bottom:60px;
  padding:15px 10px;
`
const AdmissionForm = () => {

    const history = useHistory();

    const [input, setInput] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        avatar: "",
        bio: "",
        country: "",
        city: "",
        // skill: "",
        coreSkill: "",
        softSkill: "",
        state: "",
        dob: "",
        title: "",
        gender: "",
        pincode: "",
        phoneNumber: "",
        about: "",
        workProfile: "",
        urlLink: "",
        resume: "",
      });

      const handleModalChange = (event) => {
        const { name, value } = event.target;
        setInput((prevProps) => ({
          ...prevProps,
          [name]: value,
        }));
        
      };

    const [phoneNumber, setPhoneNumber] = useState("");
    const [enable3, setEnable3] = useState(true);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [verify, setVerify] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [spinner, setSpiner] = useState(false);
    const [count, setCount] = useState(5);
    const [disable, setDisable] = useState(true);
    const [message, setMessage] = useState(null);
    const [error2, setError2] = useState(false);
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState(null);
    const [valid, setValid] = useState(false);
    const [message2, setMessage2] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [disablesslc, setDisableSslc] = useState(true);
    const [disablehsc, setDisableHsc] = useState(true);
    const [disablediploma, setDisableDiploma] = useState(true);
    const [disableug, setDisableUg] = useState(true);

    const [error3, setError3] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        avatar: "",
        bio: "",
        country: "",
        city: "",
        coreSkill: "",
        softSkill: "",
        state: "",
        language: "",
        proficiency: "",
        dob: "",
        title: "",
        gender: "",
        pincode: "",
        phoneNumber: "",
        about: "",
        workProfile: "",
        urlLink: "",
        resume: "",
      });

      const validateInput = (e) => {
        let { name, value } = e.target;
        setError3((prev) => {
          const stateObj = { ...prev, [name]: "" };
    
          switch (name) {
            case "firstName":
              const regex = /^[a-zA-Z]+$/.test(value);
              if (value === "") {
                stateObj[name] = "Please Enter Name";
              } else if (!regex) {
                stateObj[name] = "Please Enter Valid Name.";
              }
              break;
    
            case "lastName":
              const regex1 = /^[a-zA-Z]+$/.test(value);
              if (value === "") {
                stateObj[name] = "Please Enter LastName";
              } else if (!regex1) {
                stateObj[name] = "Please Enter Valid LastName.";
              }
              break;
    
            case "gender":
              const regex2 = /^[a-zA-Z]+$/.test(value);
              if (!value) {
                stateObj[name] = "Please Select Gender";
              } else if (!regex2) {
                stateObj[name] = "Please Enter Valid Detail.";
              }
              break;
    
            case "dob":
              if (value === "") {
                stateObj[name] = "Please Select Date of Birth";
              }
    
              break;
    
            case "country":
              if (value === "") {
                stateObj[name] = "Please Select Country";
              }
              break;
    
            case "state":
              if (value === "") {
                stateObj[name] = "Please Select State";
              }
              break;
    
            case "city":
              if (value === "") {
                stateObj[name] = "Please Select City";
              }
              break;
    
            case "pincode":
              const regex3 = /^[0-9]{6,6}$/.test(value);
              if (value === "") {
                stateObj[name] = "Please Enter Pincode";
              } else if (!regex3) {
                stateObj[name] = "Please Enter Valid Pincode.";
              }
              break;
    
            case "phoneNumber":
              const regex4 =
                /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/.test(value);
              if (value === "") {
                stateObj[name] = "Please Enter Mobile Number";
              } else if (!regex4) {
                stateObj[name] = "Please Enter Valid Mobile Number.";
              } 
            //   else {
            //     setEnable3(true);
            //   }
              break;
            // case "coreSkill":
            //   // const regex = /^[a-zA-Z]+$/.test(value);
            //   if (coreSkill === [] || coreSkill === undefined) {
            //     stateObj[name] = "Please Select University";
            //   }
            //   break;
    
            case "softSkill":
              // const regex1 = /^[a-zA-Z]+$/.test(value);
              if (value === "" || value === undefined) {
                stateObj[name] = "Please Select Degree";
              }
    
              break;
    
            case "language":
              if (value === "" || value === undefined) {
                stateObj[name] = "Please Select language";
              }
    
              break;
    
            case "proficiency":
              if (value === "" || value === undefined) {
                stateObj[name] = "Please Select Proficiency";
              }
              console.log(input.proficiency);
              break;
    
            case "workProfile":
              if (value === "") {
                stateObj[name] = "Please Enter WorkProfile Name";
              }
              break;
    
            case "urlLink":
              const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
              if (value === "") {
                stateObj[name] = "Please Enter URL Link";
              } else if (!urlPattern) {
                stateObj[name] = "Please Enter Valid URL";
              }
              break;
    
            case "resume":
              if (value === "") {
                stateObj[name] = "Please Select Resume";
              }
    
              break;
            default:
              break;
          }
    
          // console.log(stateObj[name]);
    
        //   if (
        //     stateObj[name] === "" &&
        //     input.firstName != "" &&
        //     input.lastName != "" &&
        //     input.gender != "" &&
        //     input.dob != "" &&
        //     input.country != "" &&
        //     input.country != undefined &&
        //     input.state != "" &&
        //     input.state != undefined &&
        //     input.city != "" &&
        //     input.city != undefined &&
        //     input.pincode != "" &&
        //     input.pincode != undefined &&
        //     input.phoneNumber != "" &&
        //     input.phoneNumber != undefined &&
        //     coreSkill != "" &&
        //     coreSkill != undefined &&
        //     softSkill != "" &&
        //     softSkill != undefined &&
        //     input.proficiency != "" &&
        //     input.proficiency != undefined &&
        //     input.language != "" &&
        //     input.language != undefined &&
        //     input.workProfile != "" &&
        //     input.workProfile != undefined &&
        //     input.urlLink != "" &&
        //     input.urlLink != undefined
        //   ) {
        //     setEnable2(true);
        //   } else {
        //     setEnable2(false);
        //   }
          
          return stateObj;
        });
      };

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
    
        if (otp === "") {
          alert("Enter Your Otp");
        
        } else if (!/[^a-zA-Z]/.test(otp)) {
          setErrors("Enter Valid Otp");
        } else {
          axios
            .post(`${process.env.PUBLIC_URL}/xakal/verify-mobile-otp`, {
              otp,
              phoneNumber: phoneNumber,
            })
    
            .then((response) => {
              console.log(response, "verifyOtp");
              
              setValid(false);
    
              if (response.data.success === true) {
                setVerify(true);
                setEnabled(false);
                setEnable3(false);
                
                setMessage(response.data.message);
              } else if (response.data.verified === true) {
                
                
                 setEnable3(false);
                setMessage2("Mobile Number Verified");
              } else if (count > 1) {
                setCount(count - 1);
                setError2("Incorrect OTP.");
                setValid(false);
              } else if (count === 1) {
                setMinutes(1);
                setSeconds(0);
                setCount(5);
                setError2(null);
                setValid(false);
                setDisable(false);
              } else {
                setErrors(response.data.error);
              }
            })
            .catch((err) => setErrors(err.error));
        }
      };

      const resendOtp = async (e) => {
        e.preventDefault();
        setDisable(true);
        setEnabled(true);
        setMinutes(1);
        setSeconds(0);
        if (input.email === "") {
          alert("Enter Your Email !");
        } else if (!input.email.includes("@")) {
          alert("Enter Valid Email !");
        } else {
          const data = {
            phoneNumber: phoneNumber,
          };
    
          const response = await axios.post(
            `${process.env.PUBLIC_URL}/xakal/resend-mobile-otp`,
            data
          );
          if (response.status === 200) {
          } else {
            // toast.error(response.response.data.error);
          }
        }
    };

    const sendOtp = async (e) => {
        e.preventDefault();
        setMinutes(1);
        setSeconds(0);
        if (phoneNumber === "") {
          alert("Enter Your phoneNumber !");
        } else {
          setSpiner(true);
          setEnabled(true);
          const data = {
            phoneNumber: phoneNumber,
          };
    
          const response = await axios.post(
            `${process.env.PUBLIC_URL}/xakal/send-mobile-otp`,
            data
          );
          if (response.status === 200) {
            setSpiner(false);
          } else {
            // toast.error(response.response.data.error);
          }
        }
      };
      useEffect(() => {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
    
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(interval);
            } else {
              setSeconds(59);
              setMinutes(minutes - 1);
            }
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
    });
    
    function isValidOtp(otp) {
        return /^[0-9]{4,4}$/.test(otp);
    }

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
        if (!isValidOtp(event.target.value)) {
          setErrors("Incorrect OTP.");
          setValid(false);
        } else {
          setErrors(null);
          setValid(true);
        }
    };

    const optionsTwo = uni.Universities.map((skill) => ({
      value: skill,
      label: skill,
    }));
   
    const options = [{ value:"",label:"Type / Search"},{ value:"State Board",label:"State Board"}, {value:"Matric",label:"Matric"},{value:"CBSE",label:"CBSE"} ]
    const options2 = [{ value:"",label:"Type / Search"},{ value:"CSE",label:"CSE"}, {value:"Commerce",label:"Commerce"},{value:"Biology",label:"Biology"} ]
    const options3 = [{ value: "", label: "Type / Search" },{ value: "PartTime", label: "PartTime" }, { value: "FullTime", label: "FullTime" },]

    return(
        <div>
        <MainLayout>
        <HeaderText>{"Admission Form"}</HeaderText>

        <PageWrapper>
            <div className="myprofile-content-head" >
                <img src={companyLogo} alt="yuo" style={{borderRadius:"50%"}}/>
                <div style={{marginTop:"40px"}}>
                    <label>FirstName*</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="First name"
                        aria-label="First name"
                        name="firstName"
                        value={input.firstName}
                        aria-describedby="basic-addon1"
                        onChange={handleModalChange}
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                    />
                    </InputGroup>
                    {error3.firstName && (
                    <span className="text-danger">{error3.firstName}</span>
                    )}
                </div>
                <div style={{marginTop:"40px"}}>
                    <label>Middle Name</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Middle name"
                    aria-label="Middle name"
                    name="middleName"
                    value={input.middleName}
                    aria-describedby="basic-addon1"
                    onChange={handleModalChange}
                    style={{ width: "330px", height: "40px" }}
                    />
                    </InputGroup>
                </div>
                <div style={{marginTop:"40px"}}>
                    <label>Last Name*</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Last name"
                    aria-label="Last name"
                    aria-describedby="basic-addon1"
                    name="last name"
                    value={input.lastName}
                    onChange={handleModalChange}
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                    />
                    </InputGroup>
                    {error3.lastName && (
                    <span className="text-danger">{error3.lastName}</span>
                    )}
                </div>
            </div>

            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Gender*</label>
                    <div>
                        <select
                        placeholder="Gender"
                        className="footer-header"
                        aria-label="Gender"
                        name="gender"
                        value={input.gender}
                        aria-describedby="basic-addon1"
                        onChange={handleModalChange}
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                            >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option>Others</option>
                        </select>
                    </div>
                    {error3.gender && (
                    <span className="text-danger">{error3.gender}</span>
                    )}
                </div>

              <div>
                <label>Date of birth</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="date"
                    placeholder="Date of birth"
                    aria-label="Date of birth"
                    name="dob"
                    value={input.dob}
                    aria-describedby="basic-addon1"
                    onChange={handleModalChange}
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                  />
                </InputGroup>
                {error3.dob && (
                  <span className="text-danger">{error3.dob}</span>
                )}
              </div>

                <div style={{marginLeft:"200px"}}>
                    <label>Mobile No*</label>
                    <InputGroup className="mb-3">
                    <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        inputProps={{
                        required: true,
                        }}
                        onBlur={validateInput}
                    />
                    </InputGroup>
                    {error3.phoneNumber && (
                    <span className="text-danger">{error3.phoneNumber}</span>
                    )}
                    {enable3
                    ? (
                    <div>
                        <button
                        className="login100-form-btn"
                        onClick={sendOtp}
                        >
                        Get OTP
                        {spinner ? (
                            <span>
                            <Spinner animation="border" />
                            </span>
                        ) : (
                            ""
                        )}
                        </button>
                    </div>
                    ) : (
                   "Mobile No Verified" 
                    )}
                    {enabled ? (
                    <div>
                        <div className="verify">
                            <div className="container-login100-form-btn">
                                <p className="otp">OTP</p>
                            </div>

                            <div className={"wrap-input100 "}>
                                <input
                                type="password"
                                className={"input102 "}
                                onChange={handleOtpChange}
                                disabled={!disable && seconds}
                                onClick={() => setDisable(true)}
                                ></input>
                            </div>

                            <div>
                                <button
                                className="verify-text"
                                disabled={!valid}
                                onClick={verifyOtp}
                                style={{ opacity: valid ? "100%" : "50%" }}
                                >
                                VERIFY
                                </button>
                            </div>
                        </div>
                        <p
                        style={{
                            color: "red",
                            position: "relative",
                            left: "130px",
                            marginTop: "-35px",
                        }}
                        >
                        {errors}
                        </p>

                        {error2 ? (
                        <div style={{ display: "flex", gap: "30px" }}>
                            <p style={{ color: "red" }}>{count} attempt left</p>
                            <p style={{ color: "red" }}>
                            After {count} attempt you have to wait for 1 minute.
                            </p>
                        </div>
                        ) : (
                        ""
                        )}

                        {seconds > 0 || minutes > 0 ? (
                        <p>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                            :{seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                        ) : (
                        <p>
                            Didn't recieve code?{" "}
                            <span style={{ color: "blue" }} onClick={resendOtp}>
                            Resend OTP
                            </span>
                        </p>
                        )}
                    </div>
                    ) : (
                    ""
                    )}
                    {verify ? (
                    <p
                    style={{
                      color: "green",
                      fontSize: "20px",
                      textAlign: "center",
                      position: "relative",
                      top: "10px",
                    }}
                    >
                    {message}
                    {message2}
                    </p>
                    ) : (
                  ""
                    )}
                </div>
              
            </div>

            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>Religion</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Religion"
                    type="text"
                    // value={input.email}
                    name="religion"
                    aria-label="religion"
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
                <div>

                <label>Email Id</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="EmailId"
                    type="email"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    disabled
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
            </div>
            
            <h2 style={{color:"black"}}>Present Address*</h2>
            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>Address Line 1</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Your Address"
                    type="text"
                    // value={input.email}
                    name="religion"
                    aria-label="religion"
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
                <div>

                <label>Address Line2</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Your Address"
                    type="email"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Country*</label>

                    <div>
                    <select
                        required
                        value={selectedCountry}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        name="country"
                        className="footer-header mb-3"
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                    >
                        <option value="">Country</option>
                        {Country &&
                        Country.getAllCountries().map((item) => (
                            <option key={item.name} value={item.isoCode}>
                            {item.name}
                            </option>
                        ))}
                    </select>
                    </div>
                    {error3.country && (
                    <span className="text-danger">{error3.country}</span>
                    )}
                </div>
                <div>
                    <label>State / Provision*</label>
                    <div>
                    <select
                    required
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    name="state"
                    className="footer-header mb-3"
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                    >
                    <option value="">State</option>
                    {State &&
                        selectedCountry &&
                        State.getStatesOfCountry(selectedCountry).map((item) => (
                        <option key={item.name} value={item.isoCode}>
                            {item.name}
                        </option>
                        ))}
                    </select>
                    </div>
                    {error3.state && (
                    <span className="text-danger">{error3.state}</span>
                    )}
                </div>
                <div>
                <label>City*</label>
                <div>
                    <select
                    required
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    name="city"
                    className="footer-header mb-3"
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                    >
                    <option value="">City</option>
                    {City &&
                        selectedCountry &&
                        selectedState &&
                        City.getCitiesOfState(selectedCountry, selectedState).map(
                        (item) => <option value={item.name}>{item.name}</option>
                        )}
                    </select>
                </div>
                {error3.city && (
                  <span className="text-danger">{error3.city}</span>
                )}
              </div>
              <div>
                <label>PIN/ZIP code*</label>

                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="PIN/ZIP code"
                    aria-label="PIN/ZIP code"
                    name="pincode"
                    value={input.pincode}
                    aria-describedby="basic-addon1"
                    onChange={handleModalChange}
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                  />
                </InputGroup>
                {error3.pincode && (
                  <span className="text-danger">{error3.pincode}</span>
                )}
              </div>
              
            </div>

            <Checkbox>
                <input type="checkbox"></input>
                <h3>Keep Present Address as Your Permanant Address</h3>
            </Checkbox>
            
            <h2 style={{color:"black"}}>Permanant Address*</h2>
            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>Address Line 1</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Your Address"
                    type="text"
                    // value={input.email}
                    name="religion"
                    aria-label="religion"
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
                <div>

                <label>Address Line2</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Your Address"
                    type="email"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "710px" }}
                    />
                    </InputGroup>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Country*</label>

                    <div>
                    <select
                        required
                        value={selectedCountry}
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        name="country"
                        className="footer-header mb-3"
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                    >
                        <option value="">Country</option>
                        {Country &&
                        Country.getAllCountries().map((item) => (
                            <option key={item.name} value={item.isoCode}>
                            {item.name}
                            </option>
                        ))}
                    </select>
                    </div>
                    {error3.country && (
                    <span className="text-danger">{error3.country}</span>
                    )}
                </div>
                <div>
                    <label>State / Provision*</label>
                    <div>
                    <select
                    required
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    name="state"
                    className="footer-header mb-3"
                    style={{ width: "330px", height: "40px" }}
                    onBlur={validateInput}
                    >
                    <option value="">State</option>
                    {State &&
                        selectedCountry &&
                        State.getStatesOfCountry(selectedCountry).map((item) => (
                        <option key={item.name} value={item.isoCode}>
                            {item.name}
                        </option>
                        ))}
                    </select>
                    </div>
                    {error3.state && (
                    <span className="text-danger">{error3.state}</span>
                    )}
                </div>
                <div>
                    <label>City*</label>
                    <div>
                        <select
                        required
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        name="city"
                        className="footer-header mb-3"
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                        >
                        <option value="">City</option>
                        {City &&
                            selectedCountry &&
                            selectedState &&
                            City.getCitiesOfState(selectedCountry, selectedState).map(
                            (item) => <option value={item.name}>{item.name}</option>
                            )}
                        </select>
                    </div>
                    {error3.city && (
                    <span className="text-danger">{error3.city}</span>
                    )}
                 </div>
                <div>
                    <label>PIN/ZIP code*</label>

                    <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="PIN/ZIP code"
                        aria-label="PIN/ZIP code"
                        name="pincode"
                        value={input.pincode}
                        aria-describedby="basic-addon1"
                        onChange={handleModalChange}
                        style={{ width: "330px", height: "40px" }}
                        onBlur={validateInput}
                    />
                    </InputGroup>
                    {error3.pincode && (
                    <span className="text-danger">{error3.pincode}</span>
                    )}
                </div>
              
            </div>

            <h2 style={{color:"black"}}>Parents/Guardian Info</h2>
            <div className="myprofile-content-head input-wrapper">
                <div>
                    <label>Father's Name</label>
                        <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="Enter Name"
                        type="text"
                        // value={input.email}
                        name="religion"
                        aria-label="religion"
                        aria-describedby="basic-addon1"
                        style={{ width: "330px" }}
                        />
                        </InputGroup>
                </div>

                <div>
                <label>Father's Occupation</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Occupation"
                    type="text"
                    // value={input.email}
                    name="occupation"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>

                <div>
                <label>Father's Annual Income</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Annual Income"
                    type="text"
                    // value={input.email}
                    name="income"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>Father's Mobile No</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Mobile No"
                    type="text"
                    // value={input.email}
                    name="phoneNumber"
                    aria-label="religion"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                <div>

                <label>Father's Email Id</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter EmailId"
                    type="email"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                
            </div>

            <div className="myprofile-content-head input-wrapper" style={{marginTop:"30px"}}>
                <div>
                    <label>Mother's Name</label>
                        <InputGroup className="mb-3">
                        <Form.Control
                        placeholder="Enter Name"
                        type="text"
                        // value={input.email}
                        name="name"
                        aria-label="religion"
                        aria-describedby="basic-addon1"
                        style={{ width: "330px" }}
                        />
                        </InputGroup>
                </div>

                <div>
                <label>Mother's Occupation</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Occupation"
                    type="text"
                    // value={input.email}
                    name="text"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>

                <div>
                <label>Mother's Annual Income</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Income"
                    type="text"
                    // value={input.email}
                    name="income"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>Motherr's Mobile No</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Mobile No"
                    type="number"
                    // value={input.email}
                    name="phoneNumber"
                    aria-label="religion"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                <div>

                <label>Mother's Email Id</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enetr EmailId"
                    type="email"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                
            </div>

            <h2 style={{color:"black"}}>Education</h2>
            <h2 style={{color:"black"}}>10th / SSLC</h2>
            
            {disablesslc ? <>
                <Checkbox onClick={()=>setDisableSslc(false)}><Plus>-</Plus></Checkbox>
            <div className="myprofile-content-head input-wrapper input-wrapper2">
            <div>
                <label>School/University*</label>
                <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    // onChange={handleChange}
                    name="university"
                    // value={aboutInput.university}
                    onBlur={validateInput}
                    // autoFocus={true}
                  >
                    {optionsTwo.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  {error2.university && (
                    <span className="text-danger">{error2.university}</span>
                  )}
                </div>
             </div>

                <div>
                <label>Year of Passing</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="EmailId"
                    type="date"
                  // value={input.email}
                    name="text"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                    
                </div>

                <div>
                <label>Board</label>
                  <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    name="board"
                    // onChange={handleChange}
                    // value={aboutInput.university}
                    onBlur={validateInput}
                  >
                    {options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </div>
                  {error2.university && (
                    <span className="text-danger">{error2.university}</span>
                  )}
                </div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                <div>
                <label>School Medium</label>
                <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    name="board"
                    // onChange={handleChange}
                    // value={aboutInput.university}
                    onBlur={validateInput}
                  >
                    {options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </div>
                </div>
                <div>

                <label>Total Marks Obtained</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter Marks"
                    type="number"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                <div>
                <h3>Upload Mark Sheet (optional)</h3>
                <input type="file"></input>
                </div>
            </div> 
            </> : 
            <Checkbox onClick={()=>setDisableSslc(true)}><Plus>+</Plus></Checkbox> }

            <h2 style={{color:"black"}}>12th / HSC</h2>
            {disablehsc ? 
            <>
            <Checkbox onClick={()=>setDisableHsc(false)}><Plus>-</Plus></Checkbox>
            <div className="myprofile-content-head input-wrapper input-wrapper2">
              <div>
                <label>School/University*</label>
                <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    // onChange={handleChange}
                    name="university"
                    // value={aboutInput.university}
                    onBlur={validateInput}
                    // autoFocus={true}
                  >
                    {optionsTwo.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  {error2.university && (
                    <span className="text-danger">{error2.university}</span>
                  )}
                </div>
              </div>

                <div>
                <label>Year of Passing</label>
                    <InputGroup className="mb-3" style={{border:"0.5px solid black",borderRadius:"5px"}}>
                    <Form.Control
                    placeholder="EmailId"
                    type="date"
                  // value={input.email}
                    name="text"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px",height:"40px" }}
                    />
                    </InputGroup>
                    
                </div>

                <div>
                <label>Board</label>
                  <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    name="board"
                    // onChange={handleChange}
                    // value={aboutInput.university}
                    onBlur={validateInput}
                  >
                    {options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </div>
                  {error2.university && (
                    <span className="text-danger">{error2.university}</span>
                  )}
                </div>

                <div>
                <label>School Medium</label>
                <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    name="board"
                    // onChange={handleChange}
                    // value={aboutInput.university}
                    onBlur={validateInput}
                  >
                    {options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </div>
                </div>
                <div></div>
            </div>
            <div className="myprofile-content-head input-wrapper">
                
                <div>
                
                <label>Group</label>
                <div>
                  <select
                    class="footer-header"
                    style={{ width: "330px", height: "40px" }}
                    name="board"
                    // onChange={handleChange}
                    // value={aboutInput.university}
                    onBlur={validateInput}
                  >
                    {options2.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                  </div>
                </div>
                <div>
                <label>Total Marks Obtained</label>
                    <InputGroup className="mb-3" style={{border:"1px solid black",borderRadius:"5px"}}>
                    <Form.Control
                    placeholder="Enter Your Mark"
                    type="number"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px",height:"40px"  }}
                    />
                    </InputGroup>
                </div>
                <div>
                <label>Cut Off</label>
                    <InputGroup className="mb-3" style={{border:"1px solid black",borderRadius:"5px"}}>
                    <Form.Control
                    placeholder="Enter Cut Off"
                    type="number"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px",height:"40px" }}
                    />
                    </InputGroup>
                </div>
                
            </div>
            <h3>Upload Mark Sheet (optional)</h3>
            <input type="file"></input>
            </> :
            <> <Checkbox onClick={()=>setDisableHsc(true)}><Plus>+</Plus></Checkbox> </> }

            <h2 style={{color:"black"}}>Diploma</h2>
            {disablediploma ?
            <>
            <> <Checkbox onClick={()=>setDisableDiploma(false)}><Plus>-</Plus></Checkbox> </> 
            <div className="myprofile-content-head input-wrapper input-wrapper2">
                  <div>
                    <label>School / University</label>
                    <div>
                      <select
                      class="footer-header"
                      style={{ width: "330px", height: "40px" }}
                      // onChange={handleChange}
                      name="university"
                      // value={aboutInput.university}
                      onBlur={validateInput}
                      // autoFocus={true}
                      >
                      {optionsTwo.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                      </select>
                      {error2.university && (
                      <span className="text-danger">{error2.university}</span>
                      )}
                    </div>
                  </div>

                <div>
                <label>Degree</label>                  
                  <select
                  class="footer-header"
                  style={{ width: "330px", height: "40px" }}
                  // onChange={handleChange}
                  // value={aboutInput.degree}
                  name="degree"
                  onBlur={validateInput}
                  >
                  {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>
                </div>

                <div>
                <label >Specilization</label>
                  
                    <select
                      class="footer-header form-select form-select-lg"
                      aria-label=".form-select-lg example"
                      style={{ width: "330px", height: "40px" }}
                      // onChange={handleChange}
                      name="specialization"
                      // value={aboutInput.specialization}
                      onBlur={validateInput}
                    >
                      {options2.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    {error2.specialization && (
                      <span className="text-danger">{error2.specialization}</span>
                    )}
                  
                </div>
                <div>
                  <label>Eduction Type</label>
                  
                    <select
                      class="footer-header form-select form-select-lg"
                      aria-label=".form-select-lg example"
                      name="educationType"
                      style={{ width: "330px", height: "40px" }}
                      // value={aboutInput.educationType}
                      // onChange={handleChange}
                      onBlur={validateInput}
                    >
                      {options3.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    {error2.educationType && (
                      <span className="text-danger">{error2.educationType}</span>
                    )}
                  
                </div>
            </div>
            <div className="myprofile-content-head input-space">
                <div>
                    <label >Start Date*</label>
                    <InputGroup className="">
                    <Form.Control
                        type="date"
                        placeholder="Gender"
                        aria-label="Gender"
                        aria-describedby="basic-addon1"
                        name="startDate"
                        className="footer-header"
                        // onChange={handleChange}
                        // value={aboutInput.startDate}
                        onBlur={validateInput}
                        style={{ width: "330px" }}
                    />
                    </InputGroup>
                    {error2.startDate && (
                    <span className="text-danger">{error2.startDate}</span>
                    )}
                </div>
                <div>
                    <label >End Date*</label>
                    <InputGroup className="">
                    <Form.Control
                        type="date"
                        name="endDate"
                        placeholder="Date of birth"
                        aria-label="Date of birth"
                        aria-describedby="basic-addon1"
                        className="footer-header"
                        // onChange={handleChange}
                        // value={aboutInput.endDate}
                        onBlur={validateInput}
                        style={{ width: "330px" }}
                    />
                    </InputGroup>
                    {error2.endDate && (
                    <span className="text-danger">{error2.endDate}</span>
                    )}
                </div>
                <div>
                <label>History of Arrears</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter History of Arrears"
                    type="number"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                <div style={{marginLeft:"18px"}}>
                    <label >Percentage Obtained*</label>
                    <InputGroup className="footer-header">
                        <Form.Control
                        type="number"
                        placeholder="Enter Percentage in Numeric or Decimel"
                        aria-label="Date of birth"
                        aria-describedby="basic-addon1"
                        className="footer-header"
                        name="percentage"
                        // value={aboutInput.percentage}
                        // onChange={handleChange}
                        onBlur={validateInput}
                        style={{width:"330px"}}
                        />
                    </InputGroup>
                    {error2.percentage && (
                        <span className="text-danger">{error2.percentage}</span>
                    )}
                </div>
            </div>
            <h3>Upload Mark Sheet (optional)</h3>
            <input type="file"></input>
            </> :
            <> <Checkbox onClick={()=>setDisableDiploma(true)}><Plus>+</Plus></Checkbox> </> }

            <h2 style={{color:"black"}}>Under Graduation</h2>
            {disableug ? 
            <>
            <> <Checkbox onClick={()=>setDisableUg(false)}><Plus>-</Plus></Checkbox> </> 
            <div className="myprofile-content-head input-wrapper input-wrapper2">
                  <div>
                    <label>School / University</label>
                    <div>
                      <select
                      class="footer-header"
                      style={{ width: "330px", height: "40px" }}
                      // onChange={handleChange}
                      name="university"
                      // value={aboutInput.university}
                      onBlur={validateInput}
                      // autoFocus={true}
                      >
                      {optionsTwo.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                      </select>
                      {error2.university && (
                      <span className="text-danger">{error2.university}</span>
                      )}
                    </div>
                  </div>

                <div>
                <label>Degree</label>                  
                  <select
                  class="footer-header"
                  style={{ width: "330px", height: "40px" }}
                  // onChange={handleChange}
                  // value={aboutInput.degree}
                  name="degree"
                  onBlur={validateInput}
                  >
                  {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                  </select>
                </div>

                <div>
                <label >Specilization</label>
                  
                    <select
                      class="footer-header form-select form-select-lg"
                      aria-label=".form-select-lg example"
                      style={{ width: "330px", height: "40px" }}
                      // onChange={handleChange}
                      name="specialization"
                      // value={aboutInput.specialization}
                      onBlur={validateInput}
                    >
                      {options2.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    {error2.specialization && (
                      <span className="text-danger">{error2.specialization}</span>
                    )}
                  
                </div>
                <div>
                  <label>Eduction Type</label>
                  
                    <select
                      class="footer-header form-select form-select-lg"
                      aria-label=".form-select-lg example"
                      name="educationType"
                      style={{ width: "330px", height: "40px" }}
                      // value={aboutInput.educationType}
                      // onChange={handleChange}
                      onBlur={validateInput}
                    >
                      {options3.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    {error2.educationType && (
                      <span className="text-danger">{error2.educationType}</span>
                    )}
                  
                </div>
            </div>
            <div className="myprofile-content-head input-space">
                <div>
                    <label >Start Date*</label>
                    <InputGroup className="">
                    <Form.Control
                        type="date"
                        placeholder="Gender"
                        aria-label="Gender"
                        aria-describedby="basic-addon1"
                        name="startDate"
                        className="footer-header"
                        // onChange={handleChange}
                        // value={aboutInput.startDate}
                        onBlur={validateInput}
                        style={{ width: "330px" }}
                    />
                    </InputGroup>
                    {error2.startDate && (
                    <span className="text-danger">{error2.startDate}</span>
                    )}
                </div>
                <div>
                    <label >End Date*</label>
                    <InputGroup className="">
                    <Form.Control
                        type="date"
                        name="endDate"
                        placeholder="Date of birth"
                        aria-label="Date of birth"
                        aria-describedby="basic-addon1"
                        className="footer-header"
                        // onChange={handleChange}
                        // value={aboutInput.endDate}
                        onBlur={validateInput}
                        style={{ width: "330px" }}
                    />
                    </InputGroup>
                    {error2.endDate && (
                    <span className="text-danger">{error2.endDate}</span>
                    )}
                </div>
                <div>
                <label>History of Arrears</label>
                    <InputGroup className="mb-3">
                    <Form.Control
                    placeholder="Enter History of Arrears"
                    type="number"
                    value={input.email}
                    name="email"
                    aria-label="Gender"
                    aria-describedby="basic-addon1"
                    style={{ width: "330px" }}
                    />
                    </InputGroup>
                </div>
                <div style={{marginLeft:"18px"}}>
                    <label >Percentage Obtained*</label>
                    <InputGroup className="footer-header">
                        <Form.Control
                        type="number"
                        placeholder="Enter Percentage in Numeric or Decimel"
                        aria-label="Date of birth"
                        aria-describedby="basic-addon1"
                        className="footer-header"
                        name="percentage"
                        // value={aboutInput.percentage}
                        // onChange={handleChange}
                        onBlur={validateInput}
                        style={{ width: "330px" }}
                        />
                    </InputGroup>
                    {error2.percentage && (
                        <span className="text-danger">{error2.percentage}</span>
                    )}
                </div>
            </div>
            <h3>Upload Mark Sheet (optional)</h3>
            <input type="file"></input>
            </> :
            <> <Checkbox onClick={()=>setDisableUg(true)}><Plus>+</Plus></Checkbox> </> }

            <div className="about-end">
            <h4 >Cancel</h4>
            <button className="button2" onClick={()=>history.push("/portal/admissionformpreview")}>
                Next
            </button>
            </div>
        </PageWrapper>
        </MainLayout>
        </div>
    )
}

export default AdmissionForm;