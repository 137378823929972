import MyAssociatesList from "./myAssociatesList";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import DesktopHeader from "../desktopHeader";
const Header = styled.div`
  display: flex;
  margin: 30px auto;
  text-align: center;
  gap: 8px;
  width: 270px;
  background: #8653b6;
  justify-content: space-around;
  border-radius:16px;
  padding:10px;
`;

const Heading = styled.p`
  color: #ffffff;
  font-size: ${(props) => (props.selected ? "18px" : "14px")};
  cursor: pointer;
`;
const AssociatesAndFollowers = ({ view }) => {
  const { id } = useParams();
  const location = useLocation();
  console.log("location", location);
  const [selected, setSelected] = useState(location?.state?.data);
  // eslint-disable-next-line no-unused-vars
  const [associates, setAssociates] = useState(0);

  const [asscdata, setAsscData] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [followingData, setFollowingData] = useState([]);
  console.log("selected", selected);
  const asscCount = (val) => {
    setAssociates(val);
  };
  

  const getMyFollowing = async () => {
    await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/followers/details/${id}`)
      .then((res) => {
        console.log("followers", res?.data);
        setFollowersData(
          res?.data?.followersData?.filter((e) => e.status === true),
        );
        setFollowingData(
          res?.data?.followingData?.filter((e) => e.status === true),
        );
      });
  };

  const getMyAssc = async () => {
    await axios
      .get(`${process.env.PUBLIC_URL}/xakal/get/associates/${id}`)
      .then((res) => {
        console.log("associates", res?.data);
        const connected = res?.data?.filter(
          (e) => e?.connectionStatus === "ASSOCIATE",
        );
        setAsscData(connected);
      });
  };

  const findChanges = () => {
    getMyAssc();
    getMyFollowing();
  };

  useEffect(() => {
    getMyAssc();
    getMyFollowing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div >
      <DesktopHeader />
      <Header>
        <Heading
          selected={selected === "associates"}
          onClick={() => setSelected("associates")}
        >
          <span>Associates</span>
          <br />
          <span>{asscdata.length}</span>
        </Heading>
        <Heading
          selected={selected === "followers"}
          onClick={() => setSelected("followers")}
        >
          <span>Followers</span>
          <br />
          <span>{followersData.length}</span>
        </Heading>
        <Heading
          selected={selected === "following"}
          onClick={() => setSelected("following")}
        >
          <span>Following</span>
          <br />
          <span>{followingData.length}</span>
        </Heading>
      </Header>
      <div>
        {selected === "associates" ? (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"associate"}
            actualData={asscdata}
            findChanges={findChanges}
          />
        ) : null}
        {selected === "followers" ? (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"followers"}
            actualData={followersData}
            findChanges={findChanges}
          />
        ) : null}
        {selected === "following" ? (
          <MyAssociatesList
            userId={id}
            setCount={asscCount}
            asscdata={asscdata}
            followersData={followersData}
            followingData={followingData}
            type={"following"}
            actualData={followingData}
            findChanges={findChanges}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AssociatesAndFollowers;
