import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "../../images/CloseIcon.svg";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Addcircle from "../../images/Add Circle.svg";
import Delete from "../../images/DelIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions } from "@mui/material";
import QuestionImg from "../../images/solar_question-square-bold-duotone.svg";
import Spinner from "react-bootstrap/Spinner";
import Calender from "../../images/Calendar.svg";
const PageWrapper = styled.div`
  background: white;
  border-radius: 10px;
  padding: 0px;
  margin: 0rem 0rem;

  // @media (max-width: 500px) {
  //   padding: 1.5rem 1.5rem 1.5rem 1rem;
  //   margin: 2rem 0;
  // }
`;

const Label = styled.div`
  font-size: 14px;
  font-family: Inter-Medium, sans-serif;
  color: #362a41;
  font-weight: normal;
  margin: 10px 0 10px 0;
`;
const Starred = styled.div`
  color: #4f4953;
  margin-top: 5px;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
`;

const DateWrappers = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 10px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  button {
    width: 396px;
    height: 40px;
    margin-top: 10px;
    padding: 7px, 142px, 9px, 131px;
    border-radius: 8px;
    border: 0.5px solid rgba(221, 223, 228, 0.5);
    font-family: Inter-Medium, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
const CalenderWrapper = styled.div`
  // position: absolute;
  // bottom:10px;
  // left:20px;
  // margin:0px 0px -30px 30px;
  font-faily: Inter-Medium, sans-serif;
  font-size: 12px;
`;

const PageWrapper2 = styled.div`
  background: white;
  // opacity:0.8;
  border-radius: 10px;
  padding: 0px;
  margin: 0rem 0 0rem 0;
  z-index: 2;
  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const JobTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;

const Wrapper2 = styled.div`
    display:flex;
    gap:10px;
    position:relative:
    // top:-30px;
`;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #36a47c;
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
`;

const JobInfo = ({
  match,
  jobId,
  previewJobId,
  previewSaveJobId,
  goToJobOne,
  closePopups,
  close,
  openSaves,
  goToOnePart,
  jobArchivedId,
  jobArchivedPreviewId,
}) => {
  const calculateInitialStarredCount = (questions) => {
    return questions.filter((question) => question.isStarred).length;
  };
  // eslint-disable-next-line no-unused-vars
  const [enable, setEnable] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openSave, setOpenSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Do something with the selected date
  };
  const [starredQuestionsCount, setStarredQuestionsCount] = useState(0);

  const [input, setInput] = useState({
    jobDesc: "",
    applyThrough: "",
    urls: "",
    resume: "",
  });
  const [error, setError] = useState({
    jobDesc: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  const [jobIds, setJobId] = useState();
  const updateOne = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${id}`)
      .then((response) => {
        setJobId(response.data.updateJob._id);
        if (response.data.updateJob.questionList) {
          const initialStarredCount = calculateInitialStarredCount(
            response.data.updateJob.questionList
          );
          setStarredQuestionsCount(initialStarredCount);
        }
        setInput({
          ...input,
          userId: response.data.updateJob._id,
          jobDesc: response.data.updateJob.jobDesc,
          applyThrough: response.data.updateJob.applyThrough,
          urls: response.data.updateJob.urls,
        });
        if (
          response.data.updateJob.questionList &&
          response.data.updateJob.questionList.length > 0
        ) {
          setInputList(response.data.updateJob.questionList);
          setEnable2(true);
        } else {
          setEnable2(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateOneArchived = (id) => {
    axios
      .get(`${process.env.PUBLIC_URL}/xakal/get-manage-job-detail/${id}`)
      .then((response) => {
        setJobId(response.data.updateJob._id);
        if (response.data.updateJob.questionList) {
          const initialStarredCount = calculateInitialStarredCount(
            response.data.updateJob.questionList
          );
          setStarredQuestionsCount(initialStarredCount);
        }
        setInput({
          ...input,
          userId: response.data.updateJob._id,
          jobDesc: response.data.updateJob.jobDesc,
          applyThrough: response.data.updateJob.applyThrough,
          urls: response.data.updateJob.urls,
        });
        if (
          response.data.updateJob.questionList &&
          response.data.updateJob.questionList.length > 0
        ) {
          setInputList(response.data.updateJob.questionList);
          setEnable2(true);
        } else {
          setEnable2(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (jobId) {
      updateOne(jobId);
    } else if (jobArchivedId) {
      updateOneArchived(jobArchivedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, jobArchivedId]);

  const handlePostSubmit = () => {
    setLoading(true);

    // Format the date as 'dd-mm-yyyy'
    const formattedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
      : null;

    const data = {
      jobDesc: input.jobDesc,
      applyThrough: input.applyThrough,
      urls: input.urls,
      resume: input.resume,
      scheduleDate: formattedDate,
    };

    // Check if jobDesc is empty and set the error message
    if (!input || !input.jobDesc || input.jobDesc.trim() === "") {
      setError({ jobDesc: "Please Enter Job description" });
      setLoading(false);
      return; // Exit the function early if there is an error
    }

    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-manage-job/${jobId}`, data)
      .then((response) => {
        const updateId = response.data.updateJob._id;
        previewJobId(updateId.toString());
        setSelectedDate(null);
        setInput({ jobDesc: "" });
        handleQuestion();
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handlePostSubmitArchived = () => {
    setLoading(true);

    // Format the date as 'dd-mm-yyyy'
    const formattedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
      : null;

    const data = {
      jobDesc: input.jobDesc,
      applyThrough: input.applyThrough,
      urls: input.urls,
      resume: input.resume,
      scheduleDate: formattedDate,
    };

    // Check if jobDesc is empty and set the error message
    if (!input || !input.jobDesc || input.jobDesc.trim() === "") {
      setError({ jobDesc: "Please Enter Job description" });
      setLoading(false);
      return; // Exit the function early if there is an error
    }

    axios
      .put(
        `${process.env.PUBLIC_URL}/xakal/get-update-archived/${jobArchivedId}`,
        data
      )
      .then((response) => {
        const updateId = response.data.updateJob._id;
        jobArchivedPreviewId(updateId.toString());
        setSelectedDate(null);
        setInput({ jobDesc: "" });
        handleQuestion();
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSave = () => {
    // Format the date as 'dd-mm-yyyy'
    const formattedDate = selectedDate
      ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
      : null;

    const data = {
      jobDesc: input.jobDesc,
      applyThrough: input.applyThrough,
      urls: input.urls,
      resume: input.resume,
      scheduleDate: formattedDate,
    };

    // Check if jobDesc is empty and set the error message
    if (!input || !input.jobDesc || input.jobDesc.trim() === "") {
      setError({ jobDesc: "Please Enter Job description" });
      setLoading(false);
      return; // Exit the function early if there is an error
    }

    axios
      .put(`${process.env.PUBLIC_URL}/xakal/update-post-draft/${jobId}`, data)
      .then((response) => {
        close();
        openSaves();
        setSelectedDate(null);
        handleQuestion();
      })
      .catch((error) => {
        console.error("Error updating item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [inputList, setInputList] = useState([
    { questionName: "", isMandatory: false, isStarred: false },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const [error2, setError2] = useState(null);
  // handle click event of the Add button
  const handleAddClick = () => {
    if (inputList.length < 25) {
      setInputList([
        ...inputList,
        { questionName: "", isMandatory: false, isStarred: false },
      ]);
    } else {
      // You can display an error message or handle the situation as needed
      setError2("You have reached the maximum limit of 25 questions.");
    }
  };
  const handleCheckboxChange = (e, index) => {
    const { name, checked } = e.target;
    const list = [...inputList];
    list[index][name] = checked;
    setInputList(list);
  };
  const handleCheckboxChanges = (e, i) => {
    const { name, checked } = e.target;
    const updatedInputList = [...inputList];

    if (name === "isStarred" && checked) {
      // Check if the limit of 5 starred questions has been reached
      if (starredQuestionsCount >= 5) {
        // Prevent further checkboxes from being checked
        return;
      }
      // Increment the starred questions count
      setStarredQuestionsCount(starredQuestionsCount + 1);
    } else if (name === "isStarred" && !checked) {
      // Decrement the starred questions count when unchecking
      setStarredQuestionsCount(starredQuestionsCount - 1);
    }

    updatedInputList[i][name] = checked;
    setInputList(updatedInputList);
  };

  const handleAddQuestionsClick = (id) => {
    setEnable2(true);
  };
  const handleQuestion = () => {
    if (jobArchivedId) {
      const data = {
        jobManageId: jobArchivedId,
        questionList: inputList,
      };
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/create-question/${jobArchivedId}`,
          data
        )
        .then((response) => {
          if (response && response.data) {
            // setEnable2(false);
            setInputList([...inputList]);
          }
        })
        .catch((erroe) => error.error);
    } else {
      const data = {
        jobManageId: input.userId,
        questionList: inputList,
      };
      axios
        .put(
          `${process.env.PUBLIC_URL}/xakal/create-question/${input.userId}`,
          data
        )
        .then((response) => {
          if (response && response.data) {
            // setEnable2(false);
            setInputList([...inputList]);
          }
        })
        .catch((erroe) => error.error);
    }
  };

  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
    <div className="custom-datepicker-header">
      <button onClick={decreaseMonth}>{"<"}</button>
      <span>{date.toLocaleDateString()}</span>
      <button onClick={increaseMonth}>{">"}</button>
      <div style={{ display: "flex", gap: "20px", left: "10px" }}>
        <button onClick={() => setOpen(false)} style={{ fontSize: "20px" }}>
          Back
        </button>
      </div>
    </div>
  );

  var currentDate = new Date();
  var minDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  var maxDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 20
  );

  const [textAreaCount, setTextAreaCount] = useState(0);
  const quillRef = useRef();
  const handleJobDescChange = (content, delta, source, editor) => {
    if (content.length <= 10000) {
      setInput({ ...input, jobDesc: content });
      setTextAreaCount(content.length);
      setError({ jobDesc: "" });
    } else {
      // If the input exceeds the limit, you may want to prevent further input or notify the user.
      // Additionally, you can handle the case where a user tries to paste content that exceeds the limit.
    }
  };

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    if (quill) {
      const updateTextAreaCount = () => {
        setTextAreaCount(quill.getLength());
      };
      quill.on("text-change", updateTextAreaCount);
      return () => {
        quill.off("text-change", updateTextAreaCount);
      };
    }
  }, []);
  return (
    <div>
      <ToastContainer />

      {!enable ? (
        <>
          <div className="modalHeader mheader">
            <div className="mTitle">Job Information</div>
            <img src={CloseIcon} alt="img" onClick={() => setOpenSave(true)} />
          </div>
          <div className="modalBody" style={{ paddingTop: "0px" }}>
            <PageWrapper className={open ? "job-info-wrap" : "job-info-wraps"}>
              <div className="modalBody" style={{ paddingTop: "0px" }}></div>
              <Label style={{ marginTop: "0px" }} className="label-name">
                Description<span>*</span>
              </Label>

              <ReactQuill
                theme="snow" // You can choose different themes like 'snow', 'bubble', etc.
                value={input.jobDesc}
                onChange={handleJobDescChange}
                placeholder="Type here"
                style={{
                  fontSize: "12px",
                  fontFamily: "Inter-Medium,sans-serif",
                  background: "#F6F6F6",
                  border: "none",
                  borderRadius: "8px",
                  marginBottom: "5px",
                }}
                ref={quillRef}
              />
              <div>
                <p
                  style={{
                    fontFamily: "Inter-Medium,sans-serif",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  {`${Math.max(textAreaCount - 1, 0)}/10000`}
                </p>
              </div>
              {error.jobDesc && (
                <span className="text-danger f12">{error.jobDesc}</span>
              )}

              <div>
                <Label style={{ margin: "20px 0px 30px 0px" }}>
                  Questions to be asked to the candidate
                </Label>
                {enable2 ? (
                  <PageWrapper2>
                    {inputList.map((input, i) => {
                      return (
                        <div className="">
                          <Label>Question </Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="questionName"
                              placeholder="Type here"
                              value={input.questionName}
                              onChange={(e) => handleInputChange(e, i)}
                              style={{ backgroundColor: "#F4F4F6" }}
                              className="footer-header font"
                            />
                          </InputGroup>

                          <input />

                          <Wrapper2>
                            <input
                              type="checkbox"
                              name="isMandatory"
                              checked={input.isMandatory}
                              onChange={(e) => handleCheckboxChange(e, i)}
                              style={{ width: "24px", height: "24px" }}
                            />
                            <Starred>Mandatory</Starred>
                            <input
                              type="checkbox"
                              name="isStarred"
                              checked={input.isStarred}
                              onChange={(e) => handleCheckboxChanges(e, i)}
                              style={{ width: "24px" }}
                            />
                            <Starred>Starred Question</Starred>
                          </Wrapper2>

                          <DateWrappers>
                            {inputList.length !== 1 && (
                              <button
                                className=""
                                onClick={() => handleRemoveClick(i)}
                              >
                                <img
                                  src={Delete}
                                  alt="img"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid grey",
                                    borderRadius: "8px",
                                    padding: "5px",
                                    marginTop: "-60px",
                                  }}
                                />
                              </button>
                            )}
                          </DateWrappers>
                          <hr />
                          {inputList.length - 1 === i && (
                            <ButtonWrapper>
                              <button
                                className=""
                                style={{ marginBottom: "20px" }}
                                onClick={handleAddClick}
                              >
                                <img src={Addcircle} alt="img" />
                                Add Question
                              </button>
                              {/* <button className="button5" onClick={handleQuestion}>Submit</button> */}
                            </ButtonWrapper>
                          )}
                        </div>
                      );
                    })}
                    <Starred>
                      Starred Questions: {starredQuestionsCount}/{5}
                    </Starred>
                    {error2 && <span className="text-danger">{error2}</span>}
                  </PageWrapper2>
                ) : (
                  <ButtonWrapper>
                    <button
                      className=""
                      onClick={() => handleAddQuestionsClick(input.userId)}
                    >
                      <img src={Addcircle} alt="img" />
                      Add Question
                    </button>
                  </ButtonWrapper>
                )}
              </div>

              <div style={{ marginTop: "30px" }}>
                <Label>Apply Through</Label>
                <div>
                  <select
                    className="footer-header  mb-4 font"
                    style={{
                      width: "100%",
                      height: "48px",
                      background: "#fff",
                      border: "1px solid #c6c6c6",
                      borderRadius: "12px",
                      padding: "10px",
                    }}
                    value={input.applyThrough}
                    name="applyThrough"
                    onChange={handleChange}
                    placeholder="Type / Search"
                    // value={aboutInput.university}
                  >
                    <option value="Xakal" className="select-drop">
                      Xakal
                    </option>
                    <option value="companySite">Company Website</option>
                  </select>
                </div>
                {input.applyThrough === "companySite" && (
                  <>
                    <Label>URL</Label>
                    <InputGroup className="mb-4">
                      <Form.Control
                        placeholder="Enter your URL"
                        aria-label="Job Description"
                        name="urls"
                        onChange={handleChange}
                        value={input.urls}
                        aria-describedby="basic-addon1"
                        className="font"
                        style={{
                          borderRadius: "8px",
                          background: "rgba(54, 42, 65, 0.04)",
                          height: "48px",
                        }}
                      />
                    </InputGroup>
                  </>
                )}
              </div>
            </PageWrapper>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="modalFooter modalfooter">
        <CalenderWrapper>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm aa"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            open={open}
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            renderCustomHeader={renderCustomHeader}
            minDate={minDate}
            maxDate={maxDate}
            readonly={true}
          />
        </CalenderWrapper>

        <div className={open ? "schedule-foot" : "schedule-footer"}>
          <button
            className="button5"
            style={{ width: "160px" }}
            onClick={() => setOpen(true)}
          >
            <img
              src={Calender}
              alt="img"
              style={{ width: "30px", marginTop: "-8px" }}
            />
            Schedule
          </button>
          {jobIds ? (
            <button className="button5" onClick={() => goToOnePart(jobIds)}>
              Back
            </button>
          ) : (
            <button className="button5" onClick={() => goToOnePart(jobId)}>
              Back
            </button>
          )}
          {jobArchivedId ? (
            <button className="button3" onClick={handlePostSubmitArchived}>
              {loading ? <Spinner /> : "Next"}
            </button>
          ) : (
            <button className="button3" onClick={handlePostSubmit}>
              {loading ? <Spinner /> : "Next"}
            </button>
          )}
        </div>
      </div>

      <Dialog
        open={openSave}
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "#F4F4F6",
            padding: "5px 8px",
            overflow: "hidden",
          },
        }}
        halfWidth
        maxWidth="md"
        className="def"
      >
        <DialogContent
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: " 8px",
              background: "white",
            }}
          >
            <img
              src={QuestionImg}
              style={{ marginTop: "2px", width: "24px", height: "24px" }}
              alt="img"
            />
          </div>
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            Save Changes As Draft ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => {
                  setOpenSave(false);
                  openSaves();
                }}
                style={{ cursor: "pointer" }}
              >
                No
              </Button4>
              <Button6
                onClick={() => {
                  handleSave();
                }}
                style={{ cursor: "pointer" }}
              >
                Yes, Save
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default JobInfo;
