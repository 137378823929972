/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";

const AssociatesCards = styled.div`
  display: flex;
  justify-content:space-around;
  margin-left:10px;
`;
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  width: auto;
  gap: 24px;
`;

const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family:
    Inter-Medium,
    sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyAssociatesList = ({
  userId,
  asscdata,
  followingData,
  actualData,
  findChanges,
}) => {
  const history = useHistory();

  const findAssociateStatus = (id) => {
    console.log("ass id", id);
    console.log("asscdata", asscdata);
    const status = asscdata?.find((e) => e.associateId === id);
    console.log("status?.connectionStatus", status?.connectionStatus);
    if (status) return status?.connectionStatus;
    else return "NOT_FOUND";
  };

  const findFollowingStatus = (id) => {
    const status = followingData?.find((e) => e.followerId === id);
    if (status && status?.status === true) return "EXISTS";
    else return "NOT_FOUND";
  };

  const sentAssociateInvitation = async (associateId) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/associate`, {
        appUserId: userId,
        associateId: associateId,
        connectionStatus: "PENDING",
      })
      .then((res) => {
        console.log("added to associate", res);
        findChanges();
      });
  };

  const updateInvitation = async (associateId) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: associateId,
        associateId: userId,
        connectionStatus: "REJECTED",
      })
      .then((res) => {
        console.log("update Invitation", res?.data);
        findChanges();
      });
  };

  const updateFollowStatus = async (status, associateId) => {
    const body = {
      appUserId: userId,
      followerId: associateId,
      status: status,
    };
    await axios
      .post(`${process.env.PUBLIC_URL}/xakal/add/follower`, body)
      .then((res) => {
        console.log("res", res?.data);
        findChanges();
      });
  };
  return (
    <div>
      <AssociatesCards>
        {actualData?.map((invitation, idx) => (
          <InvitationCard key={idx}>
            <Img src={invitation?.details?.avatar} alt="" />
            <DetailsWrapper>
              <Title>
                {invitation?.details?.firstName +
                  " " +
                  invitation?.details?.lastName}
              </Title>
              <a
                onClick={() =>
                  history.push(
                    "/portal/otherProfile/" +
                      invitation?.details?.userId,
                  )
                }
                style={{cursor:"pointer"}}
              >
                View
              </a>
            </DetailsWrapper>
            <div style={{ display: "flex", height: "40px", gap: "8px" }}>
              {findAssociateStatus(invitation?.details?.userId) ===
              "PENDING" ? (
                <div className="social-wrapper">
                  <div className="social-btn">Pending</div>
                  <div className="social-btn">
                    <QueryBuilderOutlinedIcon />
                  </div>
                </div>
              ) : null}

              {findAssociateStatus(invitation?.details?.userId) ===
              "ASSOCIATE" ? (
                <div
                  className="social-wrapper"
                  onClick={() => updateInvitation(invitation?.details?.userId)}
                >
                  <div className="social-btn">Associate</div>
                  <div className="social-btn">
                    <HandshakeOutlinedIcon />
                  </div>
                </div>
              ) : null}

              {findAssociateStatus(invitation?.details?.userId) !==
                "ASSOCIATE" &&
              findAssociateStatus(invitation?.details?.userId) !== "PENDING" ? (
                <div
                  className="social-wrapper fol-wrap"
                  onClick={() =>
                    sentAssociateInvitation(invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Associate</div>
                  <div className="social-btn">
                    <AddOutlinedIcon />
                  </div>
                </div>
              ) : null}

              {findFollowingStatus(invitation?.details?.userId) === "EXISTS" ? (
                <div
                  className="social-wrapper fol-wrap"
                  onClick={() =>
                    updateFollowStatus(false, invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Following</div>
                  <div className="social-btn">
                    <HandshakeOutlinedIcon />
                  </div>
                </div>
              ) : (
                <div
                  className="social-wrapper"
                  onClick={() =>
                    updateFollowStatus(true, invitation?.details?.userId)
                  }
                >
                  <div className="social-btn">Follow</div>
                  <div className="social-btn">
                    <AddOutlinedIcon />
                  </div>
                </div>
              )}
            </div>
          </InvitationCard>
        ))}
      </AssociatesCards>
    </div>
  );
};

export default MyAssociatesList;
